import React, { Fragment, useEffect, useState } from "react";
import { Form, useFormikContext } from "formik";
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";
import Car from "@material-ui/icons/TimeToLeave";
import Motorcycle from "@material-ui/icons/TwoWheeler";
import Truck from "@material-ui/icons/LocalShipping";
import { Row } from "@components/Row";
import {
  Manufacturer,
  Model,
} from "../../../../../client/manufacturer/Manufacturer";
import StyleSheet from "@pages/Vehicles/StyleSheet";
import { Button } from "@components/Button";
import { ROUTES } from "@config/routesConfig";
import { CircularProgress, TextField, Typography } from "@material-ui/core";
import { useUserContext } from "@hooks/UserContext";
import { useIoCContext } from "@hooks/IoCContext";
import { ManufacturerClient } from "../../../../../client/manufacturer/ManufacturerClient";
import { Types } from "@ioc/types";
import InputMask from "react-input-mask";
import { VehicleDataPayload } from "../../../../../client/vehicle/Vehicle";

const VehicleDataForm: React.FC = () => {
  const styleSheet = StyleSheet();
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const {
    values,
    errors,
    setFieldValue,
  } = useFormikContext<VehicleDataPayload>();
  const [editing, setEditing] = useState(false);
  const [selectedVehicleType, setSelectedVehicleType] = useState("CAR");
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);
  const [manufacturer, setManufacturer] = useState<Manufacturer | null>(null);
  const [
    manufacturerDescription,
    setManufacturerDescription,
  ] = useState<string>("");
  const [loadingManufacturers, setLoadingManufacturers] = useState(false);

  const [models, setModels] = useState<Model[]>([]);
  const [model, setModel] = useState<Model | null>(null);
  const [modelDescription, setModelDescription] = useState<string>("");
  const [loadingModels, setLoadingModels] = useState(false);

  const manufacturerClient = iocContext.serviceContainer.get<ManufacturerClient>(
    Types.Manufacturer
  );

  const handleModelChange = (selectedValue: Model | null) => {
    setModel(selectedValue);
    setFieldValue("modelId", selectedValue?.id);
    setModelDescription("");
  };

  const handleManufacturerChange = (selectedValue: Manufacturer | null) => {
    setManufacturer(selectedValue);
    setManufacturerDescription("");
    setFieldValue("manufacturerId", selectedValue?.id);
    handleModelChange(null);
  };

  const handleVehicleTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    selectedType: string
  ) => {
    setSelectedVehicleType(selectedType);
    setManufacturers([]);
    handleManufacturerChange(null);
  };

  const fetchManufacturers = () => {
    if (!selectedCompany) return;
    setLoadingManufacturers(true);
    manufacturerClient
      .findManufacturer(
        selectedCompany.uuid,
        selectedVehicleType,
        manufacturerDescription
      )
      .then((response) => {
        setManufacturers(response);
        setLoadingManufacturers(false);
      })
      .catch((error) => {
        setLoadingManufacturers(false);
      });
  };

  const fetchModels = () => {
    if (!selectedCompany || !manufacturer?.id) return;
    setLoadingModels(true);
    manufacturerClient
      .findModel(selectedCompany.uuid, manufacturer?.id, modelDescription)
      .then((response) => {
        setModels(response);
        setLoadingModels(false);
      })
      .catch((error) => {
        setLoadingModels(false);
      });
  };

  useEffect(fetchManufacturers, [selectedVehicleType, manufacturerDescription]);
  useEffect(fetchModels, [modelDescription]);
  useEffect(() => {
    if (values.id) {
      setEditing(true);
      setSelectedVehicleType(values.manufacturerType || "CAR");
      setManufacturer({
        id: values.manufacturerId,
        description: values.manufacturerDescription,
      });
      setManufacturerDescription(values.manufacturerDescription || "");
      setModel({
        id: values.modelId,
        description: values.modelDescription,
      });
      setModelDescription(values.modelDescription || "");
    }
  }, [values]);

  return (
    <Form noValidate>
      <Row>
        <ToggleButtonGroup
          color="primary"
          value={selectedVehicleType}
          exclusive
          onChange={handleVehicleTypeChange}
        >
          <ToggleButton
            className={styleSheet.toggleButton}
            value="CAR"
            disabled={editing}
          >
            <Car className={styleSheet.toggleButtonImage} />
            Carro
          </ToggleButton>
          <ToggleButton
            className={styleSheet.toggleButton}
            value="MOTORCYCLE"
            disabled={editing}
          >
            <Motorcycle className={styleSheet.toggleButtonImage} />
            Motocicleta
          </ToggleButton>
          <ToggleButton
            className={styleSheet.toggleButton}
            value="TRUCK"
            disabled={editing}
          >
            <Truck className={styleSheet.toggleButtonImage} />
            Caminhão
          </ToggleButton>
        </ToggleButtonGroup>
      </Row>
      <Row>
        <Autocomplete
          disabled={editing}
          blurOnSelect
          loading={loadingManufacturers}
          loadingText="Carregando..."
          filterOptions={(x) => x}
          options={manufacturers}
          getOptionLabel={(option?: Manufacturer) => option?.description || ""}
          getOptionSelected={(option, value) =>
            option.description === value.description
          }
          value={manufacturer}
          size="small"
          style={{ width: "50%" }}
          noOptionsText={
            manufacturer?.description || "Nenhum fabricante selecionado"
          }
          onChange={(event, value) => {
            if (value) {
              handleManufacturerChange(value as Manufacturer);
            }
          }}
          onInputChange={(event, newInputValue) => {
            setManufacturerDescription(newInputValue);
          }}
          renderOption={(option?: Manufacturer) => {
            return (
              <Typography className={styleSheet.autoCompleteText}>
                {option?.description || ""}
              </Typography>
            );
          }}
          renderInput={(params) => (
            <TextField
              name="manufacturerId"
              label="Fabricante"
              variant="outlined"
              size="small"
              error={!!errors.manufacturerId}
              helperText={errors.manufacturerId}
              required
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {loadingManufacturers ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
          closeIcon={null}
        />
        <div style={{ width: 6 }} />
        <Autocomplete
          disabled={editing}
          blurOnSelect
          loading={loadingModels}
          loadingText="Carregando..."
          filterOptions={(x) => x}
          options={models}
          getOptionLabel={(option?: Model) => option?.description || ""}
          getOptionSelected={(option, value) =>
            option.description === value.description
          }
          value={model}
          size="small"
          style={{ width: "50%" }}
          noOptionsText={model?.description || "Nenhum modelo selecionado"}
          onChange={(event, value) => {
            if (value) {
              handleModelChange(value as Model);
            }
          }}
          onInputChange={(event, newInputValue) => {
            setModelDescription(newInputValue);
          }}
          renderOption={(option?: Model) => {
            return (
              <Typography className={styleSheet.autoCompleteText}>
                {option?.description || ""}
              </Typography>
            );
          }}
          renderInput={(params) => (
            <TextField
              name="modelId"
              label="Modelo"
              variant="outlined"
              size="small"
              error={!!errors.modelId}
              helperText={errors.modelId}
              required
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {loadingModels ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
          closeIcon={null}
        />
      </Row>
      <Row>
        <TextField
          id="plate"
          name="plate"
          style={{ width: "10%" }}
          classes={{ root: styleSheet.textField }}
          required
          label="Placa"
          variant="outlined"
          onChange={(event) =>
            setFieldValue("plate", event.target.value.toUpperCase())
          }
          value={values.plate}
          inputProps={{ maxLength: 8 }}
          error={!!errors.plate}
          helperText={errors.plate}
          size="small"
        />
        <InputMask
          id="renavam"
          name="renavam"
          onChange={(event) => setFieldValue("renavam", event.target.value)}
          required
          value={values.renavam}
          mask="99999999999"
          maskChar={null}
        >
          {() => (
            <TextField
              style={{ width: "40%" }}
              classes={{ root: styleSheet.textField }}
              label="Renavam"
              variant="outlined"
              required
              error={!!errors.renavam}
              helperText={errors.renavam}
              size="small"
            />
          )}
        </InputMask>
        <TextField
          id="chassi"
          name="chassi"
          style={{ width: "50%" }}
          classes={{ root: styleSheet.textField }}
          required
          label="Chassi"
          variant="outlined"
          onChange={(event) =>
            setFieldValue("chassi", event.target.value.toUpperCase())
          }
          value={values.chassi}
          inputProps={{ maxLength: 17 }}
          error={!!errors.chassi}
          helperText={errors.chassi}
          size="small"
        />
      </Row>
      <Row>
        <TextField
          id="color"
          name="color"
          style={{ width: "20%" }}
          classes={{ root: styleSheet.textField }}
          label="Cor"
          variant="outlined"
          onChange={(event) => setFieldValue("color", event.target.value)}
          value={values.color}
          inputProps={{ maxLength: 256 }}
          size="small"
        />
        <InputMask
          id="year"
          name="year"
          onChange={(event) => setFieldValue("year", event.target.value)}
          value={values.year}
          mask="9999/9999"
          maskChar={null}
        >
          {() => (
            <TextField
              style={{ width: "20%" }}
              classes={{ root: styleSheet.textField }}
              label="Ano Fabricação/Modelo"
              variant="outlined"
              size="small"
            />
          )}
        </InputMask>
        <InputMask
          id="fuelCapacity"
          name="fuelCapacity"
          onChange={(event) =>
            setFieldValue("fuelCapacity", event.target.value)
          }
          value={values.fuelCapacity}
          mask="99999"
          maskChar={null}
        >
          {() => (
            <TextField
              style={{ width: "20%" }}
              classes={{ root: styleSheet.textField }}
              label="Capacidade do tanque (Litros)"
              variant="outlined"
              size="small"
            />
          )}
        </InputMask>
        <InputMask
          id="consumption"
          name="consumption"
          onChange={(event) => setFieldValue("consumption", event.target.value)}
          value={values.consumption}
          mask="99999"
          maskChar={null}
        >
          {() => (
            <TextField
              style={{ width: "20%" }}
              classes={{ root: styleSheet.textField }}
              label="Consumo esperado (km/L)"
              variant="outlined"
              size="small"
            />
          )}
        </InputMask>
        <InputMask
          id="mileage"
          name="mileage"
          onChange={(event) => setFieldValue("mileage", event.target.value)}
          value={values.mileage}
          mask="99999999"
          maskChar={null}
        >
          {() => (
            <TextField
              style={{ width: "20%" }}
              classes={{ root: styleSheet.textField }}
              label="Hodômetro (km)"
              variant="outlined"
              size="small"
            />
          )}
        </InputMask>
      </Row>
      <Row align="end">
        <Button to={ROUTES.VEHICLES} color="secondary">
          Voltar
        </Button>
        <div style={{ width: 6 }} />
        <Button color="primary" type="submit">
          Salvar
        </Button>
      </Row>
    </Form>
  );
};

export default VehicleDataForm;
