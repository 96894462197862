import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import {
  ICostCentersFilter,
  IDepartmentsFilter,
  IFilterUsersDTO,
  IFunctionsFilter,
  IStatusFilter,
} from "../dtos/IFilterUsersDTO";
import { IGetFiltersUserService } from "../models/IGetFiltersUserService";

@injectable()
export class GetFiltersUserService implements IGetFiltersUserService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: { companyID: string }): Promise<IFilterUsersDTO> {
    const respFunctions = this.httpInstance.get<IFunctionsFilter[]>(
      `/companies/${data.companyID}/functions`
    );
    const respDepartments = this.httpInstance.get<IDepartmentsFilter[]>(
      `/companies/${data.companyID}/departments`
    );
    const respCostCenters = this.httpInstance.get<ICostCentersFilter[]>(
      `/companies/${data.companyID}/costcenters`
    );

    const [functions, departments, costCenters] = await Promise.all([
      respFunctions,
      respDepartments,
      respCostCenters,
    ]);

    const status: IStatusFilter[] = [
      { id: "1", status: "Ativo" },
      { id: "2", status: "Inativo" },
    ];

    return {
      costCenters,
      departments,
      functions,
      status,
    };
  }
}
