import React, { useEffect, useState } from "react";
import StyleSheet from "@pages/Drivers/StyleSheet";
import { Title } from "@components/Title/Title";
import { DataColumn, DataTable } from "@components/DataTable";
import { useIoCContext } from "@hooks/IoCContext";
import { Types } from "@ioc/types";
import { useUserContext } from "@hooks/UserContext";
import { SearchContainer, Filter } from "@components/SearchContainer";
import { Button } from "@components/Button";
import { ROUTES } from "@config/routesConfig";
import { useLocation } from "react-router-dom";
import Message from "@components/Message";
import { useHistory } from "react-router-dom";
import { DriverClient } from "../../client/DriverClient";
import { SearchDriverInitialValues } from "../../client/model/SearchDriver";

const columns: DataColumn[] = [
  {
    field: "registration",
    headerName: "Matrícula",
    width: 160,
  },
  {
    field: "driver",
    headerName: "Motorista",
  },
  {
    field: "cnh",
    headerName: "CNH",
    width: 140,
  },
  {
    field: "cpf",
    headerName: "CPF",
    width: 140,
    converter: "cpf",
  },
  {
    field: "status",
    headerName: "Situação",
    converter: "status",
    width: 64,
  },
];

const DriversPage: React.FC = () => {
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const location = useLocation();
  const history = useHistory();
  const styleSheet = StyleSheet();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(
    location.state ? !!(location.state as any)["success"] : false
  );
  const [drivers, setDrivers] = useState(SearchDriverInitialValues);
  const [limit] = useState(9);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<Filter[]>([
    {
      label: "Geral",
      field: "query",
      placeholder: "Pesquisar por Motorista, CNH, Documento ou Matrícula",
    },
  ]);

  const driverClient = iocContext.serviceContainer.get<DriverClient>(
    Types.Driver
  );

  const handleEdit = (driverId?: string): void => {
    history.push(ROUTES.DRIVERS_EDIT(driverId));
  };

  const handleRemove = async (driverId?: string) => {
    if (!selectedCompany || !driverId) return;
    setLoading(true);
    await driverClient.remove(selectedCompany.uuid, driverId);
    setSuccess(true);
    fetchDrivers();
  };

  const fetchDrivers = () => {
    if (!selectedCompany) return;
    setLoading(true);
    const query = filters.find((filter) => filter.field === "query");
    const filter = {
      page: page,
      limit: limit,
      q: query?.value,
    };
    driverClient.find(selectedCompany.uuid, filter).then((result) => {
      setLoading(false);
      setDrivers(result);
    });
  };

  const handleFilter = (filtered: Filter[]) => {
    setFilters(filtered);
  };

  useEffect(fetchDrivers, [
    selectedCompany,
    page,
    filters,
    limit,
    driverClient,
  ]);
  return (
    <div className={styleSheet.container}>
      <Title>Motoristas</Title>
      <SearchContainer filters={filters} onFilter={handleFilter}>
        <Button to={ROUTES.DRIVERS_ADD}>Adicionar</Button>
      </SearchContainer>
      <DataTable
        columns={columns}
        rows={drivers.result}
        limit={limit}
        pages={drivers.pages}
        total={drivers.count}
        loading={loading}
        onEdit={handleEdit}
        onRemove={handleRemove}
        editable
        removable
        onPagination={(page) => setPage(page)}
      />
      <Message.Success open={success} onClose={() => setSuccess(false)} />
    </div>
  );
};

export { DriversPage };
