import { DataColumn } from "@components/DataTable";
import { Store } from "../../../../client/Quotation/Quotation";
import { Chip } from "@material-ui/core";
import AccessAlarm from "@material-ui/icons/AccessAlarm";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import Timelapse from "@material-ui/icons/Timelapse";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Block from "@material-ui/icons/Block";
import Cancel from "@material-ui/icons/Cancel";

export const ColumnDefinition: DataColumn[] = [
  {
    field: "store",
    headerName: "Provedor",
    render: (store: Store) => store?.name || "-",
  },
  {
    field: "total",
    headerName: "Valor da Proposta",
    converter: "currency",
    width: 200,
  },
  {
    field: "workingDays",
    headerName: "Prazo",
    width: 200,
    render: (workingDays) => {
      if (!workingDays) {
        return "-";
      }
      if (workingDays === 1) {
        return `${workingDays} dia útil`;
      }
      return `${workingDays} dias úteis`;
    },
  },
  {
    field: "updatedAt",
    headerName: "Ultima Atualização",
    converter: "date",
    width: 160,
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    render: (status: string) => {
      if (status === "PENDING") {
        return (
          <Chip
            icon={<AccessAlarm />}
            label="Pendente"
            color="primary"
            variant="outlined"
          />
        );
      }
      if (status === "READY_TO_APPROVAL") {
        return (
          <Chip
            icon={<AssignmentTurnedIn />}
            label="Disponível"
            color="secondary"
            variant="outlined"
          />
        );
      }
      if (status === "APPROVED") {
        return (
          <Chip
            icon={<CheckCircleOutline style={{ color: "#009900" }} />}
            label="Aprovada"
            style={{ color: "#009900", border: "solid 1px #009900" }}
            variant="outlined"
          />
        );
      }
      if (status === "REJECTED") {
        return (
          <Chip
            icon={<Block style={{ color: "#8B0000" }} />}
            label="Rejeitada"
            style={{ color: "#8B0000", border: "solid 1px #8B0000" }}
            variant="outlined"
          />
        );
      }
      if (status === "IN_EXECUTION") {
        return (
          <Chip
            icon={<Timelapse style={{ color: "#4169E1" }} />}
            label="Em Andamento"
            style={{ color: "#4169E1", border: "solid 1px #4169E1" }}
            variant="outlined"
          />
        );
      }
      if (status === "FINISHED") {
        return (
          <Chip
            icon={<CheckCircle style={{ color: "#006400" }} />}
            label="Finalizada"
            style={{ color: "#006400", border: "solid 1px #006400" }}
            variant="outlined"
          />
        );
      }
      if (status === "CANCELED") {
        return (
          <Chip
            icon={<Cancel style={{ color: "#FF0000" }} />}
            label="Cancelada"
            style={{ color: "#FF0000", border: "solid 1px #FF0000" }}
            variant="outlined"
          />
        );
      }
      return "-";
    },
  },
];
