import keycloakFactory from "@config/keycloak";
import React from "react";
import ReactDOM from "react-dom";
import "reflect-metadata";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

keycloakFactory()
  .then((keycloakUser) => {
    if (keycloakUser) {
      return ReactDOM.render(
        <React.StrictMode>
          <App
            keycloak={keycloakUser.keycloak}
            token={keycloakUser.token}
            userID={keycloakUser.profile.id as string}
            name={keycloakUser.name}
          />
        </React.StrictMode>,
        document.getElementById("root")
      );
    }
  })
  .catch(() => {
    // window.location.href =
    //   process.env.NODE_ENV === "production"
    //     ? appConfig.keycloack.urlLogout.production
    //     : appConfig.keycloack.urlLogout.development;
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
