import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IRechargesByUser } from "../dtos/IRechargesByUser";
import { IListRechargesByUserService } from "../models/IListRechargesByUserService";

@injectable()
export class ListRechargesByUserService implements IListRechargesByUserService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    userID: string;
  }): Promise<IRechargesByUser[]> {
    const resp = await this.httpInstance.get<IRechargesByUser[]>(
      `/companies/${data.companyID}/recharges/byUser/${data.userID}`
    );

    return resp.map((recharge) => {
      return {
        ...recharge,
        transaction_date: new Date(recharge.transaction_date),
      };
    });
  }
}
