import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { ICreateVehicleDTO } from "../dtos/ICreateVehicleDTO";
import { ICreateVehicleService } from "../models/ICreateVehicleService";

@injectable()
export class CreateVehicleService implements ICreateVehicleService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: ICreateVehicleDTO): Promise<void> {
    await this.httpInstance.post("/fleet/vehicle", data);
  }
}
