import { Types } from "@ioc/types";
import { inject, injectable } from "inversify";
import { IHttpService } from "modules/infra/http/models/IHttpService";
import {
  IGetResumePosPaidDTO,
  IGetResumePrePaidDTO,
} from "../dtos/IGetResumeServiceDTO";
import { IGetResumeService } from "../models/IGetResumeService";

@injectable()
export class GetResumeService implements IGetResumeService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async prePaid(data: {
    companyID: string;
  }): Promise<IGetResumePrePaidDTO> {
    return this.httpInstance.get<IGetResumePrePaidDTO>(
      `/companies/${data.companyID}/balance/dashboard`
    );
  }

  public async posPaid(data: {
    companyID: string;
  }): Promise<IGetResumePosPaidDTO> {
    const response = await this.httpInstance.get<IGetResumePosPaidDTO>(
      `/companies/${data.companyID}/dashboard/info`
    );
    return {
      consumed_liter: Number(response.consumed_liter),
      consumed_real: Number(response.consumed_real),
      credited: Number(response.credited),
      remaining: Number(response.remaining),
    };
  }
}
