import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { ISmallerBalanceDTO } from "../dtos/ISmallerBalanceDTO";
import { IListSmallerBalancesService } from "../models/IListSmallerBalancesService";

@injectable()
export class ListSmallerBalancesService implements IListSmallerBalancesService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
  }): Promise<ISmallerBalanceDTO[]> {
    const resp = await this.httpInstance.get<ISmallerBalanceDTO[]>(
      `/v1/companies/${data.companyID}/smaller-balances`
    );

    return resp.map((balance) => {
      const alter = {
        ...balance,
        quantity: Number(balance.quantity),
      };

      alter.User.periodic_credits = Number(alter.User.periodic_credits);

      return alter;
    });
  }
}
