import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IEditDepartmentService } from "../models/IEditDepartmentService";

@injectable()
export class EditDepartmentService implements IEditDepartmentService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    departmentID: string;
    name: string;
  }): Promise<void> {
    await this.httpInstance.put(
      `/companies/${data.companyID}/departments/${data.departmentID}`,
      {
        tx_departamento: data.name,
      }
    );
  }
}
