import { useIoCContext } from "@hooks/IoCContext";
import { useUserContext } from "@hooks/UserContext";
import { Types } from "@ioc/types";
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ISendJustificationTransactionService } from "@modules/company/models/ISendJustificationTransactionService";
import AppError from "@utils/AppError";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import { useInvalidTransactions } from "./InvalidTransactionsContext";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      title: {
        fontSize: "1.6rem",
        fontWeight: typography.fontWeightBold,
        [theme.breakpoints.up("sm")]: {
          fontSize: "2rem",
        },
      },
      dialogPaper: {
        minWidth: "95vw",
        [theme.breakpoints.up("sm")]: {
          minWidth: "45vw",
        },
      },
      divider: {
        backgroundColor: theme.palette.tertiary.background.primary,
      },
      buttons: {
        fontWeight: typography.fontWeightBold,
      },
      cancelButton: {
        color: theme.palette.tertiary.textButtons.primary,
      },
    })
);

const DialogJustification: React.FC = () => {
  const classes = useStyles();
  const {
    openDialogJustification,
    setOpenDialogJustification,
  } = useInvalidTransactions();
  const { enqueueSnackbar } = useSnackbar();

  const iocContext = useIoCContext();
  const { selectedCompany } = useUserContext();

  const sendJustificationService = iocContext.serviceContainer.get<ISendJustificationTransactionService>(
    Types.Company.ISendJustificationTransactionService
  );

  const [justification, setJustification] = useState(
    openDialogJustification.data && openDialogJustification.data.justification
      ? openDialogJustification.data.justification
      : ""
  );
  const [sendLoading, setSendLoading] = useState(false);

  const sendJustification = useCallback(async () => {
    if (!selectedCompany) return;
    if (!openDialogJustification.data) return;
    try {
      setSendLoading(true);
      await sendJustificationService.execute({
        companyID: selectedCompany.uuid,
        justification_for_release: justification,
        release_issuer_id: openDialogJustification.data.release_issuer_id,
        idTransaction: openDialogJustification.data.idTransaction,
      });
      setOpenDialogJustification({ open: false, data: null });
      enqueueSnackbar("Justificativa enviada com sucesso", {
        variant: "success",
      });
    } catch (error) {
      if (error instanceof AppError) {
        return enqueueSnackbar(error.message, { variant: error.variant });
      }
      enqueueSnackbar("Ocorreu um erro ao enviar justificativa", {
        variant: "error",
      });
    } finally {
      setSendLoading(false);
    }
  }, [
    enqueueSnackbar,
    justification,
    openDialogJustification.data,
    selectedCompany,
    sendJustificationService,
    setOpenDialogJustification,
  ]);

  return (
    <Dialog
      open={openDialogJustification.open}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle disableTypography>
        <Grid container alignItems="center">
          <Grid item xs={11}>
            <Typography className={classes.title}>
              Justificar desbloqueio de regra
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              onClick={() =>
                setOpenDialogJustification({ open: false, data: null })
              }
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider className={classes.divider} />
      <DialogContent>
        <form
          id="justification"
          onSubmit={(e) => {
            e.preventDefault();
            if (!sendLoading) {
              sendJustification();
            }
          }}
        >
          <div style={{ padding: "1rem 0" }}>
            <TextField
              label="Justificativa"
              fullWidth
              value={justification}
              onChange={({ currentTarget }) =>
                setJustification(currentTarget.value)
              }
              inputProps={{
                minLength: 10,
              }}
              name="justification"
              required
            />
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          className={clsx(classes.buttons, classes.cancelButton)}
          onClick={() =>
            setOpenDialogJustification({ open: false, data: null })
          }
        >
          Cancelar
        </Button>
        <Button
          color="primary"
          className={classes.buttons}
          startIcon={
            sendLoading && <CircularProgress color="primary" size={20} />
          }
          form="justification"
          type="submit"
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { DialogJustification };
