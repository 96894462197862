import { ErrorLoading } from "@components/ErrorLoading";
import { ReloadButton } from "@components/ReloadButton";
import { useIoCContext } from "@hooks/IoCContext";
import { useUserContext } from "@hooks/UserContext";
import { Types } from "@ioc/types";
import {
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Pagination } from "@material-ui/lab";
import { IDetailsRechageDTO } from "@modules/company/dtos/IDetailsRechageDTO";
import { IDetailsRechargeService } from "@modules/company/models/IDetailsRechargeService";
import { formatCurrency } from "@utils/index";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { useRecharge } from "./RechargesContext";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      dialogPaper: {
        minWidth: "95vw",
        [theme.breakpoints.up("sm")]: {
          minWidth: "50vw",
          minHeight: "95vh",
        },
      },
      dialogTitle: {
        fontWeight: typography.fontWeightBold,
        fontSize: pxToRem(16),
      },
      divider: {
        backgroundColor: theme.palette.tertiary.background.primary,
      },
      subtitleDialog: {
        color: theme.palette.tertiary.textButtons.primary,
        fontSize: pxToRem(16),
        textAlign: "center",
        [theme.breakpoints.up("xs")]: {
          textAlign: "left",
        },
      },
      columnTitle: {
        fontSize: "1.6rem",
        "& > p:first-child": {
          color: theme.palette.tertiary.textButtons.secondary,
          fontWeight: typography.fontWeightBold,
        },
        [theme.breakpoints.up("sm")]: {
          paddingRight: "2rem",
        },
      },
      columnQuantity: {
        order: 1,
        [theme.breakpoints.up("sm")]: {
          order: 0,
        },
      },
      columnDocument: {
        order: 2,
        [theme.breakpoints.up("sm")]: {
          order: 0,
        },
      },
      dividerRow: {
        order: 3,
      },
      spacingDivider: {
        margin: "1.6rem 0",
      },
      loading: {
        width: "3rem !important",
        height: "3rem !important",
      },
    })
);

const ModalInfoRecharge: React.FC = () => {
  const classes = useStyles();
  const rechargesContext = useRecharge();
  const userContext = useUserContext();
  const iocContext = useIoCContext();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });

  const getRechargeInfoService = iocContext.serviceContainer.get<IDetailsRechargeService>(
    Types.Company.IDetailsRechargeService
  );

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [rechargeInfo, setRechargeInfo] = useState<IDetailsRechageDTO | null>(
    null
  );
  const limit = 10;

  const fetchRecharges = useCallback(async () => {
    if (!rechargesContext.openModalInfoRecharge.data) return;
    if (!userContext.selectedCompany) return;
    try {
      setLoading(true);
      const resp = await getRechargeInfoService.execute({
        companyID: userContext.selectedCompany.uuid,
        rechargeID: rechargesContext.openModalInfoRecharge.data.rechargeID,
        page,
        limit,
      });
      setRechargeInfo(resp);
      setErrorLoading(false);
    } catch (error) {
      setErrorLoading(true);
    } finally {
      setLoading(false);
    }
  }, [
    getRechargeInfoService,
    page,
    rechargesContext.openModalInfoRecharge.data,
    userContext.selectedCompany,
  ]);

  useEffect(() => {
    fetchRecharges();
  }, [fetchRecharges]);

  return (
    <Dialog
      open={rechargesContext.openModalInfoRecharge.open}
      classes={{ paper: classes.dialogPaper }}
      onClose={() =>
        rechargesContext.setOpenModalInfoRecharge({
          open: false,
          data: null,
        })
      }
      fullScreen={isXs}
    >
      <DialogTitle disableTypography>
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography className={classes.dialogTitle}>
              {rechargeInfo
                ? rechargeInfo.recharge.name
                : "Detalhes da recarga"}
            </Typography>
          </Grid>
          <Grid item container justify="flex-end" xs={2}>
            <IconButton
              onClick={() =>
                rechargesContext.setOpenModalInfoRecharge({
                  open: false,
                  data: null,
                })
              }
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.subtitleDialog}>
              01/03/2021
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider className={classes.divider} />
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        {loading && !errorLoading ? (
          <Grid
            style={{ height: "100%", flex: 10 }}
            container
            justify="center"
            alignItems="center"
          >
            <CircularProgress className={classes.loading} />
          </Grid>
        ) : errorLoading ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              flex: 10,
            }}
          >
            <ErrorLoading />
            <ReloadButton reloadFunc={fetchRecharges} reloading={loading} />
          </div>
        ) : (
          rechargeInfo &&
          rechargeInfo.result.map((ele) => {
            return (
              <Grid container>
                <Grid item xs={6} sm={4} className={classes.columnTitle}>
                  <Typography>Usuário</Typography>
                  <Typography>{ele.name}</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={clsx(classes.columnDocument, classes.columnTitle)}
                >
                  <Typography>Documento</Typography>
                  <Typography>{ele.document}</Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={4}
                  className={clsx(classes.columnQuantity, classes.columnTitle)}
                >
                  <Typography>Quantidade</Typography>
                  <Typography>{formatCurrency(ele.quantity)}</Typography>
                </Grid>
                <Grid item xs={12} className={classes.dividerRow}>
                  <Divider
                    className={clsx(classes.divider, classes.spacingDivider)}
                  />
                </Grid>
              </Grid>
            );
          })
        )}
        <Pagination
          style={{ flex: 1, display: "flex", alignItems: "flex-end" }}
          page={page}
          onChange={(e, page) => setPage(page)}
          count={rechargeInfo ? Math.ceil(rechargeInfo.count / limit) : 0}
        />
      </DialogContent>
    </Dialog>
  );
};

export { ModalInfoRecharge };
