import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IDownloadReportDebtService } from "../models/IDownloadReportDebtService";

@injectable()
export class DownloadReportDebtService implements IDownloadReportDebtService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    from: Date;
    to: Date;
    query?: string;
    page: number;
    limit: number;
  }): Promise<string> {
    const resp = await this.httpInstance.get(
      `/companies/${data.companyID}/report/debit-management`,
      {
        params: {
          type: "DEBITO",
          page: data.page,
          limit: data.limit,
          startDate: data.from,
          endDate: data.to,
          q: data.query,
        },
        responseType: "arraybuffer",
      }
    );

    const blob = new Blob([resp], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);
    return url;
  }
}
