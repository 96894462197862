export interface FolderTemplate {
  id: string;
  resource: string;
  name: string;
  description: string;
  required: boolean;
}

export interface Resource {
  id?: string;
  description?: string;
  type?: string;
  template?: FolderTemplate[];
}

export interface Folder {
  id?: string;
  description: string;
  emails: string[];
  resource?: Resource;
  externalReferenceId?: string;
  externalReferenceDescription?: string;
  externalReferenceType?: string;
}

export const FolderInitialValues: Folder = {
  description: "",
  externalReferenceId: "",
  externalReferenceDescription: "",
  externalReferenceType: "general",
  emails: [],
};
