import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { ILocationEstablishmentsDTO } from "../dtos/ILocationEstablishmentsDTO";
import { ILocationsEstablishmentsService } from "../models/ILocationsEstablishmentsService";

@injectable()
export class LocationsEstablishmentsService
  implements ILocationsEstablishmentsService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
  }): Promise<ILocationEstablishmentsDTO[]> {
    const resp = await this.httpInstance.get<ILocationEstablishmentsDTO[]>(
      `/companies/${data.companyID}/whitelist`
    );
    return resp;
  }
}
