import { createStyles, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontWeight: "bold",
      fontSize: "3.2rem",
    },
    spacingTitle: {
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
      margin: "4rem 0",
    },
    subtitle: {
      fontSize: "1.8rem",
      color: theme.palette.tertiary.textButtons.primary,
    },
  })
);

interface Props extends React.HTMLProps<HTMLDivElement> {
  subtitle?: string;
}

const Title = React.forwardRef<HTMLDivElement, Props>(
  ({ children, subtitle, className, ...props }, ref) => {
    const classes = useStyles();

    return (
      <div
        ref={ref}
        className={clsx(classes.spacingTitle, className)}
        {...props}
      >
        <Typography className={classes.title}>{children}</Typography>
        {subtitle && (
          <Typography className={classes.subtitle}>{subtitle}</Typography>
        )}
      </div>
    );
  }
);

export { Title };
