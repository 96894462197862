import { Search } from "../model/Search";
import { inject, injectable } from "inversify";
import { Types } from "@ioc/types";
import Quotation, { QuotationFilter } from "./Quotation";

export interface QuotationClient {
  find(
    companyId: string,
    orderId: string,
    filter?: QuotationFilter
  ): Promise<Search<Quotation>>;

  findById(companyId: string, quotationId: string): Promise<Quotation>;

  addComment(
    companyId: string,
    quotationId: string,
    companyName: string,
    message: string
  ): Promise<void>;

  update(companyId: string, quotationId: string, status: string): Promise<void>;
}

@injectable()
export class QuotationClientImpl implements QuotationClient {
  @inject(Types.FlagcardCompanyWrapper)
  // @ts-ignore
  private instance: FlagcardCompanyWrapper;

  private readonly endpoint = "/core/v1/quotations";

  public find(
    companyId: string,
    orderId: string,
    filter?: QuotationFilter
  ): Promise<Search<Quotation>> {
    return this.instance.get(this.endpoint, {
      params: {
        ...filter,
        "order.id": orderId,
        statuses:
          filter?.status ||
          "PENDING,READY_TO_APPROVAL,APPROVED,REJECTED,IN_EXECUTION,FINISHED,CANCELED",
      },
      headers: { "x-tenant-id": companyId },
    });
  }

  public findById(companyId: string, quotationId: string): Promise<Quotation> {
    return this.instance.get(`${this.endpoint}/${quotationId}`, {
      headers: { "x-tenant-id": companyId },
    });
  }

  public addComment(
    companyId: string,
    quotationId: string,
    companyName: string,
    message: string
  ): Promise<void> {
    return this.instance.post(
      `${this.endpoint}/${quotationId}/comments`,
      {
        user: {
          id: companyId,
          name: companyName,
        },
        message,
      },
      {
        headers: { "x-tenant-id": companyId },
      }
    );
  }

  public update(
    companyId: string,
    quotationId: string,
    status: string
  ): Promise<void> {
    return this.instance.patch(
      `${this.endpoint}/${quotationId}`,
      { status },
      {
        headers: { "x-tenant-id": companyId },
      }
    );
  }
}
