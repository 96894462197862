import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IListDriversDTO } from "../dtos/IListDriversDTO";
import { IListDriversService } from "../models/IListDriversService";

@injectable()
export class ListDriversService implements IListDriversService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    page: number;
    limit: number;
    departmentID?: string;
    costCenterID?: String;
    statusID?: string;
    query?: string;
    functionID?: string;
  }): Promise<IListDriversDTO> {
    const resp = await this.httpInstance.get<IListDriversDTO>(
      `/companies/${data.companyID}/drivers`,
      {
        params: {
          page: data.page,
          limit: data.limit,
          statusId: data.statusID,
          functionId: data.functionID,
          departmentId: data.departmentID,
          costcenterId: data.costCenterID,
          q: data.query,
        },
      }
    );

    resp.result = resp.result.map((driver) => {
      return {
        ...driver,
        id_centro_custo: String(driver.id_centro_custo),
        id_departamento: String(driver.id_departamento),
        id_funcao: String(driver.id_funcao),
      };
    });

    return resp;
  }
}
