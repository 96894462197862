type Variant = "error" | "warning" | "success";

class AppError {
  public readonly message: string;

  public readonly variant: Variant;

  public readonly code?: number;

  constructor(message: string, variant: Variant, code?: number) {
    this.message = message;
    this.variant = variant;
    this.code = code;
  }
}

export default AppError;
