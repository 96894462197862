import Keycloak from "keycloak-js";
import { injectable } from "inversify";
import { camelizeKeys, decamelizeKeys } from "humps";
import Axios, { AxiosInstance } from "axios";
import { appConfig } from "@config/appConfig";

export interface FlagcardCompanyWrapper {
  get<T = any>(path: string, params?: {}): Promise<T>;

  post<T = any>(path: string, body: any, params?: {}): Promise<T>;

  patch<T = any>(path: string, body?: any, params?: {}): Promise<T>;

  put<T = any>(path: string, body?: any, params?: {}): Promise<T>;

  delete<T = any>(path: string, params?: {}): Promise<T>;

  addKeycloak(keycloak: Keycloak.KeycloakInstance): void;
}

@injectable()
export class FlagcardCompanyWrapperImpl implements FlagcardCompanyWrapper {
  private readonly instance: AxiosInstance;

  public constructor() {
    this.instance = Axios.create({
      timeout: appConfig.api.timeout,
      baseURL: "https://company-wrapper.flagcard.com.br",
    });
  }

  addKeycloak(keycloak: Keycloak.KeycloakInstance) {
    this.instance.interceptors.request.use((options) => {
      const nConfig = { ...options };

      if (options.params) {
        nConfig.params = decamelizeKeys(options.params);
      }
      if (
        options.data &&
        ["application/json", "application/json; charset=utf-8"].includes(
          nConfig.headers["content-type"]
        )
      ) {
        nConfig.data = decamelizeKeys(options.data);
      }
      keycloak.updateToken(300);
      nConfig.headers.Authorization = `Bearer ${keycloak.token}`;
      return nConfig;
    });
    this.instance.interceptors.response.use((options) => {
      const response = { ...options };
      if (options.data) {
        response.data = camelizeKeys(response.data);
      }
      return response;
    });
  }

  get<T = any>(path: string, params?: {}): Promise<T> {
    return this.instance.get<T>(path, params).then(({ data }) => data);
  }

  post<T = any>(path: string, body: any, params?: {}): Promise<T> {
    return this.instance.post<T>(path, body, params).then(({ data }) => data);
  }

  patch<T = any>(path: string, body?: any, params?: {}): Promise<T> {
    return this.instance.patch<T>(path, body, params).then(({ data }) => data);
  }

  put<T = any>(path: string, body?: any, params?: {}): Promise<T> {
    return this.instance.put<T>(path, body, params).then(({ data }) => data);
  }

  delete<T = any>(path: string, params?: {}): Promise<T> {
    return this.instance.delete<T>(path, params).then(({ data }) => data);
  }
}
