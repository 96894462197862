import { Search } from "../model/Search";
import { inject, injectable } from "inversify";
import { Types } from "@ioc/types";
import Vehicle, { FilterVehicle } from "./Vehicle";

export interface VehicleClient {
  save(companyId: string, vehicle: Vehicle): Promise<Vehicle>;

  update(companyId: string, vehicleId: string, vehicle: Vehicle): Promise<void>;

  find(companyId: string, filter?: FilterVehicle): Promise<Search<Vehicle>>;

  findById(companyId: string, vehicleId: string): Promise<Vehicle>;

  remove(companyId: string, vehicleId: string): Promise<void>;
}

@injectable()
export class VehicleClientImpl implements VehicleClient {
  @inject(Types.FlagcardCompanyWrapper)
  // @ts-ignore
  private instance: FlagcardCompanyWrapper;

  private readonly endpoint = "/rest/v2/vehicles";

  public save(companyId: string, vehicle: Vehicle): Promise<Vehicle> {
    return this.instance.post(this.endpoint, vehicle, {
      headers: { company_id: companyId },
    });
  }

  public update(
    companyId: string,
    vehicleId: string,
    vehicle: Vehicle
  ): Promise<void> {
    return this.instance.patch(`${this.endpoint}/${vehicleId}`, vehicle, {
      headers: { company_id: companyId },
    });
  }

  public find(
    companyId: string,
    filter?: FilterVehicle
  ): Promise<Search<Vehicle>> {
    const plate = filter?.plate?.replaceAll("-", "").toUpperCase() || undefined;
    const page = filter?.page || 1;
    return this.instance.get(this.endpoint, {
      params: { ...filter, plate, page },
      headers: { company_id: companyId },
    });
  }

  public findById(companyId: string, vehicleId: string): Promise<Vehicle> {
    return this.instance.get(`${this.endpoint}/${vehicleId}`, {
      headers: { company_id: companyId },
    });
  }

  public remove(companyId: string, vehicleId: string): Promise<void> {
    return this.instance.delete(`${this.endpoint}/${vehicleId}`, {
      headers: { company_id: companyId },
    });
  }
}
