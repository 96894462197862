import {createStyles, makeStyles} from "@material-ui/core";

export default makeStyles((theme) =>
    createStyles({
        title: {
            fontWeight: "bold",
            fontsize: "22px",
            color: "#58595B"
        },
        "title-history": {
            position: "relative",
            display: "flex",
            padding: "18px 6px",
        },
        "title-history-separator": {
            paddingLeft: "4px",
            paddingRight: "4px",
            color: "#58595B"
        },
        "title-history-description": {
            color: "#58595B",
            textTransform: "uppercase",
        }
    })
);
