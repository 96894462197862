import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { parse } from "date-fns";
import { inject, injectable } from "inversify";
import { IReportBalanceDTO } from "../dtos/IReportBalanceDTO";
import { IReportBalanceService } from "../models/IReportBalanceService";

@injectable()
export class ReportBalanceService implements IReportBalanceService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    page: number;
    limit: number;
    query?: string | undefined;
  }): Promise<IReportBalanceDTO> {
    const resp = await this.httpInstance.get<IReportBalanceDTO>(
      `/companies/${data.companyID}/report/balance`,
      {
        params: {
          page: data.page,
          limit: data.limit,
          q: data.query,
        },
      }
    );

    resp.result = resp.result.map((report) => {
      return {
        ...report,
        // @ts-ignore
        id: report.uuid,
        quantity: Number(report.quantity),
        date: parse(
          (report.date as unknown) as string,
          "dd/MM/yyyy",
          new Date()
        ),
      };
    });

    return resp;
  }
}
