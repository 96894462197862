import React, { useState } from "react";
import { DashboardCardProps } from "@components/CardInfo/DashboardCardProps";
import { IconButton, Paper, Tooltip } from "@material-ui/core";
import Monetization from "@material-ui/icons/MonetizationOn";
import StyleSheet from "./StyleSheet";
import { Skeleton } from "@material-ui/lab";
import { formatCurrency } from "@utils/index";
import HelpIcon from "@material-ui/icons/Help";
import { MoneyOff, OfflinePinRounded, PlaylistAdd } from "@material-ui/icons";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LocalAtm from "@material-ui/icons/LocalAtm";
import LocalGasStation from "@material-ui/icons/LocalGasStation";
import DirectionsCar from "@material-ui/icons/DirectionsCar";
import Gavel from "@material-ui/icons/Gavel";

const getFormatter = (inMoney?: boolean) => {
  if (inMoney === undefined) {
    return {
      format: (value?: number) => {
        return value || 0;
      },
    };
  }
  if (inMoney) {
    return {
      format: (value?: number) => {
        return formatCurrency(value || 0);
      },
    };
  }
  return {
    format: (value?: number) => {
      return (
        new Intl.NumberFormat("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(value || 0) + " L"
      );
    },
  };
};

const Loading: React.FC<{ loading?: boolean; width: number }> = ({
  loading,
  width,
  children,
}) => {
  return <>{!loading ? children : <Skeleton style={{ width }} />}</>;
};

export const DashboardCard: React.FC<DashboardCardProps> = (props) => {
  const { title, children, loading, inMoney, value, tooltip, color } = props;
  const styleSheet = StyleSheet({ color: color || ["#007FFF", "#004F98"] });
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Paper className={styleSheet.paper}>
      <Tooltip
        open={showTooltip}
        onClose={() => setShowTooltip(false)}
        onOpen={() => setShowTooltip(true)}
        title={tooltip}
        classes={{ tooltip: styleSheet.tooltip }}
      >
        <IconButton
          className={styleSheet["tooltip-button"]}
          style={{ opacity: !loading ? 1 : 0 }}
          onClick={() => setShowTooltip(true)}
        >
          <HelpIcon className={styleSheet["tooltip-icon"]} />
        </IconButton>
      </Tooltip>
      <div className={styleSheet.container}>
        <div className={styleSheet["container-avatar"]}>{children}</div>
        <div>
          <div className={styleSheet["container-info"]}>
            <Loading loading={loading} width={200}>
              {getFormatter(inMoney).format(value)}
            </Loading>
          </div>
          <div className={styleSheet["container-title"]}>
            <Loading loading={loading} width={150}>
              {title}
            </Loading>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export const DashboardCardTotalCredited: React.FC<{
  loading: boolean;
  prepaid: boolean;
  inMoney: boolean;
  value: number;
}> = (props) => {
  const styleSheet = StyleSheet({ color: ["#007FFF", "#004F98"] });
  const { prepaid, loading, inMoney, value } = props;
  const tooltipPrePaid = "Saldo total creditado desde o início da operação.";
  const tooltipPosPaid =
    "Crédito depositado para os colaboradores desde o início do mês atual";
  return (
    <DashboardCard
      title={prepaid ? "Total Creditado" : "Total Creditado No Mês"}
      loading={loading}
      inMoney={inMoney}
      value={value}
      tooltip={prepaid ? tooltipPrePaid : tooltipPosPaid}
    >
      <Monetization className={styleSheet["container-avatar-icon"]} />
    </DashboardCard>
  );
};

export const DashboardCardTotalUsed: React.FC<{
  loading: boolean;
  inMoney: boolean;
  value: number;
}> = (props) => {
  const color = ["#EAA222", "#ED820E"];
  const styleSheet = StyleSheet({ color });
  const { loading, inMoney, value } = props;
  const tooltipInMoney = "Total consumido no mês (em reais).";
  const tooltipInLiter = "Total consumido no mês (em litros).";
  return (
    <DashboardCard
      title="Utilizado"
      loading={loading}
      inMoney={inMoney}
      value={value}
      tooltip={inMoney ? tooltipInMoney : tooltipInLiter}
      color={color}
    >
      <OfflinePinRounded className={styleSheet["container-avatar-icon"]} />
    </DashboardCard>
  );
};

export const DashboardCardTotalAvailable: React.FC<{
  loading: boolean;
  inMoney: boolean;
  value: number;
}> = (props) => {
  const color = ["#78c263", "#028A0F"];
  const styleSheet = StyleSheet({ color });
  const { loading, inMoney, value } = props;
  return (
    <DashboardCard
      title="Disponível"
      loading={loading}
      inMoney={inMoney}
      value={value}
      tooltip="Total disponível para seus colaboradores utilizarem"
      color={color}
    >
      <CheckCircleIcon className={styleSheet["container-avatar-icon"]} />
    </DashboardCard>
  );
};

export const DashboardCardTotalConsumed: React.FC<{
  loading: boolean;
  inMoney: boolean;
  value: number;
}> = (props) => {
  const color = ["#FDFD96", "#FEDC56"];
  const styleSheet = StyleSheet({ color });
  const { loading, inMoney, value } = props;
  const titleInMoney = "Total consumido em litros";
  const titleInLiter = "Total consumido em reais";
  const tooltipInMoney = "Total consumido no mês (em litros).";
  const tooltipInLiter = "Total consumido no mês (em reais).";
  return (
    <DashboardCard
      title={inMoney ? titleInMoney : titleInLiter}
      loading={loading}
      inMoney={!inMoney}
      value={value}
      tooltip={inMoney ? tooltipInMoney : tooltipInLiter}
      color={color}
    >
      <LocalAtm className={styleSheet["container-avatar-icon"]} />
    </DashboardCard>
  );
};

export const DashboardQuantityVehicle: React.FC<{
  loading: boolean;
  value: number;
}> = (props) => {
  const color = ["#007FFF", "#004F98"];
  const styleSheet = StyleSheet({ color });
  const { loading, value } = props;
  return (
    <DashboardCard
      title="Quantidade Total de Veículos"
      loading={loading}
      value={value}
      tooltip="Quantidade Total de Veículos ativos no sistema."
      color={color}
    >
      <DirectionsCar className={styleSheet["container-avatar-icon"]} />
    </DashboardCard>
  );
};

export const DashboardQuantityInfraction: React.FC<{
  loading: boolean;
  value: number;
}> = (props) => {
  const color = ["#EAA222", "#ED820E"];
  const styleSheet = StyleSheet({ color });
  const { loading, value } = props;
  return (
    <DashboardCard
      title="Quantidade Total de Infrações"
      loading={loading}
      value={value}
      tooltip="Quantidade Total de Infrações (Multas municipais, estaduais e nacionais)"
      color={color}
    >
      <Gavel className={styleSheet["container-avatar-icon"]} />
    </DashboardCard>
  );
};

export const DashboardQuantityDebit: React.FC<{
  loading: boolean;
  value: number;
}> = (props) => {
  const color = ["#FF0000", "#8B0000"];
  const styleSheet = StyleSheet({ color });
  const { loading, value } = props;
  return (
    <DashboardCard
      title="Quantidade Total de Débitos"
      loading={loading}
      value={value}
      tooltip="Quantidade Total de Débitos (Licenciamento | IPVA | DÍVIDA ATIVA)"
      color={color}
    >
      <LocalAtm className={styleSheet["container-avatar-icon"]} />
    </DashboardCard>
  );
};
