import { Department } from "../Department/Department";
import { CostCenter } from "../CostCenter/CostCenter";
import { Model } from "../manufacturer/Manufacturer";

export default interface Vehicle {
  id?: string;
  plate?: string;
  chassi?: string;
  renavam?: string;
  model?: Model;
  color?: string;
  year?: string;
  prefix?: string;
  consumption?: number | string;
  patrimony?: string;
  fuelCapacity?: number | string;
  mileage?: number | string;
  department?: Department;
  costCenter?: CostCenter;
  observation?: string;
  status?: string;
}

export interface FilterVehicle {
  plate?: string;
  chassi?: string;
  renavam?: string;
  model?: string;
  status?: string;
  department?: string;
  cost_center?: string;
  limit?: number;
  page?: number;
}

export interface VehicleDataPayload {
  id?: string;
  manufacturerId?: string;
  manufacturerType?: string;
  manufacturerDescription?: string;
  modelId?: string;
  modelDescription?: string;
  plate?: string;
  chassi?: string;
  renavam?: string;
  color?: string;
  year?: string;
  consumption?: number | string;
  fuelCapacity?: number | string;
  mileage?: number | string;
}

export interface VehicleGeneralInfoPayload {
  id?: string;
  departmentId?: string;
  departmentDescription?: string;
  costCenterId?: string;
  costCenterDescription?: string;
  prefix?: string;
  patrimony?: string;
  observation?: string;
}

export const VehicleDataInitialValues: VehicleDataPayload = {
  manufacturerId: "",
  manufacturerType: "CAR",
  manufacturerDescription: "",
  modelId: "",
  modelDescription: "",
  plate: "",
  chassi: "",
  renavam: "",
  color: "",
  year: "",
  fuelCapacity: "",
  consumption: "",
};

export const VehicleGeneralInfoInitialValues: VehicleGeneralInfoPayload = {
  departmentId: "",
  departmentDescription: "",
  costCenterId: "",
  costCenterDescription: "",
  prefix: "",
  patrimony: "",
  observation: "",
};

export const vehicleToVehicleData = (vehicle: Vehicle): VehicleDataPayload => {
  return {
    id: vehicle.id,
    manufacturerId: vehicle.model?.manufacturer?.id,
    manufacturerType: vehicle.model?.manufacturer?.type,
    manufacturerDescription: vehicle.model?.manufacturer?.description,
    modelId: vehicle.model?.id,
    modelDescription: vehicle.model?.description,
    plate: vehicle.plate,
    chassi: vehicle.chassi,
    renavam: vehicle.renavam,
    color: vehicle.color,
    year: vehicle.year,
    consumption: vehicle.consumption,
    fuelCapacity: vehicle.fuelCapacity,
    mileage: vehicle.mileage,
  };
};

export const vehicleToVehicleGeneralInfo = (
  vehicle: Vehicle
): VehicleGeneralInfoPayload => {
  return {
    id: vehicle.id,
    departmentId: vehicle.department?.id,
    departmentDescription: vehicle.department?.description,
    costCenterId: vehicle.costCenter?.id,
    costCenterDescription: vehicle.costCenter?.description,
    prefix: vehicle.prefix,
    patrimony: vehicle.patrimony,
    observation: vehicle.observation,
  };
};
