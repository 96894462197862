// https://bifrost.flagcard.com.br/rest/companies/0e654d8c-12de-491a-b9ed-da4b7025e9d2/report/consumption/history

import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IGetHistoryOfConsumptionDTO } from "../dtos/IGetHistoryOfConsumptionDTO";
import { IGetHistoryOfConsumptionService } from "../models/IGetHistoryOfConsumptionService";

@injectable()
export class GetHistoryOfConsumptionService
  implements IGetHistoryOfConsumptionService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
  }): Promise<IGetHistoryOfConsumptionDTO[]> {
    const response = await this.httpInstance.get<IGetHistoryOfConsumptionDTO[]>(
      `/companies/${data.companyID}/report/consumption/history`
    );
    return response.map((month) => ({
      ...month,
      interval: new Date(month.interval),
    }));
  }
}
