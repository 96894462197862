import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IEditVehicleDTO } from "../dtos/IEditVehicleDTO";
import { IEditVehicleService } from "../models/IEditVehicleService";

@injectable()
export class EditVehicleService implements IEditVehicleService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: IEditVehicleDTO): Promise<void> {
    await this.httpInstance.patch("/fleet/vehicle", data);
  }
}
