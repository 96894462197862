import React, { useEffect, useState } from "react";
import StyleSheet from "./StyleSheet";
import { Chip, MenuItem, TextField } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/HighlightOff";

export interface Filter {
  label: string;
  field: string;
  placeholder?: string;
  value?: string;
}

interface InputFilterProps {
  filters: Filter[];
  onFilter?: (filters: Filter[]) => void;
}

const SearchContainer: React.FC<InputFilterProps> = ({
  filters: original,
  onFilter,
  children,
}) => {
  const defaultFilter = original[0];
  const styleSheet = StyleSheet();
  const [selectedValue, setSelectedValue] = useState("");
  const [placeholder, setPlaceholder] = useState(defaultFilter.placeholder);
  const [filters, setFilters] = useState([...original]);
  const [filter, setFilter] = useState(defaultFilter);
  const containerStyle = {
    display: "flex",
    width: "50%",
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const option = filters.find(
      (filter) => filter.field === event.target.value
    );
    if (option) {
      setFilter(option);
    }
  };
  const handleFilter = (keyCode: string) => {
    if (keyCode === "Enter") {
      const option = filters.find((o) => o.field === filter.field);
      if (option) {
        setFilter({ ...filter, value: selectedValue });
        setSelectedValue("");
      }
    }
  };
  const handleDelete = (field: string) => {
    const option = filters.find((filter) => filter.field === field);
    if (option) {
      setFilter({ ...option, value: "" });
    }
  };

  useEffect(() => {
    if (onFilter) {
      onFilter(filters);
    }
  }, [filters, onFilter]);

  useEffect(() => {
    setPlaceholder(filter.placeholder);
    setFilters(
      filters.map((o) => {
        if (o.field === filter.field) {
          return filter;
        }
        return o;
      })
    );
  }, [filter]);

  return (
    <>
      <div className={styleSheet.container}>
        <div style={containerStyle}>
          <TextField
            value={filter.field}
            onChange={handleChange}
            select
            variant="outlined"
            focused={false}
            InputProps={{
              inputProps: { className: styleSheet.selectRoot },
              className: styleSheet.selectFilter,
              classes: { notchedOutline: styleSheet.selectInput },
            }}
          >
            {filters.map((option, index) => (
              <MenuItem key={option.field} value={option.field}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            variant="outlined"
            focused={false}
            placeholder={placeholder}
            onChange={(event) => setSelectedValue(event.target.value)}
            onKeyDown={(event) => handleFilter(event.key)}
            value={selectedValue}
            disabled={!!filter.value}
            classes={{ root: styleSheet.textFieldRoot }}
            InputProps={{
              className: styleSheet.textFieldFilter,
              classes: { notchedOutline: styleSheet.textFieldInput },
            }}
          />
        </div>
        <div className={styleSheet.buttonContainer} style={containerStyle}>
          {children}
        </div>
      </div>
      <div className={styleSheet.filterContainer}>
        {filters
          .filter((o) => !!o.value)
          .map((o) => {
            return (
              <div style={{ float: "left", paddingRight: 4 }}>
                <Chip
                  key={o.field}
                  className={styleSheet.pill}
                  label={`${o.label}: ${o.value}`}
                  onDelete={() => handleDelete(o.field)}
                  deleteIcon={<DeleteIcon />}
                />
              </div>
            );
          })}
      </div>
    </>
  );
};

export { SearchContainer };
