import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import DragInDropBox from "@components/DragInDropBox";
import { Button } from "@components/Button";
import Message from "@components/Message";
import { UploadClient } from "../../../client/upload/UploadClient";
import { Types } from "@ioc/types";
import { DocumentClient } from "../../../client/Document/DocumentClient";
import { useUserContext } from "@hooks/UserContext";
import { useIoCContext } from "@hooks/IoCContext";

interface UploadProps {
  folderId?: string;
  type?: string;
  open: boolean;
  onClose: () => void;
}

const UploadDocument: React.FC<UploadProps> = (props) => {
  const { folderId, open, onClose, type } = props;
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(0);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const uploadClient = iocContext.serviceContainer.get<UploadClient>(
    Types.Upload
  );

  const documentClient = iocContext.serviceContainer.get<DocumentClient>(
    Types.Document
  );

  const sendFileAndCreateDocument = async (file: File) => {
    if (!selectedCompany) return Promise.reject();
    if (!folderId) return Promise.reject();
    const uploaded = await uploadClient.upload(selectedCompany.uuid, file);
    return documentClient.save(selectedCompany.uuid, {
      folderId,
      type,
      name: uploaded.original,
      path: uploaded.location,
      extension: uploaded.extension,
    });
  };

  const handleUpload = async (fileList: FileList) => {
    setProgress(0);
    setUploading(true);
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      const stream = Math.floor(((i + 1) / fileList.length) * 100);
      setBuffer(stream);
      if (file) {
        await sendFileAndCreateDocument(file);
      }
      setProgress(stream);
    }
    onClose();
    setUploading(false);
  };

  return (
    <>
      <Dialog fullWidth open={open} onClose={onClose}>
        <DialogTitle hidden={uploading}>Enviar Documentos</DialogTitle>
        <DialogTitle hidden={!uploading}>Enviando Documentos</DialogTitle>
        <DialogContent hidden={uploading}>
          <DragInDropBox
            multiple
            helperText="Faça o upload dos documentos"
            onSelectedFiles={handleUpload}
          />
        </DialogContent>
        <DialogContent hidden={!uploading}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Box style={{ width: "100%" }}>
              <LinearProgress
                variant="buffer"
                value={progress}
                valueBuffer={buffer}
              />
            </Box>
            <Box style={{ minWidth: 35 }}>
              <Typography
                variant="body2"
                color="secondary"
              >{`${progress}%`}</Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          {uploading ? <></> : <Button onClick={onClose}>Fechar</Button>}
        </DialogActions>
      </Dialog>
      <Message.Success open={success} onClose={() => setSuccess(false)} />
      <Message.Error open={error} onClose={() => setError(false)} />
    </>
  );
};

export default UploadDocument;
