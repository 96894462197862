import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      fontSize: "3rem",
      color: theme.palette.warning.main,
    },
    messageInfo: {
      textAlign: "center",
      fontSize: "2rem",
      fontWeight: "bold",
    },
  })
);

interface Props {
  messageInfo?: string;
}

const NoData: React.FC<Props> = ({ messageInfo }) => {
  const classes = useStyles();
  return (
    <Grid container justify="center" alignItems="center" direction="column">
      <InfoIcon className={classes.icon} />
      <Typography className={classes.messageInfo}>
        {messageInfo ? messageInfo : "Nenhum resultado encontrado."}
      </Typography>
    </Grid>
  );
};

export { NoData };
