import React, { CSSProperties, Fragment, useEffect, useState } from "react";
import { useUserContext } from "@hooks/UserContext";
import { useIoCContext } from "@hooks/IoCContext";
import { useParams } from "react-router";
import { Types } from "@ioc/types";
import { PermissionClient } from "../../../../client/permission/PermissionClient";
import { Switch, Tooltip } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import { DialogDriver } from "@pages/Vehicles/Edit/Permission/DialogDriver";
import Permission from "../../../../client/permission/Permission";
import { Loading } from "@components/Loading";
import { Driver } from "../../../../client/model/Driver";
import { DialogProduct } from "@pages/Vehicles/Edit/Permission/DialogProduct";
import { DialogConsumption } from "@pages/Vehicles/Edit/Permission/DialogConsumption";
import { DialogInterval } from "@pages/Vehicles/Edit/Permission/DialogInterval";

const DEFAULT_PERMISSION = { status: "INACTIVE", options: {} };

const VehiclePermission: React.FC<{ vehicle: string; plate: string }> = ({
  vehicle,
  plate,
}) => {
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const params = useParams<{ id?: string }>();
  const [loading, setLoading] = useState(false);
  const [vehicleId] = useState(params.id);
  const [driverPermission, setDriverPermission] = useState<Permission>(
    DEFAULT_PERMISSION
  );
  const [fuelPermission, setFuelPermission] = useState<Permission>(
    DEFAULT_PERMISSION
  );
  const [capacityPermission, setCapacityPermission] = useState<Permission>(
    DEFAULT_PERMISSION
  );
  const [
    consumptionPermission,
    setConsumptionPermission,
  ] = useState<Permission>(DEFAULT_PERMISSION);
  const [intervalPermission, setIntervalPermission] = useState<Permission>(
    DEFAULT_PERMISSION
  );
  const [openDriverDialog, setOpenDriverDialog] = useState(false);
  const [openFuelDialog, setOpenFuelDialog] = useState(false);
  const [openConsumptionDialog, setOpenConsumptionDialog] = useState(false);
  const [openIntervalDialog, setOpenIntervalDialog] = useState(false);

  const permissionClient = iocContext.serviceContainer.get<PermissionClient>(
    Types.Permission
  );

  const handleChangeStyle = (status?: string): CSSProperties => {
    if (status === "ACTIVE") {
      return {
        width: "100%",
        fontWeight: "bolder",
        textDecoration: "underline",
        cursor: "pointer",
      };
    }
    return { width: "100%" };
  };

  const savePermissionStatus = (
    type: string,
    checked: boolean
  ): Promise<Permission> => {
    if (!selectedCompany) return Promise.resolve({});
    setLoading(true);
    return permissionClient
      .save(selectedCompany.uuid, {
        vehicleId,
        type,
        status: checked ? "ACTIVE" : "INACTIVE",
      })
      .finally(() => setLoading(false));
  };

  const savePermissionOptions = (
    type: string,
    options: any
  ): Promise<Permission> => {
    if (!selectedCompany) return Promise.resolve({});
    setLoading(true);
    return permissionClient
      .save(selectedCompany.uuid, {
        vehicleId: vehicleId,
        type,
        status: "ACTIVE",
        options,
      })
      .finally(() => setLoading(false));
  };

  const handleDriverCheckboxChanged = (checked: boolean) => {
    savePermissionStatus("DRIVER", checked).then((permission) => {
      setDriverPermission(permission);
      setOpenDriverDialog(checked);
    });
  };

  const handleFuelCheckboxChanged = (checked: boolean) => {
    savePermissionStatus("FUEL", checked).then((permission) => {
      setFuelPermission(permission);
      setOpenFuelDialog(checked);
    });
  };

  const handleCapacityCheckboxChanged = (checked: boolean) => {
    savePermissionStatus("CAPACITY", checked).then((permission) => {
      setCapacityPermission(permission);
    });
  };

  const handleConsumptionCheckboxChanged = (checked: boolean) => {
    savePermissionStatus("CONSUMPTION", checked).then((permission) => {
      setConsumptionPermission(permission);
      setOpenConsumptionDialog(true);
    });
  };

  const handleIntervalCheckboxChanged = (checked: boolean) => {
    savePermissionStatus("INTERVAL", checked).then((permission) => {
      setIntervalPermission(permission);
      setOpenIntervalDialog(true);
    });
  };

  const handleSelectDrivers = (drivers: Driver[]) => {
    savePermissionOptions("DRIVER", {
      permissions: drivers.map((driver) => ({
        id: driver.id,
        driver: driver.driver,
        cnh: driver.cnh,
      })),
    }).then((permission) => {
      setDriverPermission(permission);
      setOpenDriverDialog(false);
    });
  };

  const handleSelectFuels = (products: string[]) => {
    savePermissionOptions("FUEL", {
      permissions: products,
    }).then((permission) => {
      setFuelPermission(permission);
      setOpenFuelDialog(false);
    });
  };

  const handleSelectConsumption = (percentage: number) => {
    savePermissionOptions("CONSUMPTION", { percentage }).then((permission) => {
      setConsumptionPermission(permission);
      setOpenConsumptionDialog(false);
    });
  };

  const handleSelectInterval = (interval: number, type: string) => {
    savePermissionOptions("INTERVAL", { interval, type }).then((permission) => {
      setIntervalPermission(permission);
      setOpenIntervalDialog(false);
    });
  };

  const fetchPermissions = () => {
    if (!selectedCompany) return;
    setLoading(true);
    permissionClient
      .find(selectedCompany.uuid, vehicleId)
      .then(({ result }) => {
        setDriverPermission(
          result.find((permission) => permission.type === "DRIVER") ||
            DEFAULT_PERMISSION
        );
        setFuelPermission(
          result.find((permission) => permission.type === "FUEL") ||
            DEFAULT_PERMISSION
        );
        setCapacityPermission(
          result.find((permission) => permission.type === "CAPACITY") ||
            DEFAULT_PERMISSION
        );
        setConsumptionPermission(
          result.find((permission) => permission.type === "CONSUMPTION") ||
            DEFAULT_PERMISSION
        );
        setIntervalPermission(
          result.find((permission) => permission.type === "INTERVAL") ||
            DEFAULT_PERMISSION
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(fetchPermissions, [vehicleId]);

  return (
    <div>
      <table>
        <tr>
          <td style={handleChangeStyle(driverPermission.status)}>
            <Tooltip title="Abrir detalhes da restrição">
              <span
                onClick={() =>
                  setOpenDriverDialog(driverPermission.status === "ACTIVE")
                }
              >
                Restringir o abastecimento somente a motoristas autorizados
              </span>
            </Tooltip>
          </td>
          <td>
            <Switch
              checked={driverPermission.status === "ACTIVE"}
              color="primary"
              onChange={(_, checked) => handleDriverCheckboxChanged(checked)}
            />
          </td>
          <td>
            <Tooltip
              placement="bottom-end"
              arrow
              title="Restringe o abastecimento somente à motoristas que foram adicionados nos detalhes dessa restrição."
            >
              <Help
                style={{ color: "#58595B", marginTop: 6, cursor: "pointer" }}
                fontSize="small"
              />
            </Tooltip>
          </td>
        </tr>
        <tr>
          <td style={handleChangeStyle(fuelPermission.status)}>
            <Tooltip title="Abrir detalhes da restrição">
              <span
                onClick={() =>
                  setOpenFuelDialog(fuelPermission.status === "ACTIVE")
                }
              >
                Restringir o abastecimento somente a combustíveis autorizados
              </span>
            </Tooltip>
          </td>
          <td>
            <Switch
              checked={fuelPermission.status === "ACTIVE"}
              color="primary"
              onChange={(_, checked) => handleFuelCheckboxChanged(checked)}
            />
          </td>
          <td>
            <Tooltip
              placement="bottom-end"
              arrow
              title="Restringe o abastecimento somente à produtos que foram adicionados nos detalhes dessa restrição."
            >
              <Help
                style={{ color: "#58595B", marginTop: 6, cursor: "pointer" }}
                fontSize="small"
              />
            </Tooltip>
          </td>
        </tr>
        <tr>
          <td style={handleChangeStyle(capacityPermission.status)}>
            <span>
              Restringir o abastecimento quando o tanque atingir sua capacidade
              máxima
            </span>
          </td>
          <td>
            <Switch
              checked={capacityPermission.status === "ACTIVE"}
              color="primary"
              onChange={(_, checked) => handleCapacityCheckboxChanged(checked)}
            />
          </td>
          <td>
            <Tooltip
              placement="bottom-end"
              arrow
              title="Restringe o abastecimento quando a quantidade em litros, somado à quantidade no tanque, seja maior que a capacidade informada no cadastro do veículo."
            >
              <Help
                style={{ color: "#58595B", marginTop: 6, cursor: "pointer" }}
                fontSize="small"
              />
            </Tooltip>
          </td>
        </tr>
        <tr>
          <td style={handleChangeStyle(consumptionPermission.status)}>
            <span
              onClick={() =>
                setOpenConsumptionDialog(
                  consumptionPermission.status === "ACTIVE"
                )
              }
            >
              Restringir o abastecimento com base na média de consumo
            </span>
          </td>
          <td>
            <Switch
              checked={consumptionPermission.status === "ACTIVE"}
              color="primary"
              onChange={(_, checked) =>
                handleConsumptionCheckboxChanged(checked)
              }
            />
          </td>
          <td>
            <Tooltip
              placement="bottom-end"
              arrow
              title="Para autorizar o abastecimento, o consumo médio calculado não deve ser superior ao consumo informado no cadastro do veículo somado ao percentual definido."
            >
              <Help
                style={{ color: "#58595B", marginTop: 6, cursor: "pointer" }}
                fontSize="small"
              />
            </Tooltip>
          </td>
        </tr>
        <tr>
          <td style={handleChangeStyle(intervalPermission.status)}>
            <span
              onClick={() =>
                setOpenIntervalDialog(intervalPermission.status === "ACTIVE")
              }
            >
              Restringir o abastecimento durante um período específico
            </span>
          </td>
          <td>
            <Switch
              checked={intervalPermission.status === "ACTIVE"}
              color="primary"
              onChange={(_, checked) => handleIntervalCheckboxChanged(checked)}
            />
          </td>
          <td>
            <Tooltip
              placement="bottom-end"
              arrow
              title="Restringe o abastecimento entre intervalos definidos."
            >
              <Help
                style={{ color: "#58595B", marginTop: 6, cursor: "pointer" }}
                fontSize="small"
              />
            </Tooltip>
          </td>
        </tr>
      </table>
      <DialogDriver
        selected={driverPermission}
        open={openDriverDialog}
        onCancel={() => setOpenDriverDialog(false)}
        onSave={handleSelectDrivers}
      />
      <DialogProduct
        selected={fuelPermission}
        open={openFuelDialog}
        onCancel={() => setOpenFuelDialog(false)}
        onSave={handleSelectFuels}
      />
      <DialogConsumption
        selected={consumptionPermission}
        open={openConsumptionDialog}
        onCancel={() => setOpenConsumptionDialog(false)}
        onSave={handleSelectConsumption}
      />
      <DialogInterval
        selected={intervalPermission}
        open={openIntervalDialog}
        onCancel={() => setOpenIntervalDialog(false)}
        onSave={handleSelectInterval}
      />
      <Loading open={loading} />
    </div>
  );
};

export default VehiclePermission;
