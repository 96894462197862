import React, { useEffect, useState } from "react";
import { Title } from "@components/Title/Title";
import { DataTable } from "@components/DataTable";
import StyleSheet from "./StyleSheet";
import { useUserContext } from "@hooks/UserContext";
import { useIoCContext } from "@hooks/IoCContext";
import { IReportBalanceService } from "@modules/reports/models/IReportBalanceService";
import { Types } from "@ioc/types";
import { Filter, SearchContainer } from "@components/SearchContainer";
import { ColumnReference } from "@pages/Reports/Balance/ColumnReference";
import { SearchInitialValues } from "../../../client/model/Search";
import { ReportCreditClient } from "../../../client/ReportCreditClient";
import { ReportBalanceClient } from "../../../client/ReportBalanceClient";
import { Button } from "@components/Button";
import { AddBox, Info } from "@material-ui/icons";
import { IReportBalance } from "@modules/reports/dtos/IReportBalanceDTO";
import { ReportBalance } from "../../../client/model/ReportBalance";
import { ModalInfoBalance } from "@pages/Reports/Balance/ModalInfoBalance";

const BalanceReportPage: React.FC = () => {
  const styleSheet = StyleSheet();
  const { selectedCompany, companyInfo } = useUserContext();
  const iocContext = useIoCContext();
  const [limit] = useState(9);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [report, setReport] = useState(SearchInitialValues);
  const [infoBalanceModal, setInfoBalanceModal] = useState<{
    open: boolean;
    reportData: ReportBalance | null;
  }>({
    open: false,
    reportData: null,
  });
  const [filters, setFilters] = useState<Filter[]>([
    {
      label: "Geral",
      field: "query",
      placeholder: "Pesquisar por Usuário",
    },
  ]);

  const reportBalanceClient = iocContext.serviceContainer.get<ReportBalanceClient>(
    Types.ReportBalance
  );

  const handleFilter = (filtered: Filter[]) => {
    setFilters(filtered);
  };

  const fetchReportBalance = () => {
    if (!selectedCompany) return;
    setLoading(true);
    const query = filters.find((filter) => filter.field === "query");
    const filter = {
      page: page,
      limit: limit,
      q: query?.value,
    };
    reportBalanceClient.find(selectedCompany.uuid, filter).then((result) => {
      setLoading(false);
      setReport(result);
    });
  };

  const download = () => {
    if (!selectedCompany) return;
    setLoading(true);
    const query = filters.find((filter) => filter.field === "query");
    const filter = {
      page: page,
      limit: limit,
      q: query?.value,
    };
    reportBalanceClient
      .download(selectedCompany.uuid, filter)
      .then((result) => {
        setLoading(false);
        window.open(result);
      });
  };

  useEffect(fetchReportBalance, [selectedCompany, page, filters]);

  return (
    <div className={styleSheet.container}>
      <Title subtitles={["Saldo Individual"]}>Relatório</Title>
      <SearchContainer filters={filters} onFilter={handleFilter}>
        <Button onClick={download}>Download</Button>
      </SearchContainer>
      <DataTable
        columns={ColumnReference(!!companyInfo?.in_money)}
        rows={report.result}
        limit={limit}
        pages={report.pages}
        total={report.count}
        loading={loading}
        onClick={(row) =>
          setInfoBalanceModal({
            open: true,
            reportData: row,
          })
        }
        onPagination={(page) => setPage(page)}
      />
      <ModalInfoBalance
        title={infoBalanceModal.reportData?.user as string}
        open={infoBalanceModal.open}
        closeFunc={() =>
          setInfoBalanceModal({
            open: false,
            reportData: null,
          })
        }
        reportData={infoBalanceModal.reportData as ReportBalance}
      />
    </div>
  );
};

export { BalanceReportPage };
