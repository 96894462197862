import { createStyles, makeStyles } from "@material-ui/core";
import { yellow } from "@material-ui/core/colors";

export default makeStyles((theme) =>
  createStyles({
    container: {
      padding: "20px",
      backgroundColor: "#FFF",
      borderRadius: "4px",
      webkitBoxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    },
    textField: {
      padding: "0px 3px",
    },
    input: {
      height: 30,
    },
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    donutTitle: {
      fontWeight: "bold",
      fontSize: "24px",
      textAlign: "center",
    },
    donutSubtitle: {
      fontSize: "14px",
      textAlign: "center",
    },
    legend: {
      display: "flex",
      alignItems: "center",
      flex: "1 1 20rem",
    },
    circleLegend: {
      minHeight: "1.6rem",
      minWidth: "1.6rem",
      borderRadius: "50%",
    },
    legendText: {
      marginLeft: "1rem",
      color: theme.palette.tertiary.textButtons.primary,
      fontSize: "1.6rem",
    },
    chart_container: {
      width: "570px",
    },
    chart_container_tile: {
      width: "100%",
      textAlign: "center",
      fontSize: "22px",
      fontWeight: "bold",
    },
  })
);
