import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IAutocompleteUsersDTO } from "../dtos/IAutocompleteUsersDTO";
import { IAutocompleteUsersQueryService } from "../models/IAutocompleteUsersQueryService";

@injectable()
export class AutocompleteUsersQueryService
  implements IAutocompleteUsersQueryService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    query: string;
  }): Promise<IAutocompleteUsersDTO> {
    const resp = await this.httpInstance.get<IAutocompleteUsersDTO>(
      `/companies/${data.companyID}/users/autocomplete?q=${data.query}`
    );

    return resp;
  }
}
