import {
  CardContent,
  createStyles,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { IPermissibilityFleetDTO } from "@modules/company/dtos/IListPermissibilityFleetDTO";
import clsx from "clsx";
import { format } from "date-fns/esm";
import React from "react";
import { useInvalidTransactions } from "./InvalidTransactionsContext";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontSize: "2rem",
      fontWeight: "bold",
    },
    dialogPaper: {
      minWidth: "95vw",
      [theme.breakpoints.up("sm")]: {
        minWidth: "50vw",
      },
      [theme.breakpoints.up("md")]: {
        minWidth: "65vw",
      },
    },
    divider: {
      backgroundColor: theme.palette.tertiary.background.primary,
    },
    sectionTitles: {
      color: theme.palette.tertiary.textButtons.primary,
    },
    text: {
      fontSize: "1.6rem",
    },
    justifiedBy: {
      order: 2,
      [theme.breakpoints.up("sm")]: {
        order: "initial",
      },
    },
  })
);

const getDataAvgConsumption = (infoDialog: IPermissibilityFleetDTO) => {
  return {
    meanConsumption: infoDialog.actual_behaviour
      .split(": ")[1]
      .split("km/L")[0],
    expectedConsumption: {
      min: infoDialog.expected_behaviour.split("km/L")[0].split("entre")[1],
      max: infoDialog.expected_behaviour.split("km/L e ")[1].split("km/L")[0],
    },
  };
};

const DialogInfo: React.FC = () => {
  const classes = useStyles();
  const { openDialog, infoDialog, setOpenDialog } = useInvalidTransactions();

  let cardContent;

  if (infoDialog?.type === "AVERAGE_CONSUMPTION_EXCEPTION") {
    const data = getDataAvgConsumption(infoDialog);
    cardContent = (
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography className={clsx(classes.sectionTitles, classes.text)}>
            Ocorrido
          </Typography>
          <Typography className={classes.text} style={{ marginTop: ".8rem" }}>
            Consumo médio calculado:
          </Typography>
          <Typography className={classes.text}>
            {`${data.meanConsumption} KM/L`}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            align="right"
            className={clsx(classes.sectionTitles, classes.text)}
          >
            Esperado
          </Typography>
          <Typography
            align="right"
            className={classes.text}
            style={{ marginTop: ".8rem" }}
          >
            Consumo médio esperado:
          </Typography>
          <Typography className={classes.text} align="right">
            {`entre ${data.expectedConsumption.min} KM/L e ${data.expectedConsumption.max} KM/L`}
          </Typography>
        </Grid>
      </Grid>
    );
  } else {
    cardContent = (
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography className={clsx(classes.sectionTitles, classes.text)}>
            Ocorrido
          </Typography>
          <Typography className={classes.text} style={{ marginTop: ".8rem" }}>
            {infoDialog?.actual_behaviour}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            align="right"
            className={clsx(classes.sectionTitles, classes.text)}
          >
            Esperado
          </Typography>
          <Typography
            align="right"
            className={classes.text}
            style={{ marginTop: ".8rem" }}
          >
            {infoDialog?.expected_behaviour}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return infoDialog ? (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle disableTypography>
        <Grid container alignItems="center">
          <Grid item xs={11}>
            <Typography className={classes.title}>
              {infoDialog?.description}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => setOpenDialog(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider className={classes.divider} />
      <CardContent>
        {cardContent}
        <div style={{ marginTop: "2.4rem" }}>
          <Typography className={clsx(classes.text, classes.sectionTitles)}>
            Dados informados no ato do abastecimento
          </Typography>
          <Typography className={classes.text}>
            {`Quilometragem: ${infoDialog?.mileage.toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
            })}`}
          </Typography>
          <Typography className={classes.text}>
            Quantidade de litros: {infoDialog?.quantity_liter}
          </Typography>
        </div>
        {infoDialog.able_to_release && (
          <>
            <Divider
              className={classes.divider}
              style={{ margin: "1.6rem 0" }}
            />
            <Grid container>
              <Grid item xs={6} sm={4}>
                <Typography
                  className={clsx(classes.text, classes.sectionTitles)}
                >
                  Data da utilização
                </Typography>
                <Typography className={classes.text}>
                  {format(infoDialog?.released_at as Date, "dd/MM/yyy HH:mm")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} className={classes.justifiedBy}>
                <Typography
                  className={clsx(classes.text, classes.sectionTitles)}
                >
                  Justificado por
                </Typography>
                <Typography className={classes.text}>
                  {infoDialog.release_issuer.name}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography
                  align="right"
                  className={clsx(classes.text, classes.sectionTitles)}
                >
                  Data da justificação
                </Typography>
                <Typography align="right" className={classes.text}>
                  {format(infoDialog.issued_at, "dd/MM/yyyy HH:mm")}
                </Typography>
              </Grid>
            </Grid>
            {infoDialog.issuer_driver && (
              <Grid container style={{ marginTop: "2.4rem" }}>
                <Grid item xs={8}>
                  <Typography
                    className={clsx(classes.text, classes.sectionTitles)}
                  >
                    Motorista utilizador
                  </Typography>
                  <Typography className={classes.text}>
                    {infoDialog.issuer_driver.tx_motorista}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    className={clsx(classes.text, classes.sectionTitles)}
                    align="right"
                  >
                    Matrícula
                  </Typography>
                  <Typography className={classes.text} align="right">
                    {infoDialog.issuer_driver.tx_matricula}
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "2.4rem" }}>
                  <Typography
                    className={clsx(classes.text, classes.sectionTitles)}
                  >
                    Justificativa
                  </Typography>
                  {infoDialog.justification_for_release}
                </Grid>
              </Grid>
            )}
          </>
        )}
      </CardContent>
    </Dialog>
  ) : (
    <></>
  );
};

export { DialogInfo };
