import { Search } from "../model/Search";
import { inject, injectable } from "inversify";
import { Types } from "@ioc/types";
import Maintenance, { MaintenanceFilter } from "./Maintenance";

export interface MaintenanceClient {
  save(companyId: string, maintenance: Maintenance): Promise<Maintenance>;

  update(
    companyId: string,
    maintenanceId: string,
    maintenance: Maintenance
  ): Promise<void>;

  find(
    companyId: string,
    filter?: MaintenanceFilter
  ): Promise<Search<Maintenance>>;

  findById(companyId: string, maintenanceId: string): Promise<Maintenance>;

  remove(companyId: string, maintenanceId: string): Promise<void>;
}

@injectable()
export class MaintenanceClientImpl implements MaintenanceClient {
  @inject(Types.FlagcardCompanyWrapper)
  // @ts-ignore
  private instance: FlagcardCompanyWrapper;

  private readonly endpoint = "/core/v1/orders";

  public save(
    companyId: string,
    maintenance: Maintenance
  ): Promise<Maintenance> {
    return this.instance.post(this.endpoint, maintenance, {
      headers: { "x-tenant-id": companyId },
    });
  }

  public update(
    companyId: string,
    maintenanceId: string,
    maintenance: Maintenance
  ): Promise<void> {
    return this.instance.patch(
      `${this.endpoint}/${maintenanceId}`,
      maintenance,
      {
        headers: { "x-tenant-id": companyId },
      }
    );
  }

  public find(
    companyId: string,
    filter?: MaintenanceFilter
  ): Promise<Search<Maintenance>> {
    return this.instance
      .get(this.endpoint, {
        params: { ...filter, company_id: companyId },
        headers: { "x-tenant-id": companyId },
      })
      .then(({ result, total, page, pages }: any) => ({
        page,
        pages,
        count: total,
        result: result.map((maintenance: Maintenance) => ({
          ...maintenance,
          createdAt: maintenance.createdAt
            ? new Date(maintenance.createdAt)
            : null,
        })),
      }));
  }

  public findById(
    companyId: string,
    maintenanceId: string
  ): Promise<Maintenance> {
    return this.instance.get(`${this.endpoint}/${maintenanceId}`, {
      headers: { "x-tenant-id": companyId },
    });
  }

  public remove(companyId: string, maintenanceId: string): Promise<void> {
    return this.instance.delete(`${this.endpoint}/${maintenanceId}`, {
      headers: { "x-tenant-id": companyId },
    });
  }
}
