import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IListVehicleDTO } from "../dtos/IListVehicleDTO";
import { IListVehiclesService } from "../models/IListVehiclesService";

@injectable()
export class ListVehiclesService implements IListVehiclesService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    page: number;
    limit: number;
    statusID?: string;
    departmentID?: string;
    costCenterID?: string;
    isEquipments?: boolean;
    query?: string;
  }): Promise<IListVehicleDTO> {
    const resp = await this.httpInstance.get<IListVehicleDTO>(
      `/fleet/vehicle/${data.companyID}`,
      {
        params: {
          page: data.page,
          limit: data.limit,
          statusId: data.statusID,
          departmentId: data.departmentID,
          costcenterId: data.costCenterID,
          type: data.isEquipments ? "equipments" : "",
          q: data.query,
        },
      }
    );

    resp.result = resp.result.map((vehicle) => {
      return {
        ...vehicle,
        departament_id: vehicle.departament_id
          ? String(vehicle.departament_id)
          : null,
        cost_center_id: vehicle.cost_center_id
          ? String(vehicle.cost_center_id)
          : null,
      };
    });

    return resp;
  }
}
