import React, { useEffect, useState } from "react";
import { Title } from "@components/Title/Title";
import { Formik } from "formik";
import { Loading } from "@components/Loading";
import Message from "@components/Message";
import { useUserContext } from "@hooks/UserContext";
import { useHistory, useParams } from "react-router";
import { Types } from "@ioc/types";
import { useIoCContext } from "@hooks/IoCContext";
import Validation from "./Form/Validation";
import StyleSheet from "../List/StyleSheet";
import { ROUTES } from "@config/routesConfig";
import AppError from "@utils/AppError";
import { CostCenterClient } from "../../../client/CostCenter/CostCenterClient";
import {
  CostCenter,
  CostCenterInitialValues,
} from "../../../client/CostCenter/CostCenter";
import CostCenterEditForm from "@pages/CostCenter/Edit/Form";

const CostCenterEdit: React.FC = () => {
  const styleSheet = StyleSheet();
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const history = useHistory();
  const params = useParams<{ id?: string }>();
  const [costCenterId] = useState(params.id);
  const [costCenter, setCostCenter] = useState<CostCenter>(
    CostCenterInitialValues
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const client = iocContext.serviceContainer.get<CostCenterClient>(
    Types.CostCenter
  );

  const fetchCostCenter = () => {
    if (!selectedCompany) return;
    if (!costCenterId) return;
    setLoading(true);
    client
      .findById(selectedCompany.id, costCenterId)
      .then((result) => {
        setCostCenter(result);
        setLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error.response?.data?.message);
        setError(true);
        setLoading(false);
      });
  };

  useEffect(fetchCostCenter, [selectedCompany, costCenterId, client]);

  const handleSubmit = async (event: CostCenter) => {
    setLoading(true);
    try {
      const payload = { description: event.description };
      if (selectedCompany) {
        if (!costCenterId) {
          await client.save(selectedCompany.uuid, payload);
        } else {
          await client.update(selectedCompany.uuid, costCenterId, payload);
        }
        history.push({
          pathname: ROUTES.COST_CENTERS,
          state: { success: true },
        });
      } else {
        throw new AppError("company_id is undefined", "error");
      }
    } catch (error) {
      if (error instanceof AppError) {
        setErrorMessage(error.message);
      }
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styleSheet.container}>
      <Title subtitles={costCenterId ? ["Editar"] : ["Novo"]}>
        Centro de Custo
      </Title>
      <Formik
        onSubmit={(event) => handleSubmit(event as CostCenter)}
        enableReinitialize
        initialValues={costCenter}
        validationSchema={Validation}
        validateOnBlur={false}
        validateOnChange={false}
      >
        <CostCenterEditForm />
      </Formik>
      <Loading open={loading} />
      <Message.Error
        open={error}
        onClose={() => {
          setError(false);
          setErrorMessage("");
        }}
      >
        {errorMessage}
      </Message.Error>
    </div>
  );
};

export default CostCenterEdit;
