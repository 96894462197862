import React, { useEffect, useState } from "react";
import { Types } from "@ioc/types";
import { useUserContext } from "@hooks/UserContext";
import { useIoCContext } from "@hooks/IoCContext";
import { FolderClient } from "../../../../client/folder/FolderClient";
import { useParams } from "react-router";
import { DocumentManager } from "@components/DocumentManager";

const VehicleDocument: React.FC<{ vehicle: string; plate: string }> = ({
  vehicle,
  plate,
}) => {
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const params = useParams<{ id?: string }>();
  const [vehicleId] = useState(params.id);
  const [folderId, setFolderId] = useState<string>();

  const folderClient = iocContext.serviceContainer.get<FolderClient>(
    Types.Folder
  );

  const createFolder = () => {
    if (!selectedCompany) return;
    const description = `[${plate}] - ${vehicle}`;
    return folderClient
      .save(selectedCompany.uuid, {
        description,
        emails: [],
        resource: {
          id: vehicleId,
          description,
          type: "vehicle",
        },
      })
      .then((result) => {
        setFolderId(result.id);
      });
  };

  const fetchFolders = () => {
    if (vehicle === "Veículo") {
      return;
    }
    if (!selectedCompany) return;
    const filter = {
      page: 1,
      limit: 10,
      resource: "vehicle",
      resourceId: vehicleId,
    };
    folderClient.find(selectedCompany.uuid, filter).then(({ result }) => {
      if (result.length === 0) {
        createFolder();
      } else {
        setFolderId(result[0].id);
      }
    });
  };

  useEffect(fetchFolders, [vehicle]);

  return <DocumentManager folderId={folderId} />;
};

export default VehicleDocument;
