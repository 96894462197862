import React, {useCallback, useEffect, useState} from "react";
import StyleSheet from "./StyleSheet";
import {Title} from "@components/Title/Title";
import {Button} from "@components/Button";
import {Filter, SearchContainer} from "@components/SearchContainer";
import {Grid, Menu, Typography} from "@material-ui/core";
import Moment from "moment";
import {extendMoment} from "moment-range";
import DateRangePicker, {OnSelectCallbackParam} from "react-daterange-picker";
import {DataTable} from "@components/DataTable";
import {ColumnReference} from "./ColumnReference";
import {useIoCContext} from "@hooks/IoCContext";
import {useUserContext} from "@hooks/UserContext";
import {Types} from "@ioc/types";
import {ReportCreditClient} from "../../../client/ReportCreditClient";
import {SearchInitialValues} from "../../../client/model/Search";
// @ts-ignore
const moment = extendMoment(Moment);

const CreditManagementReportPage: React.FC = () => {
    const styleSheet = StyleSheet();
    const {selectedCompany, companyInfo} = useUserContext();
    const iocContext = useIoCContext();
    const [dateRangeSelectorPosition, setDateRangeSelectorPosition] = useState<HTMLElement>();
    const [openDateRangeSelector, setOpenDateRangeSelector] = useState(false);
    const [selectedRangeDate, setSelectedRangeDate] = useState(moment.range(moment().startOf("month"), moment()));
    const [limit] = useState(9);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [report, setReport] = useState(SearchInitialValues);
    const [filters, setFilters] = useState<Filter[]>([
        {
            label: "Geral",
            field: "query",
            placeholder: "Pesquisar por Usuário ou Veículo",
        },
    ]);

    const reportCreditClient = iocContext.serviceContainer.get<ReportCreditClient>(
        Types.ReportCredit
    );

    const handleFilter = (filtered: Filter[]) => {
        setFilters(filtered);
    };

    const handleSelectRangeDate = (event: OnSelectCallbackParam) => {
        setSelectedRangeDate(moment.range(event.start, event.end));
        setOpenDateRangeSelector(false);
    };

    const fetchReportCredit = () => {
        if (!selectedCompany) return;
        setLoading(true);
        const query = filters.find((filter) => filter.field === "query");
        const filter = {
            page: page,
            limit: limit,
            q: query?.value,
        };
        reportCreditClient
            .find(
                selectedCompany.uuid,
                selectedRangeDate.start.toDate(),
                selectedRangeDate.end.toDate(),
                filter
            )
            .then((result) => {
                setLoading(false);
                setReport(result);
            });
    };

    const download = () => {
        if (!selectedCompany) return;
        setLoading(true);
        const query = filters.find((filter) => filter.field === "query");
        const filter = {
            page: page,
            limit: limit,
            q: query?.value,
        };
        reportCreditClient.download(
            selectedCompany.uuid,
            selectedRangeDate.start.toDate(),
            selectedRangeDate.end.toDate(),
            filter
        ).then((result) => {
                setLoading(false);
                window.open(result);
            });
    };

    useEffect(fetchReportCredit, [
        selectedCompany,
        page,
        filters,
        limit,
        selectedRangeDate,
        reportCreditClient,
    ])

    return (<div className={styleSheet.container}>
        <Title subtitles={["Gerencial", "Créditos"]}>Relatório</Title>
        <SearchContainer filters={filters} onFilter={handleFilter}>
            <Button onClick={download}>Download</Button>
        </SearchContainer>
        <Grid container>
            <Typography
                className={styleSheet.dateRangeLabel}
                style={{color: "#000"}}
            >
                Data:&nbsp;
            </Typography>
            <Typography
                className={styleSheet.dateRangeLabel}
                onClick={(event) => {
                    setOpenDateRangeSelector(true);
                    setDateRangeSelectorPosition(event.currentTarget);
                }}
            >
                {moment(selectedRangeDate.start).format("DD/MM/yyyy") +
                " a " +
                moment(selectedRangeDate.end).format("DD/MM/yyyy")}
            </Typography>
        </Grid>
        <div style={{height: 12}}/>
        <DataTable
            columns={ColumnReference(!!companyInfo?.in_money)}
            rows={report.result}
            limit={limit}
            pages={report.pages}
            total={report.count}
            loading={loading}
            onPagination={(page) => setPage(page)}
        />
        <Menu
            anchorEl={dateRangeSelectorPosition}
            anchorOrigin={{vertical: "bottom", horizontal: "left"}}
            getContentAnchorEl={null}
            onClose={() => setOpenDateRangeSelector(false)}
            open={openDateRangeSelector}
        >
            <DateRangePicker
                onSelect={handleSelectRangeDate}
                value={selectedRangeDate}
            />
        </Menu>
    </div>)
};

export {CreditManagementReportPage};
