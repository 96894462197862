import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "@config/routesConfig";

const NotAuthorized: React.FC<{ module: string }> = ({ module }) => {
  return (
    <div>
      O Módulo <span style={{ fontWeight: "bolder" }}>{module}</span> não esta
      ativado para esta empresa, entre em contato com o nosso{" "}
      <Link to={ROUTES.HELP}>suporte</Link>
    </div>
  );
};

export default NotAuthorized;
