import Chartist from "chartist";
import Dashboard from "../../../client/fleet/dashboard/Dashboard";
import React, { useEffect } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { formatCurrency } from "@utils/index";
import StyleSheet from "@pages/Fleet/Dashboard/StyleSheet";

export const ChartBarDebitByCostCenter: React.FC<{ dashboard?: Dashboard }> = ({
  dashboard,
}) => {
  const styleSheet = StyleSheet();
  const render = () => {
    if (dashboard) {
      const labels =
        dashboard.debit?.byCostCenter?.map((summary) => summary.key) || [];
      const series =
        dashboard.debit?.byCostCenter?.map((summary) => summary.value) || [];
      const chart = new Chartist.Bar(
        "#chart_bar_debit_cost_center",
        {
          labels,
          series: [series],
        },
        {
          stackBars: true,
          axisY: {
            labelInterpolationFnc: function (value: number) {
              return value / 1000 + "k";
            },
          },
          plugins: [
            Chartist.plugins.tooltip({
              transformTooltipTextFnc: (value: number) => formatCurrency(value),
              appendToBody: true,
            }),
          ],
        }
      );

      chart.on("draw", (data: any) => {
        if (data.type === "bar") {
          data.element.attr({
            style: "stroke-width: 30px;stroke: #4169E1",
          });
        }
      });
    }
  };
  useEffect(render, [dashboard]);
  return (
    <Card>
      <CardHeader
        title={
          <div className={styleSheet.chart_container_tile}>
            Valor Total dos Débitos por Centro de Custo
          </div>
        }
        style={{ justifyContent: "center", display: "flex" }}
      />
      <Divider />
      <CardContent style={{ display: "flex", justifyContent: "center" }}>
        <div
          id="chart_bar_debit_cost_center"
          className={clsx("ct-chart", "ct-octave", styleSheet.chart_container)}
        />
      </CardContent>
      <CardActions>
        <div className={styleSheet.legend}>
          <div
            className={styleSheet.circleLegend}
            style={{ backgroundColor: "#4169E1" }}
          />
          <Typography className={styleSheet.legendText}>
            Total Acumulado
          </Typography>
        </div>
      </CardActions>
    </Card>
  );
};
