import {
  IExceptionTransactionDTO,
  IListExceptionTransactionsDTO,
} from "@modules/company/dtos/IListExceptionTransactionsDTO";
import React, { createContext, useContext, useState } from "react";

interface IExceptionTransactionsContext {
  page: number;
  limit: number;
  transactions: IListExceptionTransactionsDTO;
  loadingTransactions: boolean;
  errorLoadingTransactions: boolean;
  openDialog: boolean;
  infoDialog: IExceptionTransactionDTO | null;
  setLoadingTransactions: (data: boolean) => void;
  setTransactions: (data: IListExceptionTransactionsDTO) => void;
  setPage: (data: number) => void;
  setErrorLoadingTransactions: (data: boolean) => void;
  setOpenDialog: (data: boolean) => void;
  setInfoDialog: (data: IExceptionTransactionDTO) => void;
}

const ExceptionTransactionsContext = createContext(
  {} as IExceptionTransactionsContext
);

const ExceptionTransactionsProvider: React.FC = ({ children }) => {
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [infoDialog, setInfoDialog] = useState<IExceptionTransactionDTO | null>(
    null
  );
  const [errorLoadingTransactions, setErrorLoadingTransactions] = useState(
    false
  );
  const [page, setPage] = useState(1);
  const [
    transactions,
    setTransactions,
  ] = useState<IListExceptionTransactionsDTO>({
    page: 1,
    count: 0,
    result: [],
  });

  return (
    <ExceptionTransactionsContext.Provider
      value={{
        limit: 5,
        page,
        transactions,
        loadingTransactions,
        errorLoadingTransactions,
        openDialog,
        infoDialog,
        setLoadingTransactions,
        setTransactions,
        setPage,
        setErrorLoadingTransactions,
        setOpenDialog,
        setInfoDialog,
      }}
    >
      {children}
    </ExceptionTransactionsContext.Provider>
  );
};

const useExceptionTransactions = () => {
  const context = useContext(ExceptionTransactionsContext);
  if (Object.values(context).length === 0) {
    throw new Error(
      "useExceptionTransactions não pode ser utilizado fora de um ExceptionTransactionsProvider"
    );
  }
  return context;
};

export { useExceptionTransactions, ExceptionTransactionsProvider };
