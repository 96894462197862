/* eslint-disable @typescript-eslint/ban-ts-comment */
import { appConfig } from "@config/appConfig";
import AppError from "@utils/AppError";
import { goToLogout } from "@utils/index";
import Axios, { AxiosError, AxiosInstance } from "axios";
import { injectable } from "inversify";
import { IHttpService } from "../models/IHttpService";

@injectable()
export default class AxiosHttpService implements IHttpService {
  // @ts-ignore
  private httpInstance: AxiosInstance;

  constructor() {
    this.httpInstance = Axios.create({
      timeout: appConfig.api.timeout,
      baseURL:
        process.env.NODE_ENV === "production"
          ? appConfig.api.url.production
          : appConfig.api.url.development,
    });
  }

  setAuthorization(token: string): void {
    this.httpInstance.defaults.headers.common.Authorization = token;
  }

  get<T = any>(path: string, params?: {}): Promise<T> {
    return this.httpInstance
      .get<T>(path, params)
      .then(({ data }) => data)
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;
        if (error.response && error.response.status === 403) {
          goToLogout();
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          throw new AppError(
            error.response.data.message,
            "error",
            error.response.status
          );
        } else {
          throw new AppError("Não foi possível realizar a operação", "error");
        }
      });
  }

  post<T = any>(path: string, body: any, params?: {}): Promise<T> {
    return this.httpInstance
      .post<T>(path, body, params)
      .then(({ data }) => data)
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;
        if (error.response && error.response.status === 403) {
          goToLogout();
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          throw new AppError(error.response.data.message, "error");
        } else {
          throw new AppError("Não foi possível realizar a operação", "error");
        }
      });
  }

  delete<T = any>(path: string, params?: {}): Promise<T> {
    return this.httpInstance
      .delete<T>(path, params)
      .then(({ data }) => data)
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;
        if (error.response && error.response.status === 403) {
          goToLogout();
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          throw new AppError(error.response.data.message, "error");
        } else {
          throw new AppError("Não foi possível realizar a operação", "error");
        }
      });
  }

  patch<T = any>(path: string, body?: any, params?: {}): Promise<T> {
    return this.httpInstance
      .patch<T>(path, body, params)
      .then(({ data }) => data)
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;
        if (error.response && error.response.status === 403) {
          goToLogout();
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          throw new AppError(error.response.data.message, "error");
        } else {
          throw new AppError("Não foi possível realizar a operação", "error");
        }
      });
  }

  put<T = any>(path: string, body?: any, params?: {}): Promise<T> {
    return this.httpInstance
      .put<T>(path, body, params)
      .then(({ data }) => data)
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;
        if (error.response && error.response.status === 403) {
          goToLogout();
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          throw new AppError(error.response.data.message, "error");
        } else {
          throw new AppError("Não foi possível realizar a operação", "error");
        }
      });
  }
}
