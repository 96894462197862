import React, { Fragment, useEffect, useState } from "react";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Driver } from "../../../../client/model/Driver";
import { Button } from "@components/Button";
import StyleSheet from "@pages/Vehicles/StyleSheet";
import { DriverClient } from "../../../../client/DriverClient";
import { Types } from "@ioc/types";
import { useUserContext } from "@hooks/UserContext";
import { useIoCContext } from "@hooks/IoCContext";
import { SearchInitialValues } from "../../../../client/model/Search";
import { DataTable } from "@components/DataTable";
import Permission from "../../../../client/permission/Permission";

interface DialogDriverProps {
  open: boolean;
  selected?: Permission;
  onCancel: () => void;
  onSave: (drivers: Driver[]) => void;
}

export const DialogDriver: React.FC<DialogDriverProps> = (props) => {
  const styleSheet = StyleSheet();
  const { selected, open, onCancel, onSave } = props;
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState(SearchInitialValues);
  const [selectedDrivers, setSelectedDrivers] = useState<Driver[]>(
    selected?.options?.permissions || []
  );
  const [driver, setDriver] = useState<Driver | null>(null);
  const [description, setDescription] = useState("");
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();

  const driverClient = iocContext.serviceContainer.get<DriverClient>(
    Types.Driver
  );

  const handleDriverChange = (driver: Driver) => {
    setDriver(null);
    setDescription("");
    setSelectedDrivers(Array.from(new Set([...selectedDrivers, driver])));
  };

  const handleCancel = () => {
    onCancel();
    setDriver(null);
    setDescription("");
    setSelectedDrivers([]);
  };

  const handleRemove = (driverId?: string) => {
    setSelectedDrivers(
      selectedDrivers.filter((driver) => driver.id !== driverId)
    );
  };

  const fetchDriver = () => {
    if (!selectedCompany) return;
    setLoading(true);
    driverClient
      .find(selectedCompany.uuid, {
        page: 1,
        limit: 10,
        q: description,
      })
      .then((response) => {
        setDrivers(response);
      })
      .finally(() => setLoading(false));
  };

  useEffect(fetchDriver, [description, selectedCompany]);
  useEffect(() => {
    if (open) {
      setSelectedDrivers(selected?.options?.permissions || []);
    }
  }, [open]);

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle disableTypography style={{ padding: "0px 16px" }}>
        <h2>Restrição por motoristas autorizados</h2>
      </DialogTitle>
      <DialogContent style={{ padding: "16px" }}>
        <Autocomplete
          blurOnSelect
          loading={loading}
          loadingText="Carregando..."
          filterOptions={(x) => x}
          options={drivers.result}
          getOptionLabel={(option?: Driver) => option?.driver || "-"}
          getOptionSelected={(option, value) =>
            option?.driver === value?.driver
          }
          value={driver}
          size="small"
          style={{ width: "100%" }}
          noOptionsText={driver?.driver || "Nenhum motorista selecionado"}
          onChange={(event, value) => {
            if (value) {
              handleDriverChange(value as Driver);
            }
          }}
          onInputChange={(event, newInputValue) => {
            setDescription(newInputValue);
          }}
          renderOption={(option?: Driver) => {
            return (
              <Typography className={styleSheet.autoCompleteText}>
                {option?.driver || ""}
              </Typography>
            );
          }}
          renderInput={(params) => (
            <TextField
              name="driverId"
              label="Motorista"
              variant="outlined"
              size="small"
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
          closeIcon={null}
        />
        <div style={{ height: 10 }} />
        <div style={{ overflowY: "scroll", height: 250 }}>
          <DataTable
            rows={selectedDrivers}
            columns={[
              { field: "driver", headerName: "Motorista" },
              { field: "cnh", headerName: "CNH" },
            ]}
            loading={loading}
            onRemove={handleRemove}
            removable
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleCancel}>
          Cancelar
        </Button>
        <Button color="primary" onClick={() => onSave(selectedDrivers)}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
