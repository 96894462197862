import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    container: {
      position: "relative",
      width: 0,
      "& div": {
        position: "relative",
        top: "50%",
        marginTop: "-13px",
        boxShadow: "inset 1px 0 0 0 rgb(255 255 255 / 25%)",
        webkitBoxShadow: "inset 1px 0 0 0 rgb(255 255 255 / 25%)",
        width: "1px",
        height: "26px",
      },
    },
  })
);
