import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { parse } from "date-fns";
import { inject, injectable } from "inversify";
import { IReportCreditDTO } from "../dtos/IReportCreditDTO";
import { IReportCreditService } from "../models/IReportCreditService";

@injectable()
export class ReportCreditService implements IReportCreditService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    from: Date;
    to: Date;
    query?: string | undefined;
    page: number;
    limit: number;
  }): Promise<IReportCreditDTO> {
    const resp = await this.httpInstance.get<IReportCreditDTO>(
      `/companies/${data.companyID}/report/credit-management/paged`,
      {
        params: {
          type: "CREDITO",
          page: data.page,
          limit: data.limit,
          startDate: data.from,
          endDate: data.to,
          q: data.query,
        },
      }
    );

    resp.result = resp.result.map((report) => {
      return {
        ...report,
        date: parse(
          (report.date as unknown) as string,
          "dd/MM/yyyy HH:mm",
          new Date()
        ),
        value: Number(report.value),
        quantity: Number(report.quantity),
        id: report.transaction_id,
      };
    });

    return resp;
  }
}
