export interface Document {
  id?: string;
  folderId: string;
  name: string;
  path: string;
  type?: string;
  extension: string;
  version?: string;
  expirationDate?: Date | null;
}

export const DocumentInitialValues: Document = {
  folderId: "",
  name: "",
  path: "",
  extension: "",
  expirationDate: null,
};
