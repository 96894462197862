import { Types } from "@ioc/types";
import { VehicleType } from "@modules/company/dtos/IListBrandVehicleDTO";
import { VehicleStatus } from "@modules/company/dtos/IListPermissibilityFleetDTO";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { format } from "date-fns";
import { inject, injectable } from "inversify";
import { IReportVehicleDTO } from "../dtos/IReportVehicleDTO";
import { IReportVehicleRankingService } from "../models/IReportVehicleRankingService";

@injectable()
export class ReportVehicleRankingService
  implements IReportVehicleRankingService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    from: Date;
    to: Date;
    page: number;
    limit: number;
    plate?: string;
    model?: string;
    type?: VehicleType;
    status?: VehicleStatus;
    costCenterID?: string;
    departmentID?: string;
  }): Promise<IReportVehicleDTO> {
    const resp = await this.httpInstance.get<IReportVehicleDTO>(
      `/companies/${data.companyID}/report/vehicle/rank/paged`,
      {
        params: {
          from_date: format(data.from, "yyyy-MM-dd"),
          to_date: format(data.to, "yyyy-MM-dd"),
          limit: data.limit,
          page: data.page,

          plate: data.plate,
          model: data.model,
          cost_center: data.costCenterID,
          department: data.departmentID,
          status: data.status,
          type: data.type,
        },
      }
    );

    resp.result = resp.result.map((report) => {
      return {
        ...report,
        consumption: Number(report.consumption),
        consumption_avg: Number(report.consumption_avg),
        newer_odometer: Number(report.newer_odometer),
      };
    });

    return resp;
  }
}
