import { themeOverrides } from "@components/Theme";
import DateFnsUtils from "@date-io/date-fns";
import "@fortawesome/fontawesome-free/css/all.css";
import { AuthProps, AuthProvider } from "@hooks/AuthContext";
import { IoCProvider } from "@hooks/IoCContext";
import { LayoutProvider } from "@hooks/LayoutContext";
import { UserProvider } from "@hooks/UserContext";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "chartist/dist/chartist.min.css";
import ptBrLocale from "date-fns/locale/pt-BR";
import { SnackbarProvider } from "notistack";
import React from "react";
import { LayoutFlex } from "@components/Layout";
const Container: React.FC<AuthProps> = ({ children, ...props }) => {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      maxSnack={1}
      preventDuplicate
      dense
    >
      <UserProvider>
        <LayoutProvider>
          <CssBaseline />
          <LayoutFlex />
        </LayoutProvider>
      </UserProvider>
    </SnackbarProvider>
  );
};

export const App: React.FC<AuthProps> = ({ children, ...props }) => {
  const theme = themeOverrides("light");
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
        <IoCProvider>
          <AuthProvider {...props}>
            <Container {...props} />
          </AuthProvider>
        </IoCProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
