import { DarkLightTheme } from "@hooks/DarkLightThemeContext";
import { createMuiTheme } from "@material-ui/core";

function hexToRGBWithAlpha(hex: string, alpha: number | string) {
  if (hex.length !== 7) {
    throw new Error("A cor deve possuir pelo menos 7 caracaters. Ex: #XXXXXX");
  }
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
}

export const themeOverrides = (typeTheme: DarkLightTheme) =>
  createMuiTheme({
    palette: {
      primary: {
        main: "#0F71D0",
        light: "#C4E1F8",
      },
      secondary: {
        main: "#313B45",
      },
      tertiary: {
        confirmation: {
          primary: "#78c263",
          secondary: "#e1fada",
        },
        intermediate: {
          primary: "#ffbf3a",
          secondary: "#fff2d4",
        },
        error: {
          primary: "#ff3a3a",
          secondary: "#ffd7d7",
        },
        textButtons: {
          primary: "#b0c1e1",
          secondary: "#9fa7b0",
        },
        background: {
          primary: "#f5f7fd",
        },
        boxAndBackgrounds: {
          primary: "#fff",
        },
        graphics: {
          "0": "#0F71D0",
          "1": "#ff3a82",
          "2": "#5f45ff",
          "3": "#f68059",
          "4": "#9fa7b0",
          "5": "#fba342",
        },
      },
      hexToRGBWithAlpha,
      type: typeTheme,
    },
    typography: {
      allVariants: {
        fontFamily: [
          '"IBM Plex Sans"',
          "-apple-system",
          "BlinkMacSystemFont",
          "sans-serif",
        ].join(","),
      },
      subtitle1: {
        fontWeight: "normal",
      },
      button: {
        fontFamily: ["-apple-system", "sans-serif"].join(","),
      },
      htmlFontSize: 10,
      fontSize: 14,
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "html,body,#root": {
            height: "100%",
          },
          html: {
            fontSize: 10,
          },
          body: {
            backgroundColor: typeTheme === "dark" ? "#303030" : "#f5f7fd",
          },
        },
      },
    },
    brand: {
      logo: {
        src: "/flagcard-logo.svg",
        icons: {
          64: { src: "/logo64.png" },
          192: { src: "/logo192.png" },
          512: { src: "/logo512.png" },
        },
      },
      manifest_url: "/manifest.json",
    },
  });
