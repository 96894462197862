import React from "react";
import StyleSheet from "./StyleSheet";

const VerticalDivider: React.FC<{ padding?: number }> = ({padding}) => {
    const styleSheet = StyleSheet();
    const style = {
        paddingLeft: padding || 0,
        paddingRight: padding || 0,
    };

    return (
        <div style={style} className={styleSheet.container}>
            <div></div>
        </div>
    );
};

export {VerticalDivider};
