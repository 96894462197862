import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IGetHistoryPriceProductsDTO } from "../dtos/IGetHistoryPriceProductsDTO";
import { IGetHistoryPriceProducts } from "../models/IGetHistoryPriceProducts";

@injectable()
export class GetHistoryPriceProducts implements IGetHistoryPriceProducts {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
  }): Promise<IGetHistoryPriceProductsDTO> {
    const response = await this.httpInstance.get<IGetHistoryPriceProductsDTO>(
      `/companies/${data.companyID}/report/consumption/prices`
    );
    return response;
  }
}
