import { Filter } from "../model/Filter";
import { Folder } from "./Folder";
import { Search } from "../model/Search";
import { inject, injectable } from "inversify";
import { Types } from "@ioc/types";

class FolderMapper {
  static toModel(folder: Folder): Folder {
    return {
      ...folder,
      externalReferenceId: folder.resource?.id,
      externalReferenceDescription: folder.resource?.description,
      externalReferenceType: folder.resource?.type || "general",
    };
  }
}

export interface FolderClient {
  save(companyId: string, folder: Folder): Promise<Folder>;

  update(companyId: string, folderId: string, folder: Folder): Promise<void>;

  find(companyId: string, filter?: Filter): Promise<Search<Folder>>;

  findById(companyId: string, folderId: string): Promise<Folder>;

  remove(companyId: string, folderId: string): Promise<void>;
}

@injectable()
export class FolderClientImpl implements FolderClient {
  @inject(Types.FlagcardCompanyWrapper)
  // @ts-ignore
  private instance: FlagcardCompanyWrapper;

  private readonly endpoint = "/rest/v1/folders";

  public save(companyId: string, folder: Folder): Promise<Folder> {
    return this.instance.post(this.endpoint, folder, {
      headers: { company_id: companyId },
    });
  }

  public update(
    companyId: string,
    folderId: string,
    folder: Folder
  ): Promise<void> {
    return this.instance.patch(`${this.endpoint}/${folderId}`, folder, {
      headers: { company_id: companyId },
    });
  }

  public find(companyId: string, filter?: Filter): Promise<Search<Folder>> {
    return this.instance
      .get(this.endpoint, {
        params: filter,
        headers: { company_id: companyId },
      })
      .then(({ result, total, page, pages }: any) => ({
        page,
        pages,
        count: total,
        result: result.map((folder: Folder) => FolderMapper.toModel(folder)),
      }));
  }

  public findById(companyId: string, folderId: string): Promise<Folder> {
    return this.instance
      .get(`${this.endpoint}/${folderId}`, {
        headers: { company_id: companyId },
      })
      .then((folder: Folder) => FolderMapper.toModel(folder));
  }

  public remove(companyId: string, folderId: string): Promise<void> {
    return this.instance.delete(`${this.endpoint}/${folderId}`, {
      headers: { company_id: companyId },
    });
  }
}
