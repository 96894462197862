import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    button: {
      height: "40px",
      fontWeight: "bold",
      padding: "0 28px !important",
      borderRadius: "4px",
      border: "none",
      cursor: "pointer",
    },
    primary: {
      backgroundColor: "#0F71D0",
      color: "#FFF",
    },
    secondary: {
      border: "solid 1px #0F71D0",
      backgroundColor: "#FFF",
      color: "#0F71D0",
    },
    warning: {
      border: "solid 1px #FF8C00",
      backgroundColor: "#FFF",
      color: "#FF8C00",
    },
    error: {
      border: "solid 1px #CC0000",
      backgroundColor: "#FFF",
      color: "#CC0000",
    },
    success: {
      border: "solid 1px #006633",
      backgroundColor: "#FFF",
      color: "#006633",
    },
  })
);
