import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    row: {
      paddingBottom: "12px",
      display: "flex",
      "& :last-child": {
        paddingRight: 0,
      },
    },
  })
);
