import React, { Fragment, useEffect, useState } from "react";
import { Form, useFormikContext } from "formik";
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";
import LocalCarWash from "@material-ui/icons/LocalCarWash";
import Maintenance from "@material-ui/icons/Build";
import Preventive from "@material-ui/icons/AccessTime";
import { Row } from "@components/Row";
import StyleSheet from "../../../StyleSheet";
import { Button } from "@components/Button";
import { ROUTES } from "@config/routesConfig";
import {
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { useUserContext } from "@hooks/UserContext";
import { useIoCContext } from "@hooks/IoCContext";
import { Types } from "@ioc/types";
import Vehicle from "../../../../../../client/vehicle/Vehicle";
import { MaintenanceDataPayload } from "../../../../../../client/Maintenance/Maintenance";
import { VehicleClient } from "../../../../../../client/vehicle/VehicleClient";
import { DataTable } from "@components/DataTable";
import AddIcon from "@material-ui/icons/AddBoxOutlined";
import Calendar from "@material-ui/icons/CalendarTodayOutlined";
import { DatePicker } from "@material-ui/pickers";

const formatVehicleLabel = (vehicle?: Vehicle): string => {
  if (!vehicle) {
    return "";
  }
  return `[${vehicle.plate}] - ${
    vehicle.model?.manufacturer?.description || ""
  } ${vehicle.model?.description || ""}`;
};

const MaintenanceDataForm: React.FC = () => {
  const styleSheet = StyleSheet();
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const {
    values,
    errors,
    setFieldValue,
  } = useFormikContext<MaintenanceDataPayload>();
  const [selectedMaintenanceType, setSelectedMaintenanceType] = useState(
    "MAINTENANCE"
  );
  const [loadingVehicles, setLoadingVehicles] = useState(false);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [vehicle, setVehicle] = useState<Vehicle | null>(null);
  const [vehiclePlate, setVehiclePlate] = useState("");
  const [report, setReport] = useState("");

  const vehicleClient = iocContext.serviceContainer.get<VehicleClient>(
    Types.Vehicle
  );

  const handleMaintenanceTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    selectedType: string
  ) => {
    setSelectedMaintenanceType(selectedType);
    setFieldValue("type", selectedType);
  };

  const handleVehicleChange = (selectedValue: Vehicle | null) => {
    setVehicle(selectedValue);
    setFieldValue("vehicleId", selectedValue?.id);
    setFieldValue("vehiclePlate", selectedValue?.plate);
    setFieldValue(
      "vehicleModel",
      `${selectedValue?.model?.manufacturer?.description} ${selectedValue?.model?.description}`
    );
    setVehiclePlate("");
  };

  const handleAddReport = () => {
    if (report) {
      const set = new Set(values.reports);
      set.add(report);
      setFieldValue("reports", Array.from(set));
      setReport("");
    }
  };

  const handleAddReportOnEnter = (event: any) => {
    if (event.key === "Enter") {
      handleAddReport();
      event.preventDefault();
    }
  };

  const handleRemoveReport = (id?: string) => {
    if (id) {
      const set = new Set(values.reports?.filter((report) => report !== id));
      setFieldValue("reports", Array.from(set));
    }
  };

  const fetchVehicles = () => {
    if (!selectedCompany) return;
    setLoadingVehicles(true);
    vehicleClient
      .find(selectedCompany.uuid, { plate: vehiclePlate })
      .then((response) => {
        setVehicles(response.result);
        setLoadingVehicles(false);
      })
      .catch(() => {
        setLoadingVehicles(false);
      });
  };

  useEffect(fetchVehicles, [vehiclePlate]);
  useEffect(() => {
    if (values.id) {
      setSelectedMaintenanceType(values.type || "MAINTENANCE");
      setVehicle({
        id: values.vehicleId,
        plate: values.vehiclePlate,
        model: {
          description: values.vehicleModel || "",
        },
      });
      setVehiclePlate(values.vehiclePlate || "");
    }
  }, [values]);

  return (
    <Form noValidate>
      <Row>
        <ToggleButtonGroup
          color="primary"
          value={selectedMaintenanceType}
          exclusive
          size="small"
          onChange={handleMaintenanceTypeChange}
        >
          <ToggleButton className={styleSheet.toggleButton} value="MAINTENANCE">
            <Maintenance className={styleSheet.toggleButtonImage} />
            Manutenção Corretiva
          </ToggleButton>
          <ToggleButton className={styleSheet.toggleButton} value="PREVENTIVE">
            <Preventive className={styleSheet.toggleButtonImage} />
            Manutenção Preventiva
          </ToggleButton>
          <ToggleButton className={styleSheet.toggleButton} value="WASH">
            <LocalCarWash className={styleSheet.toggleButtonImage} />
            Higienização
          </ToggleButton>
        </ToggleButtonGroup>
      </Row>
      <Row>
        <Autocomplete
          blurOnSelect
          loading={loadingVehicles}
          loadingText="Carregando..."
          filterOptions={(x) => x}
          options={vehicles}
          getOptionLabel={(option?: Vehicle) => formatVehicleLabel(option)}
          getOptionSelected={(option, value) =>
            formatVehicleLabel(option) === formatVehicleLabel(value)
          }
          value={vehicle}
          size="small"
          style={{ width: "75%" }}
          noOptionsText={vehicle?.plate || "Nenhum veículo selecionado"}
          onChange={(event, value) => {
            if (value) {
              handleVehicleChange(value as Vehicle);
            }
          }}
          onInputChange={(event, newInputValue) => {
            setVehiclePlate(newInputValue);
          }}
          renderOption={(option?: Vehicle) => {
            return (
              <Typography className={styleSheet.autoCompleteText}>
                {formatVehicleLabel(option)}
              </Typography>
            );
          }}
          renderInput={(params) => (
            <TextField
              name="vehicleId"
              label="Veículo"
              variant="outlined"
              size="small"
              error={!!errors.vehicleId}
              helperText={errors.vehicleId}
              required
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {loadingVehicles ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
          closeIcon={null}
        />
        <DatePicker
          id="deadline"
          name="deadline"
          label="Previsão de Início"
          format="dd/MM/yyyy"
          autoOk
          value={values.deadline}
          disablePast
          required
          inputVariant="outlined"
          size="small"
          className={styleSheet.textField}
          style={{ width: "25%" }}
          error={!!errors.deadline}
          helperText={errors.deadline}
          TextFieldComponent={(props) => (
            <TextField
              placeholder="selecione uma data"
              {...props}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Calendar
                      style={{
                        color: "rgba(0, 0, 0, 0.23)",
                        width: "18px",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          )}
          onChange={(value) => setFieldValue("deadline", value)}
        />
      </Row>
      <Row>
        <TextField
          id="report"
          name="report"
          style={{ width: "100%", marginLeft: -2 }}
          classes={{ root: styleSheet.textField }}
          required
          label="Relatos"
          variant="outlined"
          onChange={(event) => setReport(event.target.value)}
          onKeyDown={handleAddReportOnEnter}
          value={report}
          error={!!errors.reports}
          helperText={errors.reports}
          size="small"
          InputProps={{
            endAdornment: (
              <div style={{ paddingRight: 8 }}>
                <AddIcon
                  style={{ color: "#0F71D0", cursor: "pointer" }}
                  onClick={handleAddReport}
                />
              </div>
            ),
          }}
        />
      </Row>
      <Row>
        <DataTable
          columns={[{ field: "id", headerName: "Relatos" }]}
          rows={values.reports?.map((report) => ({ id: report })) || []}
          onRemove={handleRemoveReport}
          removable
        />
      </Row>
      <Row align="end">
        <Button to={ROUTES.MAINTENANCE} color="secondary">
          Voltar
        </Button>
        <div style={{ width: 6 }} />
        <Button color="primary" type="submit">
          Salvar
        </Button>
      </Row>
    </Form>
  );
};

export default MaintenanceDataForm;
