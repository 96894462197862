import Chartist from "chartist";
import Dashboard from "../../../client/fleet/dashboard/Dashboard";
import React, { useEffect } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import { formatCurrency } from "@utils/index";
import ReactDOMServer from "react-dom/server";
import StyleSheet from "@pages/Fleet/Dashboard/StyleSheet";

export const ChartPieInfractionDebit: React.FC<{ dashboard?: Dashboard }> = ({
  dashboard,
}) => {
  const styleSheet = StyleSheet();
  const colors = ["#0F71D0", "#9fa7b0"];
  const render = () => {
    if (dashboard) {
      const infractions = Number(dashboard.infraction?.total || 0);
      const debts = Number(dashboard.debit?.total || 0);
      const chart = new Chartist.Pie(
        "#chart_pie_infraction_debit",
        {
          series: [
            { meta: "Infração", value: infractions },
            { meta: "Débitos", value: debts },
          ],
        },
        {
          donut: true,
          showLabel: false,
          donutWidth: 40,
          donutSolid: true,
          plugins: [
            Chartist.plugins.tooltip({
              transformTooltipTextFnc: (value: number) => formatCurrency(value),
              appendToBody: true,
            }),
            Chartist.plugins.fillDonut({
              items: [
                {
                  content: ReactDOMServer.renderToString(
                    <div>
                      <Typography className={styleSheet.donutTitle}>
                        {formatCurrency(infractions + debts)}
                      </Typography>
                      <Typography className={styleSheet.donutSubtitle}>
                        Total de Infrações + Débitos
                      </Typography>
                    </div>
                  ),
                },
              ],
            }),
          ],
        }
      );

      chart.on("draw", (data: any) => {
        if (data.type === "slice") {
          data.element.attr({
            style: `fill: ${colors[data.index]}`,
          });
        }
      });
    }
  };
  useEffect(render, [dashboard]);
  return (
    <Card>
      <CardHeader
        title={
          <div className={styleSheet.chart_container_tile}>
            Valor Total Infrações + Débitos
          </div>
        }
        style={{ justifyContent: "center", display: "flex" }}
      />
      <Divider />
      <CardContent style={{ display: "flex", justifyContent: "center" }}>
        <div
          id="chart_pie_infraction_debit"
          className={clsx("ct-chart", "ct-octave", styleSheet.chart_container)}
        />
      </CardContent>
      <CardActions>
        <div className={styleSheet.legend}>
          <div
            className={styleSheet.circleLegend}
            style={{ backgroundColor: colors[0] }}
          />
          <Typography className={styleSheet.legendText}>Infrações</Typography>
        </div>
        <div className={styleSheet.legend}>
          <div
            className={styleSheet.circleLegend}
            style={{ backgroundColor: colors[1] }}
          />
          <Typography className={styleSheet.legendText}>Débitos</Typography>
        </div>
      </CardActions>
    </Card>
  );
};
