import {ErrorLoading} from "@components/ErrorLoading";
import {NoData} from "@components/NoData";
import {Pagination} from "@components/Pagination";
import {ReloadButton} from "@components/ReloadButton";
import {Title} from "@components/Title";
import {useIoCContext} from "@hooks/IoCContext";
import {useUserContext} from "@hooks/UserContext";
import {Types} from "@ioc/types";
import {
    createStyles,
    IconButton,
    LinearProgress,
    Link,
    makeStyles,
    Tooltip,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import {ColDef, DataGrid, GridOverlay} from "@material-ui/data-grid";
import ExploreIcon from "@material-ui/icons/Explore";
import {ILocationEstablishmentsDTO} from "@modules/company/dtos/ILocationEstablishmentsDTO";
import {ILocationsEstablishmentsService} from "@modules/company/models/ILocationsEstablishmentsService";
import {tableStyles} from "@utils/index";
import {useSnackbar} from "notistack";
import React, {useCallback, useEffect, useState} from "react";
import AutoSizer from "react-virtualized-auto-sizer";

const useStyles = makeStyles(
    ({typography: {pxToRem, ...typography}, ...theme}) =>
        createStyles({
            headerCell: {
                fontSize: pxToRem(16),
                fontWeight: typography.fontWeightMedium,
                color: theme.palette.tertiary.graphics[4],
            },
            cell: {
                borderBottom: `1px solid ${theme.palette.tertiary.background.primary} !important`,
            },
            tableLocations: tableStyles(theme).dataGridTable,
        })
);

function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{position: "absolute", top: 0, width: "100%"}}>
                <LinearProgress/>
            </div>
        </GridOverlay>
    );
}

const EstablishmentsLocationsPage: React.FC = () => {
    const classes = useStyles();
    const iocContext = useIoCContext();
    const userContext = useUserContext();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down("sm"), {
        defaultMatches: true,
    });
    const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
        defaultMatches: true,
    });

    const {enqueueSnackbar} = useSnackbar();

    const getLocationsEstablishmentsService = iocContext.serviceContainer.get<ILocationsEstablishmentsService>(
        Types.Company.ILocationsEstablishmentsService
    );

    const [locations, setLocations] = useState<ILocationEstablishmentsDTO[]>([]);
    const [locationsLoading, setLocationsLoading] = useState(false);
    const [locationsErrorLoading, setLocationsErrorLoading] = useState(false);
    const [
        positionUser,
        setPositionUser,
    ] = useState<GeolocationCoordinates | null>(null);

    const fetchLocations = useCallback(async () => {
        if (!userContext.selectedCompany) return;
        try {
            setLocationsLoading(true);

            const resp = await getLocationsEstablishmentsService.execute({
                companyID: userContext.selectedCompany.uuid,
            });

            setLocationsErrorLoading(false);
            setLocations(resp);
        } catch (error) {
            setLocationsErrorLoading(true);
        } finally {
            setLocationsLoading(false);
        }
    }, [getLocationsEstablishmentsService, userContext.selectedCompany]);

    useEffect(() => {
        fetchLocations();
    }, [fetchLocations]);

    useEffect(() => {
        async function fetchPosition() {
            if (isXs) return;
            try {
                enqueueSnackbar(
                    "A permissão de localização é usada para redirecionar você ao estabelecimento com base na sua localização",
                    {variant: "info"}
                );
                const position: GeolocationCoordinates = await new Promise(
                    (resolve, reject) => {
                        navigator.geolocation.getCurrentPosition(
                            ({coords}) => {
                                resolve(coords);
                            },
                            (error) => {
                                reject(error);
                            },
                            {
                                enableHighAccuracy: true,
                            }
                        );
                    }
                );
                setPositionUser(position);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(
                    "Conceda permissão de localização, vamos redirecionar você ao estabelecimento com base na sua localização \uD83D\uDE0A",
                    {variant: "warning", autoHideDuration: 15000}
                );
            }
        }

        fetchPosition();
    }, [enqueueSnackbar, isXs]);

    const columns: ColDef[] = [
        {
            field: "location",
            headerName: "Localização",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 200,
            sortable: false,
            valueGetter: (params) => {
                const row = params.row as ILocationEstablishmentsDTO;
                if (positionUser && !isXs) {
                    return `https://www.google.com/maps/dir/?api=1&origin=${positionUser.latitude},${positionUser.longitude}&destination=${row.Store.latitude},${row.Store.longitude}`;
                }
                return `https://www.google.com/maps/dir/?api=1&destination=${row.Store.latitude},${row.Store.longitude}`;
            },
            renderCell: (params) => {
                const url = params.getValue("location")?.toString();

                return (
                    <Tooltip title="Navegar até a localização do estabelecimento">
                        <IconButton component={Link} href={url} target="_blank">
                            <ExploreIcon/>
                        </IconButton>
                    </Tooltip>
                );
            },
        },
        {
            field: "name",
            headerName: "Estabelecimento",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 650,
            sortable: false,
            valueGetter: (params) => {
                const row = params.row as ILocationEstablishmentsDTO;
                return row.Store.tx_estabelecimento;
            },
        },
        {
            field: "endereco",
            headerName: "Endereço",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 450,
            sortable: false,
            valueGetter: (params) => {
                const row = params.row as ILocationEstablishmentsDTO;
                return row.Store.endereco;
            },
        },
        {
            field: "endereco",
            headerName: "Cidade/Estado",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 450,
            sortable: false,
            valueGetter: (params) => {
                const row = params.row as ILocationEstablishmentsDTO;
                return `${row.Store.City.name} - ${row.Store.City.State.name}/${row.Store.City.State.short_name}`;
            },
        },
    ];

    return (
        <>
            <Title>Localização de estabelecimentos</Title>

            <div
                style={{height: "100%", width: "100%"}}
                className={classes.tableLocations}
            >
                <AutoSizer>
                    {({height, width}) => (
                        <div style={{height, width}}>
                            <DataGrid
                                rows={locations}
                                columns={columns}
                                disableSelectionOnClick
                                autoPageSize
                                components={{
                                    pagination: ({pagination, api}) => (
                                        <Pagination
                                            page={pagination.page}
                                            rowCount={pagination.rowCount}
                                            pageSize={pagination.pageSize}
                                            setPage={(value) => api.current.setPage(value)}
                                        />
                                    ),
                                    noRowsOverlay: () => (
                                        <GridOverlay>
                                            <NoData/>
                                        </GridOverlay>
                                    ),
                                    loadingOverlay: CustomLoadingOverlay,
                                    errorOverlay: () => {
                                        return (
                                            <div
                                                style={{
                                                    height: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <ErrorLoading/>
                                                <ReloadButton
                                                    reloadFunc={fetchLocations}
                                                    reloading={locationsLoading}
                                                />
                                            </div>
                                        );
                                    },
                                }}
                                loading={locationsLoading}
                                error={locationsErrorLoading ? true : null}
                            />
                        </div>
                    )}
                </AutoSizer>
            </div>
        </>
    );
};

export {EstablishmentsLocationsPage};
