import {createStyles, makeStyles} from "@material-ui/core";

export default makeStyles((theme) =>
    createStyles({
        aside: {
            gridArea: "leftSideBar",
            height: "100%",
            width: "70px"
        },
        "container-closed": {
            width: "70px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            overflowY: "auto",
            overflowX: "hidden",
            paddingTop: "78px",
        },
        "container-opened": {
            position: "relative",
            width: "300px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            overflowY: "auto",
            overflowX: "hidden",
            paddingTop: "78px",
        },
        header: {
            position: "fixed",
            top: "0px",
            left: "0px",
            width: "300px",
            height: "68px",
            backgroundColor: theme.palette.primary.main,
            paddingLeft: "18px",
        },
    })
);
