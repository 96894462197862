import React, { Fragment, useEffect, useState } from "react";
import { Form, useFormikContext } from "formik";
import { Autocomplete } from "@material-ui/lab";
import { Row } from "@components/Row";
import StyleSheet from "@pages/Vehicles/StyleSheet";
import { Button } from "@components/Button";
import { ROUTES } from "@config/routesConfig";
import { CircularProgress, TextField, Typography } from "@material-ui/core";
import { useUserContext } from "@hooks/UserContext";
import { useIoCContext } from "@hooks/IoCContext";
import { Types } from "@ioc/types";
import { VehicleGeneralInfoPayload } from "../../../../../client/vehicle/Vehicle";
import { Department } from "../../../../../client/Department/Department";
import { DepartmentClient } from "../../../../../client/Department/DepartmentClient";
import { useSnackbar } from "notistack";
import { CostCenterClient } from "../../../../../client/CostCenter/CostCenterClient";
import { CostCenter } from "../../../../../client/CostCenter/CostCenter";

const VehicleGeneralInfoForm: React.FC = () => {
  const styleSheet = StyleSheet();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const {
    values,
    setFieldValue,
  } = useFormikContext<VehicleGeneralInfoPayload>();

  const [departments, setDepartments] = useState<Department[]>([]);
  const [department, setDepartment] = useState<Department | null>(null);
  const [loadingDepartments, setLoadingDepartments] = useState(false);

  const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
  const [costCenter, setCostCenter] = useState<CostCenter | null>(null);
  const [loadingCostCenters, setLoadingCostCenters] = useState(false);

  const departmentClient = iocContext.serviceContainer.get<DepartmentClient>(
    Types.Department
  );

  const costCenterClient = iocContext.serviceContainer.get<CostCenterClient>(
    Types.CostCenter
  );

  const handleDepartmentChange = (selectedValue: Department | null) => {
    setDepartment(selectedValue);
    setFieldValue("departmentId", selectedValue?.id);
    setFieldValue("departmentDescription", selectedValue?.description);
  };

  const handleCostCenterChange = (selectedValue: CostCenter | null) => {
    setCostCenter(selectedValue);
    setFieldValue("costCenterId", selectedValue?.id);
    setFieldValue("costCenterDescription", selectedValue?.description);
  };

  const fetchDepartments = () => {
    if (!selectedCompany) return;
    setLoadingDepartments(true);
    departmentClient
      .find(selectedCompany.uuid, { limit: 500, page: 1 })
      .then((response) => {
        setDepartments(response.result);
        setLoadingDepartments(false);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      })
      .finally(() => {
        setLoadingDepartments(false);
      });
  };

  const fetchCostCenters = () => {
    if (!selectedCompany) return;
    setLoadingCostCenters(true);
    costCenterClient
      .find(selectedCompany.uuid, { limit: 500, page: 1 })
      .then((response) => {
        setCostCenters(response.result);
        setLoadingCostCenters(false);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      })
      .finally(() => {
        setLoadingCostCenters(false);
      });
  };

  useEffect(fetchDepartments, []);
  useEffect(fetchCostCenters, []);
  useEffect(() => {
    if (values.id) {
      if (departments.length) {
        handleDepartmentChange(
          departments.find(
            (department) => department.id === values.departmentId
          ) || null
        );
      }
      if (costCenters.length) {
        handleCostCenterChange(
          costCenters.find(
            (costCenter) => costCenter.id === values.costCenterId
          ) || null
        );
      }
    }
  }, [values, departments, costCenters]);

  return (
    <Form noValidate>
      <Row>
        <Autocomplete
          blurOnSelect
          loading={loadingDepartments}
          loadingText="Carregando..."
          options={departments}
          getOptionLabel={(option?: Department) => option?.description || ""}
          getOptionSelected={(option, value) =>
            option.description === value.description
          }
          value={department}
          size="small"
          style={{ width: "50%" }}
          noOptionsText={"Nenhum departamento encontrado"}
          onChange={(event, value) => {
            if (value) {
              handleDepartmentChange(value as Department);
            }
          }}
          renderOption={(option?: Department) => {
            return (
              <Typography className={styleSheet.autoCompleteText}>
                {option?.description || ""}
              </Typography>
            );
          }}
          renderInput={(params) => (
            <TextField
              name="departmentId"
              label="Departamento"
              variant="outlined"
              size="small"
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {loadingDepartments ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
          closeIcon={null}
        />
        <div style={{ width: 6 }} />
        <Autocomplete
          blurOnSelect
          loading={loadingCostCenters}
          loadingText="Carregando..."
          options={costCenters}
          getOptionLabel={(option?: CostCenter) => option?.description || ""}
          getOptionSelected={(option, value) =>
            option.description === value.description
          }
          value={costCenter}
          size="small"
          style={{ width: "50%" }}
          noOptionsText={"Nenhum centro de custo encontrado"}
          onChange={(event, value) => {
            if (value) {
              handleCostCenterChange(value as CostCenter);
            }
          }}
          renderOption={(option?: CostCenter) => {
            return (
              <Typography className={styleSheet.autoCompleteText}>
                {option?.description || ""}
              </Typography>
            );
          }}
          renderInput={(params) => (
            <TextField
              name="costCenterId"
              label="Centro de Custo"
              variant="outlined"
              size="small"
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {loadingDepartments ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
          closeIcon={null}
        />
      </Row>
      <Row>
        <TextField
          id="prefix"
          name="prefix"
          style={{ width: "50%" }}
          classes={{ root: styleSheet.textField }}
          label="Prefixo"
          variant="outlined"
          onChange={(event) => setFieldValue("prefix", event.target.value)}
          value={values.prefix}
          inputProps={{ maxLength: 256 }}
          size="small"
        />
        <TextField
          id="patrimony"
          name="patrimony"
          style={{ width: "50%" }}
          classes={{ root: styleSheet.textField }}
          label="Patrimônio"
          variant="outlined"
          onChange={(event) => setFieldValue("patrimony", event.target.value)}
          value={values.patrimony}
          inputProps={{ maxLength: 256 }}
          size="small"
        />
      </Row>
      <Row>
        <TextField
          id="observation"
          name="observation"
          style={{ width: "100%" }}
          classes={{ root: styleSheet.textField }}
          label="Observações"
          variant="outlined"
          onChange={(event) => setFieldValue("observation", event.target.value)}
          value={values.observation}
          rows={6}
          multiline
          size="small"
        />
      </Row>
      <Row align="end">
        <Button to={ROUTES.VEHICLES} color="secondary">
          Voltar
        </Button>
        <div style={{ width: 6 }} />
        <Button color="primary" type="submit">
          Salvar
        </Button>
      </Row>
    </Form>
  );
};

export default VehicleGeneralInfoForm;
