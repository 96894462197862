import { DataColumn } from "@components/DataTable";
import { CostCenter } from "../../../client/CostCenter/CostCenter";
import { Department } from "../../../client/Department/Department";
import { Tooltip } from "@material-ui/core";
import TimeToLeave from "@material-ui/icons/TimeToLeave";
import Warning from "@material-ui/icons/Build";
import React from "react";
import { Model } from "../../../client/manufacturer/Manufacturer";

export const ColumnDefinition: DataColumn[] = [
  {
    field: "plate",
    headerName: "Placa",
  },
  {
    field: "model",
    headerName: "Marca/Modelo",
    render: (model: Model) => {
      return model?.manufacturer?.description + " " + model?.description;
    },
  },
  {
    field: "year",
    headerName: "Ano",
  },
  {
    field: "chassi",
    headerName: "Chassi",
  },
  {
    field: "renavam",
    headerName: "Renavam",
  },
  {
    field: "renavam",
    headerName: "Renavam",
  },
  {
    field: "department",
    headerName: "Departamento",
    render: (field: Department) => {
      return field?.description || "-";
    },
  },
  {
    field: "costCenter",
    headerName: "Centro de Custo",
    render: (field: CostCenter) => {
      return field?.description || "-";
    },
  },
  {
    field: "status",
    headerName: "Status",
    render: (status: String) => {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {status === "ACTIVE" ? (
            <Tooltip title="Ativo">
              <TimeToLeave style={{ color: "#2E8b57" }} />
            </Tooltip>
          ) : status === "INACTIVE" ? (
            <Tooltip title="Inativo">
              <TimeToLeave style={{ color: "B90E0A" }} />
            </Tooltip>
          ) : (
            <Tooltip title="Em Manutenção">
              <Warning style={{ color: "FF7F50" }} />
            </Tooltip>
          )}
        </div>
      );
    },
  },
];
