import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IGetUserCompaniesDTO } from "../dtos/IGetUserCompaniesDTO";
import { IGetUserCompanies } from "../models/IGetUserCompanies";

@injectable()
export class GetUserCompanies implements IGetUserCompanies {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    userID: string;
  }): Promise<IGetUserCompaniesDTO> {
    const response = await this.httpInstance.get<IGetUserCompaniesDTO>(
      `/auth/${data.userID}`
    );
    return response;
  }
}
