import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IListPermissibilityFleetDTO } from "../dtos/IListPermissibilityFleetDTO";
import { IListPermissibilityFleetService } from "../models/IListPermissibilityFleetService";

@injectable()
export class ListPermissibilityFleetService
  implements IListPermissibilityFleetService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    page: number;
    limit: number;
    query?: string;
  }): Promise<IListPermissibilityFleetDTO> {
    const response = await this.httpInstance.get<IListPermissibilityFleetDTO>(
      data.query
        ? `/fleet/permissibility/company/${data.companyID}/?&page=${data.page}&limit=${data.limit}&q=${data.query}`
        : `/fleet/permissibility/company/${data.companyID}/?&page=${data.page}&limit=${data.limit}`
    );

    response.result = response.result.map((transaction) => {
      return {
        ...transaction,
        quantity_liter: Number(transaction.quantity_liter),
        mileage: Number(transaction.mileage),
        createdAt: new Date(transaction.createdAt),
        released_at: transaction.released_at
          ? new Date(transaction.released_at)
          : null,
        issued_at: new Date(transaction.issued_at),
      };
    });

    return response;
  }
}
