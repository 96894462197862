import Chartist from "chartist";
import Dashboard from "../../../client/fleet/dashboard/Dashboard";
import React, { useEffect } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import StyleSheet from "@pages/Fleet/Dashboard/StyleSheet";

export const ChartBarInfractionSubCategory: React.FC<{
  dashboard?: Dashboard;
}> = ({ dashboard }) => {
  const styleSheet = StyleSheet();
  const render = () => {
    if (dashboard) {
      const labels =
        dashboard.infraction?.bySubCategory?.map((summary) => summary.key) ||
        [];
      const series =
        dashboard.infraction?.bySubCategory?.map((summary) => summary.value) ||
        [];
      const total = series.length ? series.reduce((x, y) => x + y) : 0;
      const chart = new Chartist.Bar(
        "#chart_bar_sub_category",
        {
          labels,
          series: [series],
        },
        {
          stackBars: true,
          axisY: {
            labelInterpolationFnc: function (value: number) {
              return value / 1000 + "%";
            },
          },
          plugins: [
            Chartist.plugins.tooltip({
              transformTooltipTextFnc: (value: number) =>
                `${parseFloat(String((value / total) * 100)).toFixed(0)}%`,
              appendToBody: true,
            }),
          ],
        }
      );

      chart.on("draw", (data: any) => {
        if (data.type === "bar") {
          data.element.attr({
            style: "stroke-width: 30px;stroke: #006400",
          });
        }
      });
    }
  };
  useEffect(render, [dashboard]);
  return (
    <Card>
      <CardHeader
        title={
          <div className={styleSheet.chart_container_tile}>
            Infrações por Categoria
          </div>
        }
        style={{ justifyContent: "center", display: "flex" }}
      />
      <Divider />
      <CardContent style={{ display: "flex", justifyContent: "center" }}>
        <div
          id="chart_bar_sub_category"
          className={clsx("ct-chart", "ct-octave", styleSheet.chart_container)}
        />
      </CardContent>
      <CardActions>
        <div className={styleSheet.legend}>
          <div
            className={styleSheet.circleLegend}
            style={{ backgroundColor: "#006400" }}
          />
          <Typography className={styleSheet.legendText}>
            Percentual Acumulado
          </Typography>
        </div>
      </CardActions>
    </Card>
  );
};
