import {
  Box,
  CardContent,
  createStyles,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { IPermissibilityFleetDTO } from "@modules/company/dtos/IListPermissibilityFleetDTO";
import clsx from "clsx";
import React from "react";
import { useExceptionTransactions } from "./ExceptionTransactionsContext";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontSize: "2rem",
      fontWeight: "bold",
    },
    dialogPaper: {
      minWidth: "95vw",
      [theme.breakpoints.up("sm")]: {
        minWidth: "50vw",
      },
    },
    divider: {
      backgroundColor: theme.palette.tertiary.background.primary,
    },
    sectionTitles: {
      color: theme.palette.tertiary.textButtons.primary,
    },
    text: {
      fontSize: "1.6rem",
    },
  })
);

const DialogInfo: React.FC = () => {
  const classes = useStyles();
  const { openDialog, infoDialog, setOpenDialog } = useExceptionTransactions();

  return infoDialog ? (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle disableTypography>
        <Grid container alignItems="center">
          <Grid item xs={11}>
            <Typography className={classes.title}>
              {infoDialog.cs_status}
            </Typography>
          </Grid>

          <Grid item xs={1}>
            <IconButton onClick={() => setOpenDialog(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider className={classes.divider} />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={clsx(classes.sectionTitles, classes.text)}>
              Ocorrido
            </Typography>
            <Box padding={2}>
              <Typography className={classes.text}>
                {infoDialog.explanation.message}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{ marginTop: "2.4rem" }}
              className={clsx(classes.sectionTitles, classes.text)}
            >
              Solução
            </Typography>
            <List>
              {infoDialog.explanation.workaround.map((msg) => {
                return (
                  <>
                    <ListItem>
                      <ListItemText
                        classes={{ primary: classes.text }}
                        primary={msg}
                      />
                    </ListItem>
                    <Divider component="li" />
                  </>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Dialog>
  ) : (
    <></>
  );
};

export { DialogInfo };
