import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IRemoveVehicleService } from "../models/IRemoveVehicleService";

@injectable()
export class RemoveVehicleService implements IRemoveVehicleService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    vehicleID: string;
  }): Promise<void> {
    await this.httpInstance.delete(
      `/fleet/vehicle/${data.vehicleID}/${data.companyID}`
    );
  }
}
