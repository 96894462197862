import {ErrorLoading} from "@components/ErrorLoading";
import {LoadingOverlayDataGrid} from "@components/LoadingOverlayDataGrid";
import {NoData} from "@components/NoData";
import {Pagination} from "@components/Pagination";
import {ReloadButton} from "@components/ReloadButton";
import {StyledSelect} from "@components/StyledSelect";
import {Tag} from "@components/Tag";
import {Title} from "@components/Title";
import {useIoCContext} from "@hooks/IoCContext";
import {useLayoutContext} from "@hooks/LayoutContext";
import {useUserContext} from "@hooks/UserContext";
import {Types} from "@ioc/types";
import {
    Button,
    CircularProgress,
    Collapse,
    createStyles,
    Grid,
    Hidden,
    IconButton,
    makeStyles,
    MenuItem,
    Tooltip,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import {ColDef, DataGrid, GridOverlay} from "@material-ui/data-grid";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FilterListIcon from "@material-ui/icons/FilterList";
import {IUserDTO} from "@modules/user/dtos/IListUsersDTO";
import {IGetFiltersUserService} from "@modules/user/models/IGetFiltersUserService";
import {IListUsersService} from "@modules/user/models/IListUsersService";
import {tableStyles} from "@utils/index";
import React, {useCallback, useEffect, useState} from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import {ModalAddUser} from "./ModalAddUser";
import {ModalRemoveUser} from "./ModalRemoveUser";
import {ModalUploadUsers} from "./ModalUploadUsers";
import {UsersPageProvider, useUsersPage} from "./UsersPageContext";

const useStyles = makeStyles(
    ({typography: {pxToRem, ...typography}, ...theme}) =>
        createStyles({
            cell: {
                borderBottom: `1px solid ${theme.palette.tertiary.background.primary} !important`,
            },
            headerCell: {
                fontSize: pxToRem(16),
                fontWeight: typography.fontWeightMedium,
                color: theme.palette.tertiary.graphics[4],
            },
            buttonDelete: {
                color: theme.palette.tertiary.error.primary,
            },
            button: {
                fontWeight: typography.fontWeightBold,
                flex: "0 1 30rem",
            },
            title: {
                flex: "1 1 100%",
                [theme.breakpoints.up("lg")]: {
                    flex: "1 1 30rem",
                },
            },
            spacingButtonFilter: {marginTop: "2rem"},
            loading: {
                height: "25px !important",
                width: "25px !important",
            },
            tableUsers: tableStyles(theme).dataGridTable,
        })
);

const Container: React.FC = () => {
    const classes = useStyles();
    const userContext = useUserContext();
    const iocContext = useIoCContext();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
        defaultMatches: true,
    });
    const usersPageContext = useUsersPage();
    const layoutContext = useLayoutContext();

    const getRechargesService = iocContext.serviceContainer.get<IListUsersService>(
        Types.User.IListUsersService
    );
    const getFiltersUserService = iocContext.serviceContainer.get<IGetFiltersUserService>(
        Types.User.IGetFiltersUserService
    );

    const [showFilters, setShowFilters] = useState(false);
    const [loadingFilters, setLoadingFilters] = useState(false);
    const [errorLoadingFilters, setErrorLoadingFilters] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState<{
        statusID: string;
        functionID: string;
        costCenterID: string;
        departmentID: string;
        query: string;
    }>({
        costCenterID: "",
        functionID: "",
        statusID: "",
        departmentID: "",
        query: "",
    });
    const [sizeTable, setSizeTable] = useState<null | number>(null);

    const fetchUsers = useCallback(async () => {
        if (!userContext.selectedCompany) return;
        try {
            usersPageContext.setLoadingUsers(true);

            const resp = await getRechargesService.execute({
                companyID: userContext.selectedCompany.id,
                limit: usersPageContext.limit,
                page: usersPageContext.page,
                costCenterID: selectedFilters.costCenterID,
                departmentID: selectedFilters.departmentID,
                functionID: selectedFilters.functionID,
                statusID: selectedFilters.statusID,
                query: selectedFilters.query,
            });
            usersPageContext.setUsers(resp);

            usersPageContext.setErrorLoadingUsers(false);
        } catch (error) {
            usersPageContext.setErrorLoadingUsers(true);
        } finally {
            usersPageContext.setLoadingUsers(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        userContext.selectedCompany,
        usersPageContext.limit,
        usersPageContext.page,
        selectedFilters,
    ]);

    const fetchFilters = useCallback(async () => {
        if (!userContext.selectedCompany) return;
        const {setFilters} = usersPageContext;
        try {
            setLoadingFilters(true);
            const filters = await getFiltersUserService.execute({
                companyID: userContext.selectedCompany.id,
            });
            setFilters(filters);
            setErrorLoadingFilters(false);
        } catch (error) {
            setErrorLoadingFilters(true);
        } finally {
            setLoadingFilters(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFiltersUserService, userContext.selectedCompany]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    useEffect(() => {
        if (
            usersPageContext.openModalRemoveUser.open === false ||
            usersPageContext.openModalUser.open === false
        ) {
            fetchUsers();
        }
    }, [
        fetchUsers,
        usersPageContext.openModalRemoveUser.open,
        usersPageContext.openModalUser.open,
    ]);

    useEffect(() => {
        fetchFilters();
    }, [fetchFilters]);

    useEffect(() => {
        usersPageContext.setPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilters]);

    const columns: ColDef[] = [
        {
            field: "edit",
            headerName: " ",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 70,
            sortable: false,
            renderCell: (params) => {
                const row = params.row as IUserDTO;

                return (
                    <Tooltip title="Editar usuário">
                        <IconButton
                            color="primary"
                            onClick={() =>
                                usersPageContext.setOpenModalUser({open: true, data: row})
                            }
                        >
                            <EditIcon/>
                        </IconButton>
                    </Tooltip>
                );
            },
        },
        {
            field: "tx_usuario",
            headerName: "Nome",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 600,
            sortable: false,
        },
        {
            field: "tx_documento",
            headerName: "Número do cartão",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 320,
            sortable: false,
        },
        {
            field: "funcao",
            headerName: "Função",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 200,
            sortable: false,
        },
        {
            field: "departamento",
            headerName: "Departamento",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 250,
            sortable: false,
        },
        {
            field: "status",
            headerName: "Status",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 200,
            sortable: false,
            renderCell: (params) => {
                const row = params.row as IUserDTO;
                return (
                    <Tag type={row.status === "Ativo" ? "success" : "error"}>
                        {params.getValue(params.field)}
                    </Tag>
                );
            },
        },
        {
            field: "exclude",
            headerName: " ",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 100,
            sortable: false,
            renderCell: (params) => {
                const row = params.row as IUserDTO;
                return (
                    <Tooltip title="Remover usuário">
                        <IconButton
                            className={classes.buttonDelete}
                            size="small"
                            onClick={() =>
                                usersPageContext.setOpenModalRemoveUser({
                                    open: true,
                                    data: row,
                                })
                            }
                        >
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                );
            },
        },
    ];

    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                }}
            >
                <Title className={classes.title}>Usuários cadastrados</Title>
                <Button
                    className={classes.button}
                    color="primary"
                    onClick={() => layoutContext.setOpenComingSoonDialog(true)}
                >
                    Importar usuários
                </Button>
                {loadingFilters ? (
                    <Grid
                        container
                        justify="center"
                        className={isMobile ? "" : classes.button}
                    >
                        <CircularProgress className={classes.loading}/>
                    </Grid>
                ) : errorLoadingFilters ? (
                    <></>
                ) : (
                    <Button
                        onClick={() => {
                            usersPageContext.setOpenModalUser({open: true, data: null});
                        }}
                        className={classes.button}
                        color="primary"
                    >
                        Cadastrar novo usuário
                    </Button>
                )}
            </div>

            <Hidden smUp>
                {loadingFilters ? (
                    <Grid
                        className={classes.spacingButtonFilter}
                        container
                        justify="center"
                    >
                        <CircularProgress className={classes.loading}/>
                    </Grid>
                ) : errorLoadingFilters ? (
                    <Grid
                        className={classes.spacingButtonFilter}
                        container
                        justify="center"
                    >
                        <ErrorLoading messageError="Ocorreu um erro ao baixar dados dos filtros"/>
                        <ReloadButton
                            reloadFunc={fetchFilters}
                            reloading={loadingFilters}
                        />
                    </Grid>
                ) : (
                    <Grid
                        className={classes.spacingButtonFilter}
                        container
                        justify="center"
                    >
                        <IconButton onClick={() => setShowFilters(!showFilters)}>
                            <FilterListIcon/>
                        </IconButton>
                    </Grid>
                )}
            </Hidden>

            <Collapse in={showFilters || !isMobile} style={{minHeight: "auto"}}>
                {loadingFilters ? (
                    <Grid container justify="center">
                        <CircularProgress style={{height: 30, width: 30}}/>
                    </Grid>
                ) : errorLoadingFilters ? (
                    <Grid container justify="center">
                        <ErrorLoading messageError="Ocorreu um erro ao baixar dados dos filtros"/>
                        <ReloadButton
                            reloadFunc={fetchFilters}
                            reloading={loadingFilters}
                        />
                    </Grid>
                ) : (
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={3}>
                            <StyledSelect
                                onClear={() =>
                                    setSelectedFilters({...selectedFilters, functionID: ""})
                                }
                                isFilled={!!selectedFilters.functionID}
                                textFieldProps={{
                                    label: "Selecione um registro",
                                    value: selectedFilters.functionID,
                                    onChange: ({target}) =>
                                        setSelectedFilters({
                                            ...selectedFilters,
                                            functionID: target.value,
                                        }),
                                }}
                                title="Função"
                            >
                                {usersPageContext.filters
                                    ? usersPageContext.filters.functions.map((filter) => {
                                        return (
                                            <MenuItem key={filter.id} value={filter.id}>
                                                {filter.tx_funcao}
                                            </MenuItem>
                                        );
                                    })
                                    : []}
                            </StyledSelect>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <StyledSelect
                                onClear={() =>
                                    setSelectedFilters({...selectedFilters, departmentID: ""})
                                }
                                isFilled={!!selectedFilters.departmentID}
                                textFieldProps={{
                                    label: "Selecione um registro",
                                    value: selectedFilters.departmentID,
                                    onChange: ({target}) =>
                                        setSelectedFilters({
                                            ...selectedFilters,
                                            departmentID: target.value,
                                        }),
                                }}
                                title="Departamento"
                            >
                                {usersPageContext.filters
                                    ? usersPageContext.filters.departments.map((filter) => {
                                        return (
                                            <MenuItem key={filter.id} value={filter.id}>
                                                {filter.tx_departamento}
                                            </MenuItem>
                                        );
                                    })
                                    : []}
                            </StyledSelect>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <StyledSelect
                                onClear={() =>
                                    setSelectedFilters({...selectedFilters, costCenterID: ""})
                                }
                                isFilled={!!selectedFilters.costCenterID}
                                textFieldProps={{
                                    label: "Selecione um registro",
                                    value: selectedFilters.costCenterID,
                                    onChange: ({target}) =>
                                        setSelectedFilters({
                                            ...selectedFilters,
                                            costCenterID: target.value,
                                        }),
                                }}
                                title="Centro de custos"
                            >
                                {usersPageContext.filters
                                    ? usersPageContext.filters.costCenters.map((filter) => {
                                        return (
                                            <MenuItem key={filter.id} value={filter.id}>
                                                {filter.tx_centro_custo}
                                            </MenuItem>
                                        );
                                    })
                                    : []}
                            </StyledSelect>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <StyledSelect
                                onClear={() =>
                                    setSelectedFilters({...selectedFilters, statusID: ""})
                                }
                                isFilled={!!selectedFilters.statusID}
                                textFieldProps={{
                                    label: "Selecione um registro",
                                    value: selectedFilters.statusID,
                                    onChange: ({target}) =>
                                        setSelectedFilters({
                                            ...selectedFilters,
                                            statusID: target.value,
                                        }),
                                }}
                                title="Status"
                            >
                                {usersPageContext.filters
                                    ? usersPageContext.filters.status.map((filter) => {
                                        return (
                                            <MenuItem key={filter.id} value={filter.id}>
                                                {filter.status}
                                            </MenuItem>
                                        );
                                    })
                                    : []}
                            </StyledSelect>
                        </Grid>
                    </Grid>
                )}
            </Collapse>

            {isMobile ? (
                <div
                    style={{
                        height: 500,
                        minHeight: 500,
                        width: "100%",
                        marginTop: "4rem",
                    }}
                    className={classes.tableUsers}
                >
                    <DataGrid
                        rows={usersPageContext.users.result}
                        columns={columns}
                        onPageSizeChange={(props) => {
                            usersPageContext.setLimit(props.pageSize);
                        }}
                        components={{
                            loadingOverlay: LoadingOverlayDataGrid,
                            pagination: ({pagination}) => (
                                <Pagination
                                    page={usersPageContext.page}
                                    rowCount={usersPageContext.users.count}
                                    pageSize={pagination.pageSize}
                                    setPage={(value) => usersPageContext.setPage(value)}
                                />
                            ),
                            errorOverlay: () => {
                                return (
                                    <div
                                        style={{
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <ErrorLoading/>
                                        <ReloadButton
                                            reloadFunc={fetchUsers}
                                            reloading={usersPageContext.loadingUsers}
                                        />
                                    </div>
                                );
                            },
                            noRowsOverlay: () => (
                                <GridOverlay>
                                    <NoData/>
                                </GridOverlay>
                            ),
                        }}
                        loading={usersPageContext.loadingUsers}
                        error={usersPageContext.errorLoadingUsers ? true : null}
                        scrollbarSize={5}
                        disableSelectionOnClick
                        autoPageSize
                    />
                </div>
            ) : (
                <div
                    style={{
                        height: "100%",
                        width: "100%",
                        marginTop: "4rem",
                    }}
                    className={classes.tableUsers}
                >
                    <AutoSizer onResize={() => usersPageContext.setPage(1)}>
                        {({height, width}) => (
                            <div style={{height: sizeTable ? sizeTable : height, width}}>
                                <DataGrid
                                    rows={usersPageContext.users.result}
                                    columns={columns}
                                    onPageSizeChange={(props) => {
                                        usersPageContext.setLimit(props.pageSize);
                                    }}
                                    components={{
                                        loadingOverlay: LoadingOverlayDataGrid,
                                        pagination: ({pagination}) => (
                                            <Pagination
                                                page={usersPageContext.page}
                                                rowCount={usersPageContext.users.count}
                                                pageSize={pagination.pageSize}
                                                setPage={(value) => usersPageContext.setPage(value)}
                                                sizeTable={sizeTable}
                                                setSizeTable={(value) => setSizeTable(value)}
                                            />
                                        ),
                                        errorOverlay: () => {
                                            return (
                                                <div
                                                    style={{
                                                        height: "100%",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <ErrorLoading/>
                                                    <ReloadButton
                                                        reloadFunc={fetchUsers}
                                                        reloading={usersPageContext.loadingUsers}
                                                    />
                                                </div>
                                            );
                                        },
                                        noRowsOverlay: () => (
                                            <GridOverlay>
                                                <NoData/>
                                            </GridOverlay>
                                        ),
                                    }}
                                    loading={usersPageContext.loadingUsers}
                                    error={usersPageContext.errorLoadingUsers ? true : null}
                                    scrollbarSize={5}
                                    disableSelectionOnClick
                                    autoPageSize
                                />
                            </div>
                        )}
                    </AutoSizer>
                </div>
            )}

            <ModalAddUser/>
            <ModalRemoveUser/>
            <ModalUploadUsers/>
        </>
    );
};

const UsersPage: React.FC = () => {
    return (
        <UsersPageProvider>
            <Container/>
        </UsersPageProvider>
    );
};

export {UsersPage};
