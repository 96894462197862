import * as yup from "yup";

export const FormManualRechargeSchemeValidation = yup.object().shape({
  usersSelected: yup.array().min(1, "Selecione pelo menos um usuário"),
  description: yup.string().required("Preencha a descrição"),
  rechargeValue: yup
    .number()
    .min(0.01, "O valor mínimo é R$ 0,01")
    .typeError("Digite o valor da recarga")
    .required("Digite o valor da recarga"),
  date: yup
    .date()
    .typeError("Selecione uma data")
    .required("Selecione uma data"),
});
