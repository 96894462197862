import React, { useState } from "react";
import {
  createStyles,
  GridProps,
  makeStyles,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { IPenaltyDTO } from "@modules/company/dtos/IPenaltyDTO";
import { format } from "date-fns/esm";
import { formatCurrency } from "@utils/index";
import AppError from "@utils/AppError";
import { useUserContext } from "@hooks/UserContext";
import { IMarkAsReadPenaltyService } from "@modules/company/models/IMarkAsReadPenaltyService";
import { Types } from "@ioc/types";
import { useIoCContext } from "@hooks/IoCContext";
import { useAuth } from "@hooks/AuthContext";
import { useSnackbar } from "notistack";

interface Props extends GridProps {
  rows: IPenaltyDTO[];
}

interface CardProps extends GridProps {
  title: string;
  content?: string;
  size?: number;
  punctuation?: number;
  status?: string;
}

const DialogInfractionStyle = makeStyles((theme) =>
  createStyles({
    container: {
      padding: "4px",
    },
    card: {
      border: "solid 1px",
      borderColor: "#767676",
      maxWidth: "380px",
      padding: "8px 6px",
      position: "relative",
      borderRadius: "4px",
    },
    "card-container": {
      borderRadius: "10px",
      webkitBoxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
      padding: "10px",
      borderBottom: "solid 1px #969696",
    },
    "card-title": {
      position: "absolute",
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "12px",
      fontFamily: '"IBM Plex Sans",-apple-system,BlinkMacSystemFont,sans-serif',
      marginTop: "-18px",
      backgroundColor: "#FFF",
      padding: "0 4px",
    },
    "card-text": {
      fontSize: "12px",
    },
    space: {
      height: "10px",
      width: "10px",
    },
    row: {
      display: "flex",
    },
    low: {
      textAlign: "center",
      borderRadius: "10px",
      fontSize: "12px",
      backgroundColor: "#F9E586",
      border: "solid 1px #F5C73D",
      fontWeight: "bold",
    },
    medium: {
      textAlign: "center",
      borderRadius: "10px",
      fontSize: "12px",
      backgroundColor: "#F29F05",
      border: "solid 1px #D97D0D",
      fontWeight: "bold",
    },
    high: {
      textAlign: "center",
      borderRadius: "10px",
      fontSize: "12px",
      backgroundColor: "#D0312D",
      border: "solid 1px #990F02",
      fontWeight: "bold",
      color: "#FFF",
    },
    super: {
      textAlign: "center",
      borderRadius: "10px",
      fontSize: "12px",
      backgroundColor: "#60100B",
      border: "solid 1px #541e1B",
      fontWeight: "bold",
      color: "#FFF",
    },
    closed: {
      textAlign: "center",
      borderRadius: "10px",
      fontSize: "12px",
      border: "solid 1px #028A0F",
      fontWeight: "bold",
      color: "#028A0F",
    },
    pending: {
      textAlign: "center",
      borderRadius: "10px",
      fontSize: "12px",
      border: "solid 1px #900D09",
      fontWeight: "bold",
      color: "#900D09",
    },
    "button-mark-as-read": {
      backgroundColor: theme.palette.primary.main,
      height: "16px",
      fontSize: "12px",
      color: "#FFF",
      width: "380px",
      borderColor: "#FFF",
      padding: "4px",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  })
);

const Card: React.FC<CardProps> = ({
  title,
  content,
  size,
  punctuation,
  status,
}) => {
  const classes = DialogInfractionStyle();
  return (
    <div className={classes.card} style={{ width: size }}>
      <label className={classes["card-title"]}>{title}</label>
      {punctuation ? (
        <Infraction punctuation={punctuation}></Infraction>
      ) : status ? (
        <Status status={status}></Status>
      ) : (
        <span className={classes["card-text"]}>{content}</span>
      )}
    </div>
  );
};

const Infraction: React.FC<{ punctuation: number }> = ({ punctuation }) => {
  const clazz =
    punctuation <= 3
      ? "low"
      : punctuation <= 4
      ? "medium"
      : punctuation <= 5
      ? "high"
      : "super";
  const label =
    punctuation <= 3
      ? "LEVE"
      : punctuation <= 4
      ? "MÉDIA"
      : punctuation <= 5
      ? "GRAVE"
      : "GRAVÍSSIMA";
  const classes = DialogInfractionStyle();
  return <div className={classes[clazz]}>{label}</div>;
};

const Status: React.FC<{ status: string }> = ({ status }) => {
  const clazz = status === "PAGA" ? "closed" : "pending";
  const label = status === "PAGA" ? "PAGA" : "PENDENTE";
  const classes = DialogInfractionStyle();
  return <div className={classes[clazz]}>{label}</div>;
};

const formatDate = (date: Date) => {
  if (!date) {
    return "-";
  }
  return format(new Date(date), "dd/MM/yyyy");
};

const DialogInfraction: React.FC<Props> = ({ rows, ...props }) => {
  const [infractions, setInfractions] = useState(rows);
  const [idButtonLoading, setIdButtonLoading] = useState("");
  const classes = DialogInfractionStyle();
  const userContext = useUserContext();
  const iocContext = useIoCContext();
  const authContext = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const markAsReadPenaltyService = iocContext.serviceContainer.get<IMarkAsReadPenaltyService>(
    Types.Company.IMarkAsReadPenaltyService
  );

  const removeInfraction = (penaltyId: string) => {
    setInfractions(
      infractions.filter((infraction) => infraction.id !== penaltyId)
    );
  };

  const markAsRead = async (penaltyId: string) => {
    if (!userContext.selectedCompany) return;
    try {
      setIdButtonLoading(penaltyId);
      await markAsReadPenaltyService.execute({
        companyID: userContext.selectedCompany.uuid,
        penaltyID: penaltyId,
        userID: authContext.userID,
      });
      removeInfraction(penaltyId);
    } catch (error) {
      if (error instanceof AppError) {
        return enqueueSnackbar(error.message, { variant: error.variant });
      }
      enqueueSnackbar("Ocorreu um erro ao marcar como lido", {
        variant: "error",
      });
    } finally {
      setIdButtonLoading("");
    }
  };
  return (
    <div className={classes.container}>
      {infractions.map((row, index) => (
        <div>
          <div key={row.id} className={classes["card-container"]}>
            <div className={classes.row}>
              <Card title="Infração" punctuation={row.punctuation} size={118} />
              <div className={classes.space}></div>
              <Card title="Situação" status={row.status} size={120} />
              <div className={classes.space}></div>
              <Card
                title={row.status === "NA" ? "Valor" : "Valor Pago"}
                content={formatCurrency(
                  row.status === "NA" ? row.due_value : row.paid_value
                )}
                size={120}
              />
            </div>
            <div className={classes.space}></div>
            <div className={classes.row}>
              <Card title="Veículo (Placa)" content={row.vehicle} size={118} />
              <div className={classes.space}></div>
              <Card title="Data" content={formatDate(row.date)} size={250} />
            </div>
            <div className={classes.space}></div>
            <Card title="Descrição" content={row.description} />
            <div className={classes.space}></div>
            <Button
              key={row.id}
              className={classes["button-mark-as-read"]}
              onClick={() => markAsRead(row.id)}
            >
              {row.id === idButtonLoading ? (
                <CircularProgress style={{ color: "#FFF" }} size={12} />
              ) : (
                "Marcar como lido"
              )}
            </Button>
          </div>
          <div className={classes.space}></div>
        </div>
      ))}
    </div>
  );
};

export { DialogInfraction };
