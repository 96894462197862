import {
  createStyles,
  Dialog,
  DialogProps,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      dialogPaper: {
        minWidth: "95vw",
        [theme.breakpoints.up("sm")]: {
          minWidth: "25vw",
        },
      },
      dialogTitle: {
        fontWeight: typography.fontWeightBold,
        fontSize: pxToRem(16),
        [theme.breakpoints.up("sm")]: {
          fontSize: pxToRem(20),
        },
      },
      subtitleDialog: {
        color: theme.palette.tertiary.textButtons.primary,
        fontSize: pxToRem(16),
        textAlign: "center",
        [theme.breakpoints.up("xs")]: {
          textAlign: "left",
        },
      },
      divider: {
        backgroundColor: theme.palette.tertiary.background.primary,
      },
    })
);

export interface StyledDialogProps extends DialogProps {
  title: string;
  subtitle?: string;
  /**
   * usado em botões de cancelar ou fechar modal
   */
  closeFunc: () => void;
}

const StyledDialog: React.FC<StyledDialogProps> = ({
  title,
  subtitle,
  closeFunc,
  children,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} {...props}>
      <DialogTitle disableTypography>
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography className={classes.dialogTitle}>{title}</Typography>
          </Grid>
          <Grid item xs={2} container justify="flex-end">
            <IconButton onClick={closeFunc}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.subtitleDialog}>
              {subtitle}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider className={classes.divider} />
      {children}
    </Dialog>
  );
};

export { StyledDialog };
