import { Typography } from "@material-ui/core";
import React from "react";
import Home from "@material-ui/icons/HomeOutlined";
import StyleSheet from "@components/Title/StyleSheet";

const Information: React.FC = ({ children }) => {
  const styleSheet = StyleSheet();
  return (
    <>
      <div
        style={{ fontSize: 12 }}
        className={styleSheet["title-history-separator"]}
      >
        {" "}
        &gt;{" "}
      </div>
      <div
        style={{ fontSize: 12 }}
        className={styleSheet["title-history-description"]}
      >
        {children}
      </div>
    </>
  );
};

const Title: React.FC<{ subtitles?: string[] }> = (props) => {
  const { children, subtitles } = props;
  const styleSheet = StyleSheet();
  return (
    <div>
      <Typography style={{ fontSize: 22 }} className={styleSheet.title}>
        {children}
      </Typography>
      <div className={styleSheet["title-history"]}>
        <Home style={{ color: "#58595B", fontSize: 18 }} />
        <Information>{children}</Information>
        {subtitles?.length ? (
          subtitles.map((subtitle) => (
            <Information key={subtitle}>{subtitle}</Information>
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export { Title };
