import React, { useEffect, useState } from "react";
import { useUserContext } from "@hooks/UserContext";
import { useIoCContext } from "@hooks/IoCContext";
import { FolderClient } from "../../client/folder/FolderClient";
import { Types } from "@ioc/types";
import { FolderTemplate } from "../../client/folder/Folder";
import { DocumentClient } from "../../client/Document/DocumentClient";
import { Document } from "../../client/Document/Document";
import { LinearProgress } from "@material-ui/core";
import { TemplateBar } from "@components/DocumentManager/TemplateBar";
import { TemplateContainer } from "@components/DocumentManager/TemplateContainer";
import { Thumbnail } from "@components/DocumentManager/DocumentCard";
import { UploadClient } from "../../client/upload/UploadClient";

interface DocumentManagerProps {
  folderId?: string;
}

const DocumentManager: React.FC<DocumentManagerProps> = ({ folderId }) => {
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const [documents, setDocuments] = useState<Document[]>([]);
  const [thumbnails, setThumbnails] = useState<Thumbnail[]>([]);
  const [templates, setTemplates] = useState<FolderTemplate[]>([]);
  const [loading, setLoading] = useState(false);

  const folderClient = iocContext.serviceContainer.get<FolderClient>(
    Types.Folder
  );

  const documentClient = iocContext.serviceContainer.get<DocumentClient>(
    Types.Document
  );

  const uploadClient = iocContext.serviceContainer.get<UploadClient>(
    Types.Upload
  );

  const fetchFolder = () => {
    if (!selectedCompany || !folderId) return;
    setLoading(true);
    folderClient
      .findById(selectedCompany.uuid, folderId)
      .then((result) => {
        setTemplates(result.resource?.template || []);
      })
      .finally(() => setLoading(false));
  };

  const fetchDocuments = () => {
    if (!selectedCompany || !folderId) return;
    setLoading(true);
    documentClient
      .find(selectedCompany.uuid, folderId, { page: 1, limit: 500 })
      .then(({ result }) => {
        setDocuments(result);
      })
      .finally(() => setLoading(false));
  };

  const fetchImages = () => {
    if (!selectedCompany || documents.length === 0) return;
    setLoading(true);
    const promises = [];
    for (const document of documents) {
      if (["gif", "jpeg", "jpg", "png"].includes(document.extension)) {
        promises.push(
          uploadClient
            .resolveLocation(selectedCompany.uuid, document.path)
            .then((result) => ({
              id: document.id,
              url: result,
            }))
        );
      }
    }
    Promise.all(promises)
      .then((result) => {
        setThumbnails(result.map((r) => (r as unknown) as Thumbnail));
      })
      .finally(() => setLoading(false));
  };

  useEffect(fetchFolder, [folderId]);
  useEffect(fetchDocuments, [folderId]);
  useEffect(fetchImages, [documents]);

  return (
    <>
      <div style={{ height: 10 }}>
        {loading ? <LinearProgress color="primary" /> : <></>}
      </div>
      <div style={{ padding: "16px 12px" }}>
        {templates.map((template) => {
          return (
            <div>
              <TemplateBar
                id={template.id}
                title={template.name}
                folderId={folderId}
                onChange={() => fetchDocuments()}
              />
              <TemplateContainer
                documents={documents.filter(
                  (document) => document.type === template.id
                )}
                thumbnails={thumbnails}
                onChange={() => fetchDocuments()}
                onLoading={(loading) => setLoading(loading)}
              />
            </div>
          );
        })}
        <TemplateBar
          id="other"
          title="Outros"
          folderId={folderId}
          onChange={() => fetchDocuments()}
        />
        <TemplateContainer
          documents={documents.filter((document) => document.type === "other")}
          thumbnails={thumbnails}
          onChange={() => fetchDocuments()}
          onLoading={(loading) => setLoading(loading)}
        />
      </div>
    </>
  );
};

export { DocumentManager };
