import Dashboard from "./Dashboard";
import { inject, injectable } from "inversify";
import { Types } from "@ioc/types";

export interface DashboardClient {
  find(companyId: string, pending: boolean): Promise<Dashboard>;
}

@injectable()
export class DashboardClientImpl implements DashboardClient {
  @inject(Types.FlagcardCompanyWrapper)
  // @ts-ignore
  private instance: FlagcardCompanyWrapper;

  private readonly endpoint = "/rest/v1/dashboard";

  public find(companyId: string, pending: boolean): Promise<Dashboard> {
    return this.instance.get(`${this.endpoint}${pending ? "?opened" : ""}`, {
      headers: { company_id: companyId },
    });
  }
}
