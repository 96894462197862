import React, { useEffect, useState } from "react";
import { Title } from "@components/Title/Title";
import { Formik } from "formik";
import { Loading } from "@components/Loading";
import Message from "@components/Message";
import { useUserContext } from "@hooks/UserContext";
import { useHistory, useParams } from "react-router";
import { Types } from "@ioc/types";
import { useIoCContext } from "@hooks/IoCContext";
import Validation from "./Form/Validation";
import StyleSheet from "../List/StyleSheet";
import { ROUTES } from "@config/routesConfig";
import AppError from "@utils/AppError";
import {
  Department,
  DepartmentInitialValues,
} from "../../../client/Department/Department";
import { DepartmentClient } from "../../../client/Department/DepartmentClient";
import DepartmentEditForm from "@pages/Department/Edit/Form";

const DepartmentEdit: React.FC = () => {
  const styleSheet = StyleSheet();
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const history = useHistory();
  const params = useParams<{ id?: string }>();
  const [departmentId] = useState(params.id);
  const [department, setDepartment] = useState<Department>(
    DepartmentInitialValues
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const client = iocContext.serviceContainer.get<DepartmentClient>(
    Types.Department
  );

  const fetchDepartment = () => {
    if (!selectedCompany) return;
    if (!departmentId) return;

    setLoading(true);
    client
      .findById(selectedCompany.id, departmentId)
      .then((result) => {
        setDepartment(result);
        setLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error.response?.data?.message);
        setError(true);
        setLoading(false);
      });
  };

  useEffect(fetchDepartment, [selectedCompany, departmentId, client]);

  const handleSubmit = async (event: Department) => {
    setLoading(true);
    try {
      const payload = { description: event.description };
      if (selectedCompany) {
        if (!departmentId) {
          await client.save(selectedCompany.uuid, payload);
        } else {
          await client.update(selectedCompany.uuid, departmentId, payload);
        }
        history.push({
          pathname: ROUTES.DEPARTMENTS,
          state: { success: true },
        });
      } else {
        throw new AppError("company_id is undefined", "error");
      }
    } catch (error) {
      if (error instanceof AppError) {
        setErrorMessage(error.message);
      }
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styleSheet.container}>
      <Title subtitles={departmentId ? ["Editar"] : ["Novo"]}>
        Departamento
      </Title>
      <Formik
        onSubmit={(event) => handleSubmit(event as Department)}
        enableReinitialize
        initialValues={department}
        validationSchema={Validation}
        validateOnBlur={false}
        validateOnChange={false}
      >
        <DepartmentEditForm />
      </Formik>
      <Loading open={loading} />
      <Message.Error
        open={error}
        onClose={() => {
          setError(false);
          setErrorMessage("");
        }}
      >
        {errorMessage}
      </Message.Error>
    </div>
  );
};

export default DepartmentEdit;
