import { Types } from "@ioc/types";
import { PenaltyStatus } from "@modules/company/dtos/IPenaltyDTO";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { parse } from "date-fns";
import { format } from "date-fns/esm";
import { inject, injectable } from "inversify";
import { v4 as uuidv4 } from "uuid";
import { GravityType } from "../dtos/IReportDriverPenaltiesDTO";
import { IReportVehiclesPenaltiesDTO } from "../dtos/IReportVehiclesPenaltiesDTO";
import { IReportVehiclesPenaltiesService } from "../models/IReportVehiclesPenaltiesService";

@injectable()
export class ReportVehiclesPenaltiesService
  implements IReportVehiclesPenaltiesService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    from: Date;
    to: Date;
    page: number;
    limit: number;
    plate?: string;
    modelID?: string;
    gravity?: GravityType;
    status?: PenaltyStatus;
    costCenterID?: string;
    departmentID?: string;
  }): Promise<IReportVehiclesPenaltiesDTO> {
    const resp = await this.httpInstance.get<IReportVehiclesPenaltiesDTO>(
      `/companies/${data.companyID}/report/penalties/paged`,
      {
        params: {
          from_date: format(data.from, "yyyy-MM-dd"),
          to_date: format(data.to, "yyyy-MM-dd"),
          limit: data.limit,
          page: data.page,
          type: "vehicle",
          punctuation: data.gravity
            ? data.gravity === "low"
              ? 3
              : data.gravity === "medium"
              ? 4
              : data.gravity === "high"
              ? 5
              : 7
            : undefined,
          status: data.status,
          model: data.modelID,
          plate: data.plate,
          department: data.departmentID,
          cost_center: data.costCenterID,
        },
      }
    );

    resp.content = resp.content.map((report) => {
      // @ts-ignore
      const date = report.data + " " + report.hora;

      return {
        ...report,
        id: uuidv4(),
        data: parse(
          (date as unknown) as string,
          "dd/MM/yyyy HH:mm",
          new Date()
        ),
      };
    });

    return resp;
  }
}
