import { ButtonLinkExpand } from "@components/ButtonLinkExpand";
import { ButtonLinkWithName } from "@components/ButtonLinkWithName";
import { useLayoutContext } from "@hooks/LayoutContext";
import { Paper, SwipeableDrawer } from "@material-ui/core";
import ApartmentIcon from "@material-ui/icons/Apartment";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import BuildIcon from "@material-ui/icons/Build";
import CategoryIcon from "@material-ui/icons/Category";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LocalShipping from "@material-ui/icons/LocalShipping";
import DirectionsCar from "@material-ui/icons/DirectionsCar";
import Gavel from "@material-ui/icons/Gavel";
import ExtensionIcon from "@material-ui/icons/Extension";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import LocalAtm from "@material-ui/icons/LocalAtm";
import HelpIcon from "@material-ui/icons/Help";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PeopleIcon from "@material-ui/icons/People";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@config/routesConfig";
import { ButtonLinkWithoutName } from "../ButtonLinkWithoutName";
import LeftSideBarStyle from "./LeftSideBarStyle";
import { InsertDriveFileRounded } from "@material-ui/icons";
import Maintenance from "@material-ui/icons/Build";
import { Flagcard } from "@components/Logo";

const items = [
  {
    route: ROUTES.DASHBOARD,
    title: "Dashboard",
    icon: <DashboardIcon className={"menu-icon-"} />,
    items: undefined,
  },
  {
    title: "Gestão de Frota",
    icon: <LocalShipping className={"menu-icon-"} />,
    new: true,
    items: [
      {
        route: ROUTES.FLEET_DASHBOARD,
        title: "Dashboard",
        icon: <DashboardIcon style={{ color: "gray" }} />,
      },
      {
        route: ROUTES.DRIVERS,
        title: "Motoristas",
        icon: <ContactMailIcon style={{ color: "gray" }} />,
      },
      {
        route: ROUTES.VEHICLES,
        title: "Veículos",
        icon: <DirectionsCar style={{ color: "gray" }} />,
      },
      {
        route: ROUTES.MAINTENANCE,
        title: "Ordem de Serviço",
        icon: <Maintenance style={{ color: "gray" }} />,
      },
      {
        route: ROUTES.INFRACTION,
        title: "Infrações de Trânsito",
        icon: <Gavel style={{ color: "gray" }} />,
      },
      {
        route: ROUTES.DEBITS,
        title: "Débitos de Veículo",
        icon: <LocalAtm style={{ color: "gray" }} />,
      },
    ],
  },
  {
    title: "Estabelecimentos",
    icon: <LocalGasStationIcon />,
    items: [
      {
        title: "Preços",
        icon: <AttachMoneyIcon style={{ color: "gray" }} />,
        route: ROUTES.ESTABLISHMENTS_PRICES,
      },
      {
        title: "Localização",
        icon: <LocationOnIcon style={{ color: "gray" }} />,
        route: ROUTES.ESTABLISHMENTS_LOCATIONS,
      },
    ],
  },
  {
    route: ROUTES.RECHARGE,
    title: "Recarga",
    icon: <MonetizationOnIcon />,
    items: undefined,
  },
  {
    route: ROUTES.FOLDER,
    title: "Gestão de Documentos",
    icon: <InsertDriveFileRounded />,
    items: undefined,
    new: false,
  },
  {
    title: "Relatórios",
    icon: <AssessmentIcon />,
    items: [
      {
        title: "Gerencial - débitos",
        icon: <TrendingDownIcon style={{ color: "gray" }} />,
        route: ROUTES.REPORTS_DEBTS,
      },
      {
        title: "Gerencial - créditos",
        icon: <TrendingUpIcon style={{ color: "gray" }} />,
        route: ROUTES.REPORTS_CREDITS,
      },
      // {
      //   title: "Ranking de veículos",
      //   icon: <CommuteIcon />,
      //   route: ROUTES.REPORTS_VEHICLE_RANKING,
      // },
      {
        title: "Novos usuários",
        icon: <GroupAddIcon style={{ color: "gray" }} />,
        route: ROUTES.REPORTS_NEW_USERS,
      },
      {
        title: "Saldo individual",
        icon: <LocalAtmIcon style={{ color: "gray" }} />,
        route: ROUTES.REPORTS_BALANCE,
      },
      // {
      //   title: "Multas Por Infrator",
      //   icon: <SvgIcon component={BadgeAccountAlertIcon} />,
      //   route: ROUTES.REPORTS_PENALTIES_DRIVERS,
      // },
      // {
      //   title: "Multas Por Veículo",
      //   icon: <SvgIcon component={BusAlertIcon} />,
      //   route: ROUTES.REPORTS_PENALTIES_VEHICLES,
      // },
    ],
  },
  {
    title: "Cadastros",
    icon: <ExtensionIcon />,
    items: [
      {
        route: ROUTES.USERS,
        title: "Usuários",
        icon: <PeopleIcon style={{ color: "gray" }} />,
      },
      {
        route: ROUTES.DEPARTMENTS,
        title: "Departamentos",
        icon: <ApartmentIcon style={{ color: "gray" }} />,
      },
      {
        route: ROUTES.ACTIVITIES,
        title: "Função",
        icon: <CategoryIcon style={{ color: "gray" }} />,
      },
      {
        route: ROUTES.COST_CENTERS,
        title: "Centro de custos",
        icon: <LocalAtmIcon style={{ color: "gray" }} />,
      },
      {
        route: ROUTES.EQUIPMENTS,
        title: "Equipamentos",
        icon: <BuildIcon style={{ color: "gray" }} />,
      },
    ],
  },
  {
    route: ROUTES.HELP,
    title: "Ajuda",
    icon: <HelpIcon />,
    items: undefined,
  },
];

const LeftSideBar: React.FC = () => {
  const classes = LeftSideBarStyle();
  const layoutContext = useLayoutContext();
  const history = useHistory();
  const [activePath, setActivePath] = useState(ROUTES.DASHBOARD);
  return (
    <>
      <Paper component="aside" className={classes.aside}>
        <div className={classes["container-closed"]}>
          {items.map((item) => {
            if (!item.items) {
              return (
                <ButtonLinkWithoutName
                  key={item.title}
                  route={item.route}
                  title={item.title}
                  onClick={() => setActivePath(history?.location?.pathname)}
                  badge={!!item.new}
                  active={activePath === item.route}
                >
                  {item.icon}
                </ButtonLinkWithoutName>
              );
            }
            const isParentItemActive = item.items
              .map((i) => i.route)
              .includes(activePath);
            return (
              <ButtonLinkExpand
                showExpandButton={false}
                expand={isParentItemActive}
                routes={item.items.map((i) => {
                  return {
                    ButtonLinkWithoutNameProps: {
                      route: i.route,
                      title: i.title,
                      children: i.icon,
                      onClick: () => setActivePath(history?.location?.pathname),
                      active: activePath === i.route,
                    },
                  };
                })}
              >
                <ButtonLinkWithoutName
                  route=""
                  title={item.title}
                  active={isParentItemActive}
                  clickable={false}
                >
                  {item.icon}
                </ButtonLinkWithoutName>
              </ButtonLinkExpand>
            );
          })}
        </div>
        <SwipeableDrawer
          anchor="left"
          open={layoutContext.openLeftSideBar}
          onClose={() => layoutContext.setOpenLeftSideBar(false)}
          onOpen={() => layoutContext.setOpenLeftSideBar(true)}
        >
          <div className={classes["container-opened"]}>
            <div className={classes.header}>
              <Flagcard top={-6} />
            </div>
            {items.map((item) => {
              if (!item.items) {
                return (
                  <ButtonLinkWithName
                    key={item.title}
                    route={item.route}
                    title={item.title}
                    onClick={() => setActivePath(history?.location?.pathname)}
                    active={activePath === item.route}
                  >
                    {item.icon}
                  </ButtonLinkWithName>
                );
              }
              const isParentItemActive = item.items
                .map((i) => i.route)
                .includes(activePath);
              return (
                <ButtonLinkExpand
                  showExpandButton={false}
                  expand={isParentItemActive}
                  routes={item.items.map((i) => {
                    return {
                      buttonLinkWithNameProps: {
                        route: i.route,
                        title: i.title,
                        children: i.icon,
                        onClick: () =>
                          setActivePath(history?.location?.pathname),
                        active: activePath === i.route,
                      },
                    };
                  })}
                >
                  <ButtonLinkWithName
                    route=""
                    title={item.title}
                    active={isParentItemActive}
                    onClick={() => setActivePath(history?.location?.pathname)}
                    clickable={false}
                  >
                    {item.icon}
                  </ButtonLinkWithName>
                </ButtonLinkExpand>
              );
            })}
          </div>
        </SwipeableDrawer>
      </Paper>
    </>
  );
};

export { LeftSideBar };
