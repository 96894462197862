import React, { useEffect, useState } from "react";
import { Title } from "@components/Title/Title";
import { Formik } from "formik";
import { Loading } from "@components/Loading";
import Message from "@components/Message";
import { useUserContext } from "@hooks/UserContext";
import { useHistory, useParams } from "react-router";
import { Types } from "@ioc/types";
import { useIoCContext } from "@hooks/IoCContext";
import { Folder, FolderInitialValues } from "../../../client/folder/Folder";
import { FolderClient } from "../../../client/folder/FolderClient";
import FolderEditForm from "@pages/Folder/Edit/Form";
import Validation from "@pages/Folder/Edit/Form/Validation";
import StyleSheet from "../../../StyleSheet";
import { ROUTES } from "@config/routesConfig";
import AppError from "@utils/AppError";

const FolderEdit: React.FC = () => {
  const styleSheet = StyleSheet();
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const history = useHistory();
  const params = useParams<{ id?: string }>();
  const [folderId] = useState(params.id);
  const [folder, setFolder] = useState<Folder>(FolderInitialValues);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const folderClient = iocContext.serviceContainer.get<FolderClient>(
    Types.Folder
  );

  const fetchFolder = () => {
    if (!selectedCompany) return;
    if (!folderId) return;
    setLoading(true);
    folderClient
      .findById(selectedCompany.id, folderId)
      .then((result) => {
        setFolder(result);
        setLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error.response?.data?.message);
        setError(true);
        setLoading(false);
      });
  };

  useEffect(fetchFolder, [selectedCompany, folderId, folderClient]);

  const handleSubmit = async (event: Folder) => {
    setLoading(true);
    try {
      const payload: Folder = {
        description: event.description,
        emails: event.emails,
      };
      if (["vehicle", "driver"].includes(event.externalReferenceType || "")) {
        payload.resource = {
          id: event.externalReferenceId,
          description: event.externalReferenceDescription,
          type: event.externalReferenceType,
        };
      }
      if (selectedCompany) {
        if (!folderId) {
          await folderClient.save(selectedCompany.uuid, payload);
        } else {
          await folderClient.update(selectedCompany.uuid, folderId, payload);
        }
        history.push({
          pathname: ROUTES.FOLDER,
          state: { success: true },
        });
      } else {
        throw new AppError("company_id is undefined", "error");
      }
    } catch (error) {
      if (error instanceof AppError) {
        setErrorMessage(error.message);
      }
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styleSheet.container}>
      <Title subtitles={folderId ? ["Editar"] : ["Nova Pasta"]}>Pasta</Title>
      <Formik
        onSubmit={(event) => handleSubmit(event as Folder)}
        enableReinitialize
        initialValues={folder}
        validationSchema={Validation}
        validateOnBlur={false}
        validateOnChange={false}
      >
        <FolderEditForm />
      </Formik>
      <Loading open={loading} />
      <Message.Error
        open={error}
        onClose={() => {
          setError(false);
          setErrorMessage("");
        }}
      >
        {errorMessage}
      </Message.Error>
    </div>
  );
};

export default FolderEdit;
