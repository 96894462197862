import React, { useEffect, useState } from "react";
import { Title } from "@components/Title/Title";
import { useUserContext } from "@hooks/UserContext";
import { useIoCContext } from "@hooks/IoCContext";
import { Types } from "@ioc/types";
import { FolderClient } from "../../../client/folder/FolderClient";
import { Filter, SearchContainer } from "@components/SearchContainer";
import { Button } from "@components/Button";
import { ROUTES } from "@config/routesConfig";
import { DataTable } from "@components/DataTable";
import { SearchInitialValues } from "../../../client/model/Search";
import { useHistory, useLocation } from "react-router-dom";
import Message from "@components/Message";
import { AddBox } from "@material-ui/icons";
import { ColumnDefinition } from "@pages/Folder/List/ColumDefinition";
import StyleSheet from "../../../StyleSheet";
import { Folder } from "../../../client/folder/Folder";

const getExternalReferenceType = (externalReferenceType?: string) => {
  if (externalReferenceType === "vehicle") return "Veículo";
  if (externalReferenceType === "driver") return "Motorista";
  return "Geral";
};

const FolderList: React.FC = () => {
  const styleSheet = StyleSheet();
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [success, setSuccess] = useState(
    !!(location?.state as { success: boolean })?.success
  );
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [folders, setFolders] = useState(SearchInitialValues);
  const [filters, setFilters] = useState<Filter[]>([
    {
      label: "Descrição",
      field: "description",
      placeholder: "Pesquisar por Descrição",
    },
    {
      label: "Placa",
      field: "plate",
      placeholder: "Pesquisar por Placa",
    },
    {
      label: "CPF",
      field: "document",
      placeholder: "Pesquisar por CPF",
    },
  ]);

  const folderClient = iocContext.serviceContainer.get<FolderClient>(
    Types.Folder
  );

  const fetchFolders = () => {
    if (!selectedCompany) return;
    setLoading(true);
    const description = filters.find(
      (filter) => filter.field === "description"
    );
    const externalReferenceDescription = filters.find((filter) =>
      ["plate", "document"].includes(filter.field)
    );
    const filter = {
      page: page,
      limit: limit,
      description: description?.value,
      externalReferenceDescription: externalReferenceDescription?.value,
    };
    folderClient
      .find(selectedCompany.uuid, filter)
      .then((result) => {
        setFolders(result);
        setLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error.response?.data?.message);
        setError(true);
        setLoading(false);
      });
  };

  const handleEdit = (folderId?: string): void => {
    history.push(ROUTES.FOLDER_EDIT(folderId));
  };

  const handleDocuments = (folderId?: string): void => {
    history.push(ROUTES.DOCUMENT(folderId));
  };

  const handleRemove = async (folderId?: string) => {
    if (!selectedCompany || !folderId) return;
    setLoading(true);
    await folderClient.remove(selectedCompany.uuid, folderId);
    setSuccess(true);
    fetchFolders();
  };

  useEffect(fetchFolders, [filters, page, selectedCompany, limit]);

  return (
    <div className={styleSheet.container}>
      <Title>Pastas</Title>
      <SearchContainer filters={filters} onFilter={setFilters}>
        <Button to={ROUTES.FOLDER_ADD}>Nova Pasta</Button>
      </SearchContainer>
      <DataTable
        columns={ColumnDefinition}
        rows={folders.result.map((folder: Folder) => ({
          ...folder,
          externalReferenceType: getExternalReferenceType(
            folder.externalReferenceType
          ),
        }))}
        limit={limit}
        pages={folders.pages}
        total={folders.count}
        loading={loading}
        onEdit={handleEdit}
        onRemove={handleRemove}
        editable
        removable
        contexts={[
          {
            name: "Documentos",
            icon: <AddBox fontSize="small" />,
            onClick: handleDocuments,
          },
        ]}
        onPagination={(page) => setPage(page)}
        onLimitChange={(limit) => setLimit(limit)}
      />
      <Message.Success open={success} onClose={() => setSuccess(false)} />
      <Message.Error
        open={error}
        onClose={() => {
          setError(false);
          setErrorMessage(undefined);
        }}
      >
        {errorMessage}
      </Message.Error>
    </div>
  );
};

export default FolderList;
