import React from "react";
import { Button, createStyles, GridProps, makeStyles } from "@material-ui/core";
import { ISmallerBalanceDTO } from "@modules/company/dtos/ISmallerBalanceDTO";
import { formatCurrency } from "@utils/index";
import { useLayoutContext } from "@hooks/LayoutContext";

interface Props extends GridProps {
  rows: ISmallerBalanceDTO[];
  inMoney: boolean;
  onClose: () => void;
}

const DialogBalanceStyle = makeStyles((theme) =>
  createStyles({
    "table-td": {
      fontSize: "12px",
      padding: "0 4px 0 4px",
      whiteSpace: "nowrap",
      color: "#696969",
    },
    "table-tr": {
      fontSize: "12px",
      fontWeight: "bold",
      padding: "4px 0",
    },
    odd: {
      backgroundColor: "#F5FFFA",
    },
    buttonRecharge: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      fontWeight: "bold",
      fontSize: "12px",
      borderRadius: "4px",
      "&:hover": {
        backgroundColor:
          theme.palette.type === "dark"
            ? theme.palette.primary.main
            : theme.palette.hexToRGBWithAlpha("#000000", 0.04),
        color:
          theme.palette.type === "dark" ? "#fff" : theme.palette.primary.main,
      },
    },
  })
);

const DialogBalance: React.FC<Props> = ({
  rows,
  inMoney,
  onClose,
  ...props
}) => {
  const classes = DialogBalanceStyle();
  const layoutContext = useLayoutContext();
  return (
    <table>
      <tr className={classes["table-tr"]}>
        <td />
        <td>Usuário</td>
        <td>Saldo</td>
        <td>Consumido</td>
        <td />
      </tr>
      <tbody>
        {rows.map((row, index) => (
          <tr key={row.id} className={index % 2 === 0 ? classes.odd : ""}>
            <td />
            <td className={classes["table-td"]}>{row.User.name}</td>
            <td className={classes["table-td"]}>
              {inMoney
                ? formatCurrency(row.quantity)
                : row.quantity.toLocaleString("pt-BR", {
                    maximumFractionDigits: 2,
                  }) + "L"}
            </td>
            <td className={classes["table-td"]}>
              {row.User.periodic_credits
                ? Math.ceil(
                    100 - (row.quantity / row.User.periodic_credits) * 100
                  ) + "%"
                : "-"}
            </td>
            <td>
              <Button
                className={classes.buttonRecharge}
                onClick={() => {
                  onClose();
                  layoutContext.setOpenManualRecharge({
                    open: true,
                    data: {
                      date: new Date(),
                      description: `Recarga extra para ${row.User.name}`,
                      rechargeValue: null,
                      document: row.User.document,
                    },
                  });
                }}
              >
                Recarregar
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export { DialogBalance };
