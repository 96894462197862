import React, { useEffect, useState } from "react";
import { Row } from "@components/Row";
import { Types } from "@ioc/types";
import { useUserContext } from "@hooks/UserContext";
import { useIoCContext } from "@hooks/IoCContext";
import { QuotationClient } from "../../../../client/Quotation/QuotationClient";
import { useSnackbar } from "notistack";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
} from "@material-ui/core";
import Quotation, { Product } from "../../../../client/Quotation/Quotation";
import StyleSheet from "@pages/Fleet/Maintenance/StyleSheet";
import { DataColumn, DataTable } from "@components/DataTable";
import { formatCurrency } from "@utils/index";
import { Button } from "@components/Button";
import SendIcon from "@material-ui/icons/Send";
import { format } from "date-fns/esm";

interface QuotationDetailProps {
  quotationId?: string;
  open: boolean;
  onClose: () => void;
}

const ColumnDefinition: DataColumn[] = [
  {
    field: "name",
    headerName: "Produto/Serviço",
  },
  {
    field: "quantity",
    headerName: "Quantidade",
  },
  {
    field: "price",
    headerName: "Valor Unitário",
    converter: "currency",
  },
  {
    field: "total",
    headerName: "Valor Total",
    converter: "currency",
  },
];

const getPossibleStatus = (status: string) => {
  const statuses = [
    {
      id: "pending",
      label: "Pendente",
      description:
        "O provedor ainda não finalizou o processo de cotação para esta ordem de serviço",
    },
    {
      id: "ready_to_approval",
      label: "Disponível",
      description: "Disponível para aprovação",
    },
  ];
  if (status === "CANCELED") {
    statuses.push({
      id: "canceled",
      label: "Cancelado",
      description: "Cancelado pelo provedor",
    });
    return statuses;
  }
  if (status === "REJECTED") {
    statuses.push({
      id: "rejected",
      label: "Rejeitado",
      description: "Proposta rejeitada",
    });
    return statuses;
  }
  statuses.push({
    id: "approved",
    label: "Aprovado",
    description: "Proposta aprovada",
  });
  statuses.push({
    id: "in_execution",
    label: "Em Andamento",
    description: "Execução dos serviços em andamento",
  });
  statuses.push({
    id: "finished",
    label: "Finalizado",
    description: "Serviço finalizado",
  });
  return statuses;
};

const getActiveStatus = (status: string) => {
  const statuses = [
    "PENDING",
    "READY_TO_APPROVAL",
    "APPROVED",
    "IN_EXECUTION",
    "FINISHED",
  ];
  const index = statuses.indexOf(status);
  return index === -1 ? 2 : index;
};

const formatDate = (value?: Date) => {
  if (!value) return "-";
  const date = typeof value === "string" ? new Date(value) : value;
  return `[${format(date, "dd/MM/yyyy HH:mm")}]`;
};

const QuotationDetail: React.FC<QuotationDetailProps> = ({
  quotationId,
  open,
  onClose,
}) => {
  const styleSheet = StyleSheet();
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [quotation, setQuotation] = useState<Quotation>();
  const [products, setProducts] = useState<Product[]>([]);
  const [discount, setDiscount] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [comment, setComment] = useState("");

  const client = iocContext.serviceContainer.get<QuotationClient>(
    Types.Quotation
  );

  const handleCreateComment = () => {
    setLoading(true);
    if (selectedCompany && quotationId) {
      client
        .addComment(
          selectedCompany.uuid,
          quotationId,
          selectedCompany.name,
          comment
        )
        .then(() => {
          setComment("");
          fetchQuotation();
        })
        .catch((error) => {
          enqueueSnackbar(error.response?.data?.message, { variant: "error" });
          setLoading(false);
        });
    }
  };

  const handleRejectQuotation = () => {
    setLoading(true);
    if (selectedCompany && quotationId) {
      client
        .update(selectedCompany.uuid, quotationId, "REJECTED")
        .then(() => {
          fetchQuotation();
        })
        .catch((error) => {
          enqueueSnackbar(error.response?.data?.message, { variant: "error" });
          setLoading(false);
        });
    }
  };

  const handleApproveQuotation = () => {
    setLoading(true);
    if (selectedCompany && quotationId) {
      client
        .update(selectedCompany.uuid, quotationId, "APPROVED")
        .then(() => {
          fetchQuotation();
        })
        .catch((error) => {
          enqueueSnackbar(error.response?.data?.message, { variant: "error" });
          setLoading(false);
        });
    }
  };

  const handleCreateCommentOnEnter = (event: any) => {
    if (event.key === "Enter") {
      handleCreateComment();
      event.preventDefault();
    }
  };

  const fetchQuotation = () => {
    setLoading(true);
    if (selectedCompany && open && quotationId) {
      client
        .findById(selectedCompany.uuid, quotationId)
        .then((result) => {
          const normalizedProducts =
            result.products?.map((product) => {
              const quantity = product.quantity || 1;
              return {
                ...product,
                quantity,
                total: quantity * (product.price || 0),
              };
            }) || [];
          const totals = normalizedProducts.map((product) => product.total);
          const total = totals.length ? totals.reduce((x, y) => x + y) : 0;
          setQuotation(result);
          setProducts(normalizedProducts);
          setDiscount((total * (result.discount || 0)) / 100);
          setTotal(total);
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.response?.data?.message, { variant: "error" });
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(fetchQuotation, [open, quotationId]);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={"xl"}>
        <DialogTitle>
          <span style={{ fontSize: 16, fontWeight: "bolder" }}>
            Detalhes da Proposta
          </span>
        </DialogTitle>
        <DialogContent style={{ width: 800 }}>
          <Stepper activeStep={getActiveStatus(quotation?.status || "PENDING")}>
            {getPossibleStatus(quotation?.status || "PENDING").map((status) => {
              return (
                <Step key={status.id}>
                  <StepLabel>
                    <Tooltip title={status.description}>
                      <span>{status.label}</span>
                    </Tooltip>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {quotation?.status === "READY_TO_APPROVAL" ? (
            <Row align="end">
              <Button color="error" onClick={handleRejectQuotation}>
                Rejeitar
              </Button>
              <div style={{ width: 10 }} />
              <Button color="success" onClick={handleApproveQuotation}>
                Aprovar
              </Button>
            </Row>
          ) : (
            <></>
          )}
          <Row>
            <TextField
              style={{ width: "100%" }}
              classes={{ root: styleSheet.textField }}
              label="Provedor"
              variant="outlined"
              value={quotation?.store?.name || ""}
              size="small"
              disabled
            />
          </Row>
          <Row>
            <DataTable
              loading={loading}
              rows={products}
              columns={ColumnDefinition}
            />
          </Row>
          <Row>
            <TextField
              style={{ width: "50%" }}
              classes={{ root: styleSheet.textField }}
              label="Prazo (dias úteis)"
              variant="outlined"
              value={quotation?.workingDays || ""}
              size="small"
              disabled
            />
            <TextField
              style={{ width: "50%" }}
              classes={{ root: styleSheet.textField }}
              label="Desconto"
              variant="outlined"
              value={formatCurrency(discount)}
              size="small"
              disabled
            />
          </Row>
          <Row>
            <TextField
              style={{ width: "100%" }}
              classes={{ root: styleSheet.textField }}
              label="Valor Total (Com Desconto)"
              variant="outlined"
              value={formatCurrency(total - discount)}
              size="small"
              disabled
            />
          </Row>
          <Row>
            <div style={{ padding: 4 }}>
              <div
                style={{
                  border: "solid 1px rgba(225, 225, 225, 0.5)",
                  backgroundColor: "rgba(225, 225, 225, 0.1)",
                  width: 733,
                  borderRadius: 4,
                  padding: "8px 2px",
                }}
              >
                <div style={{ minHeight: 80 }}>
                  {quotation?.comments?.map((comment) => {
                    const align =
                      comment?.user?.id === selectedCompany?.uuid
                        ? "end"
                        : "start";
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: align,
                          padding: "2px 8px",
                        }}
                      >
                        <div
                          style={{
                            border: "solid 1px rgba(225, 225, 225, 0.5)",
                            backgroundColor: "#FFF",
                            borderRadius: 4,
                            padding: 8,
                            fontStyle: "italic",
                          }}
                        >
                          <span style={{ fontSize: 10, fontWeight: 300 }}>
                            {formatDate(comment.createdAt)}
                          </span>
                          <span> - </span>
                          <span>{comment.message}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div style={{ paddingTop: 4 }}>
                  <TextField
                    style={{ width: "100%", backgroundColor: "#FFF" }}
                    classes={{ root: styleSheet.textField }}
                    variant="outlined"
                    value={comment}
                    onChange={(event) => setComment(event.target.value)}
                    size="small"
                    onKeyDown={handleCreateCommentOnEnter}
                    InputProps={{
                      endAdornment: (
                        <div style={{ paddingRight: 8 }}>
                          <SendIcon
                            color="primary"
                            style={{ cursor: "pointer", marginTop: 4 }}
                            onClick={handleCreateComment}
                          />
                        </div>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </Row>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default QuotationDetail;
