import React from "react";
import {createStyles, GridProps, Icon, IconButton, makeStyles} from "@material-ui/core";
import {IExpiringLicenseDTO} from "@modules/company/dtos/IExpiringLicenseDTO";
import {format} from "date-fns/esm";
import clsx from "clsx";
import {Link} from "react-router-dom";
import {ROUTES} from "@config/routesConfig";

interface Props extends GridProps {
    rows: IExpiringLicenseDTO[]
}

const DialogCNHStyle = makeStyles((theme) =>
    createStyles({
        error: {
            color: theme.palette.tertiary.error.primary,
        },
        warning: {
            color: theme.palette.tertiary.intermediate.primary,
        },
        "table-td": {
            fontSize: "12px",
            fontWeight: "bold",
            padding: "0 4px 0 4px",
            whiteSpace: "nowrap"
        },
        "table-tr": {
            fontSize: "12px",
            fontWeight: "bold",
            padding: "4px 0"
        },
        icon: {
            fontSize: "14px",
            overflow: "visible",
            color: theme.palette.primary.main,
        },
        odd: {
            backgroundColor: "#F5FFFA"
        }
    })
);

const formatExpirationDate = (expiration_date: Date) => {
    if (!expiration_date) {
        return "-";
    }
    return format(new Date(expiration_date), "dd/MM/yyyy");
}

const DialogCNH: React.FC<Props> = ({rows, ...props}) => {
    const classes = DialogCNHStyle();
    return (
        <table>
            <tr className={classes["table-tr"]}>
                <td/>
                <td>Motorista</td>
                <td>CNH</td>
                <td>Validade</td>
            </tr>
            <tbody>
            {rows.map((row, index) => (
                <tr key={row.id} className={index%2===0?classes.odd:""}>
                    <td>
                        <IconButton
                            size="small"
                            component={Link}
                            to={ROUTES.DRIVERS_EDIT(row.id)}>
                            <Icon component="i" className={clsx(classes.icon, "fas fa-address-card")}/>
                        </IconButton>
                    </td>
                    <td className={clsx(classes["table-td"], {
                        [classes.error]: row.alreadyExpired,
                        [classes.warning]: !row.alreadyExpired
                    })}>{row.name}</td>
                    <td className={clsx(classes["table-td"], {
                        [classes.error]: row.alreadyExpired,
                        [classes.warning]: !row.alreadyExpired
                    })}>{row.cnh}</td>
                    <td className={clsx(classes["table-td"], {
                        [classes.error]: row.alreadyExpired,
                        [classes.warning]: !row.alreadyExpired
                    })}>
                        {formatExpirationDate(row.cnh_expiration_date)}
                    </td>
                </tr>
            ))}
            </tbody>
        </table>)
};

export {DialogCNH};
