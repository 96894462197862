import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IEditCostCenterService } from "../models/IEditCostCenterService";

@injectable()
export class EditCostCenterService implements IEditCostCenterService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    costCenterID: string;
    name: string;
  }): Promise<void> {
    await this.httpInstance.put(
      `/companies/${data.companyID}/costcenters/${data.costCenterID}`,
      {
        tx_centro_custo: data.name,
      }
    );
  }
}
