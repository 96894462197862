import React, { useEffect, useState } from "react";
import StyleSheet from "../StyleSheet";
import { Title } from "@components/Title/Title";
import { Box, StandardProps, Tab, Tabs } from "@material-ui/core";
import { Formik } from "formik";
import Validation from "./Data/Form/Validation";
import { Loading } from "@components/Loading";
import { useParams } from "react-router";
import { TabPanelClassKey } from "@material-ui/lab/TabPanel/TabPanel";
import Maintenance, {
  MaintenanceDataInitialValues,
  MaintenanceDataPayload,
  VehicleMaintenance,
} from "../../../../client/Maintenance/Maintenance";
import MaintenanceDataForm from "@pages/Fleet/Maintenance/Edit/Data/Form";
import { useUserContext } from "@hooks/UserContext";
import { useSnackbar } from "notistack";
import { ManufacturerClient } from "../../../../client/manufacturer/ManufacturerClient";
import { Types } from "@ioc/types";
import { useIoCContext } from "@hooks/IoCContext";
import { MaintenanceClient } from "../../../../client/Maintenance/MaintenanceClient";
import { ROUTES } from "@config/routesConfig";
import { useHistory } from "react-router-dom";
import Vehicle, {
  VehicleDataPayload,
  vehicleToVehicleData,
  vehicleToVehicleGeneralInfo,
} from "../../../../client/vehicle/Vehicle";
import QuotationList from "@pages/Fleet/Maintenance/Quotation";

export interface TabPanelProps
  extends StandardProps<
    React.HTMLAttributes<HTMLDivElement>,
    TabPanelClassKey
  > {
  children?: React.ReactNode;
  value: number;
  index: number;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index } = props;
  const isSameIndex = value === index;
  return (
    <div
      id={`maintenance_tabpanel_${index}`}
      role="tabpanel"
      hidden={!isSameIndex}
      aria-labelledby={`maintenance_tab_${index}`}
    >
      {children}
    </div>
  );
};

export const maintenanceToMaintenanceData = (
  maintenance: Maintenance
): MaintenanceDataPayload => {
  return {
    id: maintenance.id,
    reports: maintenance.reports,
    vehicleId: maintenance.vehicle?.id,
    vehiclePlate: maintenance.vehicle?.plate,
    vehicleModel: maintenance.vehicle?.model,
    type: maintenance.type,
  };
};

const MaintenanceEdit: React.FC = () => {
  const styleSheet = StyleSheet();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const params = useParams<{ id?: string }>();
  const iocContext = useIoCContext();
  const { selectedCompany } = useUserContext();
  const [maintenanceId] = useState(params.id);
  const [title, setTitle] = useState("Ordem de Serviço");
  const [selectedTab, setSelectedTab] = useState<number>(maintenanceId ? 1 : 0);
  const [loading, setLoading] = useState(false);
  const [
    maintenanceDataPayload,
    setMaintenanceDataPayload,
  ] = useState<MaintenanceDataPayload>(MaintenanceDataInitialValues);

  const maintenanceClient = iocContext.serviceContainer.get<MaintenanceClient>(
    Types.Maintenance
  );

  const handleChangeTab = (event: any, selectedTab: number) => {
    setSelectedTab(selectedTab);
  };

  const handleMaintenanceSubmit = async (data: MaintenanceDataPayload) => {
    if (!selectedCompany) return;
    setLoading(true);
    try {
      const payload: Maintenance = {
        company: {
          id: selectedCompany.uuid,
          name: selectedCompany.name,
          document: "00608751000195",
        },
        vehicle: {
          id: data.vehicleId,
          plate: data.vehiclePlate,
          model: data.vehicleModel,
        },
        reports: data.reports,
        type: data.type || "MAINTENANCE",
        deadline: data.deadline,
      };
      if (!maintenanceId) {
        await maintenanceClient.save(selectedCompany.uuid, payload);
      } else {
        await maintenanceClient.update(
          selectedCompany.uuid,
          maintenanceId,
          payload
        );
      }
      history.push({
        pathname: ROUTES.MAINTENANCE,
        state: { success: true },
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const fetchMaintenance = () => {
    if (!selectedCompany) return;
    if (!maintenanceId) return;
    setLoading(true);
    maintenanceClient
      .findById(selectedCompany.id, maintenanceId)
      .then((result) => {
        setMaintenanceDataPayload(maintenanceToMaintenanceData(result));
        setTitle(`Ordem de Serviço - [${result.orderId}]`);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar(error.response?.data?.message, { variant: "error" });
      });
  };

  useEffect(fetchMaintenance, [
    selectedCompany,
    maintenanceId,
    maintenanceClient,
  ]);

  return (
    <div className={styleSheet.container}>
      <Title subtitles={maintenanceId ? ["Editar"] : ["Novo"]}>{title}</Title>
      <Box style={{ width: "100%" }}>
        <Box
          style={{ borderBottom: 1, borderColor: "divider", marginBottom: 12 }}
        >
          <Tabs value={selectedTab} onChange={handleChangeTab}>
            <Tab
              id="maintenance_tab_1"
              label="Dados da ordem de serviço"
              aria-controls="maintenance_tabpanel_1"
            />
            {maintenanceId ? (
              <Tab
                id="maintenance_tab_2"
                label="Propostas"
                aria-controls="maintenance_tabpanel_2"
              />
            ) : (
              <></>
            )}
          </Tabs>
        </Box>
        <TabPanel value={selectedTab} index={0}>
          <Formik
            onSubmit={(event) =>
              handleMaintenanceSubmit(event as MaintenanceDataPayload)
            }
            enableReinitialize
            initialValues={maintenanceDataPayload}
            validationSchema={Validation}
            validateOnBlur={false}
            validateOnChange={false}
          >
            <MaintenanceDataForm />
          </Formik>
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <QuotationList orderId={maintenanceId} />
        </TabPanel>
      </Box>
      <Loading open={loading} />
    </div>
  );
};

export default MaintenanceEdit;
