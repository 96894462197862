import { ErrorLoading } from "@components/ErrorLoading";
import { Pagination } from "@components/Pagination";
import { ReloadButton } from "@components/ReloadButton";
import { useIoCContext } from "@hooks/IoCContext";
import { useUserContext } from "@hooks/UserContext";
import { Types } from "@ioc/types";
import {
  createStyles,
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { ColDef, DataGrid, GridOverlay } from "@material-ui/data-grid";
import InfoIcon from "@material-ui/icons/Info";
import { IExceptionTransactionDTO } from "@modules/company/dtos/IListExceptionTransactionsDTO";
import { IGetExceptionTransactionsService } from "@modules/company/models/IGetExceptionTransactionsService";
import { tableStyles } from "@utils/index";
import clsx from "clsx";
import { isValid } from "date-fns";
import { format } from "date-fns/esm";
import React, { useCallback, useEffect } from "react";
import { DialogInfo } from "./DialogInfo";
import {
  ExceptionTransactionsProvider,
  useExceptionTransactions,
} from "./ExceptionTransactionsContext";
import "./tableGrid.css";

const useStyles = makeStyles((theme) =>
  createStyles({
    paperTable: {
      borderRadius: "2rem",
      borderTopLeftRadius: 0,
      height: "40rem",
      width: "100%",
    },
    headerColumns: {
      fontWeight: "bold",
      fontSize: "1.4rem",
      lineHeight: "inherit",
    },
    exceptionList: tableStyles(theme).dataGridTable,
  })
);

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

const Container: React.FC = () => {
  const classes = useStyles();
  const iocContext = useIoCContext();
  const userContext = useUserContext();
  const {
    setLoadingTransactions,
    setErrorLoadingTransactions,
    setTransactions,
    setInfoDialog,
    setOpenDialog,
    page,
    limit,
    transactions,
    loadingTransactions,
    errorLoadingTransactions,
    setPage,
  } = useExceptionTransactions();

  const getExceptionsTransactions = iocContext.serviceContainer.get<IGetExceptionTransactionsService>(
    Types.Company.IGetExceptionTransactionsService
  );

  const fetchExceptionsTransactions = useCallback(async () => {
    if (!userContext.selectedCompany) return;
    try {
      setLoadingTransactions(true);
      const resp = await getExceptionsTransactions.execute({
        companyID: userContext.selectedCompany.uuid,
        page,
        limit,
      });
      setTransactions(resp);
      setErrorLoadingTransactions(false);
    } catch (error) {
      setErrorLoadingTransactions(true);
    } finally {
      setLoadingTransactions(false);
    }
  }, [
    getExceptionsTransactions,
    limit,
    page,
    setErrorLoadingTransactions,
    setLoadingTransactions,
    setTransactions,
    userContext.selectedCompany,
  ]);

  useEffect(() => {
    fetchExceptionsTransactions();
  }, [fetchExceptionsTransactions]);

  const columns: ColDef[] = [
    {
      field: "info",
      headerName: " ",
      sortable: false,
      width: 70,
      renderCell: ({ row }) => {
        return (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <IconButton
              onClick={() => {
                setInfoDialog(row as IExceptionTransactionDTO);
                setOpenDialog(true);
              }}
              color="primary"
            >
              <InfoIcon />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: "tx_usuario",
      headerName: "Usuário",
      sortable: false,
      width: 350,
      align: "center",
      headerAlign: "center",
      renderHeader: (params) => {
        return (
          <Typography className={classes.headerColumns}>
            {params.colDef.headerName}
          </Typography>
        );
      },
    },
    {
      field: "tx_estabelecimento",
      headerName: "Estabelecimento",
      sortable: false,
      width: 250,
      align: "center",
      headerAlign: "center",
      renderHeader: (params) => {
        return (
          <Typography className={classes.headerColumns}>
            {params.colDef.headerName}
          </Typography>
        );
      },
    },
    {
      field: "tx_funcionario",
      headerName: "Frentista",
      sortable: false,
      width: 350,
      align: "center",
      headerAlign: "center",
      renderHeader: (params) => {
        return (
          <Typography className={classes.headerColumns}>
            {params.colDef.headerName}
          </Typography>
        );
      },
    },
    {
      field: "cs_status",
      headerName: "Motivo",
      sortable: false,
      width: 450,
      align: "center",
      headerAlign: "center",
      renderHeader: (params) => {
        return (
          <Typography className={classes.headerColumns}>
            {params.colDef.headerName}
          </Typography>
        );
      },
    },
    {
      field: "dt_transacao",
      headerName: "Data do ocorrido",
      sortable: false,
      width: 150,
      renderHeader: (params) => {
        return (
          <Typography className={classes.headerColumns}>
            {params.colDef.headerName}
          </Typography>
        );
      },
      valueFormatter: ({ value }) =>
        value && isValid(value)
          ? format(value as Date, "dd/MM/yyyy HH:mm")
          : "",
    },
  ];

  return (
    <>
      <Paper className={clsx(classes.paperTable, classes.exceptionList)}>
        <DataGrid
          rows={transactions.result}
          columns={columns}
          pageSize={limit}
          components={{
            pagination: ({ pagination, api }) => (
              <Pagination
                page={page}
                rowCount={transactions.count}
                pageSize={pagination.pageSize}
                setPage={(value) => setPage(value)}
              />
            ),
            loadingOverlay: CustomLoadingOverlay,
            errorOverlay: () => {
              return (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <ErrorLoading />
                  <ReloadButton
                    reloadFunc={fetchExceptionsTransactions}
                    reloading={loadingTransactions}
                  />
                </div>
              );
            },
          }}
          loading={loadingTransactions}
          error={errorLoadingTransactions ? true : null}
          disableSelectionOnClick
          scrollbarSize={5}
          autoPageSize
        />
      </Paper>
      <DialogInfo />
    </>
  );
};

const GenericInvalidTransactions: React.FC = () => {
  return (
    <ExceptionTransactionsProvider>
      <Container />
    </ExceptionTransactionsProvider>
  );
};

export { GenericInvalidTransactions };
