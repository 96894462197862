import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import AppError from "@utils/AppError";
import { inject, injectable } from "inversify";
import { UserStatus } from "../dtos/IListUsersDTO";
import { UserType } from "../models/ICreateUserService";
import { IEditUserService } from "../models/IEditUserService";

@injectable()
export class EditUserService implements IEditUserService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    userID: string;
    userName: string;
    CPF?: string | undefined;
    cardNumber?: string | undefined;
    vehiclePlate?: string | undefined;
    email?: string | undefined;
    phone?: string | undefined;
    functionID: string;
    departmentID: string;
    costCenterID: string;
    status: UserStatus;
    userType: UserType;
  }): Promise<void> {
    if (
      (data.userType === "USER" && !data.CPF) ||
      (data.userType === "CARD" && !data.cardNumber) ||
      (data.userType === "FLEET" && !data.vehiclePlate)
    ) {
      throw new AppError("Dados incompletos para criar usuário", "error");
    }

    await this.httpInstance.patch(
      `/companies/${data.companyID}/users/${data.userID}`,
      data.userType === "FLEET"
        ? {
            cs_status: data.status === "Ativo" ? true : false,
            id_centro_custo: data.costCenterID,
            id_departamento: data.departmentID,
            id_funcao: data.functionID,
            tx_documento: data.cardNumber,
            tx_perfil: data.userType,
            tx_usuario: data.vehiclePlate,
            type_fleet: true,
            uuid: data.userID,
          }
        : {
            cs_status: data.status === "Ativo" ? true : false,
            id_centro_custo: data.costCenterID,
            id_departamento: data.departmentID,
            id_funcao: data.functionID,
            tx_documento: data.userType === "CARD" ? data.cardNumber : data.CPF,
            tx_email: data.email,
            tx_perfil: data.userType,
            tx_telefone: data.phone,
            tx_usuario: data.userName,
            type_fleet: false,
            uuid: data.userID,
          }
    );
  }
}
