import { ErrorLoading } from "@components/ErrorLoading";
import { ReloadButton } from "@components/ReloadButton";
import { useIoCContext } from "@hooks/IoCContext";
import { useUserContext } from "@hooks/UserContext";
import { Types } from "@ioc/types";
import {
  Button,
  CircularProgress,
  Collapse,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { UserStatus } from "@modules/user/dtos/IListUsersDTO";
import { IUserDataDTO } from "@modules/user/dtos/IUserDataDTO";
import {
  ICreateUserService,
  UserType,
} from "@modules/user/models/ICreateUserService";
import { IEditUserService } from "@modules/user/models/IEditUserService";
import { IGetUserDataService } from "@modules/user/models/IGetUserDataService";
import AppError from "@utils/AppError";
import {
  maskVehiclePlateMercoSul,
  performCPFMask,
  performCreditCardMaks,
} from "@utils/index";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import { AsYouType } from "libphonenumber-js";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { FormAddUserValidationSchema } from "./FormAddUserValidationSchema";
import { useUsersPage } from "./UsersPageContext";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      dialogPaper: {
        minWidth: "95vw",
        [theme.breakpoints.up("sm")]: {
          minWidth: "50vw",
        },
      },
      dialogTitle: {
        fontWeight: typography.fontWeightBold,
        fontSize: pxToRem(16),
      },
      rootInputLabel: {
        color: theme.palette.tertiary.graphics[4],
        fontWeight: typography.fontWeightBold,
      },
      inputText: {
        color: theme.palette.tertiary.textButtons.primary,
        fontSize: "1.6rem",
        fontWeight: typography.fontWeightMedium,
      },
      divider: {
        backgroundColor: theme.palette.tertiary.background.primary,
      },
      buttons: {
        fontWeight: typography.fontWeightBold,
      },
      cancelButton: {
        color: theme.palette.tertiary.textButtons.primary,
      },
    })
);

const ModalAddUser: React.FC = () => {
  const usersPageContext = useUsersPage();
  const classes = useStyles();
  const iocContext = useIoCContext();
  const userContext = useUserContext();
  const { enqueueSnackbar } = useSnackbar();

  const createUserService = iocContext.serviceContainer.get<ICreateUserService>(
    Types.User.ICreateUserService
  );
  const getUserDataService = iocContext.serviceContainer.get<IGetUserDataService>(
    Types.User.IGetUserDataService
  );
  const editUserService = iocContext.serviceContainer.get<IEditUserService>(
    Types.User.IEditUserService
  );

  const [loadingCreateUser, setLoadingCreateUser] = useState(false);
  const [loadingUserData, setLoadingUserData] = useState(true);
  const [errorLoadingUserData, setErrorLoadingUserData] = useState(true);
  const [userData, setUserData] = useState<IUserDataDTO | null>(null);

  const fetchUserData = useCallback(async () => {
    if (!userContext.selectedCompany) return;
    if (!usersPageContext.openModalUser.data) return;
    try {
      setLoadingUserData(true);
      const userData = await getUserDataService.execute({
        companyID: userContext.selectedCompany.uuid,
        userID: usersPageContext.openModalUser.data.uuid,
      });
      setUserData(userData);
      setErrorLoadingUserData(false);
    } catch (error) {
      setErrorLoadingUserData(true);
    } finally {
      setLoadingUserData(false);
    }
  }, [
    getUserDataService,
    userContext.selectedCompany,
    usersPageContext.openModalUser.data,
  ]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const onClose = () => {
    setUserData(null);
    usersPageContext.setOpenModalUser({ open: false, data: null });
  };

  const initialValues = {
    name: userData ? userData.tx_usuario : "",
    type: userData ? userData.tx_perfil : "USER",
    CPF: userData ? userData.tx_documento : "",
    cardNumber: userData ? userData.tx_documento : "",
    vehiclePlate: "",
    email: userData ? userData.tx_email : "",
    phone: userData ? userData.tx_telefone : "",
    functionID: userData ? userData.id_funcao : "",
    departmentID: userData ? userData.id_departamento : "",
    costCenterID: userData ? userData.id_centro_custo : "",
    status: userData ? (userData.cs_status ? "Ativo" : "Inativo") : "Ativo",
  };

  return (
    <Dialog
      open={usersPageContext.openModalUser.open}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle disableTypography>
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography className={classes.dialogTitle}>
              {usersPageContext.openModalUser.data
                ? "Editar usuário"
                : "Cadastrar novo usuário"}
            </Typography>
          </Grid>
          <Grid item xs={2} container justify="flex-end">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider className={classes.divider} />
      <DialogContent>
        <Formik
          enableReinitialize={
            usersPageContext.openModalUser.data ? true : false
          }
          initialValues={initialValues}
          onSubmit={async (values) => {
            if (!userContext.selectedCompany) return;
            try {
              const casted = FormAddUserValidationSchema.cast(values);

              setLoadingCreateUser(true);
              if (usersPageContext.openModalUser.data) {
                await editUserService.execute({
                  companyID: userContext.selectedCompany.uuid,
                  costCenterID: values.costCenterID,
                  departmentID: values.departmentID,
                  functionID: values.functionID,
                  status: values.status as UserStatus,
                  userName: casted.name as string,
                  userType: values.type as UserType,
                  CPF: casted.CPF,
                  cardNumber: casted.cardNumber,
                  vehiclePlate: casted.vehiclePlate,
                  email: casted.email,
                  phone: casted.phone,
                  userID: usersPageContext.openModalUser.data.uuid,
                });
              } else {
                await createUserService.execute({
                  companyID: userContext.selectedCompany.uuid,
                  costCenterID: values.costCenterID,
                  departmentID: values.departmentID,
                  functionID: values.functionID,
                  status: values.status as UserStatus,
                  userName: casted.name as string,
                  userType: values.type as UserType,
                  CPF: casted.CPF,
                  cardNumber: casted.cardNumber,
                  vehiclePlate: casted.vehiclePlate,
                  email: casted.email,
                  phone: casted.phone,
                });
              }

              onClose();
              enqueueSnackbar(
                `Usuário ${
                  usersPageContext.openModalUser.data ? "editado" : "adicionado"
                } com sucesso`,
                {
                  variant: "success",
                }
              );
            } catch (error) {
              if (error instanceof AppError) {
                return enqueueSnackbar(error.message, {
                  variant: error.variant,
                });
              }
              enqueueSnackbar(
                `Ocorreu um erro ao ${
                  usersPageContext.openModalUser.data ? "editar" : "adicionar"
                } usuário`,
                {
                  variant: "error",
                }
              );
            } finally {
              setLoadingCreateUser(false);
            }
          }}
          validationSchema={FormAddUserValidationSchema}
        >
          {(props) => {
            return usersPageContext.openModalUser.data && loadingUserData ? (
              <Grid container justify="center">
                <CircularProgress size={22} />
              </Grid>
            ) : usersPageContext.openModalUser.data && errorLoadingUserData ? (
              <Grid container justify="center">
                <ErrorLoading messageError="Ocorreu um erro ao baixar dados do usuário" />
                <ReloadButton
                  reloading={loadingUserData}
                  reloadFunc={fetchUserData}
                />
              </Grid>
            ) : (
              <Form id="createUser">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      fullWidth
                      label="Tipo"
                      name="type"
                      placeholder="Escolha o tipo"
                      InputLabelProps={{
                        classes: { root: classes.rootInputLabel },
                      }}
                      InputProps={{ classes: { input: classes.inputText } }}
                      select
                      error={props.touched.type && !!props.errors.type}
                      helperText={props.touched.type && props.errors.type}
                    >
                      <MenuItem value="USER">Usuário</MenuItem>
                      <MenuItem value="CARD">Cartão de boas vindas</MenuItem>
                      <MenuItem value="FLEET">Frota</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item={props.values.type !== "FLEET"} xs={12}>
                    <Collapse in={props.values.type !== "FLEET"}>
                      <Field
                        as={TextField}
                        fullWidth
                        label="Nome do usuário"
                        name="name"
                        placeholder="Insira o nome do usuário"
                        InputLabelProps={{
                          classes: { root: classes.rootInputLabel },
                        }}
                        InputProps={{ classes: { input: classes.inputText } }}
                        error={props.touched.name && !!props.errors.name}
                        helperText={props.touched.name && props.errors.name}
                      />
                    </Collapse>
                  </Grid>
                  <Grid item={props.values.type === "FLEET"} xs={12}>
                    <Collapse in={props.values.type === "FLEET"}>
                      <Field
                        as={TextField}
                        fullWidth
                        label="Placa do veículo"
                        name="vehiclePlate"
                        placeholder="Informe a placa do veículo"
                        InputLabelProps={{
                          classes: { root: classes.rootInputLabel },
                        }}
                        InputProps={{ classes: { input: classes.inputText } }}
                        error={
                          props.touched.vehiclePlate &&
                          !!props.errors.vehiclePlate
                        }
                        helperText={
                          props.touched.vehiclePlate &&
                          props.errors.vehiclePlate
                        }
                        onChange={(e: { target: { value: string } }) => {
                          props.setFieldValue(
                            "vehiclePlate",
                            maskVehiclePlateMercoSul(e.target.value)
                          );
                        }}
                      />
                    </Collapse>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      fullWidth
                      label={props.values.type === "USER" ? "CPF" : "Cartão"}
                      name={props.values.type === "USER" ? "CPF" : "cardNumber"}
                      placeholder="Insira apenas números"
                      InputLabelProps={{
                        classes: { root: classes.rootInputLabel },
                      }}
                      InputProps={{ classes: { input: classes.inputText } }}
                      inputProps={{
                        inputmode: "numeric",
                      }}
                      onChange={(e: { target: { value: string } }) => {
                        if (props.values.type === "USER") {
                          props.setFieldValue(
                            "CPF",
                            performCPFMask(e.target.value)
                          );
                        } else {
                          props.setFieldValue(
                            "cardNumber",
                            performCreditCardMaks(e.target.value)
                          );
                        }
                      }}
                      error={
                        props.values.type === "USER"
                          ? props.touched.CPF && !!props.errors.CPF
                          : props.touched.cardNumber &&
                            !!props.errors.cardNumber
                      }
                      helperText={
                        props.values.type === "USER"
                          ? props.touched.CPF && props.errors.CPF
                          : props.touched.cardNumber && props.errors.cardNumber
                      }
                    />
                  </Grid>
                  <Grid item={props.values.type !== "FLEET"} xs={12}>
                    <Collapse in={props.values.type !== "FLEET"}>
                      <Field
                        as={TextField}
                        fullWidth
                        label="E-mail"
                        name="email"
                        placeholder="Insira o e-mail"
                        InputLabelProps={{
                          classes: { root: classes.rootInputLabel },
                        }}
                        InputProps={{ classes: { input: classes.inputText } }}
                        type="email"
                        error={props.touched.email && !!props.errors.email}
                        helperText={props.touched.email && props.errors.email}
                      />
                    </Collapse>
                  </Grid>
                  <Grid item={props.values.type !== "FLEET"} xs={12}>
                    <Collapse in={props.values.type !== "FLEET"}>
                      <Field
                        as={TextField}
                        fullWidth
                        label="Telefone"
                        name="phone"
                        placeholder="Insira o DDD"
                        InputLabelProps={{
                          classes: { root: classes.rootInputLabel },
                        }}
                        inputProps={{
                          inputmode: "numeric",
                        }}
                        InputProps={{ classes: { input: classes.inputText } }}
                        onChange={(e: { target: { value: string } }) => {
                          const number = new AsYouType("BR").input(
                            e.target.value
                          );
                          props.setFieldValue("phone", number);
                        }}
                        error={props.touched.phone && !!props.errors.phone}
                        helperText={props.touched.phone && props.errors.phone}
                      />
                    </Collapse>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      fullWidth
                      label="Função"
                      placeholder="Escolha a função"
                      name="functionID"
                      InputLabelProps={{
                        classes: { root: classes.rootInputLabel },
                      }}
                      InputProps={{ classes: { input: classes.inputText } }}
                      select
                      error={
                        props.touched.functionID && !!props.errors.functionID
                      }
                      helperText={
                        props.touched.functionID && props.errors.functionID
                      }
                    >
                      {usersPageContext.filters
                        ? usersPageContext.filters.functions.map((filter) => {
                            return (
                              <MenuItem key={filter.id} value={filter.id}>
                                {filter.tx_funcao}
                              </MenuItem>
                            );
                          })
                        : []}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      fullWidth
                      label="Departamento"
                      placeholder="Escolha o departamento"
                      name="departmentID"
                      InputLabelProps={{
                        classes: { root: classes.rootInputLabel },
                      }}
                      InputProps={{ classes: { input: classes.inputText } }}
                      select
                      error={
                        props.touched.departmentID &&
                        !!props.errors.departmentID
                      }
                      helperText={
                        props.touched.departmentID && props.errors.departmentID
                      }
                    >
                      {usersPageContext.filters
                        ? usersPageContext.filters.departments.map((filter) => {
                            return (
                              <MenuItem key={filter.id} value={filter.id}>
                                {filter.tx_departamento}
                              </MenuItem>
                            );
                          })
                        : []}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      fullWidth
                      label="Centro de custos"
                      placeholder="Escolha o centro de custos"
                      name="costCenterID"
                      InputLabelProps={{
                        classes: { root: classes.rootInputLabel },
                      }}
                      InputProps={{ classes: { input: classes.inputText } }}
                      select
                      error={
                        props.touched.costCenterID &&
                        !!props.errors.costCenterID
                      }
                      helperText={
                        props.touched.costCenterID && props.errors.costCenterID
                      }
                    >
                      {usersPageContext.filters
                        ? usersPageContext.filters.costCenters.map((filter) => {
                            return (
                              <MenuItem key={filter.id} value={filter.id}>
                                {filter.tx_centro_custo}
                              </MenuItem>
                            );
                          })
                        : []}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        classes={{ root: classes.rootInputLabel }}
                      >
                        Status
                      </FormLabel>
                      <RadioGroup
                        style={{ flexDirection: "row" }}
                        value={props.values.status}
                        onChange={({ target }) =>
                          props.setFieldValue("status", target.value)
                        }
                      >
                        <FormControlLabel
                          value="Ativo"
                          control={<Radio color="primary" />}
                          label="Ativo"
                        />
                        <FormControlLabel
                          value="Inativo"
                          control={<Radio color="primary" />}
                          label="Inativo"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Grid container justify="center">
          <Button
            className={clsx(classes.buttons, classes.cancelButton)}
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            form="createUser"
            color="primary"
            className={classes.buttons}
            type="submit"
            startIcon={loadingCreateUser && <CircularProgress size={22} />}
            disabled={Boolean(
              (usersPageContext.openModalUser.data && loadingUserData) ||
                (usersPageContext.openModalUser.data && errorLoadingUserData)
            )}
          >
            {usersPageContext.openModalUser.data ? "Editar" : "Adicionar"}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export { ModalAddUser };
