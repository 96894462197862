import { DriverCategory } from "./DriverCategory";

export interface Driver {
  id?: string;
  registration?: string;
  driver?: string;
  cnh?: string;
  category?: DriverCategory;
  expirationDate?: Date | null;
  rg?: string;
  cpf?: string;
  phoneNumber?: string;
  status?: boolean;
  password?: string | null;
  activityId?: string | null;
  departmentId?: string | null;
  costCenterId?: string | null;
}

export interface DriverLegacy {
  id: string;
  tx_documento: string;
  tx_documento_secundario: string;
  tx_matricula: string;
  tx_motorista: string;
  tx_telefone: string;
  tx_tipo_cnh: DriverCategory;
  tx_cnh: string;
  tx_data_expiracao_cnh: Date | null;
  id_centro_custo: string;
  id_funcao: string;
  id_departamento: string;
  cs_status: boolean;
  tx_password?: string | null;
}

export const DriverInitialValues: Driver = {
  registration: "",
  driver: "",
  cnh: "",
  category: "0",
  expirationDate: null,
  rg: "",
  cpf: "",
  phoneNumber: "",
  activityId: "0",
  departmentId: "0",
  costCenterId: "0",
};
