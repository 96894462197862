import { Filter } from "../model/Filter";
import { Search } from "../model/Search";
import { inject, injectable } from "inversify";
import { Types } from "@ioc/types";
import { FlagcardCompanyWrapper } from "../FlagcardCompanyWrapper";
import { Activity } from "./Activity";

export interface ActivityClient {
  save(companyId: string, activity: Activity): Promise<Activity>;

  update(
    companyId: string,
    activityId: string,
    activity: Activity
  ): Promise<void>;

  find(companyId: string, filter?: Filter): Promise<Search<Activity>>;

  findById(companyId: string, activityId: string): Promise<Activity>;

  remove(companyId: string, activityId: string): Promise<void>;
}

@injectable()
export class ActivityClientImpl implements ActivityClient {
  @inject(Types.FlagcardCompanyWrapper)
  // @ts-ignore
  private instance: FlagcardCompanyWrapper;

  private readonly endpoint = "/rest/v1/activities";

  public save(companyId: string, activity: Activity): Promise<Activity> {
    return this.instance.post(this.endpoint, activity, {
      headers: { company_id: companyId },
    });
  }

  public update(
    companyId: string,
    activityId: string,
    activity: Activity
  ): Promise<void> {
    return this.instance.patch(`${this.endpoint}/${activityId}`, activity, {
      headers: { company_id: companyId },
    });
  }

  public find(companyId: string, filter?: Filter): Promise<Search<Activity>> {
    return this.instance.get(this.endpoint, {
      params: filter,
      headers: { company_id: companyId },
    });
  }

  public findById(companyId: string, activityId: string): Promise<Activity> {
    return this.instance.get(`${this.endpoint}/${activityId}`, {
      headers: { company_id: companyId },
    });
  }

  public remove(companyId: string, activityId: string): Promise<void> {
    return this.instance.delete(`${this.endpoint}/${activityId}`, {
      headers: { company_id: companyId },
    });
  }
}
