import { ComingSoon } from "@components/ComingSoon";
import { DialogManualRecharge } from "@components/DialogManualRecharge";
import { LeftSideBar } from "@components/LeftSideBar";
import { RightSideBar } from "@components/RightSideBar";
import clsx from "clsx";
import React from "react";
import { Header, IHeaderProps } from "../Header";
import { HashRouter } from "react-router-dom";
import { Routes } from "@components/Routes";
import StyleSheet from "./StyleSheet";

export interface ILayoutFlexProps {
  headerProps?: IHeaderProps;
  enableFlexContent?: boolean;
}

const LayoutFlex: React.FC<ILayoutFlexProps> = (props) => {
  const { headerProps, enableFlexContent = true } = props;
  const styleSheet = StyleSheet();
  return (
    <HashRouter>
      <div className={styleSheet.container}>
        <div>
          <LeftSideBar />
        </div>
        <Header {...headerProps} />
        <div className={styleSheet.columnContainer}>
          <Header {...headerProps} />
          <div style={{ height: "100%", marginTop: 70, padding: 16 }}>
            <div
              className={clsx({ [styleSheet.contentFlex]: enableFlexContent })}
            >
              <Routes />
            </div>
          </div>
        </div>
        <RightSideBar />
        <DialogManualRecharge />
        <ComingSoon />
      </div>
    </HashRouter>
  );
};

export { LayoutFlex };
