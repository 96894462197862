import { Document } from "./Document";
import { Filter } from "../model/Filter";
import { Search } from "../model/Search";
import { inject, injectable } from "inversify";
import { Types } from "@ioc/types";
import { FlagcardCompanyWrapper } from "../FlagcardCompanyWrapper";

export interface DocumentClient {
  save(companyId: string, document: Document): Promise<Document>;

  update(
    companyId: string,
    documentId: string,
    document: Document
  ): Promise<void>;

  find(
    companyId: string,
    folderId: string,
    filter?: Filter
  ): Promise<Search<Document>>;

  findById(companyId: string, documentId: string): Promise<Document>;

  remove(companyId: string, documentId: string): Promise<void>;
}

@injectable()
export class DocumentClientImpl implements DocumentClient {
  @inject(Types.FlagcardCompanyWrapper)
  // @ts-ignore
  private instance: FlagcardCompanyWrapper;

  private readonly endpoint = "/rest/v1/documents";

  public save(companyId: string, document: Document): Promise<Document> {
    return this.instance.post(this.endpoint, document, {
      headers: { company_id: companyId },
    });
  }

  public update(
    companyId: string,
    documentId: string,
    document: Document
  ): Promise<void> {
    return this.instance.patch(`${this.endpoint}/${documentId}`, document, {
      headers: { company_id: companyId },
    });
  }

  public find(
    companyId: string,
    folderId: string,
    filter?: Filter
  ): Promise<Search<Document>> {
    return this.instance
      .get(this.endpoint, {
        params: { ...filter, folder_id: folderId },
        headers: { company_id: companyId },
      })
      .then(({ result, total, page, pages }: any) => ({
        page,
        pages,
        count: total,
        result: result.map((document: Document) => ({
          ...document,
          expirationDate: document.expirationDate
            ? new Date(document.expirationDate)
            : null,
        })),
      }));
  }

  public findById(companyId: string, documentId: string): Promise<Document> {
    return this.instance.get(`${this.endpoint}/${documentId}`, {
      headers: { company_id: companyId },
    });
  }

  public remove(companyId: string, documentId: string): Promise<void> {
    return this.instance.delete(`${this.endpoint}/${documentId}`, {
      headers: { company_id: companyId },
    });
  }
}
