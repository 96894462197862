import React from "react";
import clsx from "clsx";
import StyleSheet from "./StyleSheet";
import {Link} from "react-router-dom";

interface ButtonProps {
    to?: string;
    color?: "primary" | "secondary" | "warning" | "error" | "success";
    type?: "button" | "submit";
    disabled?: boolean;
    onClick?: () => void;
}

const Button: React.FC<ButtonProps> = (props) => {
    const {to, children, color = "primary", type = "button", onClick, disabled = false} = props;
    const styleSheet = StyleSheet();

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    if (to) {
        return (
            <Link to={to}>
                <button
                    disabled={disabled}
                    type={type}
                    className={clsx(styleSheet.button, styleSheet[color])}
                >
                    {children}
                </button>
            </Link>
        );
    }
    return (
        <button
            type={type}
            className={clsx(styleSheet.button, styleSheet[color])}
            onClick={handleClick}
        >
            {children}
        </button>
    );
};

export {Button};
