import { useAuth } from "@hooks/AuthContext";
import { useIoCContext } from "@hooks/IoCContext";
import { Types } from "@ioc/types";
import { IGetInfoCompanyDTO } from "@modules/company/dtos/IGetInfoCompanyDTO";
import { IGetInfoCompanyService } from "@modules/company/models/IGetInfoCompanyService";
import {
  ICompany,
  IGetUserCompaniesDTO,
  TypeCompanyProfile,
} from "@modules/user/dtos/IGetUserCompaniesDTO";
import { IGetUserCompanies } from "@modules/user/models/IGetUserCompanies";
import { useSnackbar } from "notistack";
import React, { createContext, useContext, useEffect, useState } from "react";

interface UserContextData {
  loadingCompanies: boolean;
  companies: IGetUserCompaniesDTO | null;
  selectedCompany: ICompany<TypeCompanyProfile> | null;
  loadingCompanyInfo: boolean;
  companyInfo: IGetInfoCompanyDTO | null;
  setSelectedCompany: (company: ICompany<TypeCompanyProfile>) => void;
}

const UserContext = createContext<UserContextData>({} as UserContextData);

const UserProvider: React.FC = ({ children }) => {
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();
  const authContext = useAuth();

  const getUserCompaniesService = iocContext.serviceContainer.get<IGetUserCompanies>(
    Types.User.IGetUserCompanies
  );
  const getInfoCompanyService = iocContext.serviceContainer.get<IGetInfoCompanyService>(
    Types.Company.IGetInfoCompanyService
  );

  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadingCompanyInfo, setLoadingCompanyInfo] = useState(false);
  const [companies, setCompanies] = useState<IGetUserCompaniesDTO | null>(null);
  const [
    selectedCompany,
    setSelectedCompany,
  ] = useState<ICompany<TypeCompanyProfile> | null>(null);
  const [companyInfo, setCompanyInfo] = useState<IGetInfoCompanyDTO | null>(
    null
  );

  useEffect(() => {
    async function fetch() {
      try {
        setLoadingCompanies(true);
        const companies = await getUserCompaniesService.execute({
          userID: authContext.userID,
        });
        setCompanies(companies);
        const company = companies.companies.find(
          (company) => company.uuid === companies.Company.uuid
        );
        setSelectedCompany(company ? company : null);
      } catch (error) {
        enqueueSnackbar(
          "Ocorreu um erro ao buscar dados da empresa, recarregando em 5 segundos...",
          { variant: "error" }
        );
        setTimeout(() => {
          // eslint-disable-next-line no-restricted-globals
          location.reload();
        }, 5000);
      } finally {
        setLoadingCompanies(false);
      }
    }

    fetch();
  }, [authContext.userID, enqueueSnackbar, getUserCompaniesService]);

  useEffect(() => {
    async function fetch() {
      if (!selectedCompany) return;
      try {
        setLoadingCompanyInfo(true);
        const response = await getInfoCompanyService.execute({
          companyID: selectedCompany.uuid,
        });
        setCompanyInfo(response);
      } catch (error) {
        enqueueSnackbar(
          "Ocorreu um erro ao buscar dados da empresa, recarregando em 5 segundos...",
          { variant: "error" }
        );
        setTimeout(() => {
          // eslint-disable-next-line no-restricted-globals
          location.reload();
        }, 5000);
      } finally {
        setLoadingCompanyInfo(false);
      }
    }
    fetch();
  }, [getInfoCompanyService, selectedCompany]);

  return (
    <UserContext.Provider
      value={{
        companies,
        loadingCompanies,
        selectedCompany,
        loadingCompanyInfo,
        companyInfo,
        setSelectedCompany,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUserContext = (): UserContextData => {
  const context = useContext(UserContext);
  if (Object.values(context).length === 0) {
    throw new Error(
      "useUserContext deve ser utilizado dentro de um UserProvider"
    );
  }
  return context;
};

export { useUserContext, UserProvider };
