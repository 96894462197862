import React, { useEffect, useState } from "react";
import StyleSheet from "../StyleSheet";
import { Title } from "@components/Title/Title";
import { DataTable } from "@components/DataTable";
import { SearchInitialValues } from "../../../client/model/Search";
import { useUserContext } from "@hooks/UserContext";
import { useIoCContext } from "@hooks/IoCContext";
import { Types } from "@ioc/types";
import { useHistory, useLocation } from "react-router-dom";
import { Filter, SearchContainer } from "@components/SearchContainer";
import { ROUTES } from "@config/routesConfig";
import { Button } from "@components/Button";
import { VehicleClient } from "../../../client/vehicle/VehicleClient";
import { ColumnDefinition } from "@pages/Vehicles/List/ColumDefinition";
import { useSnackbar } from "notistack";

const VehicleList: React.FC = () => {
  const styleSheet = StyleSheet();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const history = useHistory();
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [result, setResult] = useState(SearchInitialValues);
  const [success, setSuccess] = useState(
    !!(location?.state as { success: boolean })?.success
  );
  const [filters, setFilters] = useState<Filter[]>([
    {
      label: "Placa",
      field: "plate",
      placeholder: "Pesquisar por Placa",
    },
    {
      label: "Chassi",
      field: "chassi",
      placeholder: "Pesquisar por Chassi",
    },
    {
      label: "Renavam",
      field: "renavam",
      placeholder: "Pesquisar por Renavam",
    },
    {
      label: "Marca/Modelo",
      field: "model",
      placeholder: "Pesquisar por Marca/Modelo",
    },
    {
      label: "Departamento",
      field: "department",
      placeholder: "Pesquisar por Departamento",
    },
    {
      label: "Centro de Custo",
      field: "cost_center",
      placeholder: "Pesquisar por Centro de Custo",
    },
  ]);

  const client = iocContext.serviceContainer.get<VehicleClient>(Types.Vehicle);

  const fetchVehicles = () => {
    if (success) {
      enqueueSnackbar("Operação realizada com sucesso.", {
        variant: "success",
      });
      setSuccess(false);
    }
    if (!selectedCompany) return;
    setLoading(true);

    const plate = filters.find((filter) => filter.field === "plate");
    const chassi = filters.find((filter) => filter.field === "chassi");
    const renavam = filters.find((filter) => filter.field === "renavam");
    const model = filters.find((filter) => filter.field === "model");
    const department = filters.find((filter) => filter.field === "department");
    const cost_center = filters.find(
      (filter) => filter.field === "cost_center"
    );
    const filter = {
      page: page,
      limit: limit,
      plate: plate?.value,
      chassi: chassi?.value,
      renavam: renavam?.value,
      model: model?.value,
      department: department?.value,
      cost_center: cost_center?.value,
    };
    client
      .find(selectedCompany.uuid, filter)
      .then((response) => {
        setResult(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar(error.response?.data?.message, { variant: "error" });
      });
  };

  const handleEdit = (id?: string): void => {
    history.push(ROUTES.VEHICLES_EDIT(id));
  };

  const handleRemove = async (id?: string) => {
    if (!selectedCompany || !id) return;
    setLoading(true);
    await client.remove(selectedCompany.uuid, id);
    setSuccess(true);
    fetchVehicles();
  };

  useEffect(fetchVehicles, [page, limit, filters]);

  return (
    <div className={styleSheet.container}>
      <Title>Veículo</Title>
      <SearchContainer
        filters={filters}
        onFilter={(filters: Filter[]) => {
          setFilters(filters);
        }}
      >
        <Button to={ROUTES.VEHICLES_ADD}>Novo Veículo</Button>
      </SearchContainer>
      <DataTable
        columns={ColumnDefinition}
        rows={result.result}
        limit={limit}
        pages={result.pages}
        total={result.total}
        loading={loading}
        onEdit={handleEdit}
        onRemove={handleRemove}
        editable
        removable
        onPagination={(page) => setPage(page)}
        onLimitChange={(limit) => setLimit(limit)}
      />
    </div>
  );
};

export default VehicleList;
