import { StyledDialog } from "@components/StyledDialog";
import { StyledButtonActions } from "@components/StyledDialog/StyledButtonActions";
import { useLayoutContext } from "@hooks/LayoutContext";
import {
  createStyles,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      infoText: {
        fontSize: "1.8rem",
      },
    })
);

const ComingSoon: React.FC = () => {
  const classes = useStyles();
  const layoutContext = useLayoutContext();

  const closeFunc = () => layoutContext.setOpenComingSoonDialog(false);

  return (
    <StyledDialog
      open={layoutContext.openComingSoonDialog}
      closeFunc={closeFunc}
      onClose={closeFunc}
      title="Funcionalidade disponível em breve"
    >
      <DialogContent>
        <Typography className={classes.infoText}>
          Opa, ainda estamos trabalhando nessa funcionalidade, em breve ela
          estará disponível para você usar &#128522;
        </Typography>
      </DialogContent>
      <StyledButtonActions
        styleButtons="info"
        leftButtonLabel="Poxa, Tá Bom"
        rightButtonLabel="Beleza"
        loading={false}
        actionFunc={closeFunc}
        closeFunc={closeFunc}
      />
    </StyledDialog>
  );
};

export { ComingSoon };
