import { appConfig } from "@config/appConfig";
import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import AppError from "@utils/AppError";
import { inject, injectable } from "inversify";
import { UserStatus } from "../dtos/IListUsersDTO";
import { ICreateUserService, UserType } from "../models/ICreateUserService";

interface IUserKeycloak {
  id: string;
}

@injectable()
export class CreateUserService implements ICreateUserService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    userName: string;
    CPF?: string;
    cardNumber?: string;
    vehiclePlate?: string;
    email?: string;
    phone?: string;
    functionID: string;
    departmentID: string;
    costCenterID: string;
    status: UserStatus;
    userType: UserType;
  }): Promise<void> {
    if (
      (data.userType === "USER" && !data.CPF) ||
      (data.userType === "CARD" && !data.cardNumber) ||
      (data.userType === "FLEET" && !data.vehiclePlate)
    ) {
      throw new AppError("Dados incompletos para criar usuário", "error");
    }

    // verifica se o usuário existe no keycloak
    const userExists = await this.httpInstance.get<[]>(
      `${appConfig.keycloack.urlServer}/admin/realms/${appConfig.keycloack.realm}/users`,
      {
        params: {
          username: data.userType === "USER" ? data.CPF : data.cardNumber,
        },
      }
    );

    // se existir lança um erro
    if (userExists.length > 0) {
      throw new AppError(
        `O usuário ${
          data.userType === "FLEET" ? data.vehiclePlate : data.userName
        } já existe`,
        "error"
      );
    }

    // cria o usuário no keycloak
    await this.httpInstance.post(
      `${appConfig.keycloack.urlServer}/admin/realms/${appConfig.keycloack.realm}/users`,
      {
        username: data.userType === "USER" ? data.CPF : data.cardNumber,
        enabled: true,
      }
    );

    const userKeycloak = await this.httpInstance.get<IUserKeycloak[]>(
      `${appConfig.keycloack.urlServer}/admin/realms/${appConfig.keycloack.realm}/users`,
      {
        params: {
          username: data.userType === "USER" ? data.CPF : data.cardNumber,
        },
      }
    );

    // ação do keycloak que reinicia a senha do usuário
    await this.httpInstance.put(
      `${appConfig.keycloack.urlServer}/admin/realms/${appConfig.keycloack.realm}/users/${userKeycloak[0].id}/reset-password`,
      {
        temporary: true,
        type: "password",
        value: "000000",
      }
    );

    await this.httpInstance.post(
      `/companies/${data.companyID}/users`,
      data.userType === "FLEET"
        ? {
            cs_status: data.status === "Ativo" ? true : false,
            id_centro_custo: data.costCenterID,
            id_departamento: data.departmentID,
            id_funcao: data.functionID,
            tx_documento: data.cardNumber,
            tx_perfil: data.userType,
            tx_usuario: data.vehiclePlate,
            type_fleet: true,
            uuid: userKeycloak[0].id,
          }
        : {
            cs_status: data.status === "Ativo" ? true : false,
            id_centro_custo: data.costCenterID,
            id_departamento: data.departmentID,
            id_funcao: data.functionID,
            tx_documento: data.userType === "CARD" ? data.cardNumber : data.CPF,
            tx_email: data.email,
            tx_perfil: data.userType,
            tx_telefone: data.phone,
            tx_usuario: data.userName,
            type_fleet: false,
            uuid: userKeycloak[0].id,
          }
    );
  }
}
