import React, { ChangeEvent, useEffect, useState } from "react";
import StyleSheet from "./StyleSheet";
import { Title } from "@components/Title/Title";
import { Filter, SearchContainer } from "@components/SearchContainer";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@material-ui/core";
import { ColumnDefinition } from "@pages/Infraction/ColumDefinition";
import { DataTable } from "@components/DataTable";
import { Types } from "@ioc/types";
import { useUserContext } from "@hooks/UserContext";
import { useIoCContext } from "@hooks/IoCContext";
import { useSnackbar } from "notistack";
import { SearchInitialValues } from "../../client/model/Search";
import Infraction from "../../client/infraction/Infraction";
import { Button } from "@components/Button";
import { Subtitles } from "@material-ui/icons";
import { format } from "date-fns/esm";
import { formatCurrency } from "@utils/index";
import { InfractionClient } from "../../client/infraction/InfractionClient";
import NotAuthorized from "@pages/Auth/NotAuthorized";

const getInfractionColorByPoints = (points?: number) => {
  if (!points) {
    return "#F9E586";
  }
  return points <= 3
    ? "#F9E586"
    : points <= 4
    ? "#F29F05"
    : points <= 5
    ? "#D0312D"
    : "#60100B";
};

const getInfractionLabelByPoints = (points?: number) => {
  if (!points) {
    return "-";
  }
  return points <= 3
    ? "Leve"
    : points <= 4
    ? "Média"
    : points <= 5
    ? "Grave"
    : "Gravíssima";
};

const formatDate = (value?: Date | null) => {
  if (!value) return "-";
  return format(new Date(String(value)), "dd/MM/yyyy hh:mm:ss");
};

const getInfractionStatusLabel = (status?: string) => {
  if (status === "em_aberto") {
    return "Pendente";
  }
  if (status === "vencido") {
    return "Vencido";
  }
  if (status === "pago") {
    return "Pago";
  }
  if (status === "cancelado") {
    return "Cancelado";
  }
  return "Prescrito";
};

const InfractionDetail: React.FC<{
  title: string;
  content?: string;
  width?: number;
}> = ({ title, content, width }) => {
  const styleSheet = StyleSheet();
  return (
    <div
      className={styleSheet["infraction-detail"]}
      style={{ width: `${width || 490}px` }}
    >
      <label className={styleSheet["infraction-detail-title"]}>{title}</label>
      <span className={styleSheet["infraction-detail-text"]}>
        {content || "-"}
      </span>
    </div>
  );
};

const InfractionList: React.FC = () => {
  const styleSheet = StyleSheet();
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();
  const [result, setResult] = useState(SearchInitialValues);
  const [infraction, setInfraction] = useState<Infraction>();
  const [infractionColor, setInfractionColor] = useState<string>();
  const [infractionLabel, setInfractionLabel] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [statuses, setStatuses] = useState<string[]>(["em_aberto", "vencido"]);
  const [authorized, setAuthorized] = useState(false);
  const [status, setStatus] = useState({
    em_aberto: true,
    vencido: true,
    pago: false,
    cancelado: false,
    prescrito: false,
  });
  const [filters, setFilters] = useState<Filter[]>([
    {
      label: "Placa",
      field: "plate",
      placeholder: "Pesquisar por Placa",
    },
  ]);

  const client = iocContext.serviceContainer.get<InfractionClient>(
    Types.Infraction
  );

  const handleChangeStatusInfraction = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const set = new Set(statuses);
    if (event.target.checked) {
      set.add(event.target.name);
    } else {
      set.delete(event.target.name);
    }
    if (set.size === 0) {
      enqueueSnackbar("É necessário pelo menos um status selecionado", {
        variant: "warning",
      });
    } else {
      setStatuses(Array.from(set));
      setStatus({ ...status, [event.target.name]: event.target.checked });
    }
  };

  const handleOpenDetails = (infraction: Infraction) => {
    setInfraction(infraction);
    setInfractionColor(getInfractionColorByPoints(infraction.infractionPoint));
    setInfractionLabel(getInfractionLabelByPoints(infraction.infractionPoint));
    setOpenDetails(true);
  };

  const download = (endpoint?: string) => {
    if (endpoint) {
      window.open(endpoint);
    }
  };

  const fetchInfractions = () => {
    if (!selectedCompany) return;
    const attributes = selectedCompany?.Company?.attributes || [];
    setAuthorized(attributes.includes("infraction"));
    if (authorized) {
      setLoading(true);
      const plate = filters.find((filter) => filter.field === "plate");
      const filter = {
        page,
        statuses: statuses.join(","),
        limit,
        plate: plate?.value,
      };
      client
        .find(selectedCompany.uuid, filter)
        .then((response) => {
          setResult(response);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          enqueueSnackbar(error.response?.data?.message, { variant: "error" });
        });
    }
  };

  useEffect(fetchInfractions, [
    page,
    limit,
    filters,
    status,
    selectedCompany,
    authorized,
  ]);

  return (
    <div className={styleSheet.container}>
      <Title>Infrações de Trânsito</Title>
      {authorized ? (
        <>
          <SearchContainer
            filters={filters}
            onFilter={(filters: Filter[]) => {
              setFilters(filters);
            }}
          >
            <div>
              <FormControlLabel
                label="Pendente"
                control={
                  <Checkbox
                    name="em_aberto"
                    checked={status.em_aberto}
                    color="primary"
                    onChange={handleChangeStatusInfraction}
                  />
                }
              />
              <FormControlLabel
                label="Vencido"
                control={
                  <Checkbox
                    name="vencido"
                    checked={status.vencido}
                    color="primary"
                    onChange={handleChangeStatusInfraction}
                  />
                }
              />
              <FormControlLabel
                label="Pago"
                control={
                  <Checkbox
                    name="pago"
                    checked={status.pago}
                    color="primary"
                    onChange={handleChangeStatusInfraction}
                  />
                }
              />
              <FormControlLabel
                label="Cancelado"
                control={
                  <Checkbox
                    name="cancelado"
                    checked={status.cancelado}
                    color="primary"
                    onChange={handleChangeStatusInfraction}
                  />
                }
              />
              <FormControlLabel
                label="Prescrito"
                control={
                  <Checkbox
                    name="prescrito"
                    checked={status.prescrito}
                    color="primary"
                    onChange={handleChangeStatusInfraction}
                  />
                }
              />
            </div>
          </SearchContainer>
          <DataTable
            columns={ColumnDefinition}
            rows={result.result}
            limit={limit}
            pages={result.pages}
            total={result.total}
            loading={loading}
            onClick={handleOpenDetails}
            onPagination={(page) => setPage(page)}
            onLimitChange={(limit) => setLimit(limit)}
          />
          <Dialog open={openDetails} onClose={() => setOpenDetails(false)}>
            <DialogTitle>Detalhes da Infração</DialogTitle>
            <DialogContent>
              <div style={{ display: "flex" }}>
                <div style={{ paddingRight: 10 }}>
                  <Subtitles style={{ fontSize: 48, color: infractionColor }} />
                </div>
                <div>
                  <div style={{ width: 490 }}>
                    Infração{" "}
                    <span
                      style={{ color: infractionColor, fontWeight: "bold" }}
                    >
                      {infractionLabel}
                    </span>{" "}
                    para o veículo de &nbsp;
                    <span style={{ fontWeight: "bold" }}>Placa</span>
                    &nbsp;
                    <span style={{ fontWeight: "bold" }}>
                      {infraction?.vehicle.plate}
                    </span>
                    &nbsp;por&nbsp;
                    <span
                      style={{
                        textTransform: "lowercase",
                        fontWeight: "bolder",
                      }}
                    >
                      {infraction?.description}
                    </span>
                    . &nbsp;Código{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {infraction?.infractionCode}
                    </span>
                    .
                  </div>
                  <div style={{ display: "flex", paddingTop: 10 }}>
                    <div
                      className={styleSheet["infraction-detail-card"]}
                      style={{ width: 200 }}
                    >
                      Data:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {formatDate(infraction?.infractionDate)}
                      </span>
                    </div>
                    <div style={{ width: 10 }} />
                    <div
                      className={styleSheet["infraction-detail-card"]}
                      style={{ width: 130 }}
                    >
                      Valor:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {formatCurrency(infraction?.infractionValue || 0)}
                      </span>
                    </div>
                    <div style={{ width: 10 }} />
                    <div
                      className={styleSheet["infraction-detail-card"]}
                      style={{ width: 140 }}
                    >
                      Status:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {getInfractionStatusLabel(infraction?.status)}
                      </span>
                    </div>
                  </div>
                  <div style={{ height: 14 }} />
                  <InfractionDetail
                    title={"Órgão Autuador"}
                    content={
                      infraction?.trafficAuthority || infraction?.providerName
                    }
                  />
                  <div style={{ height: 14 }} />
                  <div style={{ display: "flex" }}>
                    <InfractionDetail
                      title={"Categoria"}
                      content={infraction?.infractionCategory}
                      width={240}
                    />
                    <div style={{ width: 10 }} />
                    <InfractionDetail
                      title={"SubCategoria"}
                      content={infraction?.infractionSubCategory}
                      width={240}
                    />
                  </div>
                  {["em_aberto", "vencido"].includes(
                    infraction?.status || ""
                  ) ? (
                    <>
                      {infraction?.driverIndicationFile ? (
                        <>
                          <div style={{ height: 14 }} />
                          <span>
                            Faça o{" "}
                            <span
                              style={{
                                cursor: "pointer",
                                color: "#0F71D0",
                                textDecoration: "underline",
                                fontWeight: "bold",
                              }}
                              onClick={() =>
                                download(infraction?.driverIndicationFile)
                              }
                            >
                              download
                            </span>{" "}
                            do Formulário de Indicação do Condutor
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                      <div style={{ height: 16 }} />
                      <InfractionDetail
                        title={"Código de Barras"}
                        content={infraction?.barcode}
                      />
                      <div style={{ height: 4 }} />
                      <span>
                        Para pagamento, copie o código de barras ou, se
                        preferir, faça o{" "}
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#0F71D0",
                            textDecoration: "underline",
                            fontWeight: "bold",
                          }}
                          onClick={() => download(infraction?.ticketFile)}
                        >
                          download
                        </span>{" "}
                        do Boleto
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  {infraction?.document ? (
                    <>
                      <span>
                        Fazer{" "}
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#0F71D0",
                            textDecoration: "underline",
                            fontWeight: "bold",
                          }}
                          onClick={() => download(infraction?.document)}
                        >
                          download
                        </span>{" "}
                        do Documento de Infração
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={() => setOpenDetails(false)}>
                Fechar
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <NotAuthorized module="Infrações de Trânsito" />
      )}
    </div>
  );
};

export default InfractionList;
