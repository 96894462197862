import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  TextField,
} from "@material-ui/core";
import { Button } from "@components/Button";
import Permission from "../../../../client/permission/Permission";
import Product from "../../../../client/product/model/Product";
import InputMask from "react-input-mask";
import StyleSheet from "@pages/Vehicles/StyleSheet";

interface DialogIntervalProps {
  open: boolean;
  selected?: Permission;
  onCancel: () => void;
  onSave: (interval: number, type: string) => void;
}

export const DialogInterval: React.FC<DialogIntervalProps> = (props) => {
  const styleSheet = StyleSheet();
  const { selected, open, onCancel, onSave } = props;
  const [interval, setInterval] = useState(8);
  const [type, setType] = useState("HOUR");

  useEffect(() => {
    if (open) {
      setInterval(selected?.options?.interval || 8);
      setType(selected?.options?.type || "HOUR");
    }
  }, [open]);

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle disableTypography style={{ padding: "0px 16px" }}>
        <h2>Restrição por intervalo entre abastecimentos</h2>
      </DialogTitle>
      <DialogContent style={{ padding: "16px" }}>
        <TextField
          id="interval"
          name="interval"
          style={{ width: "50%" }}
          classes={{ root: styleSheet.textField }}
          label="Intervalo"
          variant="outlined"
          onChange={(event) =>
            setInterval((event.target.value as unknown) as number)
          }
          value={interval}
          size="small"
        />
        <TextField
          id="type"
          name="type"
          style={{ width: "50%" }}
          classes={{ root: styleSheet.textField }}
          label="Tipo"
          variant="outlined"
          onChange={(event) =>
            setType((event.target.value as unknown) as string)
          }
          size="small"
          value={type}
          select
        >
          <MenuItem value="MINUTE">Minutos</MenuItem>
          <MenuItem value="HOUR">Horas</MenuItem>
          <MenuItem value="DAY">Dias</MenuItem>
        </TextField>
        <div style={{ height: 10 }} />
        <div style={{ padding: "10px 4px" }}>
          <Paper style={{ backgroundColor: "rgb(50, 192, 197, 0.1)" }}>
            <div style={{ padding: "10px 4px" }}>
              <span style={{ fontSize: 16, fontWeight: "bolder" }}>
                Exemplo
              </span>
              <div style={{ height: 10 }} />
              <span>Ultimo abastecimento: </span>
              <span style={{ fontWeight: "bolder" }}>12/10/2023 às 10:00h</span>
              <div style={{ height: 2 }} />
              <span>Intervalo definido: </span>
              <span style={{ fontWeight: "bolder" }}>8 horas</span>
              <div style={{ height: 2 }} />
              <span>Data/Hora da tentativa de abastecimento: </span>
              <span style={{ fontWeight: "bolder" }}>12/10/2023 às 16:00h</span>
              <div style={{ height: 2 }} />
              <span>Status: </span>
              <span style={{ fontWeight: "bolder" }}>
                <span style={{ color: "red" }}>Negado</span>, pois o intervalo
                entre os dois abastecimentos (6 horas) é menor que o intervalo
                definido
              </span>
              <div style={{ height: 2 }} />
            </div>
          </Paper>
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancel}>
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={() => {
            onSave(interval, type);
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
