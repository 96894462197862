import { Upload } from "./Upload";
import { inject, injectable } from "inversify";
import { Types } from "@ioc/types";
import { FlagcardCompanyWrapper } from "../FlagcardCompanyWrapper";

export interface UploadClient {
  resolveLocation(companyId: string, location: string): Promise<string>;

  upload(companyId: string, file: File): Promise<Upload>;
}

@injectable()
export class UploadClientImpl implements UploadClient {
  @inject(Types.FlagcardCompanyWrapper)
  // @ts-ignore
  private instance: FlagcardCompanyWrapper;

  resolveLocation(companyId: string, location: string): Promise<string> {
    return this.instance.get(location, { headers: { company_id: companyId } });
  }

  upload(companyId: string, file: File): Promise<Upload> {
    const index = file.name.lastIndexOf(".");
    const extension = index === -1 ? "unknown" : file.name.substring(index + 1);
    const formData = new FormData();
    formData.append("file", file);
    return this.instance
      .post("/rest/upload", formData, {
        headers: {
          "content-type": "multipart/form-data",
          company_id: companyId,
        },
      })
      .then((result) => ({ ...result, extension }));
  }
}
