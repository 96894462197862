import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IDriverDTO } from "../dtos/IListDriversDTO";
import { IGetDriverService } from "../models/IGetDriverService";

@injectable()
export class GetDriverService implements IGetDriverService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;
  public async execute(data: {
    companyID: string;
    driverID: string;
  }): Promise<IDriverDTO> {
    const resp = await this.httpInstance.get<IDriverDTO>(
      `/companies/${data.companyID}/drivers/${data.driverID}`
    );

    resp.id_centro_custo = String(resp.id_centro_custo);
    resp.id_departamento = String(resp.id_departamento);
    resp.id_funcao = String(resp.id_funcao);
    resp.tx_data_expiracao_cnh = resp.tx_data_expiracao_cnh
      ? new Date(resp.tx_data_expiracao_cnh)
      : null;

    return resp;
  }
}
