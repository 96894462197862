import React, { useEffect, useState } from "react";
import StyleSheet from "./StyleSheet";
import DashboardStyleSheet from "../../Dashboard/StyleSheet";
import { Title } from "@components/Title/Title";
import { Types } from "@ioc/types";
import { useUserContext } from "@hooks/UserContext";
import { useIoCContext } from "@hooks/IoCContext";
import { DashboardClient } from "../../../client/fleet/dashboard/DashboardClient";
import { useSnackbar } from "notistack";
import Dashboard from "../../../client/fleet/dashboard/Dashboard";
import { ChartPieInfractionDebit } from "@pages/Fleet/Dashboard/ChartPieInfractionDebit";
import { Skeleton } from "@material-ui/lab";
import { ChartPieVehiclesWithInfractions } from "@pages/Fleet/Dashboard/ChartPieVehiclesWithInfractions";
import { ChartPieVehiclesWithDebits } from "@pages/Fleet/Dashboard/ChartPieVehiclesWithDebits";
import { ChartBarInfractionHistory } from "@pages/Fleet/Dashboard/ChartBarInfractionHistory";
import { ChartBarInfractionCategory } from "@pages/Fleet/Dashboard/ChartBarInfractionCategory";
import { ChartBarInfractionSubCategory } from "@pages/Fleet/Dashboard/ChartBarInfractionSubCategory";
import { ChartBarInfractionByDepartment } from "@pages/Fleet/Dashboard/ChartBarInfractionByDepartment";
import { ChartBarInfractionByCostCenter } from "@pages/Fleet/Dashboard/ChartBarInfractionByCostCenter";
import { ChartBarDebitByDepartment } from "@pages/Fleet/Dashboard/ChartBarDebitByDepartment";
import { ChartBarDebitByCostCenter } from "@pages/Fleet/Dashboard/ChartBarDebitByCostCenter";
import NotAuthorized from "@pages/Auth/NotAuthorized";
import {
  DashboardQuantityDebit,
  DashboardQuantityInfraction,
  DashboardQuantityVehicle,
} from "@components/CardInfo/DashboardCard";
import { format } from "date-fns/esm";

const FleetDashboard: React.FC = () => {
  const styleSheet = StyleSheet();
  const dashboardStyleSheet = DashboardStyleSheet();
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(true);
  const [dashboard, setDashboard] = useState<Dashboard>();
  const [authorized, setAuthorized] = useState(false);

  const client = iocContext.serviceContainer.get<DashboardClient>(
    Types.FleetDashboard
  );

  const fetchDashboardData = () => {
    if (!selectedCompany) return;
    const attributes = selectedCompany?.Company?.attributes || [];
    setAuthorized(attributes.includes("infraction"));
    if (authorized) {
      setLoading(true);
      client
        .find(selectedCompany.uuid, pending)
        .then((response) => {
          setDashboard(response);
        })
        .catch(() => {
          enqueueSnackbar("Não foi possível carregar os dados", {
            variant: "error",
          });
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(fetchDashboardData, [selectedCompany, authorized, pending]);

  return (
    <div className={styleSheet.container}>
      <Title>Gestão de Frota</Title>
      {authorized ? (
        <>
          <div
            hidden={!pending}
            style={{ textAlign: "end", paddingRight: "20px" }}
          >
            <span style={{ color: "#58595B" }}>
              Mostrando infrações/débitos pendentes de pagamento{" "}
            </span>
            <span
              style={{
                color: "#58595B",
                fontWeight: "bold",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => setPending(false)}
            >
              MOSTRAR TUDO
            </span>
          </div>
          <div
            hidden={pending}
            style={{ textAlign: "end", paddingRight: "20px" }}
          >
            <span style={{ color: "#58595B" }}>
              Mostrando todas as infrações/débitos{" "}
            </span>
            <span
              style={{
                color: "#58595B",
                fontWeight: "bold",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => setPending(true)}
            >
              MOSTRAR PENDENTES
            </span>
          </div>
          <div style={{ textAlign: "end", paddingRight: "20px" }}>
            <span style={{ color: "#58595B" }}>Ultima Atualização em </span>
            <span
              style={{
                color: "#58595B",
                fontWeight: "bold",
              }}
            >
              {dashboard?.infraction?.updatedAt
                ? format(
                    new Date(dashboard?.infraction?.updatedAt),
                    "dd/MM/yyyy HH:mm:ss"
                  )
                : "-"}
            </span>
          </div>
          <div className={dashboardStyleSheet["card-info-container"]}>
            <div className={dashboardStyleSheet["card-info-container-item"]}>
              <DashboardQuantityVehicle
                loading={loading}
                value={dashboard?.vehicle?.quantity || 0}
              />
            </div>
            <div className={dashboardStyleSheet["card-info-container-item"]}>
              <DashboardQuantityInfraction
                loading={loading}
                value={dashboard?.infraction?.quantity || 0}
              />
            </div>
            <div className={dashboardStyleSheet["card-info-container-item"]}>
              <DashboardQuantityDebit
                loading={loading}
                value={dashboard?.debit?.quantity || 0}
              />
            </div>
          </div>
          <div className={dashboardStyleSheet["card-info-container"]}>
            <div className={dashboardStyleSheet["card-info-container-item"]}>
              {loading ? (
                <Skeleton height={400} />
              ) : (
                <ChartPieVehiclesWithInfractions dashboard={dashboard} />
              )}
            </div>
            <div className={dashboardStyleSheet["card-info-container-item"]}>
              {loading ? (
                <Skeleton height={400} />
              ) : (
                <ChartPieVehiclesWithDebits dashboard={dashboard} />
              )}
            </div>
          </div>
          <div className={dashboardStyleSheet["card-info-container"]}>
            <div className={dashboardStyleSheet["card-info-container-item"]}>
              {loading ? (
                <Skeleton height={400} />
              ) : (
                <ChartPieInfractionDebit dashboard={dashboard} />
              )}
            </div>
            <div className={dashboardStyleSheet["card-info-container-item"]}>
              {loading ? (
                <Skeleton height={400} />
              ) : (
                <ChartBarInfractionHistory dashboard={dashboard} />
              )}
            </div>
          </div>
          <div className={dashboardStyleSheet["card-info-container"]}>
            <div className={dashboardStyleSheet["card-info-container-item"]}>
              {loading ? (
                <Skeleton height={400} />
              ) : (
                <ChartBarInfractionCategory dashboard={dashboard} />
              )}
            </div>
            <div className={dashboardStyleSheet["card-info-container-item"]}>
              {loading ? (
                <Skeleton height={400} />
              ) : (
                <ChartBarInfractionSubCategory dashboard={dashboard} />
              )}
            </div>
          </div>
          <div className={dashboardStyleSheet["card-info-container"]}>
            <div className={dashboardStyleSheet["card-info-container-item"]}>
              {loading ? (
                <Skeleton height={400} />
              ) : (
                <ChartBarInfractionByDepartment dashboard={dashboard} />
              )}
            </div>
            <div className={dashboardStyleSheet["card-info-container-item"]}>
              {loading ? (
                <Skeleton height={400} />
              ) : (
                <ChartBarInfractionByCostCenter dashboard={dashboard} />
              )}
            </div>
          </div>
          <div className={dashboardStyleSheet["card-info-container"]}>
            <div className={dashboardStyleSheet["card-info-container-item"]}>
              {loading ? (
                <Skeleton height={400} />
              ) : (
                <ChartBarDebitByDepartment dashboard={dashboard} />
              )}
            </div>
            <div className={dashboardStyleSheet["card-info-container-item"]}>
              {loading ? (
                <Skeleton height={400} />
              ) : (
                <ChartBarDebitByCostCenter dashboard={dashboard} />
              )}
            </div>
          </div>
        </>
      ) : (
        <NotAuthorized module="Dashboard (Gestão de Frota)" />
      )}
    </div>
  );
};

export default FleetDashboard;
