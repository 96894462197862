import * as yup from "yup";

const SUPPORTED_FORMATS = ["application/.*sheet"];

export const FormUploadRechargeSchemeValidation = yup.object().shape({
  description: yup.string().required("Preencha a descrição"),
  date: yup
    .date()
    .typeError("Selecione uma data")
    .required("Selecione uma data"),
  file: yup
    .mixed()
    .test(
      "fileType",
      "Tipo de arquivo não suportado",
      (value: { type: string }) => {
        if (value) {
          for (let patternStr of SUPPORTED_FORMATS) {
            let pattern = new RegExp(patternStr);
            const test = pattern.test(value.type);
            return test;
          }
        }
        return false;
      }
    )
    .required("Anexe um arquivo válido"),
});
