import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    container: {
      padding: "20px",
      backgroundColor: "#FFF",
      borderRadius: "4px",
      webkitBoxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    },
    imageContainer: {
      padding: "18px",
    },
    actionButton: {
      border: "none",
      background: "none",
      cursor: "pointer",
    },
    documentContainer: {
      border: "solid 1px rgba(0, 0, 0, 0.1)",
      textAlign: "center",
      cursor: "pointer",
    },
    documentContainerSelected: {
      border: "1px solid rgb(15, 113, 208)",
      textAlign: "center",
      cursor: "pointer",
      boxShadow:
        "0px 2px 1px -1px rgb(15 113 208 / 20%), 0px 1px 1px 0px rgb(15 113 208 / 14%), 0px 1px 3px 0px rgb(15 113 208 / 12%)",
    },
    editButton: {
      border: "1px solid rgb(15, 113, 208)",
      background: "none",
      cursor: "pointer",
      color: "rgb(15, 113, 208)",
      borderRadius: "4px",
    },
    removeButton: {
      border: "1px solid rgb(220,20,60)",
      background: "none",
      cursor: "pointer",
      color: "rgb(220,20,60)",
      borderRadius: "4px",
    },
    downloadButton: {
      border: "1px solid rgb(34,139,34)",
      background: "none",
      cursor: "pointer",
      color: "rgb(34,139,34)",
      borderRadius: "4px",
    },
  })
);
