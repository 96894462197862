import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { v4 as uuidv4 } from "uuid";
import { IListExceptionTransactionsDTO } from "../dtos/IListExceptionTransactionsDTO";
import { IGetExceptionTransactionsService } from "../models/IGetExceptionTransactionsService";

@injectable()
export class GetExceptionTransactionsService
  implements IGetExceptionTransactionsService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    page: number;
    limit: number;
  }): Promise<IListExceptionTransactionsDTO> {
    const response = await this.httpInstance.get<IListExceptionTransactionsDTO>(
      `/companies/${data.companyID}/transactions/exceptions/?page=${data.page}&limit=${data.limit}`
    );

    response.result = response.result.map((exception) => {
      return {
        ...exception,
        id: uuidv4(),
      };
    });

    return response;
  }
}
