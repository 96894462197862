import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IUserDataDTO } from "../dtos/IUserDataDTO";
import { IGetUserDataService } from "../models/IGetUserDataService";

@injectable()
export class GetUserDataService implements IGetUserDataService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    userID: string;
  }): Promise<IUserDataDTO> {
    const resp = await this.httpInstance.get<IUserDataDTO>(
      `/companies/${data.companyID}/users/${data.userID}`
    );

    return resp;
  }
}
