import React, { useEffect, useState } from "react";
import StyleSheet from "./StyleSheet";
import { Title } from "@components/Title/Title";
import { Button } from "@components/Button";
import { Filter, SearchContainer } from "@components/SearchContainer";
import DateRangePicker, { OnSelectCallbackParam } from "react-daterange-picker";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { Grid, Menu, Typography } from "@material-ui/core";
import { DataTable } from "@components/DataTable";
import { ColumnReference } from "@pages/Reports/DebtManagementReport/ColumnReference";
import { useUserContext } from "@hooks/UserContext";
import { useIoCContext } from "@hooks/IoCContext";
import { ReportDebitClient } from "../../../client/ReportDebitClient";
import { Types } from "@ioc/types";
import { SearchInitialValues } from "../../../client/model/Search";
import LocalGasStation from "@material-ui/icons/LocalGasStation";
import LocalGasStationOutlined from "@material-ui/icons/LocalGasStationOutlined";
import { TransactionClient } from "../../../client/transaction/TransactionClient";
import Message from "@components/Message";
import { AxiosError } from "axios";
// @ts-ignore
const moment = extendMoment(Moment);

const COMPANIES_WITH_COMPLETELY_FULL_ENABLED = [
  "d3aa5365-cd79-4124-a416-99ad502999ce",
  "befb77b0-4d58-42fc-b01b-4a1d4768c432",
  "e9dd082a-b7ae-4f8e-901d-66bde42ab31d",
  "e76425ac-cfe2-4866-a9fb-f0782ab156a8",
  "cdc9c4aa-3d23-4520-a7e3-ea0cba5d652e",
  "71ded2f0-71bd-4afe-b401-ce73aab7685b",
];

const DebtManagementReportPage: React.FC = () => {
  const styleSheet = StyleSheet();
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const [report, setReport] = useState(SearchInitialValues);
  const [
    dateRangeSelectorPosition,
    setDateRangeSelectorPosition,
  ] = useState<HTMLElement>();
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [openDateRangeSelector, setOpenDateRangeSelector] = useState(false);
  const [selectedRangeDate, setSelectedRangeDate] = useState(
    moment.range(moment().startOf("month"), moment())
  );
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [filters, setFilters] = useState<Filter[]>([
    {
      label: "Geral",
      field: "query",
      placeholder: "Pesquisar por Usuário ou Veículo",
    },
  ]);

  const reportDebitClient = iocContext.serviceContainer.get<ReportDebitClient>(
    Types.ReportDebit
  );

  const transactionClient = iocContext.serviceContainer.get<TransactionClient>(
    Types.Transaction
  );

  const handleFilter = (filtered: Filter[]) => {
    setFilters(filtered);
  };

  const handleSelectRangeDate = (event: OnSelectCallbackParam) => {
    setSelectedRangeDate(moment.range(event.start, event.end));
    setOpenDateRangeSelector(false);
  };

  const formatAverageConsumption = (
    companyId: string,
    completelyFull: boolean,
    value?: string | null
  ) => {
    if (
      value &&
      (completelyFull ||
        !COMPANIES_WITH_COMPLETELY_FULL_ENABLED.includes(companyId))
    ) {
      return `${value} km/L`;
    }
    return "-";
  };

  const fetchReportDebit = () => {
    if (!selectedCompany) return;
    setLoading(true);
    const query = filters.find((filter) => filter.field === "query");
    const filter = {
      page: page,
      limit: limit,
      q: query?.value,
    };
    reportDebitClient
      .find(
        selectedCompany.uuid,
        selectedRangeDate.start.toDate(),
        selectedRangeDate.end.toDate(),
        filter
      )
      .then((result) => {
        setLoading(false);
        setReport({
          ...result,
          result: result.result.map((row) => ({
            ...row,
            id: row.vehicle_transaction_id || row.transaction_id,
            average_consumption: formatAverageConsumption(
              selectedCompany.uuid,
              !!row.completely_full,
              row.average_consumption
            ),
          })),
        });
      });
  };

  const download = () => {
    if (!selectedCompany) return;
    setLoading(true);
    const query = filters.find((filter) => filter.field === "query");
    const filter = {
      page: page,
      limit: limit,
      q: query?.value,
    };
    reportDebitClient
      .download(
        selectedCompany.uuid,
        selectedRangeDate.start.toDate(),
        selectedRangeDate.end.toDate(),
        filter
      )
      .then((result) => {
        setLoading(false);
        window.open(result);
      });
  };

  const handleSetCompletelyFull = async (
    rowId?: string,
    completelyFull?: boolean
  ): Promise<void> => {
    if (!selectedCompany) return;
    if (!rowId) {
      setErrorMessage(
        "Não é possível informar um abastecicmento completo para esta transação."
      );
      setError(true);
      return;
    }
    await transactionClient
      .setCompletelyFull(selectedCompany.uuid, rowId, !!completelyFull)
      .then(() => {
        setSuccess(true);
      })
      .catch((error: AxiosError) => {
        let message =
          "Não foi possível executar a operação. Fale com o Administrador.";
        if (error) {
          if (error.response) {
            message = error.response.data.message;
          }
        }
        setErrorMessage(message);
        setError(true);
      });
    await fetchReportDebit();
  };

  useEffect(fetchReportDebit, [
    selectedCompany,
    page,
    filters,
    limit,
    selectedRangeDate,
    reportDebitClient,
  ]);

  return (
    <div className={styleSheet.container}>
      <Title subtitles={["Gerencial", "Débitos"]}>Relatório</Title>
      <SearchContainer filters={filters} onFilter={handleFilter}>
        <Button onClick={download}>Download</Button>
      </SearchContainer>
      <Grid container>
        <Typography
          className={styleSheet.dateRangeLabel}
          style={{ color: "#000" }}
        >
          Data:&nbsp;
        </Typography>
        <Typography
          className={styleSheet.dateRangeLabel}
          onClick={(event) => {
            setOpenDateRangeSelector(true);
            setDateRangeSelectorPosition(event.currentTarget);
          }}
        >
          {moment(selectedRangeDate.start).format("DD/MM/yyyy") +
            " a " +
            moment(selectedRangeDate.end).format("DD/MM/yyyy")}
        </Typography>
      </Grid>
      <div style={{ height: 12 }} />
      <DataTable
        columns={ColumnReference}
        rows={report.result}
        limit={limit}
        pages={report.pages}
        total={report.count}
        loading={loading}
        contexts={[
          {
            icon: <LocalGasStation />,
            name: "Abastecimento Completo",
            onClick: (rowId) => handleSetCompletelyFull(rowId, true),
          },
          {
            icon: <LocalGasStationOutlined />,
            name: "Abastecimento Parcial",
            onClick: (rowId) => handleSetCompletelyFull(rowId, false),
          },
        ]}
        onPagination={(page) => setPage(page)}
        onLimitChange={(limit) => setLimit(limit)}
      />
      <Menu
        anchorEl={dateRangeSelectorPosition}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        getContentAnchorEl={null}
        onClose={() => setOpenDateRangeSelector(false)}
        open={openDateRangeSelector}
      >
        <DateRangePicker
          onSelect={handleSelectRangeDate}
          value={selectedRangeDate}
        />
      </Menu>
      <Message.Success open={success} onClose={() => setSuccess(false)} />
      <Message.Error open={error} onClose={() => setError(false)}>
        {errorMessage}
      </Message.Error>
    </div>
  );
};

export { DebtManagementReportPage };
