import { CompanyTypes } from "@modules/company/container";
import { DashboardTypes } from "@modules/dashboard/container";
import { ReportTypes } from "@modules/reports/container";
import { UserTypes } from "@modules/user/container";

export const Types = {
  IHttpService: Symbol("IHttpService"),
  FlagcardCompanyWrapper: Symbol("FlagcardCompanyWrapper"),
  Dashboard: { ...DashboardTypes },
  User: { ...UserTypes },
  Company: { ...CompanyTypes },
  Reports: { ...ReportTypes },
  Driver: Symbol("DriverType"),
  ReportDebit: Symbol("ReportDebitType"),
  ReportCredit: Symbol("ReportCreditType"),
  ReportBalance: Symbol("ReportBalanceType"),
  Folder: Symbol("FolderType"),
  Document: Symbol("DocumentType"),
  Upload: Symbol("UploadType"),
  Vehicle: Symbol("VehicleType"),
  Maintenance: Symbol("MaintenanceType"),
  Infraction: Symbol("InfractionType"),
  Debit: Symbol("DebitType"),
  Transaction: Symbol("TransactionType"),
  Activity: Symbol("ActivityType"),
  CostCenter: Symbol("CostCenterType"),
  Department: Symbol("DepartmentType"),
  Manufacturer: Symbol("ManufacturerType"),
  FleetDashboard: Symbol("FleetDashboardType"),
  Permission: Symbol("PermissionType"),
  Quotation: Symbol("QuotationType"),
};
