import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IDetailsRechageDTO } from "../dtos/IDetailsRechageDTO";
import { IDetailsRechargeService } from "../models/IDetailsRechargeService";

@injectable()
export class DetailsRechargeService implements IDetailsRechargeService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    rechargeID: string;
    page: number;
    limit: number;
  }): Promise<IDetailsRechageDTO> {
    const resp = await this.httpInstance.get<IDetailsRechageDTO>(
      `/companies/${data.companyID}/recharges/${data.rechargeID}`,
      {
        params: {
          page: data.page,
          limit: data.limit,
        },
      }
    );

    resp.recharge.rechargeDate = new Date(resp.recharge.rechargeDate);
    return resp;
  }
}
