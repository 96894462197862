import { ErrorLoading } from "@components/ErrorLoading";
import { LoadingOverlayDataGrid } from "@components/LoadingOverlayDataGrid";
import { NoData } from "@components/NoData";
import { Pagination } from "@components/Pagination";
import { ReloadButton } from "@components/ReloadButton";
import { StyledDialog, StyledDialogProps } from "@components/StyledDialog";
import { StyledButtonActions } from "@components/StyledDialog/StyledButtonActions";
import { useIoCContext } from "@hooks/IoCContext";
import { useUserContext } from "@hooks/UserContext";
import { Types } from "@ioc/types";
import {
  createStyles,
  DialogContent,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ColDef, DataGrid, GridOverlay } from "@material-ui/data-grid";
import { IRechargesByUser } from "@modules/reports/dtos/IRechargesByUser";
import { IListRechargesByUserService } from "@modules/reports/models/IListRechargesByUserService";
import { formatCurrency, tableStyles } from "@utils/index";
import { format } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { ReportBalance } from "../../../client/model/ReportBalance";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      tableRechargesInfo: tableStyles(theme).dataGridTable,
      cell: {
        borderBottom: `1px solid ${theme.palette.tertiary.background.primary} !important`,
      },
      headerCell: {
        fontSize: pxToRem(16),
        fontWeight: typography.fontWeightMedium,
        color: theme.palette.tertiary.graphics[4],
      },
      dialogPaper: {
        minWidth: "95vw",
        [theme.breakpoints.up("sm")]: {
          minWidth: "65vw",
        },
      },
    })
);

interface IModalInfoBalanceProps extends StyledDialogProps {
  reportData: ReportBalance;
}

const ModalInfoBalance: React.FC<IModalInfoBalanceProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
    defaultMatches: true,
  });
  const userContext = useUserContext();
  const iocContext = useIoCContext();

  const balanceReportService = iocContext.serviceContainer.get<IListRechargesByUserService>(
    Types.Reports.IListRechargesByUserService
  );

  const [loadingRecharges, setLoadingRecharges] = useState(false);
  const [errorLoadingRecharges, setErrorLoadingRecharges] = useState(false);
  const [recharges, setRecharges] = useState<IRechargesByUser[]>([]);

  const fetchRecharges = useCallback(async () => {
    if (!userContext.selectedCompany) return;
    try {
      setLoadingRecharges(true);
      console.log(props);
      const resp = await balanceReportService.execute({
        companyID: userContext.selectedCompany.uuid,
        userID: props.reportData.uuid,
      });
      setRecharges(resp);

      setErrorLoadingRecharges(false);
    } catch (error) {
      setErrorLoadingRecharges(true);
    } finally {
      setLoadingRecharges(false);
    }
  }, [balanceReportService, props.reportData, userContext.selectedCompany]);

  useEffect(() => {
    fetchRecharges();
  }, [fetchRecharges]);

  const columns: ColDef[] = [
    {
      field: "reference",
      headerName: "Referência",
      cellClassName: classes.cell,
      headerClassName: classes.headerCell,
      width: 400,
      sortable: false,
      valueGetter: (params) => {
        const row = params.row as IRechargesByUser;
        return row.recharge.name;
      },
    },
    {
      field: "transaction_type",
      headerName: "Tipo de transação	",
      cellClassName: classes.cell,
      headerClassName: classes.headerCell,
      width: 350,
      sortable: false,
      valueFormatter: (params) =>
        params.getValue(params.field) === "credit"
          ? "Crédito"
          : params.getValue(params.field),
    },
    {
      field: "quantity",
      headerName: "Quantidade",
      cellClassName: classes.cell,
      headerClassName: classes.headerCell,
      width: 250,
      align: "right",
      sortable: false,
      valueFormatter: (params) => {
        const row = params.row as IRechargesByUser;

        if (row.payment_type === "in_money") {
          return formatCurrency(row.quantity_money);
        } else {
          return (
            new Intl.NumberFormat("pt-BR", {
              minimumFractionDigits: 2,
            }).format(row.quantity_liter) + "L"
          );
        }
      },
    },
    {
      field: "transaction_date",
      headerName: "Data",
      cellClassName: classes.cell,
      headerClassName: classes.headerCell,
      width: 150,
      sortable: false,
      valueFormatter: (params) =>
        params.getValue(params.field)
          ? format(params.getValue(params.field) as Date, "dd/MM/yyyy")
          : "",
    },
  ];

  return (
    <StyledDialog
      {...props}
      fullScreen={isMobile}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent>
        <div
          style={{
            minHeight: 400,
            height: "100%",
            width: "100%",
          }}
          className={classes.tableRechargesInfo}
        >
          <AutoSizer>
            {({ height, width }) => (
              <div style={{ height, width }}>
                <DataGrid
                  rows={recharges}
                  columns={columns}
                  components={{
                    loadingOverlay: LoadingOverlayDataGrid,
                    errorOverlay: () => {
                      return (
                        <div
                          style={{
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <ErrorLoading />
                          <ReloadButton
                            reloadFunc={fetchRecharges}
                            reloading={loadingRecharges}
                          />
                        </div>
                      );
                    },
                    pagination: ({ pagination, api }) => (
                      <Pagination
                        page={pagination.page}
                        rowCount={pagination.rowCount}
                        pageSize={pagination.pageSize}
                        setPage={(value) => api.current.setPage(value)}
                      />
                    ),
                    noRowsOverlay: () => (
                      <GridOverlay>
                        <NoData />
                      </GridOverlay>
                    ),
                  }}
                  loading={loadingRecharges}
                  error={
                    errorLoadingRecharges && !loadingRecharges ? true : null
                  }
                  disableSelectionOnClick
                  autoPageSize
                />
              </div>
            )}
          </AutoSizer>
        </div>
      </DialogContent>

      <StyledButtonActions
        loading={false}
        styleButtons="info"
        closeFunc={props.closeFunc}
        actionFunc={props.closeFunc}
        leftButtonLabel="Cancelar"
        rightButtonLabel="Ok"
      />
    </StyledDialog>
  );
};

export { ModalInfoBalance };
