import { inject, injectable } from "inversify";
import { Types } from "@ioc/types";

export interface TransactionClient {
  setCompletelyFull(
    companyId: string,
    transactionId: string,
    completelyFull: boolean
  ): Promise<void>;
}

@injectable()
export class TransactionClientImpl implements TransactionClient {
  @inject(Types.FlagcardCompanyWrapper)
  // @ts-ignore
  private instance: FlagcardCompanyWrapper;

  private readonly endpoint = "rest/v1/transactions";

  public setCompletelyFull(
    companyId: string,
    transactionId: string,
    completelyFull: boolean
  ): Promise<void> {
    return this.instance.patch(
      `${this.endpoint}/${transactionId}`,
      { completelyFull },
      {
        headers: { company_id: companyId },
      }
    );
  }
}
