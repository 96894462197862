import { UserType } from "@modules/user/models/ICreateUserService";
import { validateBr } from "js-brasil";
import * as yup from "yup";

export const FormAddUserValidationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .when("type", (type: UserType, schema: any) => {
      return type === "FLEET"
        ? schema
        : schema.required("Preencha o nome do usuário");
    }),
  type: yup.string().oneOf(["CARD", "FLEET", "USER"]),
  CPF: yup
    .string()
    .test("validCPF", "Digite um CPF válido", (value, context) => {
      return value
        ? context.parent.type === "USER"
          ? validateBr.cpf(value)
          : true
        : true;
    })
    .transform((value: string) => value.replace(/\D+/g, ""))
    .when("type", (type: UserType, schema: any) => {
      return type === "USER"
        ? schema.required("Preencha o CPF do usuário")
        : schema;
    }),
  cardNumber: yup
    .string()
    .transform((value: string) => value.replace(/\D+/g, ""))
    .test(
      "validCardNumber",
      "Digite um número de cartão válido",
      (value, context) => {
        return value
          ? context.parent.type !== "USER" && value.length === 16
          : true;
      }
    )
    .when("type", (type: string, schema: any) => {
      return type !== "USER"
        ? schema.required("Preencha o número do cartão")
        : schema;
    }),
  vehiclePlate: yup
    .string()
    .test("vehiclePlate", "Digite uma placa válida", (value, context) => {
      const regex = new RegExp(/([a-zA-Z]{3})([0-9])(([a-zA-Z]\d{2})|\w{3})/g);
      return value
        ? context.parent.type === "FLEET"
          ? regex.test(value.replaceAll("-", ""))
          : true
        : true;
    })
    .when("type", (type: string, schema: any) => {
      return type === "FLEET"
        ? schema.required("Preencha a placa do veículo")
        : schema;
    })
    .transform((value: string) => value && value.replaceAll("-", "")),
  email: yup
    .string()
    .email("Digite um e-mail válido")
    .typeError("Digite um e-mail"),
  phone: yup
    .string()
    .typeError("Digite um telefone")
    .transform((value: string | null) => value && value.replace(/\D+/g, ""))
    .test(
      "numberValid",
      "Digite um número de telefone válido",
      (value, context) => {
        return value
          ? context.parent.type !== "FLEET"
            ? value.length === 11 || value.length === 10
            : true
          : true;
      }
    ),
  functionID: yup.string(),
  departmentID: yup.string(),
  costCenterID: yup.string(),
  status: yup.string().oneOf(["Ativo", "Inativo"]),
});
