import { Title } from "@components/Title";
import {
  Button,
  ButtonProps,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      button: {
        fontWeight: typography.fontWeightBold,
        flex: "0 1 30rem",
      },
      title: {
        flex: "1 1 100%",
        [theme.breakpoints.up("lg")]: {
          flex: "1 1 30rem",
        },
      },
    })
);

interface ITitleWithButtonsProps {
  title: string;
  buttons: {
    label: string;
    buttonProps?: ButtonProps<any, { component: any }> | ButtonProps;
  }[];
}

const TitleWithButtons: React.FC<ITitleWithButtonsProps> = ({
  title,
  buttons,
}) => {
  const classes = useStyles();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      <Title className={classes.title}>{title}</Title>
      {buttons.map((button) => {
        return (
          <Button
            className={classes.button}
            color="primary"
            {...button.buttonProps}
          >
            {button.label}
          </Button>
        );
      })}
    </div>
  );
};

export { TitleWithButtons };
