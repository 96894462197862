import { Search } from "../model/Search";
import { FilterInfraction } from "./Infraction";
import Infraction from "./Infraction";
import { inject, injectable } from "inversify";
import { Types } from "@ioc/types";

export interface InfractionClient {
  find(
    companyId: string,
    filter?: FilterInfraction
  ): Promise<Search<Infraction>>;
}

@injectable()
export class InfractionClientImpl implements InfractionClient {
  @inject(Types.FlagcardCompanyWrapper)
  // @ts-ignore
  private instance: FlagcardCompanyWrapper;

  private readonly endpoint = "/rest/v1/infractions";

  public find(
    companyId: string,
    filter?: FilterInfraction
  ): Promise<Search<Infraction>> {
    const plate = filter?.plate?.replaceAll("-", "").toUpperCase() || undefined;
    const page = filter?.page || 1;
    return this.instance.get(this.endpoint, {
      params: { ...filter, plate, page },
      headers: { company_id: companyId },
    });
  }
}
