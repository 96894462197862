import { Search } from "../model/Search";
import { inject, injectable } from "inversify";
import { Types } from "@ioc/types";
import { Manufacturer, Model } from "./Manufacturer";

export interface ManufacturerClient {
  findManufacturer(
    companyId: string,
    type: string,
    description: string
  ): Promise<Manufacturer[]>;

  findModel(
    companyId: string,
    manufacturerId: string,
    description: string
  ): Promise<Model[]>;
}

@injectable()
export class ManufacturerClientImpl implements ManufacturerClient {
  @inject(Types.FlagcardCompanyWrapper)
  // @ts-ignore
  private instance: FlagcardCompanyWrapper;

  private readonly endpoint = "/rest/v1/manufacturers";

  public findManufacturer(
    companyId: string,
    type: string,
    description: string
  ): Promise<Manufacturer[]> {
    return this.instance
      .get(this.endpoint, {
        params: { type, description },
        headers: { company_id: companyId },
      })
      .then(({ result }: Search<Manufacturer>) => result);
  }

  public findModel(
    companyId: string,
    manufacturerId: string,
    description: string
  ): Promise<Model[]> {
    return this.instance
      .get(`${this.endpoint}/${manufacturerId}/models`, {
        params: { description },
        headers: { company_id: companyId },
      })
      .then(({ result }: Search<Model>) => result);
  }
}
