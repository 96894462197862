import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { ICreateFunctionService } from "../models/ICreateFunctionService";

@injectable()
export class CreateFunctionService implements ICreateFunctionService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    name: string;
  }): Promise<void> {
    await this.httpInstance.post(`/companies/${data.companyID}/functions`, {
      tx_funcao: data.name,
    });
  }
}
