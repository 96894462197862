import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IDriverDTO } from "../dtos/IListDriversDTO";
import { ICreateDriverService } from "../models/ICreateDriverService";

@injectable()
export class CreateDriverService implements ICreateDriverService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    driver: IDriverDTO;
  }): Promise<void> {
    await this.httpInstance.post(`/companies/${data.companyID}/drivers`, {
      tx_perfil: "USER",
      ...data.driver,
    });
  }
}
