import { Search } from "../model/Search";
import Permission from "./Permission";
import { inject, injectable } from "inversify";
import { Types } from "@ioc/types";

export interface PermissionClient {
  save(companyId: string, permission: Permission): Promise<Permission>;

  find(companyId: string, vehicleId?: String): Promise<Search<Permission>>;
}

@injectable()
export class PermissionClientImpl implements PermissionClient {
  @inject(Types.FlagcardCompanyWrapper)
  // @ts-ignore
  private instance: FlagcardCompanyWrapper;

  private readonly endpoint = "/rest/v1/permissions";

  public save(companyId: string, permission: Permission): Promise<Permission> {
    return this.instance.post(this.endpoint, permission, {
      headers: { company_id: companyId },
    });
  }

  public find(
    companyId: string,
    vehicleId?: String
  ): Promise<Search<Permission>> {
    return this.instance.get(this.endpoint, {
      params: { vehicle_id: vehicleId },
      headers: { company_id: companyId },
    });
  }
}
