import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@material-ui/core";
import {Button} from "@components/Button";
import StyleSheet from "../../StyleSheet";
import {DataColumn, DataTable} from "@components/DataTable";
import {Types} from "@ioc/types";
import {useUserContext} from "@hooks/UserContext";
import {useIoCContext} from "@hooks/IoCContext";
import {SearchInitialValues} from "../../client/model/Search";
import {Driver} from "../../client/model/Driver";
import {DriverClient} from "../../client/DriverClient";

interface LookupDriversProps {
    open: boolean;
    onCancel: () => void;
    onSelect: (driver: Driver) => void;
}

export const ColumnDefinition: DataColumn[] = [
    {
        field: "driver",
        headerName: "Motorista",
    },
    {
        field: "cnh",
        headerName: "CNH",
    }
];


const LookupDrivers: React.FC<LookupDriversProps> = (props) => {
    const styleSheet = StyleSheet();
    const {open, onCancel, onSelect} = props;
    const [query, setQuery] = useState("");
    const [q, setQ] = useState<string | undefined>(undefined);
    const [drivers, setDrivers] = useState(SearchInitialValues);
    const [loading, setLoading] = useState(true);
    const limit = 8;
    const [page, setPage] = useState(1);
    const {selectedCompany} = useUserContext();
    const iocContext = useIoCContext();

    const driverClient = iocContext.serviceContainer.get<DriverClient>(
        Types.Driver
    );

    const fetchDrivers = () => {
        if (!selectedCompany) return;
        setLoading(true);
        const filter = {
            page: page,
            limit: limit,
            q,
        };
        driverClient
            .find(selectedCompany.uuid, filter)
            .then((result) => {
                setDrivers(result);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const handleFilter = (keyCode: string) => {
        if (keyCode === "Enter") {
            setQ(query);
        }
    };

    const handleSelect = (driver: Driver) => {
        onSelect(driver);
    };

    useEffect(fetchDrivers, [q, page, selectedCompany, open]);

    return (
        <Dialog fullWidth open={open}>
            <DialogTitle disableTypography style={{padding: "0px 8px"}}>
                <h2>Buscar Motoristas</h2>
            </DialogTitle>
            <DialogContent style={{padding: "8px"}}>
                <div
                    style={{
                        border: "solid 1px rgba(0, 0, 0, 0.05)",
                        padding: "10px",
                        borderRadius: "4px",
                    }}
                >
                    <TextField
                        classes={{root: styleSheet.textField}}
                        style={{width: "100%"}}
                        focused={false}
                        variant="outlined"
                        placeholder="Pesquisar por Motoristas"
                        onChange={(event) => setQuery(event.target.value)}
                        onKeyDown={(event) => handleFilter(event.key)}
                        inputProps={{
                            style: {height: 0},
                        }}
                        value={query}
                    />
                    <div style={{height: 20}}/>
                    <DataTable
                        columns={ColumnDefinition}
                        rows={drivers.result}
                        limit={limit}
                        pages={drivers.pages}
                        total={drivers.count}
                        loading={loading}
                        onPagination={(page) => setPage(page)}
                        onClick={(row) => handleSelect(row as Driver)}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={onCancel}>
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LookupDrivers;
