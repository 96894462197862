import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IListModelVehicleDTO } from "../dtos/IListModelVehicleDTO";
import { IListModelVehicleService } from "../models/IListModelVehicleService";

@injectable()
export class ListModelVehicleService implements IListModelVehicleService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    brandCode: number;
  }): Promise<IListModelVehicleDTO[]> {
    const resp = await this.httpInstance.get<IListModelVehicleDTO[]>(
      `/fleet/model/all`,
      {
        params: {
          brand: data.brandCode,
        },
      }
    );

    return resp;
  }
}
