import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IListDepartmentsDTO } from "../dtos/IListDepartmentsDTO";
import { IListDepartmentsService } from "../models/IListDepartmentsService";

@injectable()
export class ListDepartmentsService implements IListDepartmentsService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
  }): Promise<IListDepartmentsDTO[]> {
    const resp = await this.httpInstance.get<IListDepartmentsDTO[]>(
      `/companies/${data.companyID}/departments`
    );

    return resp.map((department) => {
      return {
        ...department,
        created_at: new Date(department.created_at),
      };
    });
  }
}
