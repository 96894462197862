import React, { useEffect, useState } from "react";
import StyleSheet from "../../StyleSheet";
import { Form, useFormikContext } from "formik";
import { Row } from "@components/Row";
import InputMask from "react-input-mask";
import {
  CircularProgress,
  InputAdornment,
  MenuItem,
  TextField,
} from "@material-ui/core";
import Calendar from "@material-ui/icons/CalendarTodayOutlined";
import { DatePicker } from "@material-ui/pickers";
import { IListFunctionsService } from "@modules/company/models/IListFunctionsService";
import { Types } from "@ioc/types";
import { IListDepartmentsService } from "@modules/company/models/IListDepartmentsService";
import { IListCostCentersService } from "@modules/company/models/IListCostCentersService";
import { useIoCContext } from "@hooks/IoCContext";
import { useUserContext } from "@hooks/UserContext";
import { IListFunctionsDTO } from "@modules/company/dtos/IListFunctionsDTO";
import { IListDepartmentsDTO } from "@modules/company/dtos/IListDepartmentsDTO";
import { IListCostCentersDTO } from "@modules/company/dtos/IListCostCentersDTO";
import { Button } from "@components/Button";
import { ROUTES } from "@config/routesConfig";
import { Driver } from "../../client/model/Driver";

const EditForm: React.FC = () => {
  const styleSheet = StyleSheet();
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const { values, errors, setFieldValue } = useFormikContext<Driver>();
  const [loadingFunctions, setLoadingFunctions] = useState(false);
  const [loadingDepartments, setLoadingDepartments] = useState(false);
  const [loadingCostCenters, setLoadingCostCenters] = useState(false);
  const [functions, setFunctions] = useState<IListFunctionsDTO[]>([]);
  const [departments, setDepartments] = useState<IListDepartmentsDTO[]>([]);
  const [costCenters, setCostCenters] = useState<IListCostCentersDTO[]>([]);

  const getFunctionService = iocContext.serviceContainer.get<IListFunctionsService>(
    Types.Company.IListFunctionsService
  );
  const getDepartmentService = iocContext.serviceContainer.get<IListDepartmentsService>(
    Types.Company.IListDepartmentsService
  );
  const getCostCentersService = iocContext.serviceContainer.get<IListCostCentersService>(
    Types.Company.IListCostCentersService
  );

  const fetchFunctions = () => {
    if (!selectedCompany) return;
    setLoadingFunctions(true);
    getFunctionService
      .execute({ companyID: selectedCompany.id })
      .then((result) => {
        setLoadingFunctions(false);
        setFunctions(result);
      });
  };

  const fetchDepartments = () => {
    if (!selectedCompany) return;
    setLoadingDepartments(true);
    getDepartmentService
      .execute({ companyID: selectedCompany.id })
      .then((result) => {
        setLoadingDepartments(false);
        setDepartments(result);
      });
  };

  const fetchCostCenters = () => {
    if (!selectedCompany) return;
    setLoadingCostCenters(true);
    getCostCentersService
      .execute({ companyID: selectedCompany.id })
      .then((result) => {
        setLoadingCostCenters(false);
        setCostCenters(result);
      });
  };

  useEffect(fetchFunctions, [selectedCompany, getFunctionService]);
  useEffect(fetchDepartments, [selectedCompany, getDepartmentService]);
  useEffect(fetchCostCenters, [selectedCompany, getCostCentersService]);

  return (
    <Form noValidate>
      <Row>
        <InputMask
          id="registration"
          name="registration"
          required
          onChange={(event) =>
            setFieldValue("registration", event.target.value)
          }
          value={values.registration}
          mask="999999999999999"
          maskChar={null}
        >
          {() => (
            <TextField
              style={{ width: "20%" }}
              classes={{ root: styleSheet.textField }}
              label="Matrícula"
              required
              error={!!errors.registration}
              helperText={errors.registration}
              variant="outlined"
              size="small"
            />
          )}
        </InputMask>
        <TextField
          id="driver"
          name="driver"
          style={{ width: "80%" }}
          classes={{ root: styleSheet.textField }}
          required
          label="Motorista"
          variant="outlined"
          onChange={(event) => setFieldValue("driver", event.target.value)}
          value={values.driver}
          inputProps={{ maxLength: 256 }}
          error={!!errors.driver}
          helperText={errors.driver}
          size="small"
        />
      </Row>
      <Row>
        <InputMask
          id="cnh"
          name="cnh"
          required
          onChange={(event) => setFieldValue("cnh", event.target.value)}
          value={values.cnh}
          mask="99999999999"
          maskChar={null}
        >
          {() => (
            <TextField
              style={{ width: "33%" }}
              classes={{ root: styleSheet.textField }}
              label="CNH"
              required
              error={!!errors.cnh}
              helperText={errors.cnh}
              variant="outlined"
              size="small"
            />
          )}
        </InputMask>
        <TextField
          id="category"
          name="category"
          style={{ width: "33%" }}
          classes={{ root: styleSheet.textField }}
          required
          label="Categoria"
          variant="outlined"
          select
          value={values.category}
          error={!!errors.category}
          helperText={errors.category}
          onChange={(event) => setFieldValue("category", event.target.value)}
          size="small"
        >
          <MenuItem key="0" value="0">
            --selecione uma opção--
          </MenuItem>
          {["A", "B", "AB", "C", "AC", "D", "AD", "E", "AE"].map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </TextField>
        <DatePicker
          id="expirationDate"
          name="expirationDate"
          label="Vencimento"
          format="dd/MM/yyyy"
          autoOk
          value={values.expirationDate}
          disablePast
          required
          inputVariant="outlined"
          size="small"
          className={styleSheet.textField}
          style={{ width: "34%" }}
          error={!!errors.expirationDate}
          helperText={errors.expirationDate}
          TextFieldComponent={(props) => (
            <TextField
              placeholder="selecione uma data"
              {...props}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Calendar
                      style={{
                        color: "rgba(0, 0, 0, 0.23)",
                        width: "18px",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          )}
          onChange={(value) => setFieldValue("expirationDate", value)}
        />
      </Row>
      <Row>
        <InputMask
          id="rg"
          name="rg"
          required
          onChange={(event) => setFieldValue("rg", event.target.value)}
          value={values.rg}
          mask="99999999999"
          maskChar={null}
        >
          {() => (
            <TextField
              style={{ width: "33%" }}
              classes={{ root: styleSheet.textField }}
              label="RG"
              required
              error={!!errors.rg}
              helperText={errors.rg}
              variant="outlined"
              size="small"
            />
          )}
        </InputMask>
        <InputMask
          id="cpf"
          name="cpf"
          required
          onChange={(event) => setFieldValue("cpf", event.target.value)}
          value={values.cpf}
          mask="999.999.999-99"
          maskChar={null}
        >
          {() => (
            <TextField
              style={{ width: "33%" }}
              classes={{ root: styleSheet.textField }}
              label="CPF"
              required
              error={!!errors.cpf}
              helperText={errors.cpf}
              variant="outlined"
              size="small"
            />
          )}
        </InputMask>
        <InputMask
          id="phoneNumber"
          name="phoneNumber"
          required
          onChange={(event) => setFieldValue("phoneNumber", event.target.value)}
          value={values.phoneNumber}
          mask="(99) 9 9999-9999"
          maskChar={null}
        >
          {() => (
            <TextField
              style={{ width: "34%" }}
              classes={{ root: styleSheet.textField }}
              label="Telefone"
              required
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
              variant="outlined"
              size="small"
            />
          )}
        </InputMask>
      </Row>
      <Row>
        <TextField
          id="activity"
          name="activity"
          style={{ width: "33%" }}
          classes={{ root: styleSheet.textField }}
          label="Função"
          variant="outlined"
          select
          value={values.activityId}
          error={!!errors.activityId}
          helperText={errors.activityId}
          onChange={(event) => setFieldValue("activityId", event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {loadingFunctions ? (
                  <CircularProgress
                    style={{ width: 18, height: 18, marginRight: 30 }}
                  />
                ) : (
                  <></>
                )}
              </InputAdornment>
            ),
          }}
          size="small"
        >
          <MenuItem key="0" value="0">
            --selecione uma opção--
          </MenuItem>
          {functions.map((activity) => (
            <MenuItem key={activity.id} value={activity.id}>
              {activity.tx_funcao}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="department"
          name="department"
          style={{ width: "33%" }}
          classes={{ root: styleSheet.textField }}
          label="Departamento"
          variant="outlined"
          select
          value={values.departmentId}
          error={!!errors.departmentId}
          helperText={errors.departmentId}
          onChange={(event) =>
            setFieldValue("departmentId", event.target.value)
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {loadingDepartments ? (
                  <CircularProgress
                    style={{ width: 18, height: 18, marginRight: 30 }}
                  />
                ) : (
                  <></>
                )}
              </InputAdornment>
            ),
          }}
          size="small"
        >
          <MenuItem key="0" value="0">
            --selecione uma opção--
          </MenuItem>
          {departments.map((department) => (
            <MenuItem key={department.id} value={department.id}>
              {department.tx_departamento}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="costCenter"
          name="costCenter"
          style={{ width: "34%" }}
          classes={{ root: styleSheet.textField }}
          label="Centro de Custo"
          variant="outlined"
          select
          value={values.costCenterId}
          error={!!errors.costCenterId}
          helperText={errors.costCenterId}
          onChange={(event) =>
            setFieldValue("costCenterId", event.target.value)
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {loadingCostCenters ? (
                  <CircularProgress
                    style={{ width: 18, height: 18, marginRight: 30 }}
                  />
                ) : (
                  <></>
                )}
              </InputAdornment>
            ),
          }}
          size="small"
        >
          <MenuItem key="0" value="0">
            --selecione uma opção--
          </MenuItem>
          {costCenters.map((costCenter) => (
            <MenuItem key={costCenter.id} value={costCenter.id}>
              {costCenter.tx_centro_custo}
            </MenuItem>
          ))}
        </TextField>
      </Row>
      <Row align="end">
        <Button to={ROUTES.DRIVERS} color="secondary">
          Voltar
        </Button>
        <div style={{ width: 6 }} />
        <Button color="primary" type="submit">
          Salvar
        </Button>
      </Row>
    </Form>
  );
};

export { EditForm };
