import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IListUsersDTO } from "../dtos/IListUsersDTO";
import { IListUsersService } from "../models/IListUsersService";

@injectable()
export class ListUsersService implements IListUsersService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    statusID?: string;
    functionID?: string;
    departmentID?: string;
    costCenterID?: string;
    query?: string;
    page: number;
    limit: number;
  }): Promise<IListUsersDTO> {
    const resp = await this.httpInstance.get<IListUsersDTO>(
      `/companies/${data.companyID}/users`,
      {
        params: {
          company: data.companyID,
          page: data.page,
          limit: data.limit,
          statusId: data.statusID,
          functionId: data.functionID,
          departmentId: data.departmentID,
          costcenterId: data.costCenterID,
          q: data.query,
        },
      }
    );

    return resp;
  }
}
