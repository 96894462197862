import React from "react";
import {Backdrop, CircularProgress} from "@material-ui/core";

const Loading: React.FC<{ open: boolean }> = ({open}) => {
    return (
        <Backdrop open={open} style={{zIndex: 999999}}>
            <CircularProgress color="inherit"/>
        </Backdrop>
    );
};

export {Loading};
