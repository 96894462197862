import * as yup from "yup";

const SUPPORTED_FORMATS = ["application/.*sheet"];

export const FormUploadUsersSchemaValidation = yup.object().shape({
  file: yup
    .mixed()
    .test(
      "fileType",
      "Tipo de arquivo não suportado",
      (value: { type: string }) => {
        if (value) {
          for (let patternStr of SUPPORTED_FORMATS) {
            let pattern = new RegExp(patternStr);
            const test = pattern.test(value.type);
            return test;
          }
        }
        return false;
      }
    )
    .required("Anexe um arquivo válido"),
});
