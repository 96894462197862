import { Filter } from "../model/Filter";
import { Search } from "../model/Search";
import { inject, injectable } from "inversify";
import { Types } from "@ioc/types";
import { FlagcardCompanyWrapper } from "../FlagcardCompanyWrapper";
import { CostCenter } from "./CostCenter";

export interface CostCenterClient {
  save(companyId: string, costCenter: CostCenter): Promise<CostCenter>;

  update(
    companyId: string,
    costCenterId: string,
    costCenter: CostCenter
  ): Promise<void>;

  find(companyId: string, filter?: Filter): Promise<Search<CostCenter>>;

  findById(companyId: string, costCenterId: string): Promise<CostCenter>;

  remove(companyId: string, costCenterId: string): Promise<void>;
}

@injectable()
export class CostCenterClientImpl implements CostCenterClient {
  @inject(Types.FlagcardCompanyWrapper)
  // @ts-ignore
  private instance: FlagcardCompanyWrapper;

  private readonly endpoint = "/rest/v1/costcenters";

  public save(companyId: string, costCenter: CostCenter): Promise<CostCenter> {
    return this.instance.post(this.endpoint, costCenter, {
      headers: { company_id: companyId },
    });
  }

  public update(
    companyId: string,
    costCenterId: string,
    costCenter: CostCenter
  ): Promise<void> {
    return this.instance.patch(`${this.endpoint}/${costCenterId}`, costCenter, {
      headers: { company_id: companyId },
    });
  }

  public find(companyId: string, filter?: Filter): Promise<Search<CostCenter>> {
    return this.instance.get(this.endpoint, {
      params: filter,
      headers: { company_id: companyId },
    });
  }

  public findById(
    companyId: string,
    costCenterId: string
  ): Promise<CostCenter> {
    return this.instance.get(`${this.endpoint}/${costCenterId}`, {
      headers: { company_id: companyId },
    });
  }

  public remove(companyId: string, costCenterId: string): Promise<void> {
    return this.instance.delete(`${this.endpoint}/${costCenterId}`, {
      headers: { company_id: companyId },
    });
  }
}
