import { ReloadButton, ReloadButtonProps } from "@components/ReloadButton";
import {
  CardHeader,
  CardHeaderProps,
  createStyles,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    cardHeaderText: {
      fontSize: "2.4rem",
      fontWeight: "bold",
      [theme.breakpoints.down(1600)]: {
        fontSize: "1.8rem",
      },
      [theme.breakpoints.down(1280)]: {
        textAlign: "center",
      },
    },
    cardHeader: {
      [theme.breakpoints.down(1370)]: {
        flexDirection: "column",
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
      },
      [theme.breakpoints.down(670)]: {
        flexDirection: "column",
      },
    },
  })
);

interface Props {
  cardHeaderProps?: CardHeaderProps;
  reloadButtonProps?: ReloadButtonProps;
  title: string;
}

const CardHeaderCharts: React.FC<Props> = ({
  reloadButtonProps,
  cardHeaderProps,
  title,
}) => {
  const classes = useStyles();

  let headerProps;
  if (cardHeaderProps) {
    let { className, ...other } = cardHeaderProps;
    headerProps = other;
  }

  return (
    <CardHeader
      title={
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className={clsx(
            classes.cardHeader,
            cardHeaderProps ? cardHeaderProps.className : null
          )}
          {...headerProps}
        >
          <Typography className={classes.cardHeaderText}>{title}</Typography>
          {reloadButtonProps && <ReloadButton {...reloadButtonProps} />}
        </Grid>
      }
    />
  );
};

export { CardHeaderCharts };
