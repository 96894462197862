import { inject, injectable } from "inversify";
import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { Filter } from "../model/Filter";
import { ReportDebitClient } from "../ReportDebitClient";
import { Search } from "../model/Search";
import { ReportDebit } from "../model/ReportDebit";
import { format } from "date-fns/esm";

@injectable()
export class HttpReportDebitClient implements ReportDebitClient {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public find(
    companyId: string,
    startDate: Date,
    endDate: Date,
    filter: Filter
  ): Promise<Search<ReportDebit>> {
    return this.httpInstance.get(
      `/companies/${companyId}/report/debit-management/paged`,
      {
        params: {
          ...filter,
          type: "DEBITO",
          startDate: ((format(startDate, "yyyy-MM-dd") +
            "T00:00:00.000Z") as unknown) as Date,
          endDate: ((format(endDate, "yyyy-MM-dd") +
            "T23:59:59.000Z") as unknown) as Date,
        },
      }
    );
  }

  public async download(
    companyId: string,
    startDate: Date,
    endDate: Date,
    filter: Filter
  ): Promise<string> {
    const result = await this.httpInstance.get(
      `/companies/${companyId}/report/debit-management`,
      {
        params: {
          ...filter,
          type: "DEBITO",
          startDate: ((format(startDate, "yyyy-MM-dd") +
            "T00:00:00.000Z") as unknown) as Date,
          endDate: ((format(endDate, "yyyy-MM-dd") +
            "T23:59:59.000Z") as unknown) as Date,
        },
        responseType: "arraybuffer",
      }
    );
    const blob = new Blob([result], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    return window.URL.createObjectURL(blob);
  }
}
