import React, { useEffect, useState } from "react";
import { Title } from "@components/Title/Title";
import StyleSheet from "./StyleSheet";
import { useUserContext } from "@hooks/UserContext";
import {
  DashboardCardTotalAvailable,
  DashboardCardTotalConsumed,
  DashboardCardTotalCredited,
  DashboardCardTotalUsed,
} from "@components/CardInfo/DashboardCard";
import { useIoCContext } from "@hooks/IoCContext";
import { IGetResumeService } from "@modules/dashboard/models/IGetResumeService";
import { Types } from "@ioc/types";
import { useSnackbar } from "notistack";
import { ChartFuelConsume } from "@pages/Dashboard/ChartFuelConsume/ChartFuelConsume";
import { ChartPricesMonthly } from "@pages/Dashboard/ChartPricesMonthly/ChartPricesMonthly";
import { Collapse, Tab, Tabs } from "@material-ui/core";
import { InvalidTransactionsTable } from "@pages/Dashboard/Tables/InvalidTransactions/InvalidTransactionsTable";
import { GenericInvalidTransactions } from "@pages/Dashboard/Tables/GenericInvalidTransactions";

const TabPanel: React.FC<{ value: number; index: number }> = ({
  children,
  value,
  index,
}) => {
  return (
    <Collapse in={index === value}>{index === value && children}</Collapse>
  );
};

const HomePage: React.FC = () => {
  const styleSheet = StyleSheet();
  const iocContext = useIoCContext();
  const { companyInfo, selectedCompany } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [prepaid, setPrepaid] = useState(true);
  const [inMoney, setInMoney] = useState(true);
  const [balance, setBalance] = useState(0);
  const [consumed, setConsumed] = useState(0);
  const [available, setAvailable] = useState(0);
  const [consumedOpposite, setConsumedOpposite] = useState(0);
  const [tab, setTab] = useState(0);

  const getResumeService = iocContext.serviceContainer.get<IGetResumeService>(
    Types.Dashboard.IGetResumeService
  );

  const execute = async (
    companyID: string,
    prePaid: boolean,
    inMoney: boolean
  ) => {
    try {
      const prePaidResult = await getResumeService.prePaid({ companyID });
      const posPaidResult = await getResumeService.posPaid({ companyID });
      setBalance(
        prePaid ? prePaidResult.totalCredited : posPaidResult.credited
      );
      setConsumed(
        prePaid ? prePaidResult.totalUsed : posPaidResult.consumed_real
      );
      setAvailable(prePaid ? prePaidResult.balance : posPaidResult.remaining);
      setConsumedOpposite(
        inMoney ? posPaidResult.consumed_liter : posPaidResult.consumed_real
      );
    } catch (error) {
      enqueueSnackbar(error.response?.data?.message, { variant: "error" });
    }
  };

  const fetchDashboard = (): void => {
    if (!companyInfo || !selectedCompany) return;
    setLoading(true);
    setPrepaid(companyInfo.cs_plano === "PRE_PAGO");
    setInMoney(companyInfo.in_money);
    execute(
      selectedCompany.uuid,
      companyInfo.cs_plano === "PRE_PAGO",
      companyInfo.in_money
    ).finally(() => setLoading(false));
  };

  useEffect(fetchDashboard, [companyInfo, selectedCompany, getResumeService]);

  return (
    <div className={styleSheet.container}>
      <Title>Dashboard</Title>
      <div className={styleSheet["card-info-container"]}>
        <div className={styleSheet["card-info-container-item"]}>
          <DashboardCardTotalCredited
            prepaid={prepaid}
            inMoney={inMoney}
            loading={loading}
            value={balance}
          />
        </div>
        <div className={styleSheet["card-info-container-item"]}>
          <DashboardCardTotalUsed
            inMoney={inMoney}
            loading={loading}
            value={consumed}
          />
        </div>
        <div className={styleSheet["card-info-container-item"]}>
          <DashboardCardTotalAvailable
            inMoney={inMoney}
            loading={loading}
            value={available}
          />
        </div>
        <div className={styleSheet["card-info-container-item"]}>
          <DashboardCardTotalConsumed
            inMoney={inMoney}
            loading={loading}
            value={consumedOpposite}
          />
        </div>
      </div>
      <div className={styleSheet["card-info-container"]}>
        <div className={styleSheet["card-info-container-item"]}>
          <ChartFuelConsume />
        </div>
        <div className={styleSheet["card-info-container-item"]}>
          <ChartPricesMonthly />
        </div>
      </div>
      <div className={styleSheet["card-info-container"]}>
        <div className={styleSheet["card-info-container-item"]}>
          <Tabs
            value={tab}
            onChange={(e, value) => setTab(value)}
            TabIndicatorProps={{ children: <span /> }}
            classes={{ indicator: styleSheet["indicator-tab"] }}
            variant="scrollable"
          >
            <Tab
              label="Transações inválidas passíveis de autorização"
              classes={{
                root: styleSheet["button-tab"],
                selected: styleSheet["selected-tab"],
              }}
            />
            <Tab
              label="Todas as transações inválidas"
              classes={{
                root: styleSheet["button-tab"],
                selected: styleSheet["selected-tab"],
              }}
            />
          </Tabs>
          <TabPanel value={tab} index={0}>
            <InvalidTransactionsTable />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <GenericInvalidTransactions />
          </TabPanel>
        </div>
      </div>
    </div>
  );
};

export { HomePage };
