import { ErrorLoading } from "@components/ErrorLoading";
import { Pagination } from "@components/Pagination";
import { ReloadButton } from "@components/ReloadButton";
import { Tag } from "@components/Tag";
import { useAuth } from "@hooks/AuthContext";
import { useIoCContext } from "@hooks/IoCContext";
import { useUserContext } from "@hooks/UserContext";
import { Types } from "@ioc/types";
import {
  CircularProgress,
  createStyles,
  IconButton,
  InputAdornment,
  LinearProgress,
  makeStyles,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ColDef, DataGrid, GridOverlay } from "@material-ui/data-grid";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import SearchIcon from "@material-ui/icons/Search";
import { IPermissibilityFleetDTO } from "@modules/company/dtos/IListPermissibilityFleetDTO";
import { IListPermissibilityFleetService } from "@modules/company/models/IListPermissibilityFleetService";
import { IRemoveJustificationTransactionService } from "@modules/company/models/IRemoveJustificationTransactionService";
import { tableStyles } from "@utils/index";
import clsx from "clsx";
import { isValid } from "date-fns";
import { format } from "date-fns/esm";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DialogInfo } from "./DialogInfo";
import { DialogJustification } from "./DialogJustification";
import {
  InvalidTransactionsProvider,
  useInvalidTransactions,
} from "./InvalidTransactionsContext";

const useStyles = makeStyles((theme) =>
  createStyles({
    paperTable: {
      borderRadius: "2rem",
      borderTopLeftRadius: 0,
      height: "60rem",
      width: "100%",
    },
    headerColumns: {
      fontWeight: "bold",
      fontSize: "1.4rem",
      lineHeight: "inherit",
    },
    headerTableContainer: {
      padding: "1rem",
      boxSizing: "content-box",
    },
    inputHeader: {
      width: "25rem",
      transition: "width 1s",
      textOverflow: "ellipsis",
      "&:focus": {
        [theme.breakpoints.up("sm")]: {
          width: "40rem",
        },
      },
    },
    buttonRemoveJustification: {
      color: theme.palette.tertiary.error.primary,
    },
    loadingRemoveJustification: {
      color: theme.palette.tertiary.error.primary,
    },
    tableInvalidTransactions: tableStyles(theme).dataGridTable,
  })
);

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

function CustomHeader() {
  const classes = useStyles();
  const { query, setQuery } = useInvalidTransactions();

  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <div className={classes.headerTableContainer}>
      <TextField
        size="small"
        onChange={({ currentTarget }) => setQuery(currentTarget.value)}
        value={query}
        inputRef={inputRef}
        label="Pesquisar por placa"
        variant="outlined"
        id="inputSearch"
        InputProps={{
          endAdornment: (
            <InputAdornment
              onClick={() => inputRef.current?.focus()}
              position="end"
            >
              <SearchIcon />
            </InputAdornment>
          ),
          classes: {
            input: classes.inputHeader,
          },
        }}
      />
    </div>
  );
}

interface IButtonRemove {
  idTransaction: string;
  fetchTransactions: () => void;
}
const ButtonRemove: React.FC<IButtonRemove> = ({
  idTransaction,
  fetchTransactions,
}) => {
  const classes = useStyles();
  const iocContext = useIoCContext();
  const { selectedCompany } = useUserContext();

  const removeJustificationService = iocContext.serviceContainer.get<IRemoveJustificationTransactionService>(
    Types.Company.IRemoveJustificationTransactionService
  );

  const remove = useCallback(async () => {
    if (!selectedCompany) return;
    try {
      setLoading(true);
      await removeJustificationService.execute({
        companyID: selectedCompany.uuid,
        idTransaction,
      });
    } catch (error) {
    } finally {
      setLoading(false);
      fetchTransactions();
    }
  }, [
    fetchTransactions,
    idTransaction,
    removeJustificationService,
    selectedCompany,
  ]);

  const [loading, setLoading] = useState(false);

  return (
    <Tooltip title="Remover justificativa">
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading && (
          <CircularProgress
            style={{ position: "absolute" }}
            size={35}
            className={classes.loadingRemoveJustification}
          />
        )}
        <IconButton
          onClick={() => remove()}
          className={classes.buttonRemoveJustification}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </Tooltip>
  );
};

const Container: React.FC = () => {
  const classes = useStyles();
  const iocContext = useIoCContext();
  const userContext = useUserContext();
  const auth = useAuth();
  const {
    setLoadingTransactions,
    setTransactions,
    page,
    transactions,
    limit,
    loadingTransactions,
    errorLoadingTransactions,
    setErrorLoadingTransactions,
    setOpenDialog,
    setInfoDialog,
    setLimit,
    query,
    setOpenDialogJustification,
    openDialogJustification,
    setPage,
  } = useInvalidTransactions();

  const getListPermissibilityService = iocContext.serviceContainer.get<IListPermissibilityFleetService>(
    Types.Company.IListPermissibilityFleetService
  );

  const columns: ColDef[] = [
    {
      field: "info",
      headerName: " ",
      sortable: false,
      width: 70,
      renderCell: ({ row }) => {
        return (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <IconButton
              onClick={() => {
                setInfoDialog(row as IPermissibilityFleetDTO);
                setOpenDialog(true);
              }}
              color="primary"
            >
              <InfoIcon />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: "description",
      headerName: "Tipo",
      width: 450,
      sortable: false,
      renderHeader: (params) => {
        return (
          <Typography className={classes.headerColumns}>
            {params.colDef.headerName}
          </Typography>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Data do ocorrido",
      sortable: false,
      width: 150,
      renderHeader: (params) => {
        return (
          <Typography className={classes.headerColumns}>
            {params.colDef.headerName}
          </Typography>
        );
      },
      valueFormatter: ({ value }) =>
        value && isValid(value)
          ? format(value as Date, "dd/MM/yyyy HH:mm")
          : "",
    },
    {
      field: "Vehicle.Mmodel.name",
      headerName: "Veículo",
      sortable: false,
      width: 350,
      renderHeader: (params) => {
        return (
          <Typography className={classes.headerColumns}>
            {params.colDef.headerName}
          </Typography>
        );
      },
      valueGetter: (params) => {
        const name = params?.row?.Vehicle?.Mmodel?.name as string;
        return name || 'Não informado';
      },
    },
    {
      field: "Vehicle",
      headerName: "Placa",
      sortable: false,
      width: 120,
      renderHeader: (params) => {
        return (
          <Typography className={classes.headerColumns}>
            {params.colDef.headerName}
          </Typography>
        );
      },
      valueGetter: (params) => {
        const plate = params?.row?.Vehicle?.plate as string;
        if(!plate){
          return 'Não informado';
        }

        const isMercoSulPlate = plate.match(/[A-Z]{0,3}[0-9][A-Z][0-9]{0,2}/g);
        if (isMercoSulPlate) {
          return plate;
        }

        const first = plate.slice(0, 3);
        const second = plate.slice(3);
        return `${first}-${second}`;
      },
    },
    {
      sortable: false,
      field: "issuer_driver",
      headerName: "Motorista",
      width: 250,
      renderHeader: (params) => {
        return (
          <Typography className={classes.headerColumns}>
            {params.colDef.headerName}
          </Typography>
        );
      },
      valueGetter: (params) => {
        return params.row.issuer_driver
          ? params.row.issuer_driver.tx_motorista
          : "-";
      },
    },
    {
      field: "status",
      sortable: false,
      headerName: "Status",
      width: 250,
      renderHeader: (params) => {
        return (
          <Typography className={classes.headerColumns}>
            {params.colDef.headerName}
          </Typography>
        );
      },
      renderCell: (row) => {
        return (
          <Tag
            type={
              row.getValue("status") === "Utilizado"
                ? "success"
                : row.getValue("status") === "Expirado para justificação"
                ? "warning"
                : row.getValue("status") === "Bloqueado"
                ? "error"
                : row.getValue("status") === "Aguardando utilização"
                ? "info"
                : row.getValue("status") === "Expirado para utilização"
                ? "expired"
                : // info não deverá ser utilizado, apenas para código legível
                  "info"
            }
          >
            {row.getValue("status")}
          </Tag>
        );
      },
      valueGetter: ({ row }) => {
        const typedRow = (row as unknown) as IPermissibilityFleetDTO;

        if (!typedRow.able_to_release) {
          if (!typedRow.expired_to_use) {
            return "Bloqueado";
          }
          return "Expirado para justificação";
        }
        if (!typedRow.released_at) {
          if (!typedRow.expired_to_use) {
            return "Aguardando utilização";
          }
          return "Expirado para utilização";
        }
        return "Utilizado";
      },
    },
    {
      field: "justification",
      sortable: false,
      headerName: " ",
      width: 150,
      renderCell: (params) => {
        const status = params.getValue("status");
        const row = (params.row as unknown) as IPermissibilityFleetDTO;

        return status === "Bloqueado" || status === "Aguardando utilização" ? (
          <>
            <Tooltip
              title={
                status === "Bloqueado"
                  ? "Adicionar justificativa"
                  : "Editar justificativa"
              }
            >
              <IconButton
                onClick={() => {
                  setOpenDialogJustification({
                    data: {
                      release_issuer_id: auth.userID,
                      justification: row.justification_for_release,
                      idTransaction: row.id,
                    },
                    open: true,
                  });
                }}
                color="primary"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            {status === "Aguardando utilização" && (
              <ButtonRemove
                fetchTransactions={fetchTransactions}
                idTransaction={row.id}
              />
            )}
          </>
        ) : (
          <></>
        );
      },
    },
  ];

  const fetchTransactions = useCallback(async () => {
    if (!userContext.selectedCompany) return;
    try {
      setLoadingTransactions(true);
      const response = await getListPermissibilityService.execute({
        companyID: userContext.selectedCompany.uuid,
        page,
        limit,
        query,
      });
      setTransactions(response);
      setErrorLoadingTransactions(false);
    } catch (error) {
      setErrorLoadingTransactions(true);
    } finally {
      setLoadingTransactions(false);
    }
  }, [
    getListPermissibilityService,
    limit,
    page,
    query,
    setErrorLoadingTransactions,
    setLoadingTransactions,
    setTransactions,
    userContext.selectedCompany,
  ]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions, openDialogJustification.open]);

  return (
    <>
      <Paper
        className={clsx(classes.paperTable, classes.tableInvalidTransactions)}
      >
        <DataGrid
          rows={transactions.result}
          columns={columns}
          components={{
            pagination: ({ pagination }) => (
              <Pagination
                page={page}
                rowCount={transactions.count}
                pageSize={pagination.pageSize}
                setPage={(value) => setPage(value)}
              />
            ),
            loadingOverlay: CustomLoadingOverlay,
            errorOverlay: () => {
              return (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <ErrorLoading />
                  <ReloadButton
                    reloadFunc={fetchTransactions}
                    reloading={loadingTransactions}
                  />
                </div>
              );
            },
            header: CustomHeader,
          }}
          onPageSizeChange={(params) => setLimit(params.pageSize)}
          loading={loadingTransactions}
          error={errorLoadingTransactions ? true : null}
          disableSelectionOnClick
          scrollbarSize={5}
          autoPageSize
        />
      </Paper>
      <DialogInfo />
      {openDialogJustification.open && <DialogJustification />}
    </>
  );
};

const InvalidTransactionsTable: React.FC = () => {
  return (
    <InvalidTransactionsProvider>
      <Container />
    </InvalidTransactionsProvider>
  );
};

export { InvalidTransactionsTable };
