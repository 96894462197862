import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IRemoveCostCenterService } from "../models/IRemoveCostCenterService";

@injectable()
export class RemoveCostCenterService implements IRemoveCostCenterService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    costCenterID: string;
  }): Promise<void> {
    await this.httpInstance.delete(
      `/companies/${data.companyID}/costcenters/${data.costCenterID}`
    );
  }
}
