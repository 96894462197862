import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    container: {
      padding: "20px",
      backgroundColor: "#FFF",
      borderRadius: "4px",
      webkitBoxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    },
    textField: {
      padding: "0px 3px",
    },
    input: {
      height: 30,
    },
    "card-info-container": {
      display: "flex",
      flexWrap: "wrap",
    },
    "card-info-container-item": {
      flexGrow: 1,
      flexBasis: 200,
      padding: "10px 10px",
    },
    "button-tab": {
      fontWeight: "bold",
      textTransform: "none",
      maxWidth: "unset",
    },
    "selected-tab": {
      backgroundColor: "#FFF",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
    },
    "indicator-tab": {
      backgroundColor: "transparent",
      display: "flex",
      justifyContent: "center",
      height: 3,
      "& > span": {
        width: "50%",
        borderRadius: "10px",
        backgroundColor: "#0F71D0",
      },
    },
  })
);
