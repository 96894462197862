import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IGetDocumentService } from "@modules/company/models/IGetDocumentService";
import { SearchDocumentDTO } from "@modules/company/dtos/SearchDocumentDTO";

@injectable()
export class GetDocumentService implements IGetDocumentService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public execute(
    limit: number,
    page: number,
    tenantId?: string,
    userId?: string
  ): Promise<SearchDocumentDTO> {
    return this.httpInstance.get(`/v1/documents?limit=${limit}&page=${page}`, {
      headers: { tenant_id: tenantId, user_id: userId },
    });
  }
}
