import React from "react";

export const Flagcard: React.FC<{
  height?: number;
  top?: number;
  color?: string;
}> = (props) => {
  const height = props.height || 80;
  const top = props.top || 0;
  const color = props.color || "#FFF";
  return (
    <svg
      id="Flagcard"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1064.52 378.46"
      height={height}
      fill={color}
      style={{ marginTop: top }}
    >
      <path d="M273,94.62H105.48a10.87,10.87,0,1,0,0,21.74H273a10.87,10.87,0,0,0,0-21.74Zm0,17.54a6.7,6.7,0,1,1,6.7-6.7A6.7,6.7,0,0,1,273,112.16Z" />
      <path d="M246.75,150.24H105.48a10.87,10.87,0,0,0-10.87,10.87V272.36a10.87,10.87,0,0,0,19.56,6.52l38.46-51.27h67.9a10.87,10.87,0,0,0,0-21.74H147.4a10.88,10.88,0,0,0-8.9,4.34l-22.15,29.54V172h130.4a10.87,10.87,0,1,0,0-21.74ZM220.59,210a6.7,6.7,0,1,1-6.7,6.7A6.7,6.7,0,0,1,220.59,210Zm26.19-42.26a6.7,6.7,0,1,1,6.7-6.7A6.7,6.7,0,0,1,246.78,167.78Z" />
      <path d="M390.15,160.73H330.66v24.35h56.69v17.64H330.66v33.85H310.93V143.22h79.22Z" />
      <path d="M404.21,236.57V141.92h19.21v94.65Z" />
      <path d="M472.69,165.23q17.58,0,25.85,5.6t8.26,16.92v48.82H490.53l-1.69-7.49,2.14-4.36A28.37,28.37,0,0,1,478,235a45.46,45.46,0,0,1-17.9,3.55q-11.72,0-18.1-5.6t-6.38-16.4q0-20.64,25.72-20.7h26.1v-6.12q0-5.47-3.52-7.78T472,179.68q-7.87,0-11.36,1.85a7.9,7.9,0,0,0-4.13,6.22H439.49q-.39-12.24,8.27-17.38T472.69,165.23ZM466.57,224h1.31q11.83,0,19.59-6.64V207.8H464.56q-9.45,0-9.44,7.81Q455.12,224,466.57,224Z" />
      <path d="M554.65,258.7q-17.71,0-25.85-6.25V239.24q10.16,4.95,23.76,4.95,24,0,24-16.93v-2.15l.26-3.71q-6.37,12.1-24.61,12.11-15.17,0-24.11-9.34t-8.95-24.41q0-15.07,9-24.74t24.08-9.66q17.72,0,26.89,16.79l-3.32-6.51.65-8.4h18.55v59.44a32.55,32.55,0,0,1-3.42,15.39,24.62,24.62,0,0,1-9.27,10,44.69,44.69,0,0,1-12.73,5.08A65.84,65.84,0,0,1,554.65,258.7Zm-11.59-45.6q5.07,5.18,13.87,5.18t13.86-5.18q5.09-5.17,5.08-13.31a18.36,18.36,0,0,0-5.08-13.28q-5.07-5.26-13.86-5.27t-13.87,5.21a20,20,0,0,0,0,26.65Z" />
      <path d="M645.52,238.59q-18.16,0-28.25-10.42t-10-26.23q0-15.88,10.06-26.3t28.15-10.41q15.5,0,24.9,7.87t11.1,21H662.77q-3.32-11.72-17.25-11.78-9,0-14.19,5.47t-5.14,14.16q0,8.68,5.14,14.16t14.19,5.46q13.86,0,17.25-11.78h18.75q-1.63,13.08-11.07,21T645.52,238.59Z" />
      <path d="M727.67,165.23q17.58,0,25.84,5.6t8.27,16.92v48.82H745.5l-1.69-7.49,2.15-4.36A28.47,28.47,0,0,1,733,235a45.47,45.47,0,0,1-17.91,3.55q-11.72,0-18.09-5.6t-6.38-16.4q0-20.64,25.71-20.7h26.1v-6.12q0-5.47-3.51-7.78c-2.34-1.54-6.32-2.31-11.91-2.31q-7.87,0-11.36,1.85a7.93,7.93,0,0,0-4.14,6.22h-17q-.39-12.24,8.27-17.38T727.67,165.23ZM721.55,224h1.3q11.85,0,19.59-6.64V207.8H719.53q-9.43,0-9.44,7.81Q710.09,224,721.55,224Z" />
      <path d="M819.13,165.23q12.55,0,18.94,7.61t6.38,20.64v5.07H826.61v-1.43q0-15-14.19-15.1-7.74,0-11.78,4.56t-4,13.08v36.91H777.4V167.24H796l.65,9.57L795,181a32.22,32.22,0,0,1,9.34-11.69Q809.81,165.23,819.13,165.23Z" />
      <path d="M912.47,141.92h19.21v94.65H912.86l-.26-9.76q-8.13,11.72-24.15,11.78-15.5,0-24.93-10.48t-9.44-26.17q0-15.63,9.44-26.1t24.93-10.48q18,0,27.47,16.79l-3.45-6.9Zm-19.59,79.87q9,0,14.45-5.53t5.4-14.35q0-8.82-5.4-14.36T892.88,182q-9.18,0-14.55,5.53T873,201.91q0,8.82,5.37,14.35T892.88,221.79Z" />
    </svg>
  );
};
