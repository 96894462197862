import * as yup from "yup";

const REQUIRED_MESSAGE = "campo obrigatório";

export default yup.object({
    description: yup.string().nullable().trim().required(REQUIRED_MESSAGE),
    externalReferenceDescription: yup
        .string()
        .trim()
        .when("externalReferenceType", {
            is: "vehicle",
            then: yup.string().nullable().trim().required(REQUIRED_MESSAGE),
        })
        .when("externalReferenceType", {
            is: "driver",
            then: yup.string().nullable().trim().required(REQUIRED_MESSAGE),
        }),
});
