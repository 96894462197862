import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@material-ui/core";
import { useUserContext } from "@hooks/UserContext";
import { useIoCContext } from "@hooks/IoCContext";
import {
  Document,
  DocumentInitialValues,
} from "../../../client/Document/Document";
import { UploadClient } from "../../../client/upload/UploadClient";
import { Types } from "@ioc/types";
import { DocumentClient } from "../../../client/Document/DocumentClient";
import { AxiosError } from "axios";
import Message from "@components/Message";
import EditForm from "@pages/Documents/Edit/Form";
import { Formik } from "formik";
import AppError from "@utils/AppError";
import * as yup from "yup";

interface EditDocumentProps {
  id?: string;
  open: boolean;
  onClose: () => void;
}

export const EditValidationSchema = yup.object({
  name: yup.string().nullable().trim().required("campo obrigatório"),
});

const DocumentEdit: React.FC<EditDocumentProps> = (props) => {
  const { id, open, onClose } = props;
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [document, setDocument] = useState<Document>(DocumentInitialValues);
  const [documentName, setDocumentName] = useState<string | undefined>();
  const [preview, setPreview] = useState<string | undefined>();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();

  const uploadClient = iocContext.serviceContainer.get<UploadClient>(
    Types.Upload
  );

  const documentClient = iocContext.serviceContainer.get<DocumentClient>(
    Types.Document
  );

  const handleClose = () => {
    onClose();
    setDocument(DocumentInitialValues);
    setFile(undefined);
    setPreview(undefined);
    setDocumentName(undefined);
  };

  const handleSelectedFile = (file: File) => {
    setFile(file);
    if (file.type.indexOf("image") > -1) {
      setPreview(URL.createObjectURL(file));
    } else {
      setPreview("unknown_file_extension.webp");
    }
    setDocumentName(file.name);
  };

  const update = async (
    companyId: string,
    documentId: string,
    document: Document
  ) => {
    try {
      await documentClient.update(companyId, documentId, document);
    } catch (error) {
      setError(true);
      setErrorMessage("Não foi possível atualizar o documento.");
    }
  };

  const handleSubmit = async (event: Document) => {
    setLoading(true);
    try {
      if (selectedCompany) {
        if (id) {
          if (file) {
            const result = await uploadClient.upload(
              selectedCompany.uuid,
              file
            );
            await update(selectedCompany.uuid, id, {
              ...event,
              path: result.location,
              extension: result.extension,
            });
          } else {
            await update(selectedCompany.uuid, id, event);
          }
        }
      } else {
        throw new AppError("company_id is undefined", "error");
      }
      setSuccess(true);
      handleClose();
    } catch (error) {
      if (error instanceof AppError) {
        setErrorMessage(error.message);
      }
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const catchError = (error: AxiosError) => {
    setErrorMessage(error.response?.data?.message);
    setError(true);
    setLoading(false);
  };

  const fetchDocument = () => {
    if (!selectedCompany) return;
    if (!id) return;
    setLoading(true);
    if (open) {
      documentClient
        .findById(selectedCompany.uuid, id)
        .then((doc) => {
          setDocument(doc);
          setLoading(false);
        })
        .catch(catchError);
    }
  };

  useEffect(fetchDocument, [open]);

  return (
    <>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Editar Documentos</DialogTitle>
        <DialogContent>
          {loading ? (
            <>
              <LinearProgress color="primary" />
              <div style={{ height: 20 }} />
            </>
          ) : (
            <></>
          )}
          <Formik
            onSubmit={(event) => handleSubmit(event as Document)}
            enableReinitialize
            initialValues={document}
            validationSchema={EditValidationSchema}
            validateOnBlur={false}
            validateOnChange={false}
          >
            <EditForm
              onClose={handleClose}
              onSelectedFile={handleSelectedFile}
              documentName={documentName}
              preview={preview}
              loading={loading}
            />
          </Formik>
        </DialogContent>
      </Dialog>
      <Message.Error open={error} onClose={() => setError(false)}>
        {errorMessage}
      </Message.Error>
    </>
  );
};

export default DocumentEdit;
