import { DataColumn } from "@components/DataTable";
import { Model } from "../../client/manufacturer/Manufacturer";

export const ColumnDefinition: DataColumn[] = [
  {
    field: "plate",
    headerName: "Placa",
  },
  {
    field: "model",
    headerName: "Marca/Modelo",
    render: (model: Model) => {
      return model?.manufacturer?.description + " " + model?.description;
    },
  },
];
