import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    container: {
      display: "flex",
      height: "100%",
      transition: "all 0.5s",
    },
    columnContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      minWidth: "1210px",
      overflowY: "auto",
      overflowX: "hidden",
    },
    contentFlex: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    contentContainer: {
      padding: "20px",
      backgroundColor: "#FFF",
      borderRadius: "4px",
      webkitBoxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    },
  })
);
