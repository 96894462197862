import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { parse } from "date-fns";
import { inject, injectable } from "inversify";
import { IReportNewUsersDTO } from "../dtos/IReportNewUsersDTO";
import { IReportNewUsersService } from "../models/IReportNewUsersService";

@injectable()
export class ReportNewUsersService implements IReportNewUsersService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    page: number;
    limit: number;
  }): Promise<IReportNewUsersDTO> {
    const resp = await this.httpInstance.get<IReportNewUsersDTO>(
      `/companies/${data.companyID}/report/card`,
      {
        params: {
          page: data.page,
          limit: data.limit,
        },
      }
    );

    resp.result = resp.result.map((report) => {
      // @ts-ignore
      const date = report.date + " " + report.hour;

      return {
        ...report,
        id: report.user_id,
        date: parse(
          (date as unknown) as string,
          "dd/MM/yyyy HH:mm",
          new Date()
        ),
      };
    });

    return resp;
  }
}
