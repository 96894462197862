export const ReportTypes = {
  IReportDebtService: Symbol("IReportDebtService"),
  IDownloadReportDebtService: Symbol("IDownloadReportDebtService"),
  IReportCreditService: Symbol("IReportCreditService"),
  IReportNewUsersService: Symbol("IReportNewUsersService"),
  IDownloadReportCreditService: Symbol("IDownloadReportCreditService"),
  IDownloadReportNewUsersService: Symbol("IDownloadReportNewUsersService"),
  IReportBalanceService: Symbol("IReportBalanceService"),
  IListRechargesByUserService: Symbol("IListRechargesByUserService"),
  IDownloadReportBalanceService: Symbol("IDownloadReportBalanceService"),
  IListVehicleModelsByCompanyService: Symbol(
    "IListVehicleModelsByCompanyService"
  ),
  IReportVehicleRankingService: Symbol("IReportVehicleRankingService"),
  IDownloadReportVehicleRankingService: Symbol(
    "IDownloadReportVehicleRankingService"
  ),
  IReportDriverPenaltiesService: Symbol("IReportDriverPenaltiesService"),
  IDownloadReportDriversPenaltiesService: Symbol(
    "IDownloadReportDriversPenaltiesService"
  ),
  IReportVehiclesPenaltiesService: Symbol("IReportVehiclesPenaltiesService"),
  IDownloadReportVehiclesPenaltiesService: Symbol(
    "IDownloadReportVehiclesPenaltiesService"
  ),
  IChangeKMDebtReportService: Symbol("IChangeKMDebtReportService"),
};
