import { ErrorLoading } from "@components/ErrorLoading";
import { ReloadButton } from "@components/ReloadButton";
import { StyledButtonActions } from "@components/StyledDialog/StyledButtonActions";
import { useIoCContext } from "@hooks/IoCContext";
import { useUserContext } from "@hooks/UserContext";
import { Types } from "@ioc/types";
import {
  Checkbox,
  CircularProgress,
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { IUserAutocomplete } from "@modules/company/dtos/IAutocompleteUsersDTO";
import { IFleetFuelDTO } from "@modules/company/dtos/IFleetFuelDTO";
import { IListBrandVehicleDTO } from "@modules/company/dtos/IListBrandVehicleDTO";
import { IAutocompleteUsersQueryService } from "@modules/company/models/IAutocompleteUsersQueryService";
import { IListBrandVehicleService } from "@modules/company/models/IListBrandVehicleService";
import { IListFleetFuelService } from "@modules/company/models/IListFleetFuelService";
import { IListModelVehicleService } from "@modules/company/models/IListModelVehicleService";
import { IFilterUsersDTO } from "@modules/user/dtos/IFilterUsersDTO";
import { IGetFiltersUserService } from "@modules/user/models/IGetFiltersUserService";
import { Field, Form, useFormikContext } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { BlockEquipmentTime } from "./BlockEquipmentTime";
import { IFormEquipment } from "./index";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      sectionTitle: {
        fontSize: "2rem",
        fontWeight: typography.fontWeightBold,
      },
      sectionSubtitle: {
        fontSize: "1.6rem",
        color: theme.palette.tertiary.textButtons.primary,
      },
      tooltipHelp: {
        fontSize: "1.6rem",
      },
      containerHelpIcon: {
        marginRight: "2rem",
      },
    })
);

const FormEquipment: React.FC = () => {
  const classes = useStyles();
  const iocContext = useIoCContext();
  const userContext = useUserContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
    defaultMatches: true,
  });
  const {
    values,
    setFieldValue,
    errors,
    touched,
    isSubmitting,
  } = useFormikContext<IFormEquipment>();

  const getVehicleBrandService = iocContext.serviceContainer.get<IListBrandVehicleService>(
    Types.Company.IListBrandVehicleService
  );
  const getFiltersUserService = iocContext.serviceContainer.get<IGetFiltersUserService>(
    Types.User.IGetFiltersUserService
  );
  const autocompleteUsersService = iocContext.serviceContainer.get<IAutocompleteUsersQueryService>(
    Types.Company.IAutocompleteUsersQueryService
  );
  const listFuelService = iocContext.serviceContainer.get<IListFleetFuelService>(
    Types.Company.IListFleetFuelService
  );
  const getVehicleModelService = iocContext.serviceContainer.get<IListModelVehicleService>(
    Types.Company.IListModelVehicleService
  );

  const [loadingVehicleBrand, setLoadingVehicleBrand] = useState(true);
  const [errorLoadingVehicleBrand, setErrorLoadingVehicleBrand] = useState(
    false
  );
  const [vehicleBrands, setVehicleBrands] = useState<IListBrandVehicleDTO[]>(
    []
  );
  const [loadingFilters, setLoadingFilters] = useState(true);
  const [errorLoadingFilters, setErrorLoadingFilters] = useState(false);
  const [filters, setFilters] = useState<IFilterUsersDTO | null>(null);
  const [inputQuery, setInputQuery] = useState("");
  const [loadingInputQuery, setLoadingInputQuery] = useState(false);
  const [usersFinded, setUsersFinded] = useState<IUserAutocomplete[]>([]);
  const [listFuels, setListFuels] = useState<IFleetFuelDTO[]>([]);
  const [loadingFuels, setLoadingFuels] = useState(true);
  const [errorLoadingFuels, setErrorLoadingFuels] = useState(false);
  const [openBlockTimeHelp, setOpenBlockTimeHelp] = useState(false);

  const fetchVehicleBrands = useCallback(async () => {
    try {
      setLoadingVehicleBrand(true);
      const vehicleBrands = await getVehicleBrandService.execute({
        type: values.vehicleType,
      });
      setVehicleBrands(vehicleBrands);
      if (vehicleBrands.length) {
        setFieldValue("brand", vehicleBrands[0].code);
      }
      setErrorLoadingVehicleBrand(false);
    } catch (error) {
      setErrorLoadingVehicleBrand(true);
    } finally {
      setLoadingVehicleBrand(false);
    }
  }, [getVehicleBrandService, setFieldValue, values.vehicleType]);

  const fetchFilters = useCallback(async () => {
    if (!userContext.selectedCompany) return;
    try {
      setLoadingFilters(true);
      const filters = await getFiltersUserService.execute({
        companyID: userContext.selectedCompany.id,
      });
      setFilters(filters);
      setErrorLoadingFilters(false);
    } catch (error) {
      setErrorLoadingFilters(true);
    } finally {
      setLoadingFilters(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFiltersUserService, userContext.selectedCompany]);

  const fetchVehicleModels = useCallback(async () => {
    try {
      const vehicleModels = await getVehicleModelService.execute({
        brandCode: (values.brand as unknown) as number,
      });
      if (vehicleModels.length) {
        setFieldValue("model", vehicleModels[0].id);
      }
    } catch (error) {
    } finally {
    }
  }, [getVehicleModelService, setFieldValue, values.brand]);

  const fetchAutocomplete = useCallback(
    async (query: string) => {
      if (!userContext.selectedCompany) return;
      try {
        setLoadingInputQuery(true);
        const queryResult = await autocompleteUsersService.execute({
          companyID: userContext.selectedCompany.uuid,
          query,
        });

        setUsersFinded(queryResult.result);
      } catch (error) {
      } finally {
        setLoadingInputQuery(false);
      }
    },
    [autocompleteUsersService, userContext.selectedCompany]
  );

  const fetchFuels = useCallback(async () => {
    try {
      setLoadingFuels(true);
      const fuels = await listFuelService.execute();
      setListFuels(fuels);
      setErrorLoadingFuels(false);
    } catch (error) {
      setErrorLoadingFuels(true);
    } finally {
      setLoadingFuels(false);
    }
  }, [listFuelService]);

  useEffect(() => {
    fetchVehicleBrands();
    fetchFilters();
    fetchFuels();
  }, [fetchFilters, fetchFuels, fetchVehicleBrands]);

  useEffect(() => {
    fetchVehicleModels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.brand]);

  useEffect(() => {
    fetchAutocomplete(inputQuery);
  }, [fetchAutocomplete, inputQuery]);

  const blockEquipment = [
    {
      name: "Bloquear abastecimento por tipo de combustível",
      id: 1,
      help:
        "Abastecimento/Venda permitido somente para os combustíveis (produtos) marcados no item Tipo de Combustível no cadastro do equipamento",
    },
    {
      name: "Bloquear abastecimento por capacidade do tanque",
      id: 2,
      help:
        "Abastecimento/Venda permitido somente com a quantidade de litros informada no ato da venda menor ou igual a capacidade do tanque cadastrada no equipamento",
    },
    { name: "Bloquear abastecimento por tempo", id: 4 },
  ];

  return (
    <Form id="equipmentForm">
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          container
          justify={isMobile ? "center" : "flex-start"}
        >
          <FormControl component="fieldset" color="primary">
            <FormLabel component="legend">Status do veículo</FormLabel>
            <RadioGroup
              row={!isMobile}
              name="vehicleStatus"
              value={values.vehicleStatus}
              onChange={(event) =>
                setFieldValue("vehicleStatus", event.target.value)
              }
            >
              <FormControlLabel
                value="ATIVO"
                control={<Radio color="primary" />}
                label="Ativo"
              />
              <FormControlLabel
                value="MANUTENÇÃO"
                control={<Radio color="primary" />}
                label="Manutenção"
              />
              <FormControlLabel
                value="INATIVO"
                control={<Radio color="primary" />}
                label="Inativo"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={3} container alignItems="center">
          {errorLoadingVehicleBrand ? (
            <Grid container direction="column" alignItems="center">
              <ErrorLoading messageError="Ocorreu um erro ao baixar as marcas de veículos" />
              <ReloadButton
                reloading={loadingVehicleBrand}
                reloadFunc={fetchVehicleBrands}
              />
            </Grid>
          ) : (
            <Field
              as={TextField}
              name="brand"
              label="Tipo de equipamento"
              select
              fullWidth
              SelectProps={{
                disabled: loadingVehicleBrand,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {loadingVehicleBrand && <CircularProgress size={25} />}
                  </InputAdornment>
                ),
              }}
            >
              {vehicleBrands.map((brand) => (
                <MenuItem key={brand.id} value={brand.code}>
                  {brand.name}
                </MenuItem>
              ))}
            </Field>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={3} container alignItems="center">
          <Field
            as={TextField}
            name="identification"
            label="Identificação"
            placeholder="Informe a identificação do equipamento"
            fullWidth
            error={touched.identification && !!errors.identification}
            helperText={touched.identification && errors.identification}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} container alignItems="center">
          <Field
            as={TextField}
            name="patrimony"
            label="Patrimônio"
            placeholder="Insira o número de patrimônino"
            fullWidth
            error={touched.patrimony && !!errors.patrimony}
            helperText={touched.patrimony && errors.patrimony}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} container alignItems="center">
          <Field
            as={TextField}
            name="yearManufacture"
            label="Ano de fabricação"
            placeholder="Informe o ano de fabricação"
            fullWidth
            inputProps={{
              inputmode: "numeric",
              pattern: "[0-9]*",
            }}
            error={touched.yearManufacture && !!errors.yearManufacture}
            helperText={touched.yearManufacture && errors.yearManufacture}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} container alignItems="center">
          <Field
            as={TextField}
            name="yearModel"
            label="Ano do modelo"
            placeholder="Informe o ano"
            fullWidth
            inputProps={{
              inputmode: "numeric",
              pattern: "[0-9]*",
            }}
            error={touched.yearModel && !!errors.yearModel}
            helperText={touched.yearModel && errors.yearModel}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} container alignItems="center">
          <Field
            as={TextField}
            name="capacity"
            label="Capacidade do tanque"
            fullWidth
            inputProps={{
              inputmode: "numeric",
              pattern: "[0-9]*",
            }}
            type="number"
            error={touched.capacity && !!errors.capacity}
            helperText={touched.capacity && errors.capacity}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} container alignItems="center">
          {errorLoadingFilters ? (
            <Grid container direction="column" alignItems="center">
              <ErrorLoading messageError="Ocorreu um erro ao baixar departamentos" />
              <ReloadButton
                reloading={loadingFilters}
                reloadFunc={fetchFilters}
              />
            </Grid>
          ) : (
            <Field
              as={TextField}
              name="departmentID"
              label="Departamento"
              fullWidth
              select
              inputProps={{
                inputmode: "numeric",
                pattern: "[0-9]*",
              }}
              SelectProps={{
                disabled: loadingFilters,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {loadingFilters && <CircularProgress size={25} />}
                  </InputAdornment>
                ),
              }}
              error={touched.departmentID && !!errors.departmentID}
              helperText={touched.departmentID && errors.departmentID}
            >
              {filters?.departments.map((filter) => (
                <MenuItem key={filter.id} value={filter.id}>
                  {filter.tx_departamento}
                </MenuItem>
              ))}
            </Field>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={3} container alignItems="center">
          {errorLoadingFilters ? (
            <Grid container direction="column" alignItems="center">
              <ErrorLoading messageError="Ocorreu um erro ao baixar centros de custo" />
              <ReloadButton
                reloading={loadingFilters}
                reloadFunc={fetchFilters}
              />
            </Grid>
          ) : (
            <Field
              as={TextField}
              name="costCenterID"
              label="Centor de custos"
              fullWidth
              select
              inputProps={{
                inputmode: "numeric",
                pattern: "[0-9]*",
              }}
              SelectProps={{
                disabled: loadingFilters,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {loadingFilters && <CircularProgress size={25} />}
                  </InputAdornment>
                ),
              }}
              error={touched.costCenterID && !!errors.costCenterID}
              helperText={touched.costCenterID && errors.costCenterID}
            >
              {filters?.costCenters.map((costCenter) => (
                <MenuItem key={costCenter.id} value={costCenter.id}>
                  {costCenter.tx_centro_custo}
                </MenuItem>
              ))}
            </Field>
          )}
        </Grid>
        <Grid item xs={12} sm={6} container alignItems="center">
          <Autocomplete
            options={usersFinded}
            getOptionLabel={(option) => option.name}
            loading={loadingInputQuery}
            fullWidth
            noOptionsText={`Nenhum resultado encontrado para "${inputQuery}"`}
            loadingText={"Buscando usuários..."}
            value={values.userSelected}
            onChange={(event, value) => {
              setFieldValue("userSelected", value);
            }}
            onInputChange={(event, newInputValue) => {
              setInputQuery(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadingInputQuery ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                label="Buscar usuário/cartão por nome/doc. ou número"
                fullWidth
                error={touched.userSelected && !!errors.userSelected}
                helperText={touched.userSelected && errors.userSelected}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} container alignItems="center">
          <FormControl
            component="fieldset"
            style={{ width: "100%" }}
            error={!!errors.fuelSelecteds}
          >
            <FormLabel component="legend">Tipo de combustível</FormLabel>
            <FormGroup row={!isMobile}>
              {loadingFuels ? (
                [
                  "Arla",
                  "Gasolina Comum",
                  "Etanol",
                  "Gasolina Aditivada",
                  "Óleo Diesel Comum",
                  "Óleo Diesel S-10",
                ].map((fuel) => (
                  <Skeleton style={{ margin: "0.5rem" }}>
                    <FormControlLabel
                      key={fuel}
                      control={<Checkbox color="primary" />}
                      label={fuel}
                    />
                  </Skeleton>
                ))
              ) : errorLoadingFuels ? (
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <ErrorLoading messageError="Ocorreu um erro ao baixar lista de combustíveis" />
                  <ReloadButton
                    reloading={loadingFuels}
                    reloadFunc={fetchFuels}
                  />
                </Grid>
              ) : (
                listFuels.map((fuel) => {
                  return (
                    <FormControlLabel
                      key={fuel.id}
                      control={
                        <Checkbox
                          checked={values.fuelSelecteds[fuel.id]}
                          color="primary"
                          onChange={(e, checked) => {
                            const fuels = {
                              ...values.fuelSelecteds,
                              [fuel.id]: checked,
                            };

                            setFieldValue("fuelSelecteds", fuels);
                            setFieldValue("groupFuelSelected", fuel.group);

                            // verifica se nao ha nenhum grupo selecionado
                            const isSelected = Boolean(
                              Object.keys(fuels).find((key) => fuels[key])
                            );
                            if (!isSelected) {
                              setFieldValue("groupFuelSelected", null);
                            }
                          }}
                          disabled={
                            values.groupFuelSelected !== null &&
                            fuel.group !== values.groupFuelSelected
                          }
                        />
                      }
                      label={fuel.name}
                    />
                  );
                })
              )}
            </FormGroup>
            <FormHelperText error={!!errors.fuelSelecteds}>
              {errors.fuelSelecteds}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.sectionTitle}>
            Deseja bloquear esse equipamento?
          </Typography>
          <Typography className={classes.sectionSubtitle}>
            Adicione informações sobre o bloqueio do equipamento
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <FormControl
            component="fieldset"
            style={{ width: "100%" }}
            error={!!errors.blocksSelecteds}
          >
            <FormGroup row={!isMobile}>
              {blockEquipment.map((block) => {
                return (
                  <Grid container alignItems="center" style={{ width: "auto" }}>
                    <FormControlLabel
                      key={block.id}
                      control={
                        <Checkbox
                          checked={values.blocksSelecteds[block.id]}
                          color="primary"
                          onChange={(e, checked) =>
                            setFieldValue("blocksSelecteds", {
                              ...values.blocksSelecteds,
                              [block.id]: checked,
                            })
                          }
                        />
                      }
                      label={block.name}
                    />
                    <div className={classes.containerHelpIcon}>
                      {block.id === 1 || block.id === 2 ? (
                        <Tooltip
                          arrow
                          placement="right"
                          title={
                            <Typography className={classes.tooltipHelp}>
                              {block.help}
                            </Typography>
                          }
                        >
                          <IconButton size="small" color="primary">
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => {
                            switch (block.id) {
                              case 4:
                                return setOpenBlockTimeHelp(true);
                              default:
                                return;
                            }
                          }}
                        >
                          <InfoIcon />
                        </IconButton>
                      )}
                    </div>
                  </Grid>
                );
              })}
            </FormGroup>
            <FormHelperText error={!!errors.blocksSelecteds}>
              {errors.blocksSelecteds}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}></Grid>
        <Grid item xs={12} sm={6} md={3} container alignItems="center">
          <Field
            as={TextField}
            name="qtdHoursBlock"
            label="Informe a quantidade de horas para bloqueio"
            fullWidth
            inputProps={{
              inputmode: "numeric",
              pattern: "[0-9]*",
            }}
            error={touched.qtdHoursBlock && !!errors.qtdHoursBlock}
            helperText={touched.qtdHoursBlock && errors.qtdHoursBlock}
          />
        </Grid>

        <BlockEquipmentTime
          title={String(blockEquipment.find((block) => block.id === 4)?.name)}
          open={openBlockTimeHelp}
          closeFunc={() => setOpenBlockTimeHelp(false)}
          onClose={() => setOpenBlockTimeHelp(false)}
        />
      </Grid>
      <StyledButtonActions
        loading={isSubmitting}
        formID="equipmentForm"
        leftButtonLabel="Cancelar"
        rightButtonLabel="Salvar"
        styleButtons="info"
      />
    </Form>
  );
};

export { FormEquipment };
