import { appConfig } from "@config/appConfig";
import { Theme } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

export const formatCurrency = (value: number) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};

export const maxLengthString = (str: string, length: number) => {
  if (str.length > length) {
    return `${str.slice(0, length)}...`;
  } else {
    return str;
  }
};

export const getNumberOfNames = (str: string, numberOfNames: number) => {
  const names = str.split(" ");
  let fullName = [];
  let qtdNames = 0;
  for (let name of names) {
    if (qtdNames === numberOfNames) {
      break;
    }
    if (name.length > 2) {
      fullName.push(name);
      qtdNames += 1;
    }
  }
  return fullName.join(" ");
};

export const goToLogout = () => {
  window.location.href =
    process.env.NODE_ENV === "production"
      ? appConfig.keycloack.urlLogout.production
      : appConfig.keycloack.urlLogout.development;
};

export const calculateVerticalMargins = (element: HTMLElement) => {
  let margin = 0;
  const marginTop = parseInt(window.getComputedStyle(element).marginTop, 10);
  const marginBottom = parseInt(
    window.getComputedStyle(element).marginBottom,
    10
  );
  margin = marginTop + marginBottom;

  return margin;
};

export function performCPFMask(cpf: string): string {
  cpf = cpf.replace(/\D/g, "");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  return cpf;
}

export function performCreditCardMaks(cardNumber: string): string {
  cardNumber = cardNumber.replace(/\D/g, "");
  cardNumber = cardNumber.replace(/(\d{4})(\d)/, "$1 $2");
  cardNumber = cardNumber.replace(/(\d{4})(\d)/, "$1 $2");
  cardNumber = cardNumber.replace(/(\d{4})(\d)/, "$1 $2");
  return cardNumber;
}

export function maskVehiclePlateMercoSul(plate: string): string {
  const regexPlateMercoSul = new RegExp(/[a-zA-Z]{3}[0-9][a-zA-Z]\d{2}/g);
  plate = plate.replace(/\W/g, "");
  plate = plate.toUpperCase();
  if (regexPlateMercoSul.test(plate)) {
    return plate;
  } else {
    plate = plate.replace(/([a-zA-Z]{3})(.)/, "$1-$2");
    return plate;
  }
}

export const tableStyles = (theme: Partial<Theme>) =>
  createStyles({
    dataGridTable: {
      "& .rendering-zone": {
        borderRadius: "1rem",
        // @ts-ignore
        backgroundColor: theme.palette.type === "dark" ? "transparent" : "#fff",
      },
      "& .MuiDataGrid-columnsContainer": {
        borderBottom: "none",
      },
      "& .MuiDataGrid-cell": {
        borderBottom: "none !important",
      },
      "& .rendering-zone :last-child .MuiDataGrid-cell": {
        borderBottom: "none !important",
      },
      "& .MuiDataGrid-window": {
        overflowY: "hidden !important",
      },
      "& .MuiDataGrid-window::-webkit-scrollbar": {
        // @ts-ignore
        background: theme.palette.type === "dark" ? "#202022" : "#e0e0e0",
        borderRadius: "0.2rem",
        height: "0.8rem",
      },
      "& .MuiDataGrid-window::-webkit-scrollbar-thumb": {
        background: theme.palette?.primary.main,
        borderRadius: "0.4rem",
        border: `1px solid ${theme.palette?.primary.main}`,
      },
      "& .MuiDataGrid-row": {
        borderRadius: "1rem",
      },
      "& .MuiDataGrid-row > :last-child:not([data-field])": {
        display: "none",
      },
      "& .MuiDataGrid-root": {
        border: "none",
      },
    },
  });

export function performRGMask(rg: string): string {
  rg = rg.replace(/\D/g, "");
  rg = rg.replace(/(\d{2})(\d)/, "$1.$2");
  rg = rg.replace(/(\d{2})(\d)/, "$1.$2");
  rg = rg.replace(/(\d{2})(\d)/, "$1.$2");
  rg = rg.replace(/(\d{1})(\d{1,2})$/, "$1-$2");
  return rg;
}
