import React from "react";
import StyleSheet from "../../../../StyleSheet";
import { Form, useFormikContext } from "formik";
import { Document } from "../../../../client/Document/Document";
import { Row } from "@components/Row";
import { Skeleton } from "@material-ui/lab";
import { InputAdornment, TextField, Typography } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import Calendar from "@material-ui/icons/CalendarTodayOutlined";
import DragInDropBox from "@components/DragInDropBox";
import { Button } from "@components/Button";

interface EditFormProps {
  loading?: boolean;
  preview?: string;
  documentName?: string;
  onClose?: () => void;
  onSelectedFile?: (file: File) => void;
}

const EditForm: React.FC<EditFormProps> = (props) => {
  const { onClose, onSelectedFile, loading, preview, documentName } = props;
  const styleSheet = StyleSheet();
  const { values, errors, setFieldValue } = useFormikContext<Document>();

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Form noValidate>
      <Row>
        {loading ? (
          <Skeleton width={"70%"} />
        ) : (
          <TextField
            id="name"
            name="name"
            style={{ width: "70%" }}
            classes={{ root: styleSheet.textField }}
            required
            label="Nome"
            variant="outlined"
            onChange={(event) => setFieldValue("name", event.target.value)}
            value={values.name}
            inputProps={{ maxLength: 256 }}
            error={!!errors.name}
            helperText={errors.name}
            size="small"
          />
        )}
        {loading ? (
          <Skeleton width={"30%"} />
        ) : (
          <DatePicker
            id="expirationDate"
            name="expirationDate"
            label="Vencimento"
            format="dd/MM/yyyy"
            autoOk
            value={values.expirationDate}
            disablePast
            required
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            size="small"
            className={styleSheet.textField}
            style={{ width: "30%" }}
            error={!!errors.expirationDate}
            helperText={errors.expirationDate}
            TextFieldComponent={(props) => (
              <TextField
                placeholder="selecione uma data"
                {...props}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Calendar
                        style={{
                          color: "rgba(0, 0, 0, 0.23)",
                          width: "18px",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            onChange={(value) => setFieldValue("expirationDate", value)}
          />
        )}
      </Row>
      {loading ? (
        <Skeleton width="100%" height={200} />
      ) : preview ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Typography style={{ fontSize: 18 }}>{documentName}</Typography>
          <img src={preview} height={180} alt={documentName} />
        </div>
      ) : (
        <DragInDropBox
          helperText="Faça o upload de uma nova versão do documento"
          onChange={onSelectedFile}
        />
      )}
      <Row align="end">
        <Button color="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <div style={{ width: 6 }} />
        <Button color="primary" type="submit">
          Salvar
        </Button>
      </Row>
    </Form>
  );
};

export default EditForm;
