import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { DashboardCardProps } from "@components/CardInfo/DashboardCardProps";

export default makeStyles<Theme, Pick<{ color: string[] }, "color">>((theme) =>
  createStyles({
    paper: {
      padding: "2.4rem 4.8rem",
      flex: "1 1 30rem",
      margin: "1rem",
      position: "relative",
      "&::after": {
        position: "absolute",
        bottom: 0,
        left: 0,
        content: "' '",
        width: "100%",
        backgroundColor: ({ color }) => color[0],
        height: "0.5rem",
      },
    },
    container: {
      display: "flex",
      alignItems: "center",
      minWidth: "500px",
    },
    "container-avatar": {
      backgroundColor: ({ color }) => color[0],
      borderRadius: "6px",
      padding: "2px",
      marginTop: "-36px",
      marginRight: "1.6rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "container-avatar-icon": {
      fontSize: "4rem",
      color: ({ color }) => color[1],
    },
    "container-info": {
      fontWeight: "bold",
      fontSize: "3.2rem",
      marginBottom: "0.4rem",
    },
    "container-title": {
      fontSize: "1.6rem",
      color: "#b0c1e1",
      paddingBottom: "10px",
    },
    tooltip: {
      fontSize: "1.4rem",
    },
    "tooltip-button": {
      position: "absolute",
      right: "2rem",
      top: "1rem",
      opacity: 0,
      transition: "opacity 650ms",
    },
    "tooltip-icon": {
      color: theme.palette.grey[300],
    },
  })
);
