import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IListCostCentersDTO } from "../dtos/IListCostCentersDTO";
import { IListCostCentersService } from "../models/IListCostCentersService";

@injectable()
export class ListCostCentersService implements IListCostCentersService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;
  //TODO remove
  public async execute(data: {
    companyID: string;
  }): Promise<IListCostCentersDTO[]> {
    const resp = await this.httpInstance.get<IListCostCentersDTO[]>(
      `/companies/${data.companyID}/costcenters`
    );

    return resp.map((department) => {
      return {
        ...department,
        created_at: new Date(department.created_at),
      };
    });
  }
}
