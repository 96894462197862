import React from "react";
import { Document } from "../../client/Document/Document";
import {
  DocumentCard,
  Thumbnail,
} from "@components/DocumentManager/DocumentCard";

interface TemplateContainerProps {
  documents: Document[];
  thumbnails: Thumbnail[];
  onChange: () => void;
  onLoading: (loading: boolean) => void;
}

const TemplateContainer: React.FC<TemplateContainerProps> = (props) => {
  const { documents, thumbnails, onLoading, onChange } = props;
  return (
    <div
      style={{
        padding: "20px 10px",
        border: "solid 1px rgba(0, 0, 0, 0.1)",
        borderTop: "none",
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {documents.length === 0 ? (
        <div style={{ color: "red", fontStyle: "italic" }}>
          * Ainda não existem documentos nesta seção
        </div>
      ) : (
        documents.map((document) => {
          return (
            <DocumentCard
              document={document}
              thumbnails={thumbnails}
              onChange={onChange}
              onLoading={onLoading}
            />
          );
        })
      )}
    </div>
  );
};

export { TemplateContainer };
