import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IMarkAsReadPenaltyService } from "../models/IMarkAsReadPenaltyService";

@injectable()
export class MarkAsReadPenaltyService implements IMarkAsReadPenaltyService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    userID: string;
    penaltyID: string;
    companyID: string;
  }): Promise<void> {
    await this.httpInstance.post(
      `/companies/${data.companyID}/penalty-awarenesses`,
      {
        user_id: data.userID,
        penalty_id: data.penaltyID,
      }
    );
  }
}
