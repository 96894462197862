import React, { useEffect, useState } from "react";
import StyleSheet from "../StyleSheet";
import { Title } from "@components/Title/Title";
import { useHistory, useParams } from "react-router";
import { Box, StandardProps, Tab, Tabs } from "@material-ui/core";
import { TabPanelClassKey } from "@material-ui/lab/TabPanel/TabPanel";
import { Loading } from "@components/Loading";
import { useSnackbar } from "notistack";
import { useUserContext } from "@hooks/UserContext";
import { useIoCContext } from "@hooks/IoCContext";
import { VehicleClient } from "../../../client/vehicle/VehicleClient";
import { Types } from "@ioc/types";
import Vehicle, {
  VehicleDataInitialValues,
  VehicleDataPayload,
  VehicleGeneralInfoInitialValues,
  VehicleGeneralInfoPayload,
  vehicleToVehicleData,
  vehicleToVehicleGeneralInfo,
} from "../../../client/vehicle/Vehicle";
import { ROUTES } from "@config/routesConfig";
import Validation from "@pages/Vehicles/Edit/Data/Form/Validation";
import VehicleDataForm from "@pages/Vehicles/Edit/Data/Form";
import { Formik } from "formik";
import VehicleGeneralInfoForm from "@pages/Vehicles/Edit/General/Form";
import VehicleDocument from "@pages/Vehicles/Edit/Document";
import VehiclePermission from "@pages/Vehicles/Edit/Permission";

export interface TabPanelProps
  extends StandardProps<
    React.HTMLAttributes<HTMLDivElement>,
    TabPanelClassKey
  > {
  children?: React.ReactNode;
  value: number;
  index: number;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index } = props;
  const isSameIndex = value === index;
  return (
    <div
      id={`vehicle_tabpanel_${index}`}
      role="tabpanel"
      hidden={!isSameIndex}
      aria-labelledby={`vehicle_tab_${index}`}
    >
      {children}
    </div>
  );
};

const VehicleEdit: React.FC = () => {
  const styleSheet = StyleSheet();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const history = useHistory();
  const params = useParams<{ id?: string }>();
  const [title, setTitle] = useState("Veículo");
  const [vehicleId] = useState(params.id);
  const [vehicleDataPayload, setVehicleDataPayload] = useState<Vehicle>(
    VehicleDataInitialValues
  );
  const [
    vehicleGeneralInfoPayload,
    setVehicleGeneralInfoPayload,
  ] = useState<Vehicle>(VehicleGeneralInfoInitialValues);
  const [selectedTab, setSelectedTab] = useState<number>(vehicleId ? 1 : 0);
  const [loading, setLoading] = useState(false);

  const vehicleClient = iocContext.serviceContainer.get<VehicleClient>(
    Types.Vehicle
  );

  const handleChangeTab = (event: any, selectedTab: number) => {
    setSelectedTab(selectedTab);
  };

  const fetchVehicle = () => {
    if (!selectedCompany) return;
    if (!vehicleId) return;
    setLoading(true);
    vehicleClient
      .findById(selectedCompany.id, vehicleId)
      .then((result) => {
        setVehicleDataPayload(vehicleToVehicleData(result));
        setVehicleGeneralInfoPayload(vehicleToVehicleGeneralInfo(result));
        setTitle(
          `${result.model?.manufacturer?.description} ${result.model?.description}`
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar(error.response?.data?.message, { variant: "error" });
      });
  };

  useEffect(fetchVehicle, [selectedCompany, vehicleId, vehicleClient]);

  const handleVehicleDataSubmit = async (data: VehicleDataPayload) => {
    if (!selectedCompany) return;
    setLoading(true);
    try {
      const payload: Vehicle = {
        plate: data.plate,
        chassi: data.chassi,
        renavam: data.renavam,
        model: {
          id: data.modelId,
        },
        consumption: parseInt(String(data.consumption), 10),
        fuelCapacity: parseInt(String(data.fuelCapacity), 10),
        mileage: parseInt(String(data.mileage), 10),
        color: data.color,
        year: data.year,
      };
      if (!vehicleId) {
        await vehicleClient.save(selectedCompany.uuid, payload);
        history.push({
          pathname: ROUTES.VEHICLES,
          state: { success: true },
        });
      } else {
        await vehicleClient.update(selectedCompany.uuid, vehicleId, payload);
        enqueueSnackbar("Operação realizada com sucesso.", {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleVehicleGeneralInfoSubmit = async (
    data: VehicleGeneralInfoPayload
  ) => {
    if (!selectedCompany || !vehicleId) return;
    setLoading(true);
    try {
      const payload: Vehicle = {
        department: {
          id: data.departmentId,
          description: data.departmentDescription,
        },
        costCenter: {
          id: data.costCenterId,
          description: data.costCenterDescription,
        },
        prefix: data.prefix,
        patrimony: data.patrimony,
        observation: data.observation,
      };
      await vehicleClient.update(selectedCompany.uuid, vehicleId, payload);
      enqueueSnackbar("Operação realizada com sucesso.", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styleSheet.container}>
      <Title subtitles={vehicleId ? ["Editar"] : ["Novo"]}>{title}</Title>
      <Box style={{ width: "100%" }}>
        <Box
          style={{ borderBottom: 1, borderColor: "divider", marginBottom: 12 }}
        >
          <Tabs value={selectedTab} onChange={handleChangeTab}>
            <Tab
              id="vehicle_tab_1"
              label="Dados do veículo"
              aria-controls="vehicle_tabpanel_1"
            />
            {vehicleId ? (
              <Tab
                id="vehicle_tab_2"
                label="Informações gerais"
                aria-controls="vehicle_tabpanel_2"
              />
            ) : (
              <></>
            )}
            {vehicleId ? (
              <Tab
                id="vehicle_tab_3"
                label="Documentos"
                aria-controls="vehicle_tabpanel_3"
              />
            ) : (
              <></>
            )}
            {vehicleId ? (
              <Tab
                id="vehicle_tab_4"
                label="Permissões"
                aria-controls="vehicle_tabpanel_4"
              />
            ) : (
              <></>
            )}
          </Tabs>
        </Box>
        <TabPanel value={selectedTab} index={0}>
          <Formik
            onSubmit={(event) =>
              handleVehicleDataSubmit(event as VehicleDataPayload)
            }
            enableReinitialize
            initialValues={vehicleDataPayload}
            validationSchema={Validation}
            validateOnBlur={false}
            validateOnChange={false}
          >
            <VehicleDataForm />
          </Formik>
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Formik
            onSubmit={(event) =>
              handleVehicleGeneralInfoSubmit(event as VehicleGeneralInfoPayload)
            }
            enableReinitialize
            initialValues={vehicleGeneralInfoPayload}
            validateOnBlur={false}
            validateOnChange={false}
          >
            <VehicleGeneralInfoForm />
          </Formik>
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <VehicleDocument
            vehicle={title}
            plate={vehicleDataPayload.plate || ""}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={3}>
          <VehiclePermission
            vehicle={title}
            plate={vehicleDataPayload.plate || ""}
          />
        </TabPanel>
      </Box>
      <Loading open={loading} />
    </div>
  );
};

export default VehicleEdit;
