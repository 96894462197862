import {IFormManualRecharge} from "@components/DialogManualRecharge/IForm";
import React, {createContext, useContext, useState} from "react";

interface LayoutContextData {
    openLeftSideBar: boolean;
    openRightSideBar: boolean;
    openManualRecharge: IOpenModalRecharge;
    headerHeight: number;
    openComingSoonDialog: boolean;
    accessPenalties: boolean;
    loadingAccessPenalties: boolean;
    errorLoadingAccessPenalties: boolean;
    setOpenLeftSideBar: (data: boolean) => void;
    setOpenRightSideBar: (data: boolean) => void;
    setOpenManualRecharge: (data: IOpenModalRecharge) => void;
    setHeaderHeight: (data: number) => void;
    setOpenComingSoonDialog: (data: boolean) => void;
    setAccessPenalties: (data: boolean) => void;
    setLoadingAccessPenalties: (data: boolean) => void;
    setErrorLoadingAccessPenalties: (data: boolean) => void;
}

interface IDataModalRecharge
    extends Omit<IFormManualRecharge, "usersSelected"> {
    document: string;
}

interface IOpenModalRecharge {
    data: IDataModalRecharge | null;
    open: boolean;
}

const LayoutContext = createContext<LayoutContextData>({} as LayoutContextData);

const LayoutProvider: React.FC = ({children}) => {
    const [openLeftSideBar, setOpenLeftSideBar] = useState(false);
    const [openRightSideBar, setOpenRightSideBar] = useState(false);
    const [
        openManualRecharge,
        setOpenManualRecharge,
    ] = useState<IOpenModalRecharge>({open: false, data: null});
    const [headerHeight, setHeaderHeight] = useState(0);
    const [openComingSoonDialog, setOpenComingSoonDialog] = useState(false);
    const [loadingAccessPenalties, setLoadingAccessPenalties] = useState(false);
    const [accessPenalties, setAccessPenalties] = useState(false);
    const [
        errorLoadingAccessPenalties,
        setErrorLoadingAccessPenalties,
    ] = useState(false);

    return (
        <LayoutContext.Provider
            value={{
                openLeftSideBar,
                openRightSideBar,
                openManualRecharge,
                headerHeight,
                openComingSoonDialog,
                accessPenalties,
                loadingAccessPenalties,
                errorLoadingAccessPenalties,
                setOpenLeftSideBar,
                setOpenRightSideBar,
                setOpenManualRecharge,
                setHeaderHeight,
                setOpenComingSoonDialog,
                setAccessPenalties,
                setLoadingAccessPenalties,
                setErrorLoadingAccessPenalties,
            }}
        >
            {children}
        </LayoutContext.Provider>
    );
};

const useLayoutContext = (): LayoutContextData => {
    const context = useContext(LayoutContext);
    if (Object.values(context).length === 0) {
        throw new Error(
            "useLayoutContext deve ser utilizado dentro de um LayoutProvider"
        );
    }
    return context;
};

export {useLayoutContext, LayoutProvider};
