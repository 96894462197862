import { ReloadButton } from "@components/ReloadButton";
import { useAuth } from "@hooks/AuthContext";
import { useIoCContext } from "@hooks/IoCContext";
import { useLayoutContext } from "@hooks/LayoutContext";
import { useUserContext } from "@hooks/UserContext";
import { Types } from "@ioc/types";
import {
  Avatar,
  Badge,
  Button,
  Collapse,
  Grid,
  Hidden,
  IconButton,
  Menu,
  OutlinedTextFieldProps,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Skeleton } from "@material-ui/lab";
import { IExpiringLicenseDTO } from "@modules/company/dtos/IExpiringLicenseDTO";
import { IPenaltyDTO } from "@modules/company/dtos/IPenaltyDTO";
import { ISmallerBalanceDTO } from "@modules/company/dtos/ISmallerBalanceDTO";
import { IGetExpiringLicencesService } from "@modules/company/models/IGetExpiringLicencesService";
import { IListPenaltiesService } from "@modules/company/models/IListPenaltiesService";
import { IListSmallerBalancesService } from "@modules/company/models/IListSmallerBalancesService";
import AppError from "@utils/AppError";
import React, { useCallback, useEffect, useRef, useState } from "react";
import HeaderStyle from "./HeaderStyle";
import { DialogCNH } from "@components/Header/DialogCNH";
import { DialogBalance } from "@components/Header/DialogBalance";
import { DialogInfraction } from "@components/Header/DialogInfraction";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import { VerticalDivider } from "@components/VerticalDivider";
import { Flagcard } from "@components/Logo";

export interface IHeaderProps {
  showSearchBar?: boolean;
  searchBarProps?: Omit<OutlinedTextFieldProps, "variant">;
}

const Header: React.FC<IHeaderProps> = ({ searchBarProps }) => {
  const classes = HeaderStyle();
  const { companyInfo, selectedCompany } = useUserContext();
  const layoutContext = useLayoutContext();
  const iocContext = useIoCContext();
  const headerRef = useRef<HTMLElement | null>(null);
  const authContext = useAuth();

  const getExpiringLicensesService = iocContext.serviceContainer.get<IGetExpiringLicencesService>(
    Types.Company.IGetExpiringLicencesService
  );
  const getListSmallerBalancesService = iocContext.serviceContainer.get<IListSmallerBalancesService>(
    Types.Company.IListSmallerBalancesService
  );
  const getListPenaltiesService = iocContext.serviceContainer.get<IListPenaltiesService>(
    Types.Company.IListPenaltiesService
  );

  const [
    anchorElInfractions,
    setAnchorElInfractions,
  ] = useState<null | HTMLElement>(null);
  const [anchorElBalance, setAnchorElBalance] = useState<null | HTMLElement>(
    null
  );
  const [anchorElCNH, setAnchorElCNH] = useState<null | HTMLElement>(null);
  const [openModalInfractions, setOpenModalInfractions] = useState(false);
  const [openModalBalance, setOpenModalBalance] = useState(false);
  const [listExpiringLicenses, setListExpiringLicenses] = useState<
    IExpiringLicenseDTO[]
  >([]);
  const [loadingExpiringLiceses, setLoadingExpiringLiceses] = useState(false);
  const [
    errorLoadingExpiringLiceses,
    setErrorLoadingExpiringLiceses,
  ] = useState(false);
  const [loadingSmallerBalances, setLoadingSmallerBalances] = useState(false);
  const [
    errorLoadingSmallerBalances,
    setErrorLoadingSmallerBalances,
  ] = useState(false);
  const [listSmallerBalances, setListSmallerBalances] = useState<
    ISmallerBalanceDTO[]
  >([]);
  const [loadingPenalties, setLoadingPenalties] = useState(false);
  const [errorLoadingPenalties, setErrorLoadingPenalties] = useState(false);
  const [penalties, setPenalties] = useState<IPenaltyDTO[]>([]);

  const fetchExpiringLicenses = useCallback(async () => {
    if (!selectedCompany) return;
    try {
      setLoadingExpiringLiceses(true);
      const expiringLicences = await getExpiringLicensesService.execute({
        companyID: selectedCompany.uuid,
      });
      setListExpiringLicenses(expiringLicences);
      setErrorLoadingExpiringLiceses(false);
    } catch (error) {
      setErrorLoadingExpiringLiceses(true);
    } finally {
      setLoadingExpiringLiceses(false);
    }
  }, [getExpiringLicensesService, selectedCompany]);

  const fetchSmalerBalances = useCallback(async () => {
    if (!selectedCompany) return;
    try {
      setLoadingSmallerBalances(true);
      const smallerBalances = await getListSmallerBalancesService.execute({
        companyID: selectedCompany.uuid,
      });
      setListSmallerBalances(smallerBalances);
      setErrorLoadingSmallerBalances(false);
    } catch (error) {
      setErrorLoadingSmallerBalances(true);
    } finally {
      setLoadingSmallerBalances(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getListSmallerBalancesService,
    selectedCompany,
    layoutContext.openManualRecharge,
  ]);

  const fetchPenalties = useCallback(async () => {
    if (!selectedCompany) return;
    try {
      setPenalties([]);
      setLoadingPenalties(true);
      layoutContext.setLoadingAccessPenalties(true);
      const penalties = await getListPenaltiesService.execute({
        companyID: selectedCompany.uuid,
        userID: authContext.userID,
      });
      setPenalties(penalties);
      layoutContext.setAccessPenalties(true);
      setErrorLoadingPenalties(false);
      layoutContext.setErrorLoadingAccessPenalties(false);
    } catch (error) {
      if (error instanceof AppError) {
        if (error.code && error.code <= 400 && error.code >= 500) {
          setErrorLoadingPenalties(true);
          layoutContext.setErrorLoadingAccessPenalties(true);
          return;
        } else {
          return layoutContext.setAccessPenalties(false);
        }
      }
    } finally {
      layoutContext.setLoadingAccessPenalties(false);
      setLoadingPenalties(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany, authContext.userID]);

  useEffect(() => {
    fetchExpiringLicenses();
    fetchSmalerBalances();
    fetchPenalties();

    if (headerRef && headerRef.current) {
      layoutContext.setHeaderHeight(headerRef.current.offsetHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchExpiringLicenses, fetchPenalties, fetchSmalerBalances]);

  return (
    <header ref={headerRef} className={classes.header}>
      <div className={classes.menu}>
        <IconButton onClick={() => layoutContext.setOpenLeftSideBar(true)}>
          <MenuIcon htmlColor="#FFF" />
        </IconButton>
      </div>
      <div className={classes.logo}>
        <Flagcard />
      </div>
      {searchBarProps ? (
        <div className={classes["search-bar"]}>
          <TextField
            fullWidth
            variant="outlined"
            InputProps={{
              style: {
                height: 36,
                width: 300,
                backgroundColor: "#FFF",
                fontSize: 12,
              },
              startAdornment: <SearchIcon />,
              endAdornment: (
                <Collapse
                  in={Boolean(searchBarProps && searchBarProps.value)}
                  timeout={{
                    appear: 1000,
                    enter: 1000,
                    exit: 750,
                  }}
                >
                  <IconButton
                    onClick={() =>
                      searchBarProps &&
                      // @ts-ignore
                      searchBarProps.onChange({ target: { value: "" } })
                    }
                  >
                    <CloseIcon />
                  </IconButton>
                </Collapse>
              ),
            }}
            {...searchBarProps}
          />
        </div>
      ) : (
        <></>
      )}
      <Grid item container justify="flex-end">
        <div className={classes.infoContainer}>
          {/*<div>*/}
          {/*  {loadingPenalties ? (*/}
          {/*    <Skeleton>*/}
          {/*      <Button>Multas</Button>*/}
          {/*    </Skeleton>*/}
          {/*  ) : errorLoadingPenalties ? (*/}
          {/*    <ReloadButton*/}
          {/*      reloading={loadingPenalties}*/}
          {/*      reloadFunc={fetchPenalties}*/}
          {/*      tooltipProps={{*/}
          {/*        title: "Ocorreu um erro ao carregar multas",*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  ) : penalties.length <= 0 ? (*/}
          {/*    <></>*/}
          {/*  ) : (*/}
          {/*    <Badge*/}
          {/*      classes={{ badge: classes.badge }}*/}
          {/*      badgeContent={penalties.length}*/}
          {/*      max={9}*/}
          {/*    >*/}
          {/*      <Button*/}
          {/*        className={classes.button}*/}
          {/*        onClick={({ currentTarget }) => {*/}
          {/*          setOpenModalInfractions(!openModalInfractions);*/}
          {/*          setAnchorElInfractions(currentTarget);*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        Multas*/}
          {/*      </Button>*/}
          {/*    </Badge>*/}
          {/*  )}*/}
          {/*</div>*/}
          <div>
            {loadingSmallerBalances ? (
              <Skeleton>
                <Button>Saldos</Button>
              </Skeleton>
            ) : errorLoadingSmallerBalances ? (
              <ReloadButton
                reloading={loadingSmallerBalances}
                reloadFunc={fetchSmalerBalances}
                tooltipProps={{
                  title: "Ocorreu um erro ao carregar os saldos",
                }}
              />
            ) : listSmallerBalances.length > 0 ? (
              <Badge
                classes={{ badge: classes.badge }}
                badgeContent={listSmallerBalances.length}
                max={9}
              >
                <Button
                  className={classes.button}
                  onClick={({ currentTarget }) => {
                    setOpenModalBalance(!openModalBalance);
                    setAnchorElBalance(currentTarget);
                  }}
                >
                  Saldos
                </Button>
              </Badge>
            ) : (
              <></>
            )}
          </div>
          <div>
            {loadingExpiringLiceses ? (
              <Skeleton>
                <Button>CNH</Button>
              </Skeleton>
            ) : errorLoadingExpiringLiceses ? (
              <ReloadButton
                reloading={loadingExpiringLiceses}
                reloadFunc={fetchExpiringLicenses}
                tooltipProps={{
                  title: "Ocorreu um erro ao carregar CNHs",
                }}
              />
            ) : listExpiringLicenses.length > 0 ? (
              <Badge
                classes={{ badge: classes.badge }}
                badgeContent={listExpiringLicenses.length}
                max={9}
              >
                <Button
                  className={classes.button}
                  onClick={({ currentTarget }) => {
                    setAnchorElCNH(currentTarget);
                  }}
                >
                  CNH
                </Button>
              </Badge>
            ) : (
              <></>
            )}
          </div>
          <VerticalDivider padding={20} />
          <Typography
            className={classes.label}
            onClick={() => layoutContext.setOpenRightSideBar(true)}
          >
            {selectedCompany?.name}
          </Typography>

          <Avatar
            className={classes.avatar}
            onClick={() => layoutContext.setOpenRightSideBar(true)}
            src="https://image.flaticon.com/icons/png/512/1077/1077012.png"
          />
        </div>
      </Grid>
      <Hidden>
        <Menu
          style={{ maxHeight: 400 }}
          anchorEl={anchorElCNH}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          getContentAnchorEl={null}
          open={Boolean(anchorElCNH)}
          onClose={() => {
            setAnchorElCNH(null);
          }}
        >
          <div className={classes["card-container"]}>
            <Grid container alignItems="center" justify="space-between">
              <div className={classes["card-container-title"]}>
                <Typography className={classes["card-container-title-label"]}>
                  CARTEIRA NACIONAL DE HABILITAÇÃO
                </Typography>
                <IconButton
                  style={{ padding: 0, position: "absolute", right: 0 }}
                  onClick={() => {
                    setAnchorElCNH(null);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </Grid>
            <DialogCNH rows={listExpiringLicenses} />
          </div>
        </Menu>
      </Hidden>
      <Hidden>
        <Menu
          style={{ maxHeight: 400 }}
          anchorEl={anchorElBalance}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          getContentAnchorEl={null}
          open={Boolean(anchorElBalance)}
          onClose={() => {
            setOpenModalBalance(false);
            setAnchorElBalance(null);
          }}
        >
          <div className={classes["card-container"]}>
            <Grid container alignItems="center" justify="space-between">
              <div className={classes["card-container-title"]}>
                <Typography className={classes["card-container-title-label"]}>
                  USUÁRIOS COM SALDO BAIXO
                </Typography>
                <IconButton
                  style={{ padding: 0, position: "absolute", right: 0 }}
                  onClick={() => {
                    setOpenModalBalance(false);
                    setAnchorElBalance(null);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </Grid>
            <DialogBalance
              rows={listSmallerBalances}
              inMoney={Boolean(!!companyInfo?.in_money)}
              onClose={() => {
                setOpenModalBalance(false);
                setAnchorElBalance(null);
              }}
            />
          </div>
        </Menu>
      </Hidden>
      <Hidden>
        <Menu
          style={{ maxHeight: 400 }}
          anchorEl={anchorElInfractions}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          getContentAnchorEl={null}
          open={Boolean(anchorElInfractions)}
          onClose={() => {
            setOpenModalInfractions(false);
            setAnchorElInfractions(null);
          }}
        >
          <div className={classes["card-container"]}>
            <Grid container alignItems="center" justify="space-between">
              <div className={classes["card-container-title"]}>
                <Typography className={classes["card-container-title-label"]}>
                  MULTAS E INFRAÇÕES
                </Typography>
                <IconButton
                  style={{ padding: 0, position: "absolute", right: 0 }}
                  onClick={() => {
                    setOpenModalInfractions(false);
                    setAnchorElInfractions(null);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </Grid>
            <div style={{ height: 10 }}></div>
            <DialogInfraction rows={penalties} />
          </div>
        </Menu>
      </Hidden>
    </header>
  );
};

export { Header };
