import {ErrorLoading} from "@components/ErrorLoading";
import {NoData} from "@components/NoData";
import {Pagination} from "@components/Pagination";
import {ReloadButton} from "@components/ReloadButton";
import {Tag} from "@components/Tag";
import {TitleWithButtons} from "@components/TitleWithButtons";
import {useIoCContext} from "@hooks/IoCContext";
import {useLayoutContext} from "@hooks/LayoutContext";
import {useUserContext} from "@hooks/UserContext";
import {Types} from "@ioc/types";
import {
    createStyles,
    IconButton,
    LinearProgress,
    makeStyles,
    Tooltip,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import {ColDef, DataGrid, GridOverlay} from "@material-ui/data-grid";
import CachedIcon from "@material-ui/icons/Cached";
import InfoIcon from "@material-ui/icons/Info";
import {
    IListRechargesDTO,
    IRechageDTO,
} from "@modules/company/dtos/IListRechargesDTO";
import {IListRechargesService} from "@modules/company/models/IListRechargesService";
import {formatCurrency, tableStyles} from "@utils/index";
import {format} from "date-fns/esm";
import React, {useCallback, useEffect, useState} from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import {ModalInfoRecharge} from "./ModalInfoRecharge";
import {ModalReverseRecharge} from "./ModalReverseRecharge";
import {ModalUploadRecharge} from "./ModalUploadRecharge";
import {RechargeProvider, useRecharge} from "./RechargesContext";

const useStyles = makeStyles(
    ({typography: {pxToRem, ...typography}, ...theme}) =>
        createStyles({
            button: {
                fontWeight: typography.fontWeightBold,
                flex: "0 1 30rem",
            },
            title: {
                flex: "1 1 100%",
                [theme.breakpoints.up("lg")]: {
                    flex: "1 1 30rem",
                },
            },
            cell: {
                borderBottom: `1px solid ${theme.palette.tertiary.background.primary} !important`,
            },
            headerCell: {
                fontSize: pxToRem(16),
                fontWeight: typography.fontWeightMedium,
                color: theme.palette.tertiary.graphics[4],
            },
            buttonReverse: {
                backgroundColor: theme.palette.tertiary.error.primary,
                color: "#fff",
                "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                },
                "& svg": {
                    fontSize: "1.6rem",
                },
            },
            tableRecharges: tableStyles(theme).dataGridTable,
        })
);

function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{position: "absolute", top: 0, width: "100%", zIndex: 100}}>
                <LinearProgress/>
            </div>
        </GridOverlay>
    );
}

const Container: React.FC = () => {
    const classes = useStyles();
    const layoutContext = useLayoutContext();
    const userContext = useUserContext();
    const iocContext = useIoCContext();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
        defaultMatches: true,
    });
    const rechargesContext = useRecharge();

    const getRechargesService = iocContext.serviceContainer.get<IListRechargesService>(
        Types.Company.IListRechargesService
    );

    const [recharge, setRecharges] = useState<IListRechargesDTO>({
        page: 1,
        count: 0,
        result: [],
    });
    const [rechargeLoading, setRechargesLoading] = useState(false);
    const [rechargeErrorLoading, setRechargesErrorLoading] = useState(false);
    const [sizeTable, setSizeTable] = useState<null | number>(null);

    const fetchRecharges = useCallback(async () => {
        if (!userContext.selectedCompany) return;
        try {
            setRechargesLoading(true);

            const resp = await getRechargesService.execute({
                companyID: userContext.selectedCompany.uuid,
                page: rechargesContext.page,
                limit: rechargesContext.limit,
            });

            setRecharges(resp);
            setRechargesErrorLoading(false);
        } catch (error) {
            setRechargesErrorLoading(true);
        } finally {
            setRechargesLoading(false);
        }
    }, [
        getRechargesService,
        rechargesContext.limit,
        rechargesContext.page,
        userContext.selectedCompany,
    ]);

    useEffect(() => {
        fetchRecharges();
    }, [
        fetchRecharges,
        layoutContext.openManualRecharge,
        rechargesContext.reverseDialog.open,
    ]);

    useEffect(() => {
        if (rechargesContext.openModalUploadRecharges === false) {
            fetchRecharges();
        }
    }, [fetchRecharges, rechargesContext.openModalUploadRecharges]);

    const columns: ColDef[] = [
        {
            field: "info",
            headerName: " ",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 70,
            sortable: false,
            renderCell: (params) => {
                const row = params.row as IRechageDTO;
                return (
                    <Tooltip title="Visualizar dados da recarga">
                        <IconButton
                            color="primary"
                            onClick={() =>
                                rechargesContext.setOpenModalInfoRecharge({
                                    open: true,
                                    data: {
                                        rechargeID: row.id,
                                    },
                                })
                            }
                        >
                            <InfoIcon/>
                        </IconButton>
                    </Tooltip>
                );
            },
        },
        {
            field: "name",
            headerName: "Descrição",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 500,
            sortable: false,
        },
        {
            field: "owner_name",
            headerName: "Lançado por",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 350,
            sortable: false,
        },
        {
            field: "createdAt",
            headerName: "Data da efetivação",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 200,
            sortable: false,
            valueFormatter: (params) => {
                return format(params.row.createdAt, "dd/MM/yyyy HH:mm");
            },
        },
        {
            field: "total",
            headerName: "Total de usuários",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 120,
            sortable: false,
        },
        {
            field: "quantity",
            headerName: "Quantidade",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 200,
            sortable: false,
            valueFormatter: (params) => {
                const row = params.row as IRechageDTO;
                if (row.recharge_type === "in_money") {
                    return formatCurrency(row.quantity);
                } else {
                    return row.quantity.toLocaleString("pt-BR");
                }
            },
        },
        {
            field: "status",
            headerName: "Status",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 200,
            sortable: false,
            valueGetter: (params) => {
                const row = params.row as IRechageDTO;
                if (row.status === "recharged") {
                    return "Recarregado";
                } else {
                    return "Estornado";
                }
            },
            renderCell: (params) => {
                const status = params.getValue("status");
                return (
                    <Tag type={status === "Recarregado" ? "success" : "error"}>
                        {params.getValue(params.field)}
                    </Tag>
                );
            },
        },
        {
            field: "reversal",
            headerName: "Estorno",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 120,
            sortable: false,
            renderCell: (params) => {
                const row = params.row as IRechageDTO;
                return (
                    <Tooltip title="Estornar recarga">
                        <IconButton
                            className={classes.buttonReverse}
                            size="small"
                            onClick={() =>
                                rechargesContext.setReverseDialog({
                                    open: true,
                                    data: {
                                        date: row.createdAt,
                                        name: row.name,
                                        rechargeID: row.id,
                                        in_money: row.recharge_type === "in_money",
                                    },
                                })
                            }
                        >
                            <CachedIcon/>
                        </IconButton>
                    </Tooltip>
                );
            },
        },
    ];

    return (
        <>
            <TitleWithButtons
                title="Recarga de cartão"
                buttons={[
                    {
                        label: "Nova recarga manual",
                        buttonProps: {
                            onClick: () =>
                                layoutContext.setOpenManualRecharge({data: null, open: true}),
                            className: classes.button,
                            color: "primary",
                        },
                    },
                    {
                        label: "Nova recarga via excel",
                        buttonProps: {
                            onClick: () => rechargesContext.setOpenModalUploadRecharges(true),
                            className: classes.button,
                            color: "primary",
                        },
                    },
                ]}
            />

            {isMobile ? (
                <div
                    style={{
                        height: 500,
                        minHeight: 500,
                        width: "100%",
                        marginTop: "4rem",
                    }}
                    className={classes.tableRecharges}
                >
                    <DataGrid
                        rows={recharge.result}
                        columns={columns}
                        onPageSizeChange={(props) => {
                            rechargesContext.setLimit(props.pageSize);
                        }}
                        components={{
                            loadingOverlay: CustomLoadingOverlay,
                            pagination: ({pagination}) => (
                                <Pagination
                                    page={rechargesContext.page}
                                    rowCount={recharge.count}
                                    pageSize={pagination.pageSize}
                                    setPage={(value) => rechargesContext.setPage(value)}
                                />
                            ),
                            errorOverlay: () => {
                                return (
                                    <div
                                        style={{
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <ErrorLoading/>
                                        <ReloadButton
                                            reloadFunc={fetchRecharges}
                                            reloading={rechargeLoading}
                                        />
                                    </div>
                                );
                            },
                            noRowsOverlay: () => (
                                <GridOverlay>
                                    <NoData/>
                                </GridOverlay>
                            ),
                        }}
                        loading={rechargeLoading}
                        error={rechargeErrorLoading ? true : null}
                        scrollbarSize={5}
                        disableSelectionOnClick
                        autoPageSize
                    />
                </div>
            ) : (
                <div
                    style={{height: "100%", width: "100%"}}
                    className={classes.tableRecharges}
                >
                    <AutoSizer onResize={() => rechargesContext.setPage(1)}>
                        {({height, width}) => (
                            <div style={{height: sizeTable ? sizeTable : height, width}}>
                                <DataGrid
                                    rows={recharge.result}
                                    columns={columns}
                                    onPageSizeChange={(props) => {
                                        rechargesContext.setLimit(props.pageSize);
                                    }}
                                    components={{
                                        loadingOverlay: CustomLoadingOverlay,
                                        pagination: ({pagination}) => (
                                            <Pagination
                                                page={rechargesContext.page}
                                                rowCount={recharge.count}
                                                pageSize={pagination.pageSize}
                                                setPage={(value) => rechargesContext.setPage(value)}
                                                sizeTable={sizeTable}
                                                setSizeTable={(value) => setSizeTable(value)}
                                            />
                                        ),
                                        errorOverlay: () => {
                                            return (
                                                <div
                                                    style={{
                                                        height: "100%",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <ErrorLoading/>
                                                    <ReloadButton
                                                        reloadFunc={fetchRecharges}
                                                        reloading={rechargeLoading}
                                                    />
                                                </div>
                                            );
                                        },
                                        noRowsOverlay: () => (
                                            <GridOverlay>
                                                <NoData/>
                                            </GridOverlay>
                                        ),
                                    }}
                                    loading={rechargeLoading}
                                    error={rechargeErrorLoading ? true : null}
                                    scrollbarSize={5}
                                    disableSelectionOnClick
                                    autoPageSize
                                />
                            </div>
                        )}
                    </AutoSizer>
                </div>
            )}

            <ModalUploadRecharge/>
            <ModalInfoRecharge/>
            <ModalReverseRecharge/>
        </>
    );
};

const RechargesPage = () => {
    return (
        <RechargeProvider>
            <Container/>
        </RechargeProvider>
    );
};

export {RechargesPage};
