import { ErrorLoading } from "@components/ErrorLoading";
import { LoadingOverlayDataGrid } from "@components/LoadingOverlayDataGrid";
import { NoData } from "@components/NoData";
import { Pagination } from "@components/Pagination";
import { ReloadButton } from "@components/ReloadButton";
import { TitleWithButtons } from "@components/TitleWithButtons";
import { useIoCContext } from "@hooks/IoCContext";
import { useUserContext } from "@hooks/UserContext";
import { Types } from "@ioc/types";
import {
  CircularProgress,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ColDef, DataGrid, GridOverlay } from "@material-ui/data-grid";
import { IReportNewUsersDTO } from "@modules/reports/dtos/IReportNewUsersDTO";
import { IDownloadReportNewUsersService } from "@modules/reports/models/IDownloadReportNewUsersService";
import { IReportNewUsersService } from "@modules/reports/models/IReportNewUsersService";
import AppError from "@utils/AppError";
import { tableStyles } from "@utils/index";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      spacingButtonFilter: { marginTop: "2rem" },
      tableCreditReport: tableStyles(theme).dataGridTable,
      cell: {
        borderBottom: `1px solid ${theme.palette.tertiary.background.primary} !important`,
      },
      headerCell: {
        fontSize: pxToRem(16),
        fontWeight: typography.fontWeightMedium,
        color: theme.palette.tertiary.graphics[4],
      },
    })
);

const NewUsersReportPage: React.FC = () => {
  const classes = useStyles();
  const userContext = useUserContext();
  const iocContext = useIoCContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
    defaultMatches: true,
  });
  const { enqueueSnackbar } = useSnackbar();

  const newUsersReportService = iocContext.serviceContainer.get<IReportNewUsersService>(
    Types.Reports.IReportNewUsersService
  );
  const downloadNewUsersReportService = iocContext.serviceContainer.get<IDownloadReportNewUsersService>(
    Types.Reports.IDownloadReportNewUsersService
  );

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loadingNewUsersReport, setLoadingNewUsersReport] = useState(false);
  const [errorLoadingNewUsersReport, setErrorLoadingNewUsersReport] = useState(
    false
  );
  const [newUsersReport, setNewUsersReport] = useState<IReportNewUsersDTO>({
    pages: 1,
    count: 0,
    result: [],
  });
  const [
    loadingDownloadNewUsersReport,
    setLoadingDownloadNewUsersReport,
  ] = useState(false);
  const [sizeTable, setSizeTable] = useState<null | number>(null);

  const fetchNewUsersReport = useCallback(async () => {
    if (!userContext.selectedCompany) return;
    try {
      setLoadingNewUsersReport(true);

      const resp = await newUsersReportService.execute({
        companyID: userContext.selectedCompany.uuid,
        limit,
        page,
      });
      setNewUsersReport(resp);

      setErrorLoadingNewUsersReport(false);
    } catch (error) {
      setErrorLoadingNewUsersReport(true);
    } finally {
      setLoadingNewUsersReport(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.selectedCompany, limit, page]);

  const downloadNewUsersReport = useCallback(async () => {
    if (!userContext.selectedCompany) return;
    try {
      setLoadingDownloadNewUsersReport(true);

      const resp = await downloadNewUsersReportService.execute({
        companyID: userContext.selectedCompany.uuid,
        limit,
        page,
      });
      window.open(resp);
    } catch (error) {
      if (error instanceof AppError) {
        return enqueueSnackbar(error.message, { variant: error.variant });
      }
      enqueueSnackbar("Não foi possível efetuar o download", {
        variant: "error",
      });
    } finally {
      setLoadingDownloadNewUsersReport(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.selectedCompany, limit, page]);

  useEffect(() => {
    fetchNewUsersReport();
  }, [fetchNewUsersReport]);

  const columns: ColDef[] = [
    {
      field: "user",
      headerName: "Usuário",
      cellClassName: classes.cell,
      headerClassName: classes.headerCell,
      width: 700,
      sortable: false,
    },
    {
      field: "card",
      headerName: "Cartão",
      cellClassName: classes.cell,
      headerClassName: classes.headerCell,
      width: 450,
      sortable: false,
    },
    {
      field: "date",
      headerName: "Data e hora",
      cellClassName: classes.cell,
      headerClassName: classes.headerCell,
      width: 500,
      sortable: false,
      valueFormatter: (params) =>
        params.getValue(params.field)
          ? format(
              params.getValue(params.field) as Date,
              "dd/MM/yyyy 'às' HH:mm"
            )
          : "",
    },
  ];

  return (
    <>
      <TitleWithButtons
        title="Relatório de novos usuários"
        buttons={[
          {
            label: "Download",
            buttonProps: {
              onClick: () => {
                downloadNewUsersReport();
              },
              startIcon: loadingDownloadNewUsersReport && (
                <CircularProgress size={22} />
              ),
            },
          },
        ]}
      />

      {isMobile ? (
        <div
          style={{
            height: 500,
            minHeight: 500,
            width: "100%",
            marginTop: "4rem",
          }}
          className={classes.tableCreditReport}
        >
          <DataGrid
            rows={newUsersReport.result}
            columns={columns}
            onPageSizeChange={(props) => {
              setLimit(props.pageSize);
            }}
            components={{
              loadingOverlay: LoadingOverlayDataGrid,
              pagination: ({ pagination }) => (
                <Pagination
                  page={page}
                  rowCount={newUsersReport.count}
                  pageSize={pagination.pageSize}
                  setPage={(value) => setPage(value)}
                />
              ),
              errorOverlay: () => {
                return (
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <ErrorLoading />
                    <ReloadButton
                      reloadFunc={fetchNewUsersReport}
                      reloading={loadingNewUsersReport}
                    />
                  </div>
                );
              },
              noRowsOverlay: () => (
                <GridOverlay>
                  <NoData />
                </GridOverlay>
              ),
            }}
            loading={loadingNewUsersReport}
            error={errorLoadingNewUsersReport ? true : null}
            scrollbarSize={5}
            disableSelectionOnClick
            autoPageSize
          />
        </div>
      ) : (
        <div
          style={{
            height: "100%",
            width: "100%",
            marginTop: "4rem",
          }}
          className={classes.tableCreditReport}
        >
          <AutoSizer onResize={() => setPage(1)}>
            {({ height, width }) => (
              <div style={{ height: sizeTable ? sizeTable : height, width }}>
                <DataGrid
                  rows={newUsersReport.result}
                  columns={columns}
                  onPageSizeChange={(props) => {
                    setLimit(props.pageSize);
                  }}
                  components={{
                    loadingOverlay: LoadingOverlayDataGrid,
                    pagination: ({ pagination }) => (
                      <Pagination
                        page={page}
                        rowCount={newUsersReport.count}
                        pageSize={pagination.pageSize}
                        setPage={(value) => setPage(value)}
                        sizeTable={sizeTable}
                        setSizeTable={(value) => setSizeTable(value)}
                      />
                    ),
                    errorOverlay: () => {
                      return (
                        <div
                          style={{
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <ErrorLoading />
                          <ReloadButton
                            reloadFunc={fetchNewUsersReport}
                            reloading={loadingNewUsersReport}
                          />
                        </div>
                      );
                    },
                    noRowsOverlay: () => (
                      <GridOverlay>
                        <NoData />
                      </GridOverlay>
                    ),
                  }}
                  loading={loadingNewUsersReport}
                  error={errorLoadingNewUsersReport ? true : null}
                  scrollbarSize={5}
                  disableSelectionOnClick
                  autoPageSize
                />
              </div>
            )}
          </AutoSizer>
        </div>
      )}
    </>
  );
};

export { NewUsersReportPage };
