import { appConfig } from "./appConfig";

export interface IPropsVehicleEditPage {
  vehicleID: string;
}

export interface IPropsEquipmentsEditPage {
  equipmentID: string;
}

export interface IPropsDriversEditPage {
  driverID: string;
}

export const ROUTES = {
  DASHBOARD: "/",
  ESTABLISHMENTS: "/establishments",
  ESTABLISHMENTS_LOCATIONS: "/establishments/locations",
  ESTABLISHMENTS_PRICES: "/establishments/prices",
  REPORTS: "/reports",
  REPORTS_DEBTS: "/reports/debts",
  REPORTS_CREDITS: "/reports/credits",
  REPORTS_VEHICLE_RANKING: "/reports/vehicle-ranking",
  REPORTS_NEW_USERS: "/reports/new-users",
  REPORTS_BALANCE: "/reports/balance",
  RECHARGE: "/recharge",
  FOLDER: "/folders",
  FOLDER_ADD: "/folders/create",
  FOLDER_EDIT: (id?: string) =>
    id ? `/folders/edit/${id}` : "/folders/edit/:id",
  DOCUMENT: (folderId?: string) =>
    folderId ? `/folders/${folderId}/documents` : "/folders/:id/documents",
  HELP: "/help",
  USERS: "/users",
  DEPARTMENTS: "/departamentos",
  DEPARTMENTS_ADD: "/departamento",
  DEPARTMENTS_EDIT: (id?: string) =>
    id ? `/departamento/${id}` : "/departamento/:id",
  ACTIVITIES: "/funcoes",
  ACTIVITIES_ADD: "/funcao",
  ACTIVITIES_EDIT: (id?: string) => (id ? `/funcao/${id}` : "/funcao/:id"),
  COST_CENTERS: "/centro-custos",
  COST_CENTERS_ADD: "/centro-custo",
  COST_CENTERS_EDIT: (id?: string) =>
    id ? `/centro-custo/${id}` : "/centro-custo/:id",
  VEHICLES: "/veiculos",
  VEHICLES_ADD: "/veiculo",
  VEHICLES_EDIT: (id?: string) => (id ? `/veiculo/${id}` : "/veiculo/:id"),
  MAINTENANCE: "/manutencoes",
  MAINTENANCE_ADD: "/manutencao",
  MAINTENANCE_EDIT: (id?: string) =>
    id ? `/manutencao/${id}` : "/manutencao/:id",
  INFRACTION: "/multas",
  DEBITS: "/debitos",
  EQUIPMENTS: "/equipments",
  EQUIPMENTS_CREATE: "/equipments/create",
  EQUIPMENTS_EDIT: (data?: IPropsEquipmentsEditPage) =>
    data
      ? `/equipments/edit/${data.equipmentID}`
      : "/equipments/edit/:equipmentID",
  DRIVERS: "/drivers",
  FLEET_DASHBOARD: "/fleet/dashboard",
  DRIVERS_ADD: "/driver/create",
  DRIVERS_EDIT: (id?: string) =>
    id ? `/driver/edit/${id}` : "/driver/edit/:id",
  LOGOUT:
    process.env.NODE_ENV === "production"
      ? appConfig.keycloack.urlLogout.production
      : appConfig.keycloack.urlLogout.development,
};
