import BlockImg from "@assets/block_hours_diagram.png";
import { PermissiveTransactions } from "@components/PermissiveTransactions";
import { StyledDialog, StyledDialogProps } from "@components/StyledDialog";
import {
  createStyles,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      imgExample: {
        width: "100%",
      },
      titleExample: {
        fontSize: "2rem",
        fontWeight: typography.fontWeightBold,
      },
      dialogPaper: {
        minWidth: "95vw",
        [theme.breakpoints.up("sm")]: {
          minWidth: "45vw",
        },
      },
    })
);

interface IBlockEquipmentTimeProps extends StyledDialogProps {}

const BlockEquipmentTime: React.FC<IBlockEquipmentTimeProps> = ({
  ...props
}) => {
  const classes = useStyles();
  return (
    <StyledDialog {...props} classes={{ paper: classes.dialogPaper }}>
      <DialogContent style={{ paddingBottom: "3rem" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography align="justify">
              Abastecimento/Venda permitido somente se a quantidade de tempo (em
              horas) presente no cadastro do equipamento (campo Quantidade de
              Horas) estiver passado após o último abastecimento efetuado pelo
              equipamento.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.titleExample}>Exemplo</Typography>
            <Typography align="justify">
              Um equipamento tem em seu cadastro no item "Quantidade de Horas"
              igual a 5.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="justify">
              Suponha que este mesmo equipamento abasteceu as 10 horas em ponto.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="justify">
              Portanto, este equipamento só conseguirá abastecer novamente após
              as 15 horas, visto que em seu cadastro são permitidos novos
              abastecimentos somente após passar 5 horas em relação ao último
              abastecimento.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <img src={BlockImg} alt="exemplo" className={classes.imgExample} />
          </Grid>
          <Grid item xs={12}>
            <PermissiveTransactions />
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
};

export { BlockEquipmentTime };
