import {
  ButtonLinkWithName,
  IButtonLinkWithName,
} from "@components/ButtonLinkWithName";
import {
  ButtonLinkWithoutName,
  IButtonLinkWithoutName,
} from "@components/ButtonLinkWithoutName";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  Grid,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React, { useState } from "react";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      summary: {
        padding: 0,
        paddingRight: pxToRem(16),
      },
      buttonsWithoutName: {
        paddingRight: 0,
      },
      paper: {
        width: "100%",
      },
      summaryContent: {
        "&.Mui-expanded": {
          margin: 0,
        },
        margin: 0,
      },
      accordionRoot: {
        "&:before": {
          display: "none",
        },
        "&.Mui-expanded ": {
          margin: 0,
        },
      },
    })
);

interface Props {
  routes: {
    buttonLinkWithNameProps?: IButtonLinkWithName;
    ButtonLinkWithoutNameProps?: IButtonLinkWithoutName;
  }[];
  showExpandButton?: boolean;
  expand: boolean;
}

const ButtonLinkExpand: React.FC<Props> = ({
  routes,
  showExpandButton = true,
  expand,
  children,
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <Accordion
      expanded={expand || open}
      onChange={() => setOpen(!open)}
      elevation={0}
      className={classes.paper}
      classes={{
        root: classes.accordionRoot,
      }}
    >
      <AccordionSummary
        expandIcon={showExpandButton && <ExpandMoreIcon />}
        classes={{
          root: clsx(classes.summary, {
            [classes.buttonsWithoutName]: Boolean(
              routes.find((route) => route.ButtonLinkWithoutNameProps)
            ),
          }),
          content: classes.summaryContent,
        }}
      >
        {children}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          {routes.map((button) => {
            if (button.ButtonLinkWithoutNameProps) {
              return (
                <ButtonLinkWithoutName
                  key={button.ButtonLinkWithoutNameProps.route}
                  {...button.ButtonLinkWithoutNameProps}
                />
              );
            }
            if (button.buttonLinkWithNameProps) {
              return (
                <ButtonLinkWithName
                  key={button.buttonLinkWithNameProps.route}
                  {...button.buttonLinkWithNameProps}
                />
              );
            }
            return null;
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export { ButtonLinkExpand };
