import React, { useEffect, useState } from "react";
import { Title } from "@components/Title/Title";
import { useUserContext } from "@hooks/UserContext";
import { useIoCContext } from "@hooks/IoCContext";
import { Types } from "@ioc/types";
import { useParams } from "react-router";
import { FolderClient } from "../../../client/folder/FolderClient";
import StyleSheet from "./StyleSheet";
import { DocumentManager } from "@components/DocumentManager";
import { useSnackbar } from "notistack";
import { Button } from "@components/Button";
import { ROUTES } from "@config/routesConfig";
import { Row } from "@components/Row";

const DocumentList: React.FC = () => {
  const styleSheet = StyleSheet();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const params = useParams<{ id?: string }>();
  const [folderId] = useState(params.id);
  const [folderDescription, setFolderDescription] = useState<string>();

  const folderClient = iocContext.serviceContainer.get<FolderClient>(
    Types.Folder
  );

  const fetchFolder = () => {
    if (!selectedCompany) return;
    if (!folderId) return;
    folderClient
      .findById(selectedCompany.id, folderId)
      .then((result) => {
        setFolderDescription(result.description);
      })
      .catch((error) => {
        enqueueSnackbar(error.response?.data?.message, { variant: "error" });
      });
  };

  useEffect(fetchFolder, [selectedCompany, folderId, folderClient]);

  return (
    <div className={styleSheet.container}>
      <Title subtitles={["Documentos"]}>{folderDescription}</Title>
      <DocumentManager folderId={folderId} />
      <Row align="end">
        <Button to={ROUTES.FOLDER} color="secondary">
          Voltar
        </Button>
      </Row>
    </div>
  );
};

export default DocumentList;
