import { DriverClient } from "../DriverClient";
import { inject, injectable } from "inversify";
import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { Driver, DriverLegacy } from "../model/Driver";
import { format } from "date-fns/esm";
import { SearchDriver } from "../model/SearchDriver";
import { Filter } from "../model/Filter";

@injectable()
export class HttpDriverClient implements DriverClient {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public save(companyId: string, driver: Driver): Promise<Driver> {
    const payload = {
      tx_perfil: "USER",
      tx_matricula: driver.registration,
      tx_motorista: driver.driver,
      tx_cnh: driver.cnh,
      tx_tipo_cnh: driver.category,
      tx_data_expiracao_cnh: driver.expirationDate
        ? ((format(driver.expirationDate, "dd/MM/yyyy") as unknown) as Date)
        : undefined,
      tx_documento_secundario: driver.rg,
      tx_documento: driver.cpf,
      tx_telefone: driver.phoneNumber,
      id_funcao: driver.activityId,
      id_departamento: driver.departmentId,
      id_centro_custo: driver.costCenterId,
      cs_status: true,
    };
    return this.httpInstance.post(`/companies/${companyId}/drivers`, payload);
  }

  public update(
    companyId: string,
    driverId: string,
    driver: Driver
  ): Promise<void> {
    const payload = {
      tx_matricula: driver.registration,
      tx_motorista: driver.driver,
      tx_cnh: driver.cnh,
      tx_tipo_cnh: driver.category,
      tx_data_expiracao_cnh: driver.expirationDate
        ? ((format(driver.expirationDate, "dd/MM/yyyy") as unknown) as Date)
        : undefined,
      tx_documento_secundario: driver.rg,
      tx_documento: driver.cpf,
      tx_telefone: driver.phoneNumber,
      id_funcao: driver.activityId,
      id_departamento: driver.departmentId,
      id_centro_custo: driver.costCenterId,
      cs_status: driver.status,
      tx_password: driver.password,
    };
    return this.httpInstance.patch(
      `/companies/${companyId}/drivers/${driverId}`,
      payload
    );
  }

  public find(companyId: string, filter: Filter): Promise<SearchDriver> {
    return this.httpInstance
      .get(`/companies/${companyId}/drivers`, { params: filter })
      .then(
        (response: {
          page: number;
          pages: number;
          count: number;
          result: DriverLegacy[];
        }) => ({
          page: response.page || 1,
          pages: response.pages || 1,
          count: response.count || 0,
          result: response.result.map((driver) => ({
            id: driver.id,
            registration: driver.tx_matricula,
            driver: driver.tx_motorista,
            cnh: driver.tx_cnh,
            category: driver.tx_tipo_cnh,
            expirationDate: driver.tx_data_expiracao_cnh
              ? new Date(driver.tx_data_expiracao_cnh)
              : null,
            rg: driver.tx_documento_secundario,
            cpf: driver.tx_documento,
            phoneNumber: driver.tx_telefone,
            status: driver.cs_status,
            activityId: String(driver.id_funcao),
            departmentId: String(driver.id_departamento),
            costCenterId: String(driver.id_centro_custo),
          })),
        })
      );
  }

  public findById(companyId: string, driverId: string): Promise<Driver> {
    return this.httpInstance
      .get<DriverLegacy>(`/companies/${companyId}/drivers/${driverId}`)
      .then((result) => ({
        id: result.id,
        registration: result.tx_matricula,
        driver: result.tx_motorista,
        cnh: result.tx_cnh,
        category: result.tx_tipo_cnh,
        expirationDate: result.tx_data_expiracao_cnh
          ? new Date(result.tx_data_expiracao_cnh)
          : null,
        rg: result.tx_documento_secundario,
        cpf: result.tx_documento,
        phoneNumber: result.tx_telefone,
        status: result.cs_status,
        activityId: String(result.id_funcao),
        departmentId: String(result.id_departamento),
        costCenterId: String(result.id_centro_custo),
      }));
  }

  public remove(companyId: string, driverId: string): Promise<void> {
    return this.httpInstance.delete(
      `/companies/${companyId}/drivers/${driverId}`
    );
  }
}
