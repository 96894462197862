import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { Button } from "@components/Button";
import StyleSheet from "../../StyleSheet";
import { DataTable } from "@components/DataTable";
import { ColumnDefinition } from "@components/Lookup/ColumDefinition";
import { Types } from "@ioc/types";
import { useUserContext } from "@hooks/UserContext";
import { useIoCContext } from "@hooks/IoCContext";
import { VehicleClient } from "../../client/vehicle/VehicleClient";
import { SearchInitialValues } from "../../client/model/Search";
import Vehicle, { FilterVehicle } from "../../client/vehicle/Vehicle";

interface LookupVehiclesProps {
  open: boolean;
  onCancel: () => void;
  onSelect: (vehicle: Vehicle) => void;
}

const LookupVehicles: React.FC<LookupVehiclesProps> = (props) => {
  const styleSheet = StyleSheet();
  const { open, onCancel, onSelect } = props;
  const [query, setQuery] = useState("");
  const [plate, setPlate] = useState<string | undefined>(undefined);
  const [vehicles, setVehicles] = useState(SearchInitialValues);
  const [loading, setLoading] = useState(true);
  const limit = 8;
  const [page, setPage] = useState(0);
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();

  const vehicleClient = iocContext.serviceContainer.get<VehicleClient>(
    Types.Vehicle
  );

  const fetchVehicles = () => {
    if (!selectedCompany) return;
    setLoading(true);
    const filter: FilterVehicle = {
      page: page,
      limit: limit,
      plate: plate,
    };
    vehicleClient
      .find(selectedCompany.uuid, filter)
      .then((result) => {
        setVehicles(result);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFilter = (keyCode: string) => {
    if (keyCode === "Enter") {
      setPlate(query);
      setPage(0);
    }
  };

  const handleSelect = (vehicle: Vehicle) => {
    onSelect(vehicle);
  };

  useEffect(fetchVehicles, [plate, page, selectedCompany, open]);

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle disableTypography style={{ padding: "0px 8px" }}>
        <h2>Buscar Veículos</h2>
      </DialogTitle>
      <DialogContent style={{ padding: "8px" }}>
        <div
          style={{
            border: "solid 1px rgba(0, 0, 0, 0.05)",
            padding: "10px",
            borderRadius: "4px",
          }}
        >
          <TextField
            classes={{ root: styleSheet.textField }}
            style={{ width: "100%" }}
            focused={false}
            variant="outlined"
            placeholder="Pesquisar por placa"
            onChange={(event) => setQuery(event.target.value)}
            onKeyDown={(event) => handleFilter(event.key)}
            inputProps={{
              style: { height: 0 },
            }}
            value={query}
          />
          <div style={{ height: 20 }} />
          <DataTable
            columns={ColumnDefinition}
            rows={vehicles.result}
            limit={limit}
            pages={vehicles.pages}
            total={vehicles.count}
            loading={loading}
            onPagination={(page) => setPage(page - 1)}
            onClick={(row) => handleSelect(row as Vehicle)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancel}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LookupVehicles;
