import { Filter } from "../model/Filter";
import { Search } from "../model/Search";
import { inject, injectable } from "inversify";
import { Types } from "@ioc/types";
import { FlagcardCompanyWrapper } from "../FlagcardCompanyWrapper";
import { Department } from "./Department";

export interface DepartmentClient {
  save(companyId: string, department: Department): Promise<Department>;

  update(
    companyId: string,
    departmentId: string,
    department: Department
  ): Promise<void>;

  find(companyId: string, filter?: Filter): Promise<Search<Department>>;

  findById(companyId: string, departmentId: string): Promise<Department>;

  remove(companyId: string, departmentId: string): Promise<void>;
}

@injectable()
export class DepartmentClientImpl implements DepartmentClient {
  @inject(Types.FlagcardCompanyWrapper)
  // @ts-ignore
  private instance: FlagcardCompanyWrapper;

  private readonly endpoint = "/rest/v1/departments";

  public save(companyId: string, department: Department): Promise<Department> {
    return this.instance.post(this.endpoint, department, {
      headers: { company_id: companyId },
    });
  }

  public update(
    companyId: string,
    departmentId: string,
    department: Department
  ): Promise<void> {
    return this.instance.patch(`${this.endpoint}/${departmentId}`, department, {
      headers: { company_id: companyId },
    });
  }

  public find(companyId: string, filter?: Filter): Promise<Search<Department>> {
    return this.instance.get(this.endpoint, {
      params: filter,
      headers: { company_id: companyId },
    });
  }

  public findById(
    companyId: string,
    departmentId: string
  ): Promise<Department> {
    return this.instance.get(`${this.endpoint}/${departmentId}`, {
      headers: { company_id: companyId },
    });
  }

  public remove(companyId: string, departmentId: string): Promise<void> {
    return this.instance.delete(`${this.endpoint}/${departmentId}`, {
      headers: { company_id: companyId },
    });
  }
}
