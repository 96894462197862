import {createStyles, makeStyles} from "@material-ui/core";

export default makeStyles((theme) =>
    createStyles({
        header: {
            gridArea: "header",
            position: "fixed",
            top: "0px",
            left: "0px",
            height: "68px",
            width: "100%",
            minWidth: "1280px",
            backgroundColor: theme.palette.primary.main,
            zIndex: 998,
            textAlign: "right",
            padding: "5px",
            webkitBoxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
            boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
        },
        logo: {
            position: "fixed",
            marginTop: "-10px",
            marginLeft: "68px",
        },
        menu: {
            position: "fixed",
            marginTop: "4px",
            marginLeft: "7px",
        },
        infoContainer: {
            marginTop: "8px",
            display: "flex",
            paddingLeft: "16px",
        },
        label: {
            fontSize: "14px",
            fontWeight: "bold",
            marginRight: "20px",
            color: "#FFF",
            cursor: "pointer",
            paddingTop: "10px",
        },
        avatar: {
            height: "40px",
            width: "40px",
            marginRight: "20px",
            cursor: "pointer",
        },
        badge: {
            marginRight: "2px",
            color: "#FFF",
            backgroundColor: "#FF5F00",
            marginTop: "6px",
            fontSize: "10px",
            padding: "0 4px",
            fontWeight: "bold",
        },
        button: {
            fontSize: "14px",
            color: "#FFFAFA",
            fontWeight: "bold",
            marginTop: "2px",
        },
        "card-container": {
            padding: "0 10px",
        },
        "card-container-title": {
            display: "flex",
            borderTop: "solid 1px #414141",
            borderBottom: "solid 1px #414141",
            padding: "6px 12px",
            position: "relative",
            width: "100%",
        },
        "card-container-title-label": {
            fontSize: "14px",
            color: "#1B1B1B",
            fontWeight: "bold",
            marginTop: "2px",
        },
        divider: {
            backgroundColor: theme.palette.tertiary.background.primary,
        },
        "search-bar": {
            position: "fixed",
            marginLeft: "260px",
            marginTop: "10px",
        },
        "search-bar-container": {
            height: "36px",
        },
    })
);
