import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { Button } from "@components/Button";
import Permission from "../../../../client/permission/Permission";
import Product from "../../../../client/product/model/Product";

interface DialogProductProps {
  open: boolean;
  selected?: Permission;
  onCancel: () => void;
  onSave: (products: string[]) => void;
}

const PRODUCTS = [
  { id: "9f651d54-7cae-471a-a9ef-1b6f391eafbb", name: "Arla", checked: false },
  {
    id: "b9d2575b-8075-453e-b885-07a788214468",
    name: "Gasolina Comum",
    checked: false,
  },
  {
    id: "f34c0bdd-1027-443e-918f-e953886de79f",
    name: "Gasolina Aditivada",
    checked: false,
  },
  {
    id: "2f170bd3-6718-4b7d-9bd7-7f1e493c4909",
    name: "Etanol",
    checked: false,
  },
  {
    id: "a261a86a-17f1-4d3d-ba2a-bc1c69c4ce53",
    name: "Óleo Diesel Comum",
    checked: false,
  },
  {
    id: "0cb0ec7f-c06f-43e7-917f-3c1fad33d062",
    name: "Óleo Diesel S-10",
    checked: false,
  },
];

export const DialogProduct: React.FC<DialogProductProps> = (props) => {
  const { selected, open, onCancel, onSave } = props;
  const [products, setProducts] = useState<Product[]>([]);

  const handleCancel = () => {
    onCancel();
  };

  const handleCheckBoxChanged = (checked: boolean, id: string) => {
    setProducts(
      products.map((product) => {
        if (product.id === id) {
          return { ...product, checked: checked };
        }
        return product;
      })
    );
  };

  useEffect(() => {
    if (open) {
      const selectedProducts = selected?.options?.permissions || [];
      setProducts(
        PRODUCTS.map((product) => {
          return { ...product, checked: selectedProducts.includes(product.id) };
        })
      );
    }
  }, [open]);

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle disableTypography style={{ padding: "0px 16px" }}>
        <h2>Restrição por combustíveis/produtos autorizados</h2>
      </DialogTitle>
      <DialogContent style={{ padding: "16px" }}>
        <FormGroup>
          <div>
            {products.map((product) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={product.checked}
                      onChange={(_, checked) => {
                        handleCheckBoxChanged(checked, product.id);
                      }}
                    />
                  }
                  label={product.name}
                />
              );
            })}
          </div>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleCancel}>
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={() => {
            onSave(
              products
                .filter((product) => product.checked)
                .map((product) => product.id)
            );
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
