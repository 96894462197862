import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    errorIcon: {
      fontSize: "4rem",
      color: theme.palette.error.main,
      [theme.breakpoints.up("sm")]: {
        fontSize: "6rem",
      },
    },
    messageError: {
      textAlign: "center",
      fontSize: "1.4rem",
      fontWeight: "bold",
      [theme.breakpoints.up("sm")]: {
        fontSize: "2rem",
      },
    },
  })
);

interface Props {
  messageError?: string;
}

const ErrorLoading: React.FC<Props> = ({ messageError }) => {
  const classes = useStyles();
  return (
    <Grid container justify="center" alignItems="center" direction="column">
      <ErrorIcon className={classes.errorIcon} />
      <Typography className={classes.messageError}>
        {messageError ? messageError : "Ocorreu um erro ao baixar dados"}
      </Typography>
    </Grid>
  );
};

export { ErrorLoading };
