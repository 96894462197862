import {inject, injectable} from "inversify";
import {Types} from "@ioc/types";
import {IHttpService} from "@modules/infra/http/models/IHttpService";
import {Filter} from "../model/Filter";
import {Search} from "../model/Search";
import {format} from "date-fns/esm";
import {ReportCreditClient} from "../ReportCreditClient";
import {ReportCredit} from "../model/ReportCredit";

@injectable()
export class HttpReportCreditClient implements ReportCreditClient {
    // @ts-ignore
    @inject(Types.IHttpService) private httpInstance: IHttpService;

    public find(
        companyId: string,
        startDate: Date,
        endDate: Date,
        filter: Filter
    ): Promise<Search<ReportCredit>> {
        return this.httpInstance.get(
            `/companies/${companyId}/report/credit-management/paged`,
            {
                params: {
                    ...filter,
                    type: "CREDITO",
                    startDate: ((format(startDate, "yyyy-MM-dd") +
                        "T00:00:00.000Z") as unknown) as Date,
                    endDate: ((format(endDate, "yyyy-MM-dd") +
                        "T23:59:59.000Z") as unknown) as Date,
                },
            }
        );
    }

    public async download(
        companyId: string,
        startDate: Date,
        endDate: Date,
        filter: Filter
    ): Promise<string> {
        const result = await this.httpInstance.get(
            `/companies/${companyId}/report/credit-management`,
            {
                params: {
                    ...filter,
                    type: "CREDITO",
                    startDate: ((format(startDate, "yyyy-MM-dd") +
                        "T00:00:00.000Z") as unknown) as Date,
                    endDate: ((format(endDate, "yyyy-MM-dd") +
                        "T23:59:59.000Z") as unknown) as Date,
                },
                responseType: "arraybuffer",
            }
        );
        const blob = new Blob([result], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        return window.URL.createObjectURL(blob);
    }
}
