import { StyledDialog } from "@components/StyledDialog";
import { StyledSelect } from "@components/StyledSelect";
import {
  createStyles,
  DialogContent,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { Pagination as PaginationComponent } from "@material-ui/lab";
import React, { useState } from "react";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      paginationContainer: {
        padding: "0 2rem",
        [theme.breakpoints.down("xs")]: {
          margin: "1rem",
        },
      },
      paginationTextShow: {
        fontSize: "1.6rem",
      },
      paginationButton: {
        "& li > button": {
          color: theme.palette.primary.main,
          background: "none",
        },
        "& li> button.Mui-selected": {
          backgroundColor:
            theme.palette.type === "dark"
              ? theme.palette.hexToRGBWithAlpha("#ffffff", 0.13)
              : theme.palette.tertiary.boxAndBackgrounds.primary,
          boxShadow: theme.shadows[2],
        },
      },
    })
);

interface Props {
  pageSize: number;
  rowCount: number;
  page: number;
  setPage: (newPage: number) => void;
  sizeTable?: number | null;
  setSizeTable?: (value: number | null) => void;
}

const Pagination: React.FC<Props> = ({
  pageSize,
  rowCount,
  page,
  setPage,
  sizeTable,
  setSizeTable,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <Grid
      container
      justify="space-between"
      className={classes.paginationContainer}
    >
      <Grid item container style={{ width: "auto" }} alignItems="center">
        <Typography className={classes.paginationTextShow}>
          Mostrando {pageSize} de {rowCount} registros
        </Typography>
        {setSizeTable && (
          <IconButton
            style={{ marginLeft: "1rem" }}
            onClick={() => setOpen(true)}
          >
            <SettingsIcon />
          </IconButton>
        )}
      </Grid>
      <PaginationComponent
        count={Math.ceil(rowCount / pageSize)}
        page={page}
        onChange={(e, value) => setPage(value)}
        shape="rounded"
        classes={{ ul: classes.paginationButton }}
      />

      {setSizeTable && (
        <StyledDialog
          closeFunc={() => setOpen(false)}
          open={open}
          title="Alterar tamanho da tabela"
        >
          <DialogContent>
            <StyledSelect
              onClear={() => setSizeTable(null)}
              isFilled={sizeTable !== null}
              textFieldProps={{
                label: "Selecione a quantidade de registros",
                value: sizeTable,
                onChange: ({ target }) => {
                  setSizeTable(Number(target.value));
                  setPage(1);
                },
              }}
              title="Registros"
            >
              <MenuItem value={1000}>10</MenuItem>
              <MenuItem value={2000}>20</MenuItem>
              <MenuItem value={3000}>30</MenuItem>
              <MenuItem value={4000}>50</MenuItem>
              <MenuItem value={5000}>100</MenuItem>
            </StyledSelect>
          </DialogContent>
        </StyledDialog>
      )}
    </Grid>
  );
};

export { Pagination };
