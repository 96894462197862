import React, { useState } from "react";
import { AppBar, Fab, Toolbar, Tooltip, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import UploadDocument from "@pages/Documents/Upload";

interface TemplateBarProps {
  id: string;
  title: string;
  folderId?: string;
  onChange: () => void;
}

const TemplateBar: React.FC<TemplateBarProps> = (props) => {
  const { title, id, folderId, onChange } = props;
  const [openModalUploadDocument, setOpenModalUploadDocument] = useState(false);

  const handleOpenModalUploadDocument = () => {
    setOpenModalUploadDocument(true);
  };

  const handleCloseModalUploadDocument = () => {
    setOpenModalUploadDocument(false);
    onChange();
  };

  return (
    <>
      <AppBar position="static" color="default">
        <Toolbar variant="dense">
          <Typography variant="subtitle2" color="inherit" component="div">
            {title}
          </Typography>
          <div style={{ position: "absolute", right: 20 }}>
            <Tooltip title="Enviar Documento">
              <Fab
                size="small"
                color="primary"
                onClick={handleOpenModalUploadDocument}
              >
                <Add style={{ cursor: "pointer" }} />
              </Fab>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
      <UploadDocument
        folderId={folderId}
        open={openModalUploadDocument}
        type={id}
        onClose={handleCloseModalUploadDocument}
      />
    </>
  );
};

export { TemplateBar };
