import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    container: {
      padding: "20px",
      backgroundColor: "#FFF",
      borderRadius: "4px",
      webkitBoxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    },
    textField: {
      padding: "0px 3px",
    },
    input: {
      height: 30,
    },
    autoCompleteText: {
      fontSize: "12px",
    },
    autoCompleteInputText: {
      fontSize: "12px",
    },
    toggleButton: {
      padding: "4px",
    },
    toggleButtonImage: {
      fontSize: "20px",
      marginRight: "6px",
    },
    "infraction-detail-card": {
      backgroundColor: "lightgray",
      padding: "4px 8px",
      borderRadius: 2,
    },
    "infraction-detail": {
      border: "solid 1px",
      borderColor: "#767676",
      padding: "8px 6px",
      position: "relative",
      borderRadius: "4px",
    },
    "infraction-detail-title": {
      position: "absolute",
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "12px",
      fontFamily: '"IBM Plex Sans",-apple-system,BlinkMacSystemFont,sans-serif',
      marginTop: "-18px",
      backgroundColor: "#FFF",
      padding: "0 4px",
    },
    "infraction-detail-text": {
      fontSize: "12px",
    },
  })
);
