import { createStyles, makeStyles, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      paperTag: {
        height: "3rem",
        padding: "0 1rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& > p": {
          fontSize: "1.4rem",
          fontWeight: "bold",
        },
      },
      tagSuccess: {
        color: theme.palette.tertiary.confirmation.primary,
        backgroundColor: theme.palette.tertiary.confirmation.secondary,
      },
      tagWarning: {
        color: theme.palette.tertiary.intermediate.primary,
        backgroundColor: theme.palette.tertiary.intermediate.secondary,
      },
      tagError: {
        color: theme.palette.tertiary.error.primary,
        backgroundColor: theme.palette.tertiary.error.secondary,
      },
      tagInfo: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light,
      },
      tagExpired: {
        color: "#fff",
        backgroundColor: theme.palette.warning.light,
      },
    })
);

interface Props {
  type: "error" | "success" | "warning" | "info" | "expired";
}

const Tag: React.FC<Props> = ({ children, type }) => {
  const classes = useStyles();
  return (
    <Paper
      elevation={0}
      className={clsx(classes.paperTag, {
        [classes.tagSuccess]: type === "success",
        [classes.tagError]: type === "error",
        [classes.tagWarning]: type === "warning",
        [classes.tagInfo]: type === "info",
        [classes.tagExpired]: type === "expired",
      })}
    >
      <Typography>{children}</Typography>
    </Paper>
  );
};

export { Tag };
