import React, { useState } from "react";
import { Form, useFormikContext } from "formik";
import { Row } from "@components/Row";
import { Chip, InputAdornment, MenuItem, TextField } from "@material-ui/core";
import { Button } from "@components/Button";
import { ROUTES } from "@config/routesConfig";
import StyleSheet from "../../../../StyleSheet";
import { Folder } from "../../../../client/folder/Folder";
import { Search } from "@material-ui/icons";
import Vehicle from "../../../../client/vehicle/Vehicle";
import { Driver } from "../../../../client/model/Driver";
import AddIcon from "@material-ui/icons/AddBoxOutlined";
import LookupVehicles from "@components/Lookup/LookupVehicles";
import LookupDrivers from "@components/Lookup/LookupDrivers";

const optionsExternalReferences = [
  { id: 1, label: "Veículo", value: "vehicle" },
  { id: 2, label: "Motorista", value: "driver" },
];

const FolderEditForm: React.FC = () => {
  const styleSheet = StyleSheet();
  const { values, errors, setFieldValue } = useFormikContext<Folder>();
  const [email, setEmail] = useState("");
  const [openLookupVehicles, setOpenLookupVehicles] = useState(false);
  const [openLookupDrivers, setOpenLookupDrivers] = useState(false);

  const handleSelectVehicle = (vehicle: Vehicle) => {
    const model = `[${vehicle.plate}] - ${vehicle.model?.manufacturer?.description} ${vehicle.model?.description}`;
    setFieldValue("externalReferenceId", vehicle.id);
    setFieldValue("externalReferenceDescription", model);
    setFieldValue("description", model);
    setOpenLookupVehicles(false);
  };

  const handleSelectDriver = (driver: Driver) => {
    setFieldValue("externalReferenceId", driver.id);
    setFieldValue("externalReferenceDescription", driver.driver);
    setFieldValue("description", driver.driver);
    setOpenLookupDrivers(false);
  };

  const handleOpenLookup = (externalReferenceType?: string) => {
    setOpenLookupVehicles(externalReferenceType === "vehicle");
    setOpenLookupDrivers(externalReferenceType === "driver");
  };

  const handleAddEmail = () => {
    if (email) {
      const set = new Set(values.emails);
      email.split(/,\s?|,|\s/).forEach((item) => {
        if (item.trim().length > 0) {
          set.add(item.trim());
        }
      });
      setFieldValue("emails", Array.from(set));
      setEmail("");
    }
  };

  const handleDeleteEmail = (value: string) => {
    setFieldValue(
      "emails",
      values.emails.filter((email) => email !== value)
    );
  };

  return (
    <Form noValidate>
      <Row>
        <TextField
          id="externalReferenceType"
          name="externalReferenceType"
          style={{ width: "30%" }}
          classes={{ root: styleSheet.textField }}
          label="Tipo de Pasta"
          variant="outlined"
          select
          value={values.externalReferenceType}
          onChange={(event) => {
            setFieldValue("externalReferenceType", event.target.value);
            setFieldValue("externalReferenceId", "");
            setFieldValue("externalReferenceDescription", "");
            setFieldValue("description", "");
          }}
          size="small"
        >
          <MenuItem key={0} value="general">
            Geral
          </MenuItem>
          {optionsExternalReferences.map((item) => (
            <MenuItem key={item.id} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </TextField>
        {optionsExternalReferences
          .map((option) => option.value)
          .includes(values.externalReferenceType || "") ? (
          <TextField
            id="externalReferenceDescription"
            name="externalReferenceDescription"
            style={{ width: "70%", cursor: "pointer" }}
            classes={{ root: styleSheet.textField }}
            required
            disabled
            onClick={() => handleOpenLookup(values.externalReferenceType)}
            label={
              optionsExternalReferences.find(
                (option) => option.value === values.externalReferenceType
              )?.label
            }
            variant="outlined"
            value={values.externalReferenceDescription}
            inputProps={{ maxLength: 256 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Search
                    style={{
                      color: "rgba(0, 0, 0, 0.23)",
                      width: "18px",
                    }}
                  />
                </InputAdornment>
              ),
            }}
            error={!!errors.externalReferenceDescription}
            helperText={errors.externalReferenceDescription}
            size="small"
          />
        ) : (
          <></>
        )}
      </Row>
      {values.externalReferenceType === "general" ? (
        <Row>
          <TextField
            id="description"
            name="description"
            style={{ width: "100%" }}
            classes={{ root: styleSheet.textField }}
            required
            label="Descrição"
            variant="outlined"
            onChange={(event) =>
              setFieldValue("description", event.target.value)
            }
            value={values.description}
            inputProps={{ maxLength: 256 }}
            error={!!errors.description}
            helperText={errors.description}
            size="small"
          />
        </Row>
      ) : (
        <></>
      )}
      <Row>
        <TextField
          id="email"
          name="email"
          style={{ width: "30%" }}
          classes={{ root: styleSheet.textField }}
          required
          label="Email"
          variant="outlined"
          onChange={(event) => setEmail(event.target.value)}
          onBlur={handleAddEmail}
          value={email}
          inputProps={{ maxLength: 256 }}
          InputProps={{
            endAdornment: (
              <div style={{ paddingRight: 8 }}>
                <AddIcon style={{ color: "#0F71D0", cursor: "pointer" }} />
              </div>
            ),
          }}
          size="small"
        />
      </Row>
      <Row>
        {values.emails.map((email) => (
          <Chip
            key={email}
            label={email}
            onDelete={() => handleDeleteEmail(email)}
          />
        ))}
      </Row>
      <Row align="end">
        <Button to={ROUTES.FOLDER} color="secondary">
          Voltar
        </Button>
        <div style={{ width: 6 }} />
        <Button color="primary" type="submit">
          Salvar
        </Button>
      </Row>
      <LookupVehicles
        open={openLookupVehicles}
        onCancel={() => setOpenLookupVehicles(false)}
        onSelect={handleSelectVehicle}
      />
      <LookupDrivers
        open={openLookupDrivers}
        onCancel={() => setOpenLookupDrivers(false)}
        onSelect={handleSelectDriver}
      />
    </Form>
  );
};

export default FolderEditForm;
