import { Types } from "@ioc/types";
import { PenaltyStatus } from "@modules/company/dtos/IPenaltyDTO";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { parse } from "date-fns";
import { format } from "date-fns/esm";
import { inject, injectable } from "inversify";
import { v4 as uuidv4 } from "uuid";
import {
  GravityType,
  IReportDriverPenaltiesDTO,
} from "../dtos/IReportDriverPenaltiesDTO";
import { IReportDriverPenaltiesService } from "../models/IReportDriverPenaltiesService";

@injectable()
export class ReportDriverPenaltiesService
  implements IReportDriverPenaltiesService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    from: Date;
    to: Date;
    page: number;
    limit: number;
    plate?: string;
    modelID?: string;
    gravity?: GravityType;
    status?: PenaltyStatus;
    costCenterID?: string;
    departmentID?: string;
  }): Promise<IReportDriverPenaltiesDTO> {
    const resp = await this.httpInstance.get<IReportDriverPenaltiesDTO>(
      `/companies/${data.companyID}/report/penalties/paged`,
      {
        params: {
          from_date: format(data.from, "yyyy-MM-dd"),
          to_date: format(data.to, "yyyy-MM-dd"),
          limit: data.limit,
          page: data.page,
          type: "driver",
          punctuation: data.gravity
            ? data.gravity === "low"
              ? 3
              : data.gravity === "medium"
              ? 4
              : data.gravity === "high"
              ? 5
              : 7
            : undefined,
          status: data.status,
          model: data.modelID,
          plate: data.plate,
          department: data.departmentID,
          cost_center: data.costCenterID,
        },
      }
    );

    resp.content = resp.content.map((report) => {
      return {
        ...report,
        id: uuidv4(),
        data: parse(
          (report.data as unknown) as string,
          "dd/MM/yyyy",
          new Date()
        ),
      };
    });

    return resp;
  }
}
