import {createStyles, makeStyles} from "@material-ui/core";

export default makeStyles((theme) =>
    createStyles({
        filterContainer: {
            display: "flex",
            "& :first-child": {
                textAlign: "start"
            },
            "& :last-child": {
                textAlign: "end"
            }
        },
        filterBox: {
            width: "50%"
        },
        container: {
            padding: "20px",
            backgroundColor: "#FFF",
            borderRadius: "4px",
            webkitBoxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
            boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
        }
    })
);
