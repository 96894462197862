import {
  IListPermissibilityFleetDTO,
  IPermissibilityFleetDTO,
} from "@modules/company/dtos/IListPermissibilityFleetDTO";
import React, { createContext, useContext, useState } from "react";

interface RechargeContext {
  page: number;
  limit: number;
  recharges: IListPermissibilityFleetDTO;
  loadingRecharges: boolean;
  errorLoadingRecharges: boolean;
  openDialog: boolean;
  openModalUploadRecharges: boolean;
  infoDialog: IPermissibilityFleetDTO | null;
  openModalInfoRecharge: IDetailsRecharge;
  reverseDialog: IReverseDialog;
  setLoadingRecharges: (data: boolean) => void;
  setRecharges: (data: IListPermissibilityFleetDTO) => void;
  setPage: (data: number) => void;
  setErrorLoadingRecharges: (data: boolean) => void;
  setOpenDialog: (data: boolean) => void;
  setInfoDialog: (data: IPermissibilityFleetDTO) => void;
  setLimit: (data: number) => void;
  setOpenModalUploadRecharges: (data: boolean) => void;
  setOpenModalInfoRecharge: (data: IDetailsRecharge) => void;
  setReverseDialog: (data: IReverseDialog) => void;
}

interface IDetailsRecharge {
  open: boolean;
  data: {
    rechargeID: string;
  } | null;
}

interface IReverseDialog {
  open: boolean;
  data: {
    name: string;
    date: Date;
    rechargeID: string;
    in_money: boolean;
  } | null;
}

const RechargeContext = createContext({} as RechargeContext);

const RechargeProvider: React.FC = ({ children }) => {
  const [loadingRecharges, setLoadingRecharges] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [infoDialog, setInfoDialog] = useState<IPermissibilityFleetDTO | null>(
    null
  );
  const [errorLoadingRecharges, setErrorLoadingRecharges] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(1);
  const [recharges, setRecharges] = useState<IListPermissibilityFleetDTO>({
    page: 1,
    count: 0,
    result: [],
  });
  const [openModalUploadRecharges, setOpenModalUploadRecharges] = useState(
    false
  );
  const [reverseDialog, setReverseDialog] = useState<IReverseDialog>({
    open: false,
    data: null,
  });
  const [
    openModalInfoRecharge,
    setOpenModalInfoRecharge,
  ] = useState<IDetailsRecharge>({ open: false, data: null });

  return (
    <RechargeContext.Provider
      value={{
        limit,
        page,
        recharges,
        loadingRecharges,
        errorLoadingRecharges,
        openDialog,
        infoDialog,
        openModalUploadRecharges,
        openModalInfoRecharge,
        reverseDialog,
        setLoadingRecharges,
        setRecharges,
        setPage,
        setErrorLoadingRecharges,
        setOpenDialog,
        setInfoDialog,
        setLimit,
        setOpenModalUploadRecharges,
        setOpenModalInfoRecharge,
        setReverseDialog,
      }}
    >
      {children}
    </RechargeContext.Provider>
  );
};

const useRecharge = () => {
  const context = useContext(RechargeContext);
  if (Object.values(context).length === 0) {
    throw new Error(
      "useRecharge não pode ser utilizado fora de um RechargeProvider"
    );
  }
  return context;
};

export { useRecharge, RechargeProvider };
