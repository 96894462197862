import { ROUTES } from "@config/routesConfig";
import { DataColumn } from "@components/DataTable";

export const ColumnDefinition: DataColumn[] = [
  {
    field: "externalReferenceType",
    headerName: "Tipo de Documento",
    defaultValue: "Geral",
    navigateToOnClick: (folderId) => ROUTES.DOCUMENT(folderId),
    width: 200,
  },
  {
    field: "description",
    headerName: "Descrição",
    navigateToOnClick: (folderId) => ROUTES.DOCUMENT(folderId),
  },
  // {
  //     field: "id",
  //     headerName: "",
  //     width: 50,
  //     render: (folderId) => {
  //         return <AddDocument folderId={folderId || "0"} />;
  //     },
  // },
];
