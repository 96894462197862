import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import {
  IListBrandVehicleDTO,
  VehicleType,
} from "../dtos/IListBrandVehicleDTO";
import { IListBrandVehicleService } from "../models/IListBrandVehicleService";

@injectable()
export class ListBrandVehicleService implements IListBrandVehicleService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    type: VehicleType;
  }): Promise<IListBrandVehicleDTO[]> {
    const resp = await this.httpInstance.get<IListBrandVehicleDTO[]>(
      `/fleet/brand/all`,
      {
        params: {
          type: data.type,
        },
      }
    );

    return resp;
  }
}
