import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IReverseRechargeService } from "../models/IReverseRechargeService";

@injectable()
export class ReverseRechargeService implements IReverseRechargeService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    rechargeID: string;
    in_money: boolean;
  }): Promise<void> {
    await this.httpInstance.post(
      `/companies/${data.companyID}/recharges/${data.rechargeID}/chargeback`,
      {}
    );
  }
}
