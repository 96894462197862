import { IAutocompleteUsersQueryService } from "@modules/company/models/IAutocompleteUsersQueryService";
import { ICreateCostCenterService } from "@modules/company/models/ICreateCostCenterService";
import { ICreateDepartmentService } from "@modules/company/models/ICreateDepartmentService";
import { ICreateDriverService } from "@modules/company/models/ICreateDriverService";
import { ICreateFunctionService } from "@modules/company/models/ICreateFunctionService";
import { ICreateRechargeService } from "@modules/company/models/ICreateRechargeService";
import { ICreateVehicleService } from "@modules/company/models/ICreateVehicleService";
import { IDetailsRechargeService } from "@modules/company/models/IDetailsRechargeService";
import { IDownloadVehicleService } from "@modules/company/models/IDownloadVehicleService";
import { IEditCostCenterService } from "@modules/company/models/IEditCostCenterService";
import { IEditDepartmentService } from "@modules/company/models/IEditDepartmentService";
import { IEditDriverService } from "@modules/company/models/IEditDriverService";
import { IEditFunctionService } from "@modules/company/models/IEditFunctionService";
import { IEditVehicleService } from "@modules/company/models/IEditVehicleService";
import { IGetCostCentersConsumptionService } from "@modules/company/models/IGetCostCentersConsumptionService";
import { IGetDriverService } from "@modules/company/models/IGetDriverService";
import { IGetExceptionTransactionsService } from "@modules/company/models/IGetExceptionTransactionsService";
import { IGetExpiringLicencesService } from "@modules/company/models/IGetExpiringLicencesService";
import { IGetHistoryOfConsumptionService } from "@modules/company/models/IGetHistoryOfConsumptionService";
import { IGetInfoCompanyService } from "@modules/company/models/IGetInfoCompanyService";
import { IGetPricesEstablishmentsService } from "@modules/company/models/IGetPricesEstablishmentsService";
import { IGetTransactionsCreditService } from "@modules/company/models/IGetTransactionsCreditService";
import { IGetVehicleDataService } from "@modules/company/models/IGetVehicleDataService";
import { IListBrandVehicleService } from "@modules/company/models/IListBrandVehicleService";
import { IListCostCentersService } from "@modules/company/models/IListCostCentersService";
import { IListDepartmentsService } from "@modules/company/models/IListDepartmentsService";
import { IListDriversService } from "@modules/company/models/IListDriversService";
import { IListFleetFuelService } from "@modules/company/models/IListFleetFuelService";
import { IListFunctionsService } from "@modules/company/models/IListFunctionsService";
import { IListModelVehicleService } from "@modules/company/models/IListModelVehicleService";
import { IListPenaltiesService } from "@modules/company/models/IListPenaltiesService";
import { IListPermissibilityFleetService } from "@modules/company/models/IListPermissibilityFleetService";
import { IListRechargesService } from "@modules/company/models/IListRechargesService";
import { IListSmallerBalancesService } from "@modules/company/models/IListSmallerBalancesService";
import { IListVehiclesService } from "@modules/company/models/IListVehiclesService";
import { ILocationsEstablishmentsService } from "@modules/company/models/ILocationsEstablishmentsService";
import { IMarkAsReadPenaltyService } from "@modules/company/models/IMarkAsReadPenaltyService";
import { IRemoveCostCenterService } from "@modules/company/models/IRemoveCostCenterService";
import { IRemoveDepartmentService } from "@modules/company/models/IRemoveDepartmentService";
import { IRemoveDriverService } from "@modules/company/models/IRemoveDriverService";
import { IRemoveFunctionService } from "@modules/company/models/IRemoveFunctionService";
import { IRemoveJustificationTransactionService } from "@modules/company/models/IRemoveJustificationTransactionService";
import { IRemoveVehicleService } from "@modules/company/models/IRemoveVehicleService";
import { IResetDriverPasswordService } from "@modules/company/models/IResetDriverPasswordService";
import { IReverseRechargeService } from "@modules/company/models/IReverseRechargeService";
import { ISendJustificationTransactionService } from "@modules/company/models/ISendJustificationTransactionService";
import { AutocompleteUsersQueryService } from "@modules/company/services/AutocompleteUsersQueryService";
import { CreateCostCenterService } from "@modules/company/services/CreateCostCenterService";
import { CreateDepartmentService } from "@modules/company/services/CreateDepartmentService";
import { CreateDriverService } from "@modules/company/services/CreateDriverService";
import { CreateFunctionService } from "@modules/company/services/CreateFunctionService";
import { CreateRechargeService } from "@modules/company/services/CreateRechargeService";
import { CreateVehicleService } from "@modules/company/services/CreateVehicleService";
import { DetailsRechargeService } from "@modules/company/services/DetailsRechargeService";
import { DownloadVehicleService } from "@modules/company/services/DownloadVehicleService";
import { EditCostCenterService } from "@modules/company/services/EditCostCenterService";
import { EditDepartmentService } from "@modules/company/services/EditDepartmentService";
import { EditDriverService } from "@modules/company/services/EditDriverService";
import { EditFunctionService } from "@modules/company/services/EditFunctionService";
import { EditVehicleService } from "@modules/company/services/EditVehicleService";
import { GetCostCentersConsumptionService } from "@modules/company/services/GetCostCentersConsumptionService";
import { GetDriverService } from "@modules/company/services/GetDriverService";
import { GetExceptionTransactionsService } from "@modules/company/services/GetExceptionTransactionsService";
import { GetExpiringLicencesService } from "@modules/company/services/GetExpiringLicencesService";
import { GetHistoryOfConsumptionService } from "@modules/company/services/GetHistoryOfConsumptionService";
import { GetInfoCompanyService } from "@modules/company/services/GetInfoCompanyService";
import { GetPricesEstablishmentsService } from "@modules/company/services/GetPricesEstablishmentsService";
import { GetTransactionsCreditService } from "@modules/company/services/GetTransactionsCreditService";
import { GetVehicleDataService } from "@modules/company/services/GetVehicleDataService";
import { ListBrandVehicleService } from "@modules/company/services/ListBrandVehicleService";
import { ListCostCentersService } from "@modules/company/services/ListCostCentersService";
import { ListDepartmentsService } from "@modules/company/services/ListDepartmentsService";
import { ListDriversService } from "@modules/company/services/ListDriversService";
import { ListFleetFuelService } from "@modules/company/services/ListFleetFuelService";
import { ListFunctionsService } from "@modules/company/services/ListFunctionsService";
import { ListModelVehicleService } from "@modules/company/services/ListModelVehicleService";
import { ListPenaltiesService } from "@modules/company/services/ListPenaltiesService";
import { ListPermissibilityFleetService } from "@modules/company/services/ListPermissibilityFleetService";
import { ListRechargesService } from "@modules/company/services/ListRechargesService";
import { ListSmallerBalancesService } from "@modules/company/services/ListSmallerBalancesService";
import { ListVehiclesService } from "@modules/company/services/ListVehiclesService";
import { LocationsEstablishmentsService } from "@modules/company/services/LocationsEstablishmentsService";
import { MarkAsReadPenaltyService } from "@modules/company/services/MarkAsReadPenaltyService";
import { RemoveCostCenterService } from "@modules/company/services/RemoveCostCenterService";
import { RemoveDepartmentService } from "@modules/company/services/RemoveDepartmentService";
import { RemoveDriverService } from "@modules/company/services/RemoveDriverService";
import { RemoveFunctionService } from "@modules/company/services/RemoveFunctionService";
import { RemoveJustificationTransactionService } from "@modules/company/services/RemoveJustificationTransactionService";
import { RemoveVehicleService } from "@modules/company/services/RemoveVehicleService";
import { ResetDriverPasswordService } from "@modules/company/services/ResetDriverPasswordService";
import { ReverseRechargeService } from "@modules/company/services/ReverseRechargeService";
import { SendJustificationTransactionService } from "@modules/company/services/SendJustificationTransactionService";
import { IGetHistoryPriceProducts } from "@modules/dashboard/models/IGetHistoryPriceProducts";
import { IGetPriceProducts } from "@modules/dashboard/models/IGetPriceProducts";
import { IGetResumeService } from "@modules/dashboard/models/IGetResumeService";
import { GetHistoryPriceProducts } from "@modules/dashboard/services/GetHistoryPriceProducts";
import { GetPriceProducts } from "@modules/dashboard/services/GetPriceProducts";
import { GetResumeService } from "@modules/dashboard/services/GetResumeService";
import AxiosHttpService from "@modules/infra/http/implementations/AxiosHttpService";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { IChangeKMDebtReportService } from "@modules/reports/models/IChangeKMDebtReportService";
import { IDownloadReportBalanceService } from "@modules/reports/models/IDownloadReportBalanceService";
import { IDownloadReportCreditService } from "@modules/reports/models/IDownloadReportCreditService";
import { IDownloadReportDebtService } from "@modules/reports/models/IDownloadReportDebtService";
import { IDownloadReportDriversPenaltiesService } from "@modules/reports/models/IDownloadReportDriversPenaltiesService";
import { IDownloadReportNewUsersService } from "@modules/reports/models/IDownloadReportNewUsersService";
import { IDownloadReportVehicleRankingService } from "@modules/reports/models/IDownloadReportVehicleRankingService";
import { IDownloadReportVehiclesPenaltiesService } from "@modules/reports/models/IDownloadReportVehiclesPenaltiesService";
import { IListRechargesByUserService } from "@modules/reports/models/IListRechargesByUserService";
import { IListVehicleModelsByCompanyService } from "@modules/reports/models/IListVehicleModelsByCompanyService";
import { IReportBalanceService } from "@modules/reports/models/IReportBalanceService";
import { IReportCreditService } from "@modules/reports/models/IReportCreditService";
import { IReportDebtService } from "@modules/reports/models/IReportDebtService";
import { IReportDriverPenaltiesService } from "@modules/reports/models/IReportDriverPenaltiesService";
import { IReportNewUsersService } from "@modules/reports/models/IReportNewUsersService";
import { IReportVehicleRankingService } from "@modules/reports/models/IReportVehicleRankingService";
import { IReportVehiclesPenaltiesService } from "@modules/reports/models/IReportVehiclesPenaltiesService";
import { ChangeKMDebtReportService } from "@modules/reports/services/ChangeKMDebtReportService";
import { DownloadReportBalanceService } from "@modules/reports/services/DownloadReportBalanceService";
import { DownloadReportCreditService } from "@modules/reports/services/DownloadReportCreditService";
import { DownloadReportDebtService } from "@modules/reports/services/DownloadReportDebtService";
import { DownloadReportDriversPenaltiesService } from "@modules/reports/services/DownloadReportDriversPenaltiesService";
import { DownloadReportNewUsersService } from "@modules/reports/services/DownloadReportNewUsersService";
import { DownloadReportVehicleRankingService } from "@modules/reports/services/DownloadReportVehicleRankingService";
import { DownloadReportVehiclesPenaltiesService } from "@modules/reports/services/DownloadReportVehiclesPenaltiesService";
import { ListRechargesByUserService } from "@modules/reports/services/ListRechargesByUserService";
import { ListVehicleModelsByCompanyService } from "@modules/reports/services/ListVehicleModelsByCompanyService";
import { ReportBalanceService } from "@modules/reports/services/ReportBalanceService";
import { ReportCreditService } from "@modules/reports/services/ReportCreditService";
import { ReportDebtService } from "@modules/reports/services/ReportDebtService";
import { ReportDriverPenaltiesService } from "@modules/reports/services/ReportDriverPenaltiesService";
import { ReportNewUsersService } from "@modules/reports/services/ReportNewUsersService";
import { ReportVehicleRankingService } from "@modules/reports/services/ReportVehicleRankingService";
import { ReportVehiclesPenaltiesService } from "@modules/reports/services/ReportVehiclesPenaltiesService";
import { ICreateUserService } from "@modules/user/models/ICreateUserService";
import { IEditUserService } from "@modules/user/models/IEditUserService";
import { IGetFiltersUserService } from "@modules/user/models/IGetFiltersUserService";
import { IGetUserCompanies } from "@modules/user/models/IGetUserCompanies";
import { IGetUserDataService } from "@modules/user/models/IGetUserDataService";
import { IListUsersService } from "@modules/user/models/IListUsersService";
import { IRemoveUserService } from "@modules/user/models/IRemoveUserService";
import { CreateUserService } from "@modules/user/services/CreateUserService";
import { EditUserService } from "@modules/user/services/EditUserService";
import { GetFiltersUserService } from "@modules/user/services/GetFiltersUserService";
import { GetUserCompanies } from "@modules/user/services/GetUserCompanies";
import { GetUserDataService } from "@modules/user/services/GetUserDataService";
import { ListUsersService } from "@modules/user/services/ListUsersService";
import { RemoveUserService } from "@modules/user/services/RemoveUserService";
import { Container } from "inversify";
import { Types } from "./types";
import { ICreateDocumentService } from "@modules/company/models/ICreateDocumentService";
import { CreateDocumentService } from "@modules/company/services/CreateDocumentService";
import { IGetDocumentService } from "@modules/company/models/IGetDocumentService";
import { GetDocumentService } from "@modules/company/services/GetDocumentService";
import { IRemoveDocumentWithIdService } from "@modules/company/models/IRemoveDocumentWithIdService.ts";
import { RemoveDocumentWithIdService } from "@modules/company/services/RemoveDocumentWithIdService";
import { DriverClient } from "../client/DriverClient";
import { HttpDriverClient } from "../client/impl/HttpDriverClient";
import { ReportDebitClient } from "../client/ReportDebitClient";
import { HttpReportDebitClient } from "../client/impl/HttpReportDebitClient";
import { ReportCreditClient } from "../client/ReportCreditClient";
import { HttpReportCreditClient } from "../client/impl/HttpReportCreditClient";
import { CostCenterClient } from "../client/CostCenter/CostCenterClient";
import {
  FlagcardCompanyWrapper,
  FlagcardCompanyWrapperImpl,
} from "../client/FlagcardCompanyWrapper";
import { FolderClient, FolderClientImpl } from "../client/folder/FolderClient";
import { UploadClient, UploadClientImpl } from "../client/upload/UploadClient";
import {
  DocumentClient,
  DocumentClientImpl,
} from "../client/Document/DocumentClient";
import {
  VehicleClient,
  VehicleClientImpl,
} from "../client/vehicle/VehicleClient";
import { HttpReportBalanceClient } from "../client/impl/HttpReportBalanceClient";
import { ReportBalanceClient } from "../client/ReportBalanceClient";
import {
  TransactionClient,
  TransactionClientImpl,
} from "../client/transaction/TransactionClient";
import {
  DepartmentClient,
  DepartmentClientImpl,
} from "../client/Department/DepartmentClient";
import { CostCenterClientImpl } from "../client/CostCenter/CostCenterClient";
import {
  ActivityClient,
  ActivityClientImpl,
} from "../client/Activity/ActivityClient";
import {
  ManufacturerClient,
  ManufacturerClientImpl,
} from "../client/manufacturer/ManufacturerClient";
import {
  InfractionClient,
  InfractionClientImpl,
} from "../client/infraction/InfractionClient";
import { DebitClient, DebitClientImpl } from "../client/Debit/DebitClient";
import {
  DashboardClient,
  DashboardClientImpl,
} from "../client/fleet/dashboard/DashboardClient";
import {
  PermissionClient,
  PermissionClientImpl,
} from "../client/permission/PermissionClient";
import {
  MaintenanceClient,
  MaintenanceClientImpl,
} from "../client/Maintenance/MaintenanceClient";
import {
  QuotationClient,
  QuotationClientImpl,
} from "../client/Quotation/QuotationClient";

const appIocContainer = new Container({ defaultScope: "Singleton" });

appIocContainer
  .bind<IHttpService>(Types.IHttpService)
  .to(AxiosHttpService)
  .inSingletonScope();

appIocContainer
  .bind<FlagcardCompanyWrapper>(Types.FlagcardCompanyWrapper)
  .to(FlagcardCompanyWrapperImpl)
  .inSingletonScope();

appIocContainer
  .bind<IGetResumeService>(Types.Dashboard.IGetResumeService)
  .to(GetResumeService);

appIocContainer
  .bind<IGetPriceProducts>(Types.Dashboard.IGetPriceProducts)
  .to(GetPriceProducts);

appIocContainer
  .bind<IGetHistoryPriceProducts>(Types.Dashboard.IGetHistoryPriceProducts)
  .to(GetHistoryPriceProducts);

appIocContainer
  .bind<IGetUserCompanies>(Types.User.IGetUserCompanies)
  .to(GetUserCompanies);

appIocContainer
  .bind<IGetInfoCompanyService>(Types.Company.IGetInfoCompanyService)
  .to(GetInfoCompanyService);

appIocContainer
  .bind<IGetHistoryOfConsumptionService>(
    Types.Company.IGetHistoryOfConsumptionService
  )
  .to(GetHistoryOfConsumptionService);

appIocContainer
  .bind<IGetCostCentersConsumptionService>(
    Types.Company.IGetCostCentersConsumptionService
  )
  .to(GetCostCentersConsumptionService);

appIocContainer
  .bind<IListPermissibilityFleetService>(
    Types.Company.IListPermissibilityFleetService
  )
  .to(ListPermissibilityFleetService);

appIocContainer
  .bind<IGetExceptionTransactionsService>(
    Types.Company.IGetExceptionTransactionsService
  )
  .to(GetExceptionTransactionsService);

appIocContainer
  .bind<IGetExpiringLicencesService>(Types.Company.IGetExpiringLicencesService)
  .to(GetExpiringLicencesService);

appIocContainer
  .bind<IListSmallerBalancesService>(Types.Company.IListSmallerBalancesService)
  .to(ListSmallerBalancesService);

appIocContainer
  .bind<IAutocompleteUsersQueryService>(
    Types.Company.IAutocompleteUsersQueryService
  )
  .to(AutocompleteUsersQueryService);

appIocContainer
  .bind<ICreateRechargeService>(Types.Company.ICreateRechargeService)
  .to(CreateRechargeService);

appIocContainer
  .bind<ICreateDocumentService>(Types.Company.ICreateDocumentService)
  .to(CreateDocumentService);

appIocContainer
  .bind<IGetDocumentService>(Types.Company.IGetDocumentService)
  .to(GetDocumentService);

appIocContainer
  .bind<IGetPricesEstablishmentsService>(
    Types.Company.IGetPricesEstablishmentsService
  )
  .to(GetPricesEstablishmentsService);

appIocContainer
  .bind<ILocationsEstablishmentsService>(
    Types.Company.ILocationsEstablishmentsService
  )
  .to(LocationsEstablishmentsService);

appIocContainer
  .bind<IListRechargesService>(Types.Company.IListRechargesService)
  .to(ListRechargesService);

appIocContainer
  .bind<ISendJustificationTransactionService>(
    Types.Company.ISendJustificationTransactionService
  )
  .to(SendJustificationTransactionService);

appIocContainer
  .bind<IRemoveJustificationTransactionService>(
    Types.Company.IRemoveJustificationTransactionService
  )
  .to(RemoveJustificationTransactionService);

appIocContainer
  .bind<IDetailsRechargeService>(Types.Company.IDetailsRechargeService)
  .to(DetailsRechargeService);

appIocContainer
  .bind<IReverseRechargeService>(Types.Company.IReverseRechargeService)
  .to(ReverseRechargeService);

appIocContainer
  .bind<IListUsersService>(Types.User.IListUsersService)
  .to(ListUsersService);

appIocContainer
  .bind<IGetFiltersUserService>(Types.User.IGetFiltersUserService)
  .to(GetFiltersUserService);

appIocContainer
  .bind<IRemoveUserService>(Types.User.IRemoveUserService)
  .to(RemoveUserService);

appIocContainer
  .bind<IListDepartmentsService>(Types.Company.IListDepartmentsService)
  .to(ListDepartmentsService);

appIocContainer
  .bind<IRemoveDepartmentService>(Types.Company.IRemoveDepartmentService)
  .to(RemoveDepartmentService);

appIocContainer
  .bind<ICreateDepartmentService>(Types.Company.ICreateDepartmentService)
  .to(CreateDepartmentService);

appIocContainer
  .bind<IEditDepartmentService>(Types.Company.IEditDepartmentService)
  .to(EditDepartmentService);

appIocContainer
  .bind<IListFunctionsService>(Types.Company.IListFunctionsService)
  .to(ListFunctionsService);

appIocContainer
  .bind<ICreateFunctionService>(Types.Company.ICreateFunctionService)
  .to(CreateFunctionService);

appIocContainer
  .bind<IEditFunctionService>(Types.Company.IEditFunctionService)
  .to(EditFunctionService);

appIocContainer
  .bind<IRemoveFunctionService>(Types.Company.IRemoveFunctionService)
  .to(RemoveFunctionService);

appIocContainer
  .bind<IListCostCentersService>(Types.Company.IListCostCentersService)
  .to(ListCostCentersService);

appIocContainer
  .bind<ICreateCostCenterService>(Types.Company.ICreateCostCenterService)
  .to(CreateCostCenterService);

appIocContainer
  .bind<IEditCostCenterService>(Types.Company.IEditCostCenterService)
  .to(EditCostCenterService);

appIocContainer
  .bind<IRemoveCostCenterService>(Types.Company.IRemoveCostCenterService)
  .to(RemoveCostCenterService);

appIocContainer
  .bind<IListVehiclesService>(Types.Company.IListVehiclesService)
  .to(ListVehiclesService);

appIocContainer
  .bind<IRemoveVehicleService>(Types.Company.IRemoveVehicleService)
  .to(RemoveVehicleService);

appIocContainer
  .bind<IListBrandVehicleService>(Types.Company.IListBrandVehicleService)
  .to(ListBrandVehicleService);

appIocContainer
  .bind<IListModelVehicleService>(Types.Company.IListModelVehicleService)
  .to(ListModelVehicleService);

appIocContainer
  .bind<IListFleetFuelService>(Types.Company.IListFleetFuelService)
  .to(ListFleetFuelService);

appIocContainer
  .bind<ICreateVehicleService>(Types.Company.ICreateVehicleService)
  .to(CreateVehicleService);

appIocContainer
  .bind<IGetVehicleDataService>(Types.Company.IGetVehicleDataService)
  .to(GetVehicleDataService);

appIocContainer
  .bind<IEditVehicleService>(Types.Company.IEditVehicleService)
  .to(EditVehicleService);

appIocContainer
  .bind<IGetUserDataService>(Types.User.IGetUserDataService)
  .to(GetUserDataService);

appIocContainer
  .bind<IListDriversService>(Types.Company.IListDriversService)
  .to(ListDriversService);

appIocContainer
  .bind<IGetDriverService>(Types.Company.IGetDriverService)
  .to(GetDriverService);

appIocContainer
  .bind<ICreateDriverService>(Types.Company.ICreateDriverService)
  .to(CreateDriverService);

appIocContainer
  .bind<IEditDriverService>(Types.Company.IEditDriverService)
  .to(EditDriverService);

appIocContainer
  .bind<IRemoveDriverService>(Types.Company.IRemoveDriverService)
  .to(RemoveDriverService);

appIocContainer
  .bind<IResetDriverPasswordService>(Types.Company.IResetDriverPasswordService)
  .to(ResetDriverPasswordService);

appIocContainer
  .bind<IReportDebtService>(Types.Reports.IReportDebtService)
  .to(ReportDebtService);

appIocContainer
  .bind<IDownloadReportDebtService>(Types.Reports.IDownloadReportDebtService)
  .to(DownloadReportDebtService);

appIocContainer
  .bind<IReportCreditService>(Types.Reports.IReportCreditService)
  .to(ReportCreditService);

appIocContainer
  .bind<IReportNewUsersService>(Types.Reports.IReportNewUsersService)
  .to(ReportNewUsersService);

appIocContainer
  .bind<IDownloadReportCreditService>(
    Types.Reports.IDownloadReportCreditService
  )
  .to(DownloadReportCreditService);

appIocContainer
  .bind<IDownloadReportNewUsersService>(
    Types.Reports.IDownloadReportNewUsersService
  )
  .to(DownloadReportNewUsersService);

appIocContainer
  .bind<IReportBalanceService>(Types.Reports.IReportBalanceService)
  .to(ReportBalanceService);

appIocContainer
  .bind<IListRechargesByUserService>(Types.Reports.IListRechargesByUserService)
  .to(ListRechargesByUserService);

appIocContainer
  .bind<IDownloadReportBalanceService>(
    Types.Reports.IDownloadReportBalanceService
  )
  .to(DownloadReportBalanceService);

appIocContainer
  .bind<IListVehicleModelsByCompanyService>(
    Types.Reports.IListVehicleModelsByCompanyService
  )
  .to(ListVehicleModelsByCompanyService);

appIocContainer
  .bind<IReportVehicleRankingService>(
    Types.Reports.IReportVehicleRankingService
  )
  .to(ReportVehicleRankingService);

appIocContainer
  .bind<IDownloadReportVehicleRankingService>(
    Types.Reports.IDownloadReportVehicleRankingService
  )
  .to(DownloadReportVehicleRankingService);

appIocContainer
  .bind<IListPenaltiesService>(Types.Company.IListPenaltiesService)
  .to(ListPenaltiesService);

appIocContainer
  .bind<IMarkAsReadPenaltyService>(Types.Company.IMarkAsReadPenaltyService)
  .to(MarkAsReadPenaltyService);

appIocContainer
  .bind<IReportDriverPenaltiesService>(
    Types.Reports.IReportDriverPenaltiesService
  )
  .to(ReportDriverPenaltiesService);

appIocContainer
  .bind<IDownloadReportDriversPenaltiesService>(
    Types.Reports.IDownloadReportDriversPenaltiesService
  )
  .to(DownloadReportDriversPenaltiesService);

appIocContainer
  .bind<IReportVehiclesPenaltiesService>(
    Types.Reports.IReportVehiclesPenaltiesService
  )
  .to(ReportVehiclesPenaltiesService);

appIocContainer
  .bind<IDownloadReportVehiclesPenaltiesService>(
    Types.Reports.IDownloadReportVehiclesPenaltiesService
  )
  .to(DownloadReportVehiclesPenaltiesService);

appIocContainer
  .bind<ICreateUserService>(Types.User.ICreateUserService)
  .to(CreateUserService);

appIocContainer
  .bind<IEditUserService>(Types.User.IEditUserService)
  .to(EditUserService);

appIocContainer
  .bind<IGetTransactionsCreditService>(
    Types.Company.IGetTransactionsCreditService
  )
  .to(GetTransactionsCreditService);

appIocContainer
  .bind<IDownloadVehicleService>(Types.Company.IDownloadVehicleService)
  .to(DownloadVehicleService);

appIocContainer
  .bind<IChangeKMDebtReportService>(Types.Reports.IChangeKMDebtReportService)
  .to(ChangeKMDebtReportService);

appIocContainer
  .bind<IRemoveDocumentWithIdService>(
    Types.Company.IRemoveDocumentWithIdService
  )
  .to(RemoveDocumentWithIdService);

appIocContainer.bind<DriverClient>(Types.Driver).to(HttpDriverClient);
appIocContainer
  .bind<ReportDebitClient>(Types.ReportDebit)
  .to(HttpReportDebitClient);
appIocContainer
  .bind<ReportCreditClient>(Types.ReportCredit)
  .to(HttpReportCreditClient);
appIocContainer
  .bind<ReportBalanceClient>(Types.ReportBalance)
  .to(HttpReportBalanceClient);
appIocContainer.bind<FolderClient>(Types.Folder).to(FolderClientImpl);
appIocContainer.bind<DocumentClient>(Types.Document).to(DocumentClientImpl);
appIocContainer.bind<UploadClient>(Types.Upload).to(UploadClientImpl);
appIocContainer.bind<VehicleClient>(Types.Vehicle).to(VehicleClientImpl);
appIocContainer
  .bind<MaintenanceClient>(Types.Maintenance)
  .to(MaintenanceClientImpl);
appIocContainer
  .bind<TransactionClient>(Types.Transaction)
  .to(TransactionClientImpl);
appIocContainer.bind<ActivityClient>(Types.Activity).to(ActivityClientImpl);
appIocContainer
  .bind<CostCenterClient>(Types.CostCenter)
  .to(CostCenterClientImpl);
appIocContainer
  .bind<DepartmentClient>(Types.Department)
  .to(DepartmentClientImpl);
appIocContainer
  .bind<ManufacturerClient>(Types.Manufacturer)
  .to(ManufacturerClientImpl);
appIocContainer
  .bind<InfractionClient>(Types.Infraction)
  .to(InfractionClientImpl);
appIocContainer.bind<DebitClient>(Types.Debit).to(DebitClientImpl);
appIocContainer
  .bind<DashboardClient>(Types.FleetDashboard)
  .to(DashboardClientImpl);
appIocContainer
  .bind<PermissionClient>(Types.Permission)
  .to(PermissionClientImpl);
appIocContainer.bind<QuotationClient>(Types.Quotation).to(QuotationClientImpl);

export { appIocContainer };
