import React from "react";
import StyleSheet from "./StyleSheet";

interface RowProps {
    align?: "start" | "center" | "end"
}

const Row: React.FC<RowProps> = (props) => {
    const {children, align = "start"} = props;
    const styleSheet = StyleSheet();
    return (
        <div className={styleSheet.row} style={{justifyContent: align}}>
            {children}
        </div>
    );
};

export {Row};
