import { Title } from "@components/Title/Title";
import { Card, CardContent, Grid, Link, Typography } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import ForumIcon from "@material-ui/icons/Forum";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import React from "react";
import StyleSheet from "./StyleSheet";

const HelpPage: React.FC = () => {
  const styleSheet = StyleSheet();
  return (
    <div className={styleSheet.container}>
      <Title>Ajuda</Title>
      <Grid container spacing={2}>
        <Grid item>
          <Card style={{ width: "260px" }}>
            <CardContent>
              <Grid container>
                <div
                  style={{
                    marginRight: "2rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <EmailIcon color="primary" className={styleSheet.iconSize} />
                </div>
                <div>
                  <Typography className={styleSheet.subTitle}>
                    E-mail
                  </Typography>
                  <Link
                    underline="none"
                    target="_blank"
                    href="mailto:suporte@flagcard.com.br"
                  >
                    suporte@flagcard.com.br
                  </Link>
                </div>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card style={{ width: "260px" }}>
            <CardContent>
              <Grid container>
                <div
                  style={{
                    marginRight: "2rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <WhatsAppIcon
                    color="primary"
                    className={styleSheet.iconSize}
                  />
                </div>
                <div>
                  <Typography className={styleSheet.subTitle}>
                    Whatsapp
                  </Typography>
                  <Link
                    underline="none"
                    target="_blank"
                    href="http://wa.me/+55092992708123"
                  >
                    (92) 9 9270-8123
                  </Link>
                </div>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card style={{ width: "260px" }}>
            <CardContent>
              <Grid container>
                <div
                  style={{
                    marginRight: "2rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ForumIcon color="primary" className={styleSheet.iconSize} />
                </div>
                <div>
                  <Typography className={styleSheet.subTitle}>
                    Suporte no site
                  </Typography>
                  <Link
                    underline="none"
                    target="_blank"
                    href="http://suporte.flagcard.com.br"
                  >
                    suporte.flagcard.com.br
                  </Link>
                </div>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export { HelpPage };
