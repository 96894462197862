import * as yup from "yup";

export const FormEquipmentValidationSchema = yup.object().shape({
  vehicleStatus: yup.string().oneOf(["ATIVO", "MANUTENÇÃO", "INATIVO"]),
  brand: yup.number().required("Selecione a marca do veículo"),
  patrimony: yup.string().trim().required("Informe o número de patrimônio"),
  identification: yup
    .string()
    .trim()
    .required("Informe o identificação do equipamento"),
  yearManufacture: yup
    .string()
    .trim()
    .length(4, "A quantidade de dígitos ser 4")
    .test("isNumber", "Digite um número válido", (value) =>
      value ? Number.isInteger(Number(value)) : true
    )
    .required("Informe o ano de fabricação"),
  yearModel: yup
    .string()
    .trim()
    .length(4, "A quantidade de dígitos ser 4")
    .test("isNumber", "Digite um número válido", (value) =>
      value ? Number.isInteger(Number(value)) : true
    )
    .required("Informe o ano do modelo"),
  capacity: yup
    .number()
    .positive("Digite um número maior que 0")
    .required("Informe a capacidade do tanque do veículo"),
  departmentID: yup.string().required("Selecione um departamento"),
  costCenterID: yup.string().required("Selecione um centro de custos"),
  userSelected: yup.object().nullable().required("Selecione um usuário"),
  fuelSelecteds: yup
    .object()
    .test("isChecked", "Selecione pelo menos um combustível", (value) => {
      if (!value) return true;
      const keys = Object.keys(value);
      if (keys.find((key) => value[key])) {
        return true;
      }
      return false;
    }),
  qtdHoursBlock: yup
    .number()
    .test(
      "checkBlockIsChecked",
      "Informe a quantidade de horas de bloqueio",
      (value, context) => {
        const blockTime = context.parent.blocksSelecteds["4"];

        if (blockTime && Boolean(value) === false) {
          return false;
        }
        return true;
      }
    ),
});
