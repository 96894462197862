import { Driver } from "./Driver";

export interface SearchDriver {
  page: number;
  pages: number;
  count: number;
  result: Driver[];
}

export const SearchDriverInitialValues: SearchDriver = {
  page: 1,
  pages: 1,
  count: 0,
  result: [],
};
