import { CardHeaderCharts } from "@components/CardHeaderCharts";
import { ErrorLoading } from "@components/ErrorLoading";
import { NoData } from "@components/NoData";
import { useIoCContext } from "@hooks/IoCContext";
import { useUserContext } from "@hooks/UserContext";
import { Types } from "@ioc/types";
import {
  Card,
  CardActions,
  CardContent,
  Collapse,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { IGetHistoryPriceProducts } from "@modules/dashboard/models/IGetHistoryPriceProducts";
import { formatCurrency } from "@utils/index";
import Chartist, { IChartistSeriesData } from "chartist";
import clsx from "clsx";
import { format } from "date-fns";
import { parse } from "date-fns/esm";
import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import "../tooltip.css";
import "./ChartPricesMonthly.css";

const useStyles = makeStyles((theme) =>
  createStyles({
    divider: {
      backgroundColor: theme.palette.tertiary.background.primary,
    },
    color0: {
      stroke: `${theme.palette.tertiary.graphics[0]} !important`,
      backgroundColor: theme.palette.tertiary.graphics[0],
    },
    color1: { stroke: `${theme.palette.tertiary.graphics[1]} !important` },
    color2: { stroke: `${theme.palette.tertiary.graphics[2]} !important` },
    color3: { stroke: `${theme.palette.tertiary.graphics[3]} !important` },
    color4: { stroke: `${theme.palette.tertiary.graphics[4]} !important` },
    circleLegend: {
      minHeight: "1.6rem",
      minWidth: "1.6rem",
      borderRadius: "50%",
    },
    legend: {
      display: "flex",
      alignItems: "center",
      flex: "1 1 20rem",
    },
    legendText: {
      marginLeft: "1rem",
      color: theme.palette.tertiary.textButtons.primary,
      fontSize: "1.6rem",
    },
    chart: {
      "& .ct-label": {
        color:
          theme.palette.type === "dark"
            ? "#fff"
            : theme.palette.hexToRGBWithAlpha("#000000", 0.4),
      },
    },
  })
);

const ChartPricesMonthly: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const iocContext = useIoCContext();
  const { selectedCompany } = useUserContext();

  const getHistoryPricesService = iocContext.serviceContainer.get<IGetHistoryPriceProducts>(
    Types.Dashboard.IGetHistoryPriceProducts
  );

  const [loadingPriceProducts, setLoadingPriceProducts] = useState(false);
  const [errorLoadingPrices, setErrorLoadinPrices] = useState(false);
  const [priceProducts, setPriceProducts] = useState<IChartistSeriesData[]>([]);

  useEffect(() => {
    if (priceProducts.length > 0) {
      new Chartist.Line(
        "#chart2",
        {
          series: priceProducts,
        },
        {
          plugins: [
            Chartist.plugins.tooltip({
              transformTooltipTextFnc: (value: string) => {
                return formatCurrency(Number(value.split(",")[1]));
              },
            }),
          ],
          axisY: {
            scaleMinSpace: 30,
            labelInterpolationFnc: (value: number) => {
              return formatCurrency(value);
            },
          },
          axisX: {
            showGrid: false,
            type: Chartist.FixedScaleAxis,
            divisor: priceProducts[0].data
              ? priceProducts[0].data.length + 1
              : 3,
            //@ts-ignore
            ticks: priceProducts[0].data.map((ele) => ele.x),
            labelInterpolationFnc: (value: number) => {
              return format(value, "MM/yy");
            },
          },
          chartPadding: {
            right: 50,
            left: 50,
          },
        }
      );
    }
  }, [classes, priceProducts]);

  const fetchPrices = useCallback(async () => {
    if (selectedCompany) {
      try {
        setLoadingPriceProducts(true);
        setErrorLoadinPrices(false);
        const prices = await getHistoryPricesService.execute({
          companyID: selectedCompany.uuid,
        });

        const data = prices.products.map(
          (productName, idx): IChartistSeriesData => {
            const data = Object.keys(prices.accumulators).map((key) => {
              return {
                x: new Date(parse(key, "MM/yyyy", new Date())),
                y: prices.accumulators[key][productName],
              };
            });

            return {
              meta: productName,
              // @ts-ignore
              className: classes[`color${idx}`],
              data,
            };
          }
        );

        setPriceProducts(data);
      } catch (error) {
        setErrorLoadinPrices(true);
      } finally {
        setLoadingPriceProducts(false);
      }
    }
  }, [classes, getHistoryPricesService, selectedCompany]);

  useEffect(() => {
    fetchPrices();
  }, [fetchPrices]);

  return (
    <Card>
      <CardHeaderCharts
        title="Histórico de preços médios por produtos no mês (R$)"
        reloadButtonProps={{
          reloadFunc: fetchPrices,
          reloading: loadingPriceProducts,
        }}
      />
      <Divider className={classes.divider} />
      <CardContent>
        <Collapse in={errorLoadingPrices}>
          <ErrorLoading />
        </Collapse>
        <Collapse in={loadingPriceProducts} timeout={400}>
          <Skeleton
            component="div"
            className="ct-chart ct-octave"
            style={{ height: "35rem" }}
          />
        </Collapse>
        <Collapse
          in={
            !loadingPriceProducts &&
            !errorLoadingPrices &&
            priceProducts.length > 0
          }
          timeout={400}
        >
          <div
            id="chart2"
            className={clsx("ct-chart ct-octave", classes.chart)}
          />
        </Collapse>
        <Collapse
          in={
            priceProducts.length === 0 &&
            !errorLoadingPrices &&
            !loadingPriceProducts
          }
        >
          <NoData />
        </Collapse>
      </CardContent>
      <CardActions>
        {loadingPriceProducts ? (
          <Grid
            container
            justify="center"
            wrap="wrap"
            style={{ padding: "0 6rem" }}
          >
            {[1, 2, 3, 4, 5, 6].map((ele) => {
              return (
                <Skeleton
                  key={uuidv4()}
                  height={50}
                  style={{
                    flex: "1 1 100px",
                    margin: "1rem",
                  }}
                />
              );
            })}
          </Grid>
        ) : errorLoadingPrices ? (
          <></>
        ) : (
          <Grid container wrap="wrap" style={{ padding: "0 6rem" }}>
            {priceProducts.map((ele, idx) => {
              return (
                <div
                  className={classes.legend}
                  // a key é um uuid pois os produtos se repetem em outros lugares
                  key={uuidv4()}
                >
                  <div
                    className={classes.circleLegend}
                    style={{
                      // @ts-ignore
                      backgroundColor: theme.palette.tertiary.graphics[idx],
                    }}
                  />
                  <Typography className={classes.legendText}>
                    {ele.meta}
                  </Typography>
                </div>
              );
            })}
          </Grid>
        )}
      </CardActions>
    </Card>
  );
};

export { ChartPricesMonthly };
