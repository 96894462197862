import {
  Button,
  ButtonProps,
  createStyles,
  Link,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { Link as LinkRouter } from "react-router-dom";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      button: {
        textTransform: "none",
        fontSize: "1.6rem",
        justifyContent: "flex-start",
        paddingLeft: "4rem",
        "& > span": {
          fontSize: "1.2rem",
          // iphone 5
          [theme.breakpoints.up(321)]: {
            fontSize: "1.4rem",
          },
          [theme.breakpoints.up("sm")]: {
            fontSize: "1.6rem",
          },
        },
      },
      buttonEnabled: {
        color: theme.palette.primary.main,
      },
      buttonDisabled: {
        color: theme.palette.tertiary.textButtons.secondary,
      },
      icon: {
        "&:first-child": {
          fontSize: "1.8rem",
        },
        [theme.breakpoints.up("sm")]: {
          "&:first-child": {
            fontSize: "2.5rem",
          },
        },
      },
    })
);

export interface IButtonLinkWithName extends ButtonProps {
  route: string;
  title: string;
  routeExternal?: boolean;
  clickable?: boolean;
  active?: boolean;
}

const ButtonLinkWithName: React.FC<IButtonLinkWithName> = ({
  route,
  title,
  children,
  routeExternal,
  clickable = true,
  active = false,
  ...props
}) => {
  const classes = useStyles();

  return (
    //@ts-ignore
    <Button
      component={clickable ? (routeExternal ? Link : LinkRouter) : "span"}
      to={routeExternal ? "" : route}
      href={routeExternal ? route : ""}
      fullWidth
      startIcon={React.Children.map(children, (child) => {
        // @ts-ignore
        return React.cloneElement(child, {
          className: classes.icon,
        });
      })}
      className={clsx(classes.button, {
        [classes.buttonEnabled]: active,
        [classes.buttonDisabled]: active === false,
      })}
      {...props}
    >
      {title}
    </Button>
  );
};

export { ButtonLinkWithName };
