import React, { useEffect, useState } from "react";
import StyleSheet from "../StyleSheet";
import { Title } from "@components/Title/Title";
import { Filter, SearchContainer } from "@components/SearchContainer";
import { Button } from "@components/Button";
import { ROUTES } from "@config/routesConfig";
import { FilterDefinition } from "@pages/Fleet/Maintenance/List/FilterDefinition";
import { ColumnDefinition } from "./ColumDefinition";
import { DataTable } from "@components/DataTable";
import { SearchInitialValues } from "../../../../client/model/Search";
import { useHistory, useLocation } from "react-router-dom";
import { useUserContext } from "@hooks/UserContext";
import { useIoCContext } from "@hooks/IoCContext";
import { Types } from "@ioc/types";
import { MaintenanceClient } from "../../../../client/Maintenance/MaintenanceClient";
import { useSnackbar } from "notistack";

const MaintenanceList: React.FC = () => {
  const styleSheet = StyleSheet();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [result, setResult] = useState(SearchInitialValues);
  const [filters, setFilters] = useState<Filter[]>(FilterDefinition);
  const [success, setSuccess] = useState(
    !!(location?.state as { success: boolean })?.success
  );

  const client = iocContext.serviceContainer.get<MaintenanceClient>(
    Types.Maintenance
  );

  const handleEdit = (id?: string): void => {
    history.push(ROUTES.MAINTENANCE_EDIT(id));
  };

  const handleRemove = async (id?: string) => {
    if (!selectedCompany || !id) return;
    setLoading(true);
    await client.remove(selectedCompany.uuid, id);
    setSuccess(true);
    fetchOrders();
  };

  const fetchOrders = () => {
    if (success) {
      enqueueSnackbar("Operação realizada com sucesso.", {
        variant: "success",
      });
      setSuccess(false);
    }
    if (!selectedCompany) return;
    setLoading(true);
    const plate = filters.find((filter) => filter.field === "plate")?.value;
    const orderId = filters.find((filter) => filter.field === "order_id")
      ?.value;
    const type = filters.find((filter) => filter.field === "type")?.value;
    const status = filters.find((filter) => filter.field === "status")?.value;
    const filter = {
      page: page - 1,
      limit,
      plate,
      order_id: orderId,
      type,
      status,
    };
    client
      .find(selectedCompany.uuid, filter)
      .then((response) => {
        setResult(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar(error.response?.data?.message, { variant: "error" });
      });
  };

  useEffect(fetchOrders, [page, limit, filters]);

  return (
    <div className={styleSheet.container}>
      <Title>Ordem de Serviço</Title>
      <SearchContainer
        filters={filters}
        onFilter={(filters: Filter[]) => {
          setFilters(filters);
        }}
      >
        <Button to={ROUTES.MAINTENANCE_ADD}>Nova Ordem de Serviço</Button>
      </SearchContainer>
      <DataTable
        columns={ColumnDefinition}
        rows={result.result}
        limit={limit}
        pages={result.pages}
        total={result.total}
        loading={loading}
        onEdit={handleEdit}
        onRemove={handleRemove}
        editable
        removable
        onPagination={(page) => setPage(page)}
        onLimitChange={(limit) => setLimit(limit)}
      />
    </div>
  );
};

export default MaintenanceList;
