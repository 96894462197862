import {ErrorLoading} from "@components/ErrorLoading";
import {ReloadButton} from "@components/ReloadButton";
import {Title} from "@components/Title";
import {IPropsEquipmentsEditPage, ROUTES} from "@config/routesConfig";
import {useIoCContext} from "@hooks/IoCContext";
import {useUserContext} from "@hooks/UserContext";
import {Types} from "@ioc/types";
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    createStyles,
    Grid,
    makeStyles,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {IUserAutocomplete} from "@modules/company/dtos/IAutocompleteUsersDTO";
import {VehicleType} from "@modules/company/dtos/IListBrandVehicleDTO";
import {
    IVehicleDTO,
    VehicleStatus,
} from "@modules/company/dtos/IListPermissibilityFleetDTO";
import {ICreateVehicleService} from "@modules/company/models/ICreateVehicleService";
import {IEditVehicleService} from "@modules/company/models/IEditVehicleService";
import {IGetVehicleDataService} from "@modules/company/models/IGetVehicleDataService";
import AppError from "@utils/AppError";
import {Formik} from "formik";
import {useSnackbar} from "notistack";
import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import {FormEquipment} from "./FormEquipment";
import {FormEquipmentValidationSchema} from "./FormEquipmentValidationSchema";

const useStyles = makeStyles(
    ({typography: {pxToRem, ...typography}, ...theme}) =>
        createStyles({
            backButton: {
                fontWeight: typography.fontWeightBold,
            },
        })
);

export interface IFormEquipment {
    vehicleType: VehicleType;
    vehicleStatus: VehicleStatus;
    model: string;
    brand: string;
    identification: string;
    patrimony: string;
    yearManufacture: number;
    yearModel: number;
    capacity: number;
    departmentID: string;
    costCenterID: string;
    userSelected: IUserAutocomplete | null;
    fuelSelecteds: { [fuelID: string]: boolean };
    groupFuelSelected: number | null;
    blocksSelecteds: { [blockID: string]: boolean };
    qtdHoursBlock: number;
}

const EquipmentsCreateOrEditPage: React.FC = () => {
    const classes = useStyles();
    const {equipmentID} = useParams<IPropsEquipmentsEditPage>();
    const userContext = useUserContext();
    const history = useHistory();
    const iocContext = useIoCContext();
    const {enqueueSnackbar} = useSnackbar();

    const getDataEquipmentService = iocContext.serviceContainer.get<IGetVehicleDataService>(
        Types.Company.IGetVehicleDataService
    );
    const createEquipmentService = iocContext.serviceContainer.get<ICreateVehicleService>(
        Types.Company.ICreateVehicleService
    );
    const editEquipmentService = iocContext.serviceContainer.get<IEditVehicleService>(
        Types.Company.IEditVehicleService
    );

    const [loading, setLoading] = useState(false);
    const [errorLoading, setErrorLoading] = useState(false);
    const [equipmentData, setEquipmentData] = useState<IVehicleDTO | null>(null);

    const fetchEquipment = useCallback(async () => {
        if (!equipmentID) return;
        if (!userContext.selectedCompany) return;
        try {
            setLoading(true);
            const equipmentData = await getDataEquipmentService.execute({
                companyID: userContext.selectedCompany.uuid,
                vehicleID: equipmentID,
            });
            setEquipmentData(equipmentData);
            setErrorLoading(false);
        } catch (error) {
            setErrorLoading(true);
        } finally {
            setLoading(false);
        }
    }, [getDataEquipmentService, userContext.selectedCompany, equipmentID]);

    useEffect(() => {
        fetchEquipment();
    }, [fetchEquipment]);

    const initialValues: IFormEquipment = {
        vehicleType: "equipment",
        model: "",
        vehicleStatus: equipmentData ? equipmentData.status : "ATIVO",
        brand: equipmentData ? equipmentData.Mmodel.Brand.id : "",
        identification: equipmentData ? equipmentData.plate : "",
        patrimony: equipmentData ? equipmentData.patrimony : "",
        yearManufacture: equipmentData
            ? equipmentData.year_born
            : ((undefined as unknown) as number),
        yearModel: equipmentData
            ? equipmentData.year_model
            : ((undefined as unknown) as number),
        capacity: equipmentData
            ? equipmentData.capacity
            : ((undefined as unknown) as number),
        costCenterID: equipmentData ? (equipmentData.cost_center_id as string) : "",
        departmentID: equipmentData ? (equipmentData.departament_id as string) : "",
        userSelected: equipmentData ? equipmentData.user : null,
        fuelSelecteds: equipmentData
            ? equipmentData.VehicleFuels.reduce((a, b) => {
                //@ts-ignore
                if (!a[b.fuel_id]) {
                    //@ts-ignore
                    a[b.fuel_id] = true;
                }
                return a;
            }, {})
            : {},
        groupFuelSelected: null,
        blocksSelecteds: equipmentData
            ? {
                "1": equipmentData.block_fuel,
                "2": equipmentData.block_capacity,
                "4": equipmentData.block_hours > 0 ? true : false,
            }
            : {},
        qtdHoursBlock: equipmentData
            ? equipmentData.block_hours
            : ((undefined as unknown) as number),
    };

    return (
        <>
            <Button
                color="primary"
                startIcon={<ArrowBackIosIcon/>}
                className={classes.backButton}
                onClick={() => history.goBack()}
            >
                Voltar
            </Button>
            <Title
                subtitle={
                    equipmentID
                        ? "Adicione ou altere informações do equipamento"
                        : "Adicione informações do equipamento"
                }
            >
                {equipmentID ? "Editar equipamento" : "Cadastrar novo equipamento"}
            </Title>

            {loading ? (
                <Grid container justify="center" alignItems="center">
                    <CircularProgress/>
                </Grid>
            ) : errorLoading ? (
                <Grid container justify="center" alignItems="center">
                    <ErrorLoading messageError="Ocorreu um erro ao baixar dados do equipamento"/>
                    <ReloadButton reloading={loading} reloadFunc={fetchEquipment}/>
                </Grid>
            ) : (
                <Card>
                    <CardContent>
                        <Formik
                            onSubmit={async (values) => {
                                if (!userContext.selectedCompany) return;
                                try {
                                    const casted = FormEquipmentValidationSchema.cast(values);

                                    const equipmentDataForm = {
                                        block_fuel: values.blocksSelecteds["1"],
                                        block_capacity: values.blocksSelecteds["2"],
                                        block_hours: values.blocksSelecteds["4"]
                                            ? (casted.qtdHoursBlock as number)
                                            : 0,
                                        capacity: casted.capacity as number,
                                        /**
                                         * equipamento não tem as propriedades, mas como o veículo possui
                                         * apenas aceitamos e adicionamos
                                         */
                                        color: "Azul",

                                        company_id: userContext.selectedCompany.uuid,

                                        /**
                                         * novamente
                                         */
                                        consumption: 10,
                                        max_consumption: 10,

                                        cost_center_id: String(values.costCenterID),
                                        departament_id: String(values.departmentID),
                                        fuels: Object.keys(casted.fuelSelecteds),

                                        /**
                                         * novamente
                                         */
                                        model_id: values.model,
                                        /**
                                         * novamente
                                         */
                                        motorization: 1,

                                        patrimony: casted.patrimony as string,
                                        plate: casted.identification as string,

                                        /**
                                         * novamente
                                         */
                                        prefix: "",

                                        status: casted.vehicleStatus as string,
                                        year_born: Number(casted.yearManufacture),
                                        year_model: Number(casted.yearModel),
                                        user_id: values.userSelected?.id as string,
                                    };

                                    if (equipmentData) {
                                        // @ts-ignore
                                        await editEquipmentService.execute({
                                            ...equipmentDataForm,
                                            // @ts-ignore
                                            chassi: null,
                                            // @ts-ignore
                                            renavam: null,
                                            id: equipmentID,
                                        });
                                    } else {
                                        // @ts-ignore
                                        await createEquipmentService.execute(equipmentDataForm);
                                    }

                                    history.push(ROUTES.EQUIPMENTS);
                                } catch (error) {
                                    if (error instanceof AppError) {
                                        return enqueueSnackbar(error.message, {
                                            variant: error.variant,
                                        });
                                    }
                                    enqueueSnackbar(
                                        equipmentID
                                            ? "Ocorreu um erro ao editar equipamento"
                                            : "Ocorreu um erro ao adicionar equipamento",
                                        {
                                            variant: "error",
                                        }
                                    );
                                }
                            }}
                            initialValues={initialValues}
                            validationSchema={FormEquipmentValidationSchema}
                        >
                            <FormEquipment/>
                        </Formik>
                    </CardContent>
                </Card>
            )}
        </>
    );
};

export {EquipmentsCreateOrEditPage};
