import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { parse } from "date-fns";
import { inject, injectable } from "inversify";
import { IPenaltyDTO } from "../dtos/IPenaltyDTO";
import { IListPenaltiesService } from "../models/IListPenaltiesService";

@injectable()
export class ListPenaltiesService implements IListPenaltiesService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    userID: string;
  }): Promise<IPenaltyDTO[]> {
    const resp = await this.httpInstance.get<IPenaltyDTO[]>(
      `/companies/${data.companyID}/penalties`,
      {
        params: {
          user_id: data.userID,
        },
      }
    );

    return resp.map((penalty) => {
      return {
        ...penalty,
        date: parse(
          (penalty.date as unknown) as string,
          "dd/MM/yyyy",
          new Date()
        ),
        due_value: Number(penalty.due_value),
        paid_value: Number(penalty.paid_value),
      };
    });
  }
}
