import * as yup from "yup";

const REQUIRED_MESSAGE = "campo obrigatório";

const INVALID_FORMAT = "Formato incorreto";

export default yup.object({
  manufacturerId: yup.string().nullable().trim().required(REQUIRED_MESSAGE),
  modelId: yup.string().nullable().trim().required(REQUIRED_MESSAGE),
  plate: yup
    .string()
    .nullable()
    .trim()
    .required(REQUIRED_MESSAGE)
    .matches(/[A-Z]{3}[0-9][A-Z][0-9]{2}|[A-Z]{3}-[0-9]{4}/, INVALID_FORMAT),
  chassi: yup
    .string()
    .nullable()
    .trim()
    .required(REQUIRED_MESSAGE)
    .min(17, "Tamanho mínimo de 17 caracteres"),
  renavam: yup
    .string()
    .nullable()
    .trim()
    .required(REQUIRED_MESSAGE)
    .min(11, "Tamanho mínimo de 11 caracteres"),
  consumption: yup.number().typeError(INVALID_FORMAT),
});
