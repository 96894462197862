import React, { useEffect, useState } from "react";
import StyleSheet from "./StyleSheet";
import { Title } from "@components/Title/Title";
import { DataColumn, DataTable } from "@components/DataTable";
import { SearchInitialValues } from "../../../client/model/Search";
import { useUserContext } from "@hooks/UserContext";
import { useIoCContext } from "@hooks/IoCContext";
import { Types } from "@ioc/types";
import { useHistory, useLocation } from "react-router-dom";
import { Filter, SearchContainer } from "@components/SearchContainer";
import Message from "@components/Message";
import { ROUTES } from "@config/routesConfig";
import { Button } from "@components/Button";
import { DepartmentClient } from "../../../client/Department/DepartmentClient";

const columns: DataColumn[] = [
  {
    field: "description",
    headerName: "Descrição",
  },
];

const DepartmentList: React.FC = () => {
  const styleSheet = StyleSheet();
  const history = useHistory();
  const location = useLocation();
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [result, setResult] = useState(SearchInitialValues);
  const [success, setSuccess] = useState(
    !!(location?.state as { success: boolean })?.success
  );
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [filters, setFilters] = useState<Filter[]>([
    {
      label: "Descrição",
      field: "description",
      placeholder: "Pesquisar por Departamento",
    },
  ]);

  const client = iocContext.serviceContainer.get<DepartmentClient>(
    Types.Department
  );

  const fetchDepartments = () => {
    if (!selectedCompany) return;
    setLoading(true);
    const description = filters.find(
      (filter) => filter.field === "description"
    );
    const filter = {
      page: page,
      limit: limit,
      description: description?.value,
    };
    client
      .find(selectedCompany.uuid, filter)
      .then((response) => {
        setResult(response);
        setLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error.response?.data?.message);
        setError(true);
        setLoading(false);
      });
  };

  const handleEdit = (id?: string): void => {
    history.push(ROUTES.DEPARTMENTS_EDIT(id));
  };

  const handleRemove = async (id?: string) => {
    if (!selectedCompany || !id) return;
    setLoading(true);
    await client.remove(selectedCompany.uuid, id);
    setSuccess(true);
    fetchDepartments();
  };

  useEffect(fetchDepartments, [filters, page, selectedCompany, limit]);

  return (
    <div className={styleSheet.container}>
      <Title>Departamento</Title>
      <SearchContainer
        filters={filters}
        onFilter={(filters: Filter[]) => {
          setPage(1);
          setFilters(filters);
        }}
      >
        <Button to={ROUTES.DEPARTMENTS_ADD}>Novo Departamento</Button>
      </SearchContainer>
      <DataTable
        columns={columns}
        rows={result.result}
        limit={limit}
        pages={result.pages}
        total={result.total}
        loading={loading}
        onEdit={handleEdit}
        onRemove={handleRemove}
        editable
        removable
        onPagination={(page) => setPage(page)}
        onLimitChange={(limit) => setLimit(limit)}
      />
      <Message.Success open={success} onClose={() => setSuccess(false)} />
      <Message.Error
        open={error}
        onClose={() => {
          setError(false);
          setErrorMessage(undefined);
        }}
      >
        {errorMessage}
      </Message.Error>
    </div>
  );
};

export default DepartmentList;
