import React, { ChangeEvent, useEffect, useState } from "react";
import StyleSheet from "./StyleSheet";
import { Title } from "@components/Title/Title";
import { Filter, SearchContainer } from "@components/SearchContainer";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@material-ui/core";
import { ColumnDefinition } from "@pages/Debit/ColumDefinition";
import { DataTable } from "@components/DataTable";
import { useUserContext } from "@hooks/UserContext";
import { useIoCContext } from "@hooks/IoCContext";
import { useSnackbar } from "notistack";
import { SearchInitialValues } from "../../client/model/Search";
import { Types } from "@ioc/types";
import { DebitClient } from "../../client/Debit/DebitClient";
import Debit from "../../client/Debit/Debit";
import { Subtitles } from "@material-ui/icons";
import { Button } from "@components/Button";
import { formatCurrency } from "@utils/index";
import { format } from "date-fns/esm";
import NotAuthorized from "@pages/Auth/NotAuthorized";

const getDebitType = (type?: string) => {
  if (type === "licenciamento") {
    return "Licenciamento";
  }
  if (type === "dpvat") {
    return "DPVAT";
  }
  if (type === "ipva") {
    return "IPVA";
  }
  if (type === "outros_debitos") {
    return "Outros Débitos";
  }
};

const getDebitStatus = (status?: string) => {
  if (status === "em_aberto") {
    return "Pendente";
  }
  return "Dívida Ativa";
};

const formatDate = (value?: Date | null) => {
  if (!value) return "-";
  return format(new Date(String(value)), "dd/MM/yyyy");
};

const DebitDetail: React.FC<{
  title: string;
  content?: string;
  width?: number;
}> = ({ title, content, width }) => {
  const styleSheet = StyleSheet();
  return (
    <div
      className={styleSheet["infraction-detail"]}
      style={{ width: `${width || 490}px` }}
    >
      <label className={styleSheet["infraction-detail-title"]}>{title}</label>
      <span className={styleSheet["infraction-detail-text"]}>
        {content || "-"}
      </span>
    </div>
  );
};

const DebitList: React.FC = () => {
  const styleSheet = StyleSheet();
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();
  const [result, setResult] = useState(SearchInitialValues);
  const [debit, setDebit] = useState<Debit>();
  const [loading, setLoading] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [authorized, setAuthorized] = useState(false);
  const [status, setStatus] = useState({
    em_aberto: true,
    divida_ativa: true,
  });
  const [statuses, setStatuses] = useState<string[]>([
    "em_aberto",
    "divida_ativa",
  ]);
  const [filters, setFilters] = useState<Filter[]>([
    {
      label: "Placa",
      field: "plate",
      placeholder: "Pesquisar por Placa",
    },
  ]);

  const client = iocContext.serviceContainer.get<DebitClient>(Types.Debit);

  const handleOpenDetails = (debit: Debit) => {
    setDebit(debit);
    // setInfractionColor(getInfractionColorByPoints(infraction.infractionPoint));
    // setInfractionLabel(getInfractionLabelByPoints(infraction.infractionPoint));
    setOpenDetails(true);
  };

  const handleChangeStatusDebit = (event: ChangeEvent<HTMLInputElement>) => {
    const set = new Set(statuses);
    if (event.target.checked) {
      set.add(event.target.name);
    } else {
      set.delete(event.target.name);
    }
    if (set.size === 0) {
      enqueueSnackbar("É necessário pelo menos um status selecionado", {
        variant: "warning",
      });
    } else {
      setStatuses(Array.from(set));
      setStatus({ ...status, [event.target.name]: event.target.checked });
    }
  };

  const download = (endpoint?: string) => {
    if (endpoint) {
      window.open(endpoint);
    }
  };

  const fetchDebits = () => {
    if (!selectedCompany) return;
    const attributes = selectedCompany?.Company?.attributes || [];
    setAuthorized(attributes.includes("infraction"));
    if (authorized) {
      setLoading(true);
      const plate = filters.find((filter) => filter.field === "plate");
      const filter = {
        page,
        statuses: statuses.join(","),
        limit,
        plate: plate?.value,
      };
      client
        .find(selectedCompany.uuid, filter)
        .then((response) => {
          setResult(response);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          enqueueSnackbar(error.response?.data?.message, { variant: "error" });
        });
    }
  };

  useEffect(fetchDebits, [
    page,
    limit,
    filters,
    status,
    selectedCompany,
    authorized,
  ]);

  return (
    <div className={styleSheet.container}>
      <Title>Débitos de Veículos</Title>
      {authorized ? (
        <>
          <SearchContainer
            filters={filters}
            onFilter={(filters: Filter[]) => {
              setFilters(filters);
            }}
          >
            <div>
              <FormControlLabel
                label="Pendente"
                control={
                  <Checkbox
                    name="em_aberto"
                    checked={status.em_aberto}
                    color="primary"
                    onChange={handleChangeStatusDebit}
                  />
                }
              />
              <FormControlLabel
                label="Dívida Ativa"
                control={
                  <Checkbox
                    name="divida_ativa"
                    checked={status.divida_ativa}
                    color="primary"
                    onChange={handleChangeStatusDebit}
                  />
                }
              />
            </div>
          </SearchContainer>
          <DataTable
            columns={ColumnDefinition}
            rows={result.result}
            limit={limit}
            pages={result.pages}
            total={result.total}
            onClick={handleOpenDetails}
            loading={loading}
            onPagination={(page) => setPage(page)}
            onLimitChange={(limit) => setLimit(limit)}
          />
          <Dialog open={openDetails} onClose={() => setOpenDetails(false)}>
            <DialogTitle>Detalhes do Débito</DialogTitle>
            <DialogContent>
              <div style={{ display: "flex" }}>
                <div style={{ paddingRight: 10 }}>
                  <Subtitles style={{ fontSize: 48 }} />
                </div>
                <div>
                  <div style={{ width: 490 }}>
                    Parcela{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {debit?.installment}
                    </span>{" "}
                    do{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {getDebitType(debit?.type)}
                    </span>{" "}
                    para o veículo de{" "}
                    <span style={{ fontWeight: "bold" }}>Placa</span>{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {debit?.vehicle.plate}
                    </span>{" "}
                    referente ao ano de{" "}
                    <span style={{ fontWeight: "bold" }}>{debit?.year}</span>.
                  </div>
                  <div style={{ display: "flex", paddingTop: 10 }}>
                    <div
                      className={styleSheet["infraction-detail-card"]}
                      style={{ width: 200 }}
                    >
                      Vencimento:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {formatDate(debit?.expirationDate)}
                      </span>
                    </div>
                    <div style={{ width: 10 }} />
                    <div
                      className={styleSheet["infraction-detail-card"]}
                      style={{ width: 130 }}
                    >
                      Valor:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {formatCurrency(debit?.paymentValue || 0)}
                      </span>
                    </div>
                    <div style={{ width: 10 }} />
                    <div
                      className={styleSheet["infraction-detail-card"]}
                      style={{ width: 140 }}
                    >
                      Status:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {getDebitStatus(debit?.status)}
                      </span>
                    </div>
                  </div>
                  <div style={{ height: 14 }} />
                  <DebitDetail
                    title={"Órgão Autuador"}
                    content={debit?.providerName}
                  />
                  <div style={{ height: 16 }} />
                  <DebitDetail
                    title={"Código de Barras"}
                    content={debit?.barcode}
                  />
                  <div style={{ height: 4 }} />
                  <span>
                    Para pagamento, copie o código de barras ou, se preferir,
                    faça o{" "}
                    <span
                      style={{
                        cursor: "pointer",
                        color: "#0F71D0",
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                      onClick={() => download(debit?.ticketFile)}
                    >
                      download
                    </span>{" "}
                    do Boleto
                  </span>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={() => setOpenDetails(false)}>
                Fechar
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <NotAuthorized module="Débitos de Veículos" />
      )}
      <></>
    </div>
  );
};

export default DebitList;
