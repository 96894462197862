import { inject, injectable } from "inversify";
import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { Filter } from "../model/Filter";
import { Search } from "../model/Search";
import { ReportBalanceClient } from "../ReportBalanceClient";
import { ReportBalance } from "../model/ReportBalance";

@injectable()
export class HttpReportBalanceClient implements ReportBalanceClient {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public find(
    companyId: string,
    filter: Filter
  ): Promise<Search<ReportBalance>> {
    return this.httpInstance.get(`/companies/${companyId}/report/balance`, {
      params: filter,
    });
  }

  public async download(companyId: string, filter: Filter): Promise<string> {
    const result = await this.httpInstance.get(
      `/companies/${companyId}/report/balance/download`,
      { params: filter, responseType: "arraybuffer" }
    );
    const blob = new Blob([result], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    return window.URL.createObjectURL(blob);
  }
}
