import Chartist from "chartist";
import Dashboard from "../../../client/fleet/dashboard/Dashboard";
import React, { useEffect } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import ReactDOMServer from "react-dom/server";
import StyleSheet from "@pages/Fleet/Dashboard/StyleSheet";

export const ChartPieVehiclesWithInfractions: React.FC<{
  dashboard?: Dashboard;
}> = ({ dashboard }) => {
  const styleSheet = StyleSheet();
  const colors = ["#FFA500", "#8B0000"];
  const render = () => {
    if (dashboard) {
      const quantityVehicles = Number(dashboard.vehicle?.quantity || 0);
      const infractions = Number(dashboard.infraction?.quantity || 0);
      const quantityInfractions =
        quantityVehicles < infractions ? quantityVehicles : infractions;
      const chart = new Chartist.Pie(
        "#chart_pie_vehicles_with_infraction_infraction",
        {
          series: [
            {
              meta: "Sem Infração",
              value: quantityVehicles - quantityInfractions,
            },
            { meta: "Com Infração", value: quantityInfractions },
          ],
        },
        {
          donut: true,
          showLabel: false,
          donutWidth: 40,
          donutSolid: true,
          plugins: [
            Chartist.plugins.tooltip({
              transformTooltipTextFnc: (value: number) => value,
              appendToBody: true,
            }),
            Chartist.plugins.fillDonut({
              items: [
                {
                  content: ReactDOMServer.renderToString(
                    <div>
                      <Typography className={styleSheet.donutTitle}>
                        {quantityVehicles}
                      </Typography>
                      <Typography className={styleSheet.donutSubtitle}>
                        Total de Veículos
                      </Typography>
                    </div>
                  ),
                },
              ],
            }),
          ],
        }
      );

      chart.on("draw", (data: any) => {
        if (data.type === "slice") {
          data.element.attr({
            style: `fill: ${colors[data.index]}`,
          });
        }
      });
    }
  };
  useEffect(render, [dashboard]);
  return (
    <Card>
      <CardHeader
        title={
          <div className={styleSheet.chart_container_tile}>
            Quantidade de Infrações por Veículos
          </div>
        }
        style={{ justifyContent: "center", display: "flex" }}
      />
      <Divider />
      <CardContent style={{ display: "flex", justifyContent: "center" }}>
        <div
          id="chart_pie_vehicles_with_infraction_infraction"
          className={clsx("ct-chart", "ct-octave", styleSheet.chart_container)}
        />
      </CardContent>
      <CardActions>
        <div className={styleSheet.legend}>
          <div
            className={styleSheet.circleLegend}
            style={{ backgroundColor: colors[0] }}
          />
          <Typography className={styleSheet.legendText}>
            Veículos sem Infração
          </Typography>
        </div>
        <div className={styleSheet.legend}>
          <div
            className={styleSheet.circleLegend}
            style={{ backgroundColor: colors[1] }}
          />
          <Typography className={styleSheet.legendText}>
            Veículos com Infração
          </Typography>
        </div>
      </CardActions>
    </Card>
  );
};
