import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import React from "react";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      container: {
        borderWidth: 1,
        borderStyle: "dashed",
        borderColor: theme.palette.tertiary.error.primary,

        borderRadius: theme.shape.borderRadius,

        flexDirection: "column",
        padding: "1rem",
        [theme.breakpoints.up("sm")]: {
          flexDirection: "row",
        },
      },
      iconWarning: {
        color: theme.palette.tertiary.intermediate.primary,
        fontSize: "6.5rem",
      },
      title: {
        fontSize: "2.4rem",
        color: theme.palette.tertiary.intermediate.primary,
        fontWeight: typography.fontWeightBold,
      },
    })
);

const PermissiveTransactions: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      justify="space-evenly"
      alignItems="center"
      className={classes.container}
      wrap="nowrap"
      spacing={2}
    >
      <Grid item>
        <WarningIcon className={classes.iconWarning} />
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography align="center" className={classes.title}>
            Atenção
          </Typography>
          <Typography align="center">
            Ao ser violada, essa regra gera uma&nbsp;
            <strong>transação inválida passível de autorização.</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="justify">
            Após ocorrer uma transação inválida (consultar Tela de&nbsp;
            <strong>Dashboard</strong>) esse bloqueio é passível de ser liberado
            momentaneamente mediante a uma justificativa. A validade da
            liberação é somente para o próximo abastecimento que deverá ocorrer
            em um prazo de até 8 horas após a justificativa.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { PermissiveTransactions };
