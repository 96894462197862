import { HomePage } from "@pages/Dashboard";
import { DriversPage } from "@pages/Drivers";
import { EquipmentsPage } from "@pages/Equipments";
import { EquipmentsCreateOrEditPage } from "@pages/EquipmentsCreateOrEdit";
import { EstablishmentsLocationsPage } from "@pages/EstablishmentsLocations";
import { EstablishmentsPricePage } from "@pages/EstablishmentsPrices";
import { HelpPage } from "@pages/Help";
import { RechargesPage } from "@pages/Recharges";
import { BalanceReportPage } from "@pages/Reports/Balance";
import { CreditManagementReportPage } from "@pages/Reports/CreditManagementReport";
import { DebtManagementReportPage } from "@pages/Reports/DebtManagementReport";
import { NewUsersReportPage } from "@pages/Reports/NewUsersReport";
import { UsersPage } from "@pages/Users";
import CostCenterList from "@pages/CostCenter/List";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ROUTES } from "@config/routesConfig";
import { DriversEditPage } from "@pages/Drivers/Edit";
import FolderList from "@pages/Folder/List";
import FolderEdit from "@pages/Folder/Edit";
import DocumentList from "@pages/Documents/List";
import CostCenterEdit from "@pages/CostCenter/Edit";
import DepartmentList from "@pages/Department/List";
import DepartmentEdit from "@pages/Department/Edit";
import ActivityList from "@pages/Activity/List";
import ActivityEdit from "@pages/Activity/Edit";
import VehicleList from "@pages/Vehicles/List";
import VehicleEdit from "@pages/Vehicles/Edit";
import InfractionList from "@pages/Infraction";
import DebitList from "@pages/Debit";
import FleetDashboard from "@pages/Fleet/Dashboard";
import MaintenanceList from "@pages/Fleet/Maintenance/List";
import MaintenanceEdit from "@pages/Fleet/Maintenance/Edit";

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path={ROUTES.DASHBOARD} exact component={HomePage} />
      <Route exact component={FolderList} path={ROUTES.FOLDER} />
      <Route component={FolderEdit} path={ROUTES.FOLDER_ADD} />
      <Route component={FolderEdit} path={ROUTES.FOLDER_EDIT()} />

      <Route component={DocumentList} path={ROUTES.DOCUMENT()} />

      <Route component={DepartmentList} path={ROUTES.DEPARTMENTS} />
      <Route exact component={DepartmentEdit} path={ROUTES.DEPARTMENTS_ADD} />
      <Route component={DepartmentEdit} path={ROUTES.DEPARTMENTS_EDIT()} />

      <Route component={ActivityList} path={ROUTES.ACTIVITIES} />
      <Route exact component={ActivityEdit} path={ROUTES.ACTIVITIES_ADD} />
      <Route component={ActivityEdit} path={ROUTES.ACTIVITIES_EDIT()} />

      <Route component={CostCenterList} path={ROUTES.COST_CENTERS} />
      <Route exact component={CostCenterEdit} path={ROUTES.COST_CENTERS_ADD} />
      <Route component={CostCenterEdit} path={ROUTES.COST_CENTERS_EDIT()} />

      <Route component={VehicleList} path={ROUTES.VEHICLES} />
      <Route exact component={VehicleEdit} path={ROUTES.VEHICLES_ADD} />
      <Route component={VehicleEdit} path={ROUTES.VEHICLES_EDIT()} />

      <Route component={InfractionList} path={ROUTES.INFRACTION} />
      <Route component={DebitList} path={ROUTES.DEBITS} />
      <Route component={FleetDashboard} path={ROUTES.FLEET_DASHBOARD} />

      <Route component={MaintenanceList} path={ROUTES.MAINTENANCE} />
      <Route exact component={MaintenanceEdit} path={ROUTES.MAINTENANCE_ADD} />
      <Route component={MaintenanceEdit} path={ROUTES.MAINTENANCE_EDIT()} />

      <Route
        component={EstablishmentsPricePage}
        path={ROUTES.ESTABLISHMENTS_PRICES}
      />
      <Route
        component={EstablishmentsLocationsPage}
        path={ROUTES.ESTABLISHMENTS_LOCATIONS}
      />
      <Route component={RechargesPage} path={ROUTES.RECHARGE} />

      <Route component={UsersPage} path={ROUTES.USERS} />

      <Route exact component={EquipmentsPage} path={ROUTES.EQUIPMENTS} />
      <Route
        component={EquipmentsCreateOrEditPage}
        path={ROUTES.EQUIPMENTS_CREATE}
      />
      <Route
        component={EquipmentsCreateOrEditPage}
        path={ROUTES.EQUIPMENTS_EDIT()}
      />
      <Route exact component={DriversPage} path={ROUTES.DRIVERS} />
      <Route component={DriversEditPage} path={ROUTES.DRIVERS_ADD} />
      <Route component={DriversEditPage} path={ROUTES.DRIVERS_EDIT()} />
      <Route component={HelpPage} path={ROUTES.HELP} />
      <Route component={NewUsersReportPage} path={ROUTES.REPORTS_NEW_USERS} />
      <Route component={DebtManagementReportPage} path={ROUTES.REPORTS_DEBTS} />
      <Route
        component={CreditManagementReportPage}
        path={ROUTES.REPORTS_CREDITS}
      />
      <Route component={BalanceReportPage} path={ROUTES.REPORTS_BALANCE} />
      <Redirect path="*" to={ROUTES.DASHBOARD} />
    </Switch>
  );
};

export { Routes };
