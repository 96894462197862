import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IPricesEstablishmentDTO } from "../dtos/IPriceEstablismentDTO";
import { IGetPricesEstablishmentsService } from "../models/IGetPricesEstablishmentsService";

@injectable()
export class GetPricesEstablishmentsService
  implements IGetPricesEstablishmentsService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
  }): Promise<IPricesEstablishmentDTO[]> {
    const resp = await this.httpInstance.get(
      `/companies/${data.companyID}/prices/current`
    );
    return resp;
  }
}
