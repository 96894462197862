import React, { useEffect, useState } from "react";
import { Button } from "@components/Button";
import { ROUTES } from "@config/routesConfig";
import { Row } from "@components/Row";
import { Types } from "@ioc/types";
import { useUserContext } from "@hooks/UserContext";
import { useIoCContext } from "@hooks/IoCContext";
import { Filter, SearchContainer } from "@components/SearchContainer";
import { FilterDefinition } from "./FilterDefinition";
import { SearchInitialValues } from "../../../../client/model/Search";
import { QuotationClient } from "../../../../client/Quotation/QuotationClient";
import { useSnackbar } from "notistack";
import { ColumnDefinition } from "./ColumDefinition";
import { DataTable } from "@components/DataTable";
import QuotationDetail from "@pages/Fleet/Maintenance/Quotation/Detail";
import Quotation from "../../../../client/Quotation/Quotation";

const QuotationList: React.FC<{ orderId?: string }> = ({ orderId }) => {
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [result, setResult] = useState(SearchInitialValues);
  const [filters, setFilters] = useState<Filter[]>(FilterDefinition);
  const [openDetail, setOpenDetail] = useState(false);
  const [quotationId, setQuotationId] = useState<string>();

  const client = iocContext.serviceContainer.get<QuotationClient>(
    Types.Quotation
  );

  const handleOpenDetail = (row?: Quotation) => {
    setOpenDetail(true);
    setQuotationId(row?.id);
  };

  const fetchQuotations = () => {
    if (!selectedCompany || !orderId) return;
    setLoading(true);
    const storeName = filters.find((filter) => filter.field === "store")?.value;
    const filter = {
      page: page - 1,
      limit,
      "store.name": storeName,
    };
    client
      .find(selectedCompany.uuid, orderId, filter)
      .then((response) => {
        setResult(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar(error.response?.data?.message, { variant: "error" });
      });
  };

  useEffect(fetchQuotations, [page, limit, filters]);

  return (
    <>
      <SearchContainer
        filters={filters}
        onFilter={(filters: Filter[]) => {
          setFilters(filters);
        }}
      />
      <DataTable
        columns={ColumnDefinition}
        rows={result.result}
        limit={limit}
        pages={result.pages}
        total={result.total}
        loading={loading}
        onClick={handleOpenDetail}
        onPagination={(page) => setPage(page)}
        onLimitChange={(limit) => setLimit(limit)}
      />
      <Row align="end">
        <Button to={ROUTES.MAINTENANCE} color="secondary">
          Voltar
        </Button>
      </Row>
      <QuotationDetail
        quotationId={quotationId}
        open={openDetail}
        onClose={() => setOpenDetail(false)}
      />
    </>
  );
};

export default QuotationList;
