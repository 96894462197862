import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { format, startOfDay } from "date-fns";
import { inject, injectable } from "inversify";
import { ICreateRechageDTO } from "../dtos/ICreateRechageDTO";
import { ICreateRechargeService } from "../models/ICreateRechargeService";

@injectable()
export class CreateRechargeService implements ICreateRechargeService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: ICreateRechageDTO): Promise<void> {
    await this.httpInstance.post(`/companies/${data.companyID}/recharges`, {
      ...data,
      recharge_date: format(
        startOfDay(data.recharge_date),
        "yyyy-MM-dd'T'HH:mm:ss"
      ),
    });
  }
}
