import {createStyles, makeStyles} from "@material-ui/core";

export default makeStyles((theme) =>
    createStyles({
        container: {
            width: "300px",
            paddingLeft: "16px",
            paddingRight: "16px"
        },
        textExit: {
            textTransform: "uppercase",
            color: theme.palette.tertiary.textButtons.primary,
            fontSize: "12px",
            fontWeight: "bold",
            cursor: "pointer"
        },
        buttonExit: {
            width: "min-content",
            marginBottom: 0,
        },
        exitIcon: {
            fontSize: "18px"
        },
        avatar: {
            width: "160px",
            height: "160px",
            "& img": {
                borderRadius: "50%",
                borderColor: theme.palette.primary.light,
                borderWidth: "4px",
                borderStyle: "solid",
            },
        },
        personName: {
            fontSize: "18px",
            textAlign: "center",
            fontWeight: "bold",
        },
        otherTexts: {
            fontSize: "14px",
            color: theme.palette.tertiary.textButtons.primary,
        },
        buttons: {
            backgroundColor: theme.palette.tertiary.background.primary,
            borderStyle: "solid",
            borderWidth: "2px",
            color: theme.palette.primary.main,
            fontWeight: "bold",
            borderRadius: "10px",
            fontSize: "12px",
            textTransform: "uppercase",
        },
        companyName: {
            color: theme.palette.primary.main,
        },
        autoCompleteText: {
            fontSize: "12px"
        },
        autoCompleteInputText: {
            fontSize: "12px"
        },
    })
);
