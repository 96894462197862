import { LinearProgress } from "@material-ui/core";
import { GridOverlay } from "@material-ui/data-grid";
import React from "react";

const LoadingOverlayDataGrid: React.FC = () => {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%", zIndex: 100 }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
};

export { LoadingOverlayDataGrid };
