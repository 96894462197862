import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IDownloadVehicleService } from "../models/IDownloadVehicleService";

@injectable()
export class DownloadVehicleService implements IDownloadVehicleService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    statusID?: string;
    departmentID?: string;
    costCenterID?: string;
    query?: string;
    isEquipments?: boolean;
  }): Promise<string> {
    const resp = await this.httpInstance.get(
      `/companies/${data.companyID}/report/vehicle`,
      {
        params: {
          statusId: data.statusID,
          departmentId: data.departmentID,
          costcenterId: data.costCenterID,
          type: data.isEquipments ? "equipments" : "",
          q: data.query,
        },
        responseType: "arraybuffer",
      }
    );

    const blob = new Blob([resp], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);
    return url;
  }
}
