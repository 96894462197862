import {
    Badge,
    createStyles,
    IconButton,
    Link,
    makeStyles,
    Tooltip,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import {Link as LinkRouter} from "react-router-dom";

const useStyles = makeStyles(
    ({typography: {pxToRem, ...typography}, ...theme}) =>
        createStyles({
            buttonLink: {
                position: "relative",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                // marginBottom: "0.8rem",
            },
            buttonLinkEnabled: {
                "&::after": {
                    content: '" "',
                    height: 32,
                    width: 4,
                    backgroundColor: theme.palette.primary.main,
                    position: "absolute",
                    right: -4,
                    top: "50%",
                    transform: "translate(0, -50%)",
                    borderRadius: theme.shape.borderRadius,
                },
            },
            buttonEnabled: {
                color: theme.palette.primary.main,
            },
            buttonDisabled: {
                color: theme.palette.tertiary.textButtons.secondary,
            },
            icon: {
                "&:first-child": {
                    fontSize: "1.8rem",
                },
                [theme.breakpoints.up("sm")]: {
                    "&:first-child": {
                        fontSize: "2.2rem",
                    },
                },
            },
            badge: {
                marginLeft: "10px",
                marginTop: "10px",
                fontSize: "10px",
                padding: "0 4px",
                fontWeight: "bold",
            },
        })
);

export interface IButtonLinkWithoutName
    extends React.HtmlHTMLAttributes<HTMLDivElement> {
    title: string;
    route: string;
    routeExternal?: boolean;
    clickable?: boolean;
    active?: boolean;
    badge?: boolean;
}

const ButtonLinkWithoutName: React.FC<IButtonLinkWithoutName> = ({
                                                                     title,
                                                                     route,
                                                                     children,
                                                                     className,
                                                                     routeExternal,
                                                                     clickable = true,
                                                                     active = false,
                                                                     badge,
                                                                     ...props
                                                                 }) => {
    const classes = useStyles();

    const badgeColor = !!badge?"error":"default";
    const badgeContent = !!badge?"N":"";

    return (
        <Tooltip placement="right" arrow title={title}>
            <div
                className={clsx(
                    classes.buttonLink,
                    {
                        [classes.buttonLinkEnabled]: active,
                    },
                    className
                )}
                {...props}
            >
                <Badge color={badgeColor} badgeContent={badgeContent} classes={{ badge: classes.badge }}
                       anchorOrigin={{horizontal: "left", vertical: "top"}}>
                    <IconButton
                        component={clickable ? (routeExternal ? Link : LinkRouter) : "span"}
                        to={routeExternal ? "" : route}
                        href={routeExternal ? route : ""}
                        className={clsx({
                            [classes.buttonDisabled]: active === false,
                            [classes.buttonEnabled]: active,
                        })}
                    >
                        {React.Children.map(children, (child) => {
                            // @ts-ignore
                            return React.cloneElement(child, {
                                className: classes.icon,
                            });
                        })}
                    </IconButton>
                </Badge>
            </div>
        </Tooltip>
    );
};

export {ButtonLinkWithoutName};
