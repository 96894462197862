import {ErrorLoading} from "@components/ErrorLoading";
import {NoData} from "@components/NoData";
import {Pagination} from "@components/Pagination";
import {ReloadButton} from "@components/ReloadButton";
import {Title} from "@components/Title";
import {useIoCContext} from "@hooks/IoCContext";
import {useUserContext} from "@hooks/UserContext";
import {Types} from "@ioc/types";
import {
    createStyles,
    Grid,
    IconButton,
    LinearProgress,
    makeStyles,
    Tooltip,
    Typography,
} from "@material-ui/core";
import {
    CellParams,
    ColDef,
    DataGrid,
    GridOverlay,
} from "@material-ui/data-grid";
import HelpIcon from "@material-ui/icons/Help";
import {
    IPricesEstablishment,
    TypePriceEstablishment,
} from "@modules/company/dtos/IPriceEstablismentDTO";
import {IGetPricesEstablishmentsService} from "@modules/company/models/IGetPricesEstablishmentsService";
import {formatCurrency, tableStyles} from "@utils/index";
import clsx from "clsx";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import AutoSizer from "react-virtualized-auto-sizer";

const useStyles = makeStyles(
    ({typography: {pxToRem, ...typography}, ...theme}) =>
        createStyles({
            cell: {
                borderBottom: `1px solid ${theme.palette.tertiary.background.primary} !important`,
            },
            headerCell: {
                fontSize: pxToRem(16),
                fontWeight: typography.fontWeightMedium,
                color: theme.palette.tertiary.graphics[4],
            },
            unavailable: {
                color: theme.palette.tertiary.graphics[4],
            },
            renderedCell: {
                textAlign: "center",
                margin: 0,
                lineHeight: "initial",
            },
            descontedValue: {
                color: theme.palette.tertiary.confirmation.primary,
                fontWeight: typography.fontWeightBold,
            },
            descontedPercentValue: {
                color: theme.palette.tertiary.graphics[3],
                fontWeight: typography.fontWeightBold,
            },
            descontedFixedValue: {
                color: theme.palette.primary.main,
                fontWeight: typography.fontWeightBold,
            },
            iconHelp: {
                fontSize: pxToRem(18),
            },
            buttonHelp: {
                marginLeft: pxToRem(14),
            },
            tooltipHelp: {
                fontSize: pxToRem(14),
                textAlign: "center",
            },
            widthTooltip: {
                maxWidth: 500,
            },
            paginationContainer: {
                padding: "0 2rem",
                [theme.breakpoints.down("xs")]: {
                    margin: "1rem",
                },
            },
            paginationTextShow: {
                fontSize: "1.6rem",
            },
            paginationButton: {
                "& li > button": {
                    color: theme.palette.primary.main,
                    background: "none",
                },
                "& li> button.Mui-selected": {
                    backgroundColor: theme.palette.tertiary.boxAndBackgrounds.primary,
                    boxShadow: theme.shadows[2],
                },
            },
            tablePrices: tableStyles(theme).dataGridTable,
        })
);

interface IPricesFormatted<T> extends IPricesEstablishment {
}

type IEstablishmentsPrices<T> = IPricesFormatted<T> & {
    name: string;
    id: string;
};

const CellComponent: React.FC<CellParams> = (params) => {
    const classes = useStyles();

    const [openTooltipHelp, setOpenTooltipHelp] = useState(false);

    function getHelpMsg(price: TypePriceEstablishment) {
        switch (price.type) {
            case "FIXO":
                return (
                    <>
                        <Typography
                            className={classes.tooltipHelp}
                            variant="button"
                            component="p"
                        >
                            PREÇO FIXO
                        </Typography>
                        <Typography className={classes.tooltipHelp}>
                            {`Nessa modalidade, o preço do combustível no posto será sempre de R$ ${price.value.toFixed(
                                3
                            )}, independente do preço do dia/bomba.`}
                        </Typography>
                    </>
                );
            case "PORCENTAGEM":
                return (
                    <>
                        <Typography
                            className={classes.tooltipHelp}
                            variant="button"
                            component="p"
                        >
                            DESCONTO POR LITRO EM PORCENTAGEM (%)
                        </Typography>
                        <Typography className={classes.tooltipHelp}>
                            {`Nessa modalidade, o preço do combustível no posto  será com um desconto de ${price.value.toFixed(
                                2
                            )}% por litro em cima do preço do dia/bomba.`}
                        </Typography>
                    </>
                );
            case "CENTAVOS":
                return (
                    <>
                        <Typography
                            className={classes.tooltipHelp}
                            variant="button"
                            component="p"
                        >
                            DESCONTO POR LITRO EM DINHEIRO (R$)
                        </Typography>
                        <Typography className={classes.tooltipHelp}>
                            {`Nessa modalidade, o preço do combustível no posto será com um desconto de ${formatCurrency(
                                price.value
                            )} por litro em cima do preço do dia/bomba.`}
                        </Typography>
                    </>
                );
            default:
                return (
                    <>
                        <Typography
                            className={classes.tooltipHelp}
                            variant="button"
                            component="p"
                        >
                            PREÇO PADRÃO
                        </Typography>
                        <Typography className={classes.tooltipHelp}>
                            {`Nessa modalidade, o preço do combustível no posto será padrão, no valor de ${formatCurrency(
                                price.value
                            )}   por litro.`}
                        </Typography>
                    </>
                );
        }
    }

    return (
        <Grid container alignItems="center" justify="center">
            <p
                className={clsx(classes.renderedCell, {
                    [classes.unavailable]: params
                        .getValue(params.field)
                        ?.toString()
                        .includes("Não"),
                    [classes.descontedValue]:
                    params.row[params.field] &&
                    params.row[params.field].type === "CENTAVOS",
                    [classes.descontedFixedValue]:
                    params.row[params.field] &&
                    params.row[params.field].type === "FIXO",
                    [classes.descontedPercentValue]:
                    params.row[params.field] &&
                    params.row[params.field].type === "PORCENTAGEM",
                })}
            >
                {params.getValue(params.field)}
            </p>
            {!params.getValue(params.field)?.toString().includes("Não") &&
            params.row[params.field] && (
                <Tooltip
                    title={getHelpMsg(params.row[params.field])}
                    classes={{tooltip: classes.widthTooltip}}
                    open={openTooltipHelp}
                    onClose={() => setOpenTooltipHelp(false)}
                    onOpen={() => setOpenTooltipHelp(true)}
                >
                    <IconButton
                        className={classes.buttonHelp}
                        onClick={() => setOpenTooltipHelp(true)}
                        size="small"
                    >
                        <HelpIcon className={classes.iconHelp}/>
                    </IconButton>
                </Tooltip>
            )}
        </Grid>
    );
};

function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{position: "absolute", top: 0, width: "100%", zIndex: 100}}>
                <LinearProgress/>
            </div>
        </GridOverlay>
    );
}

const EstablishmentsPricePage: React.FC = () => {
    const classes = useStyles();
    const iocContext = useIoCContext();
    const userContext = useUserContext();

    const getPricesEstablishmentsService = iocContext.serviceContainer.get<IGetPricesEstablishmentsService>(
        Types.Company.IGetPricesEstablishmentsService
    );

    const [prices, setPrices] = useState<IEstablishmentsPrices<string>[]>([]);
    const [pricesLoading, setPricesLoading] = useState(false);
    const [pricesErrorLoading, setPricesErrorLoading] = useState(false);

    const productsName = useMemo(
        () => [
            {
                id: "b9d2575b-8075-453e-b885-07a788214468",
                name: "Gasolina Comum",
            },
            {
                id: "f34c0bdd-1027-443e-918f-e953886de79f",
                name: "Gasolina Aditivada",
            },
            {
                id: "2f170bd3-6718-4b7d-9bd7-7f1e493c4909",
                name: "Etanol",
            },
            {
                id: "a261a86a-17f1-4d3d-ba2a-bc1c69c4ce53",
                name: "Óleo Diesel Comum",
            },
            {
                id: "0cb0ec7f-c06f-43e7-917f-3c1fad33d062",
                name: "Óleo Diesel S-10",
            },
        ],
        []
    );

    const fetchPrices = useCallback(async () => {
        if (!userContext.selectedCompany) return;
        try {
            setPricesLoading(true);

            const resp = await getPricesEstablishmentsService.execute({
                companyID: userContext.selectedCompany.uuid,
            });

            const transform = resp.map((price) => {
                const keyProducts = Object.keys(price.prices);

                const productsFormatted2 = keyProducts.reduce<IPricesFormatted<string>>(
                    (obj, key) => {
                        const productName = productsName.find((name) => name.id === key);

                        if (productName) {
                            obj[productName.name] = {
                                ...price.prices[key],
                            };
                        }

                        return obj;
                    },
                    {}
                );

                return {
                    id: price.company.id,
                    name: price.company.name,
                    ...productsFormatted2,
                };
            });

            setPrices((transform as unknown) as IEstablishmentsPrices<string>[]);
            setPricesErrorLoading(false);
        } catch (error) {
            setPricesErrorLoading(true);
        } finally {
            setPricesLoading(false);
        }
    }, [
        getPricesEstablishmentsService,
        productsName,
        userContext.selectedCompany,
    ]);

    useEffect(() => {
        fetchPrices();
    }, [fetchPrices]);

    const columns: ColDef[] = [
        {
            field: "name",
            headerName: "Estabelecimento",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 350,
            sortable: false,
        },
        ...productsName.map(
            (product): ColDef => {
                return {
                    field: product.name,
                    headerName: product.name,
                    cellClassName: classes.cell,
                    headerClassName: classes.headerCell,
                    width: 270,
                    sortable: false,
                    headerAlign: "center",

                    valueGetter: (params) => {
                        if (params.field && params.row[params.field]) {
                            return formatCurrency(params.row[params.field].calculatedValue);
                        } else {
                            return "Não disponível";
                        }
                    },
                    renderCell: (params) => <CellComponent {...params} />,
                };
            }
        ),
    ];

    return (
        <>
            <Title>Preços por estabelecimento </Title>
            <div
                style={{height: "100%", width: "100%"}}
                className={classes.tablePrices}
            >
                <AutoSizer>
                    {({height, width}) => (
                        <div style={{height, width}}>
                            <DataGrid
                                rows={prices}
                                columns={columns}
                                disableSelectionOnClick
                                autoPageSize
                                components={{
                                    pagination: ({pagination, api}) => (
                                        <Pagination
                                            page={pagination.page}
                                            rowCount={pagination.rowCount}
                                            pageSize={pagination.pageSize}
                                            setPage={(value) => api.current.setPage(value)}
                                        />
                                    ),
                                    loadingOverlay: CustomLoadingOverlay,
                                    noRowsOverlay: () => (
                                        <GridOverlay>
                                            <NoData/>
                                        </GridOverlay>
                                    ),
                                    errorOverlay: () => {
                                        return (
                                            <div
                                                style={{
                                                    height: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <ErrorLoading/>
                                                <ReloadButton
                                                    reloadFunc={fetchPrices}
                                                    reloading={pricesLoading}
                                                />
                                            </div>
                                        );
                                    },
                                }}
                                loading={pricesLoading}
                                error={pricesErrorLoading ? true : null}
                            />
                        </div>
                    )}
                </AutoSizer>
            </div>
        </>
    );
};

export {EstablishmentsPricePage};
