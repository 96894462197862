export const CompanyTypes = {
  IGetInfoCompanyService: Symbol("IGetInfoCompanyService"),
  IGetHistoryOfConsumptionService: Symbol("IGetHistoryOfConsumptionService"),
  IGetCostCentersConsumptionService: Symbol(
    "IGetCostCentersConsumptionService"
  ),
  IListPermissibilityFleetService: Symbol("IListPermissibilityFleetService"),
  IGetExceptionTransactionsService: Symbol("IGetExceptionTransactionsService"),
  IGetExpiringLicencesService: Symbol("IGetExpiringLicencesService"),
  IListSmallerBalancesService: Symbol("IListSmallerBalancesService"),
  IAutocompleteUsersQueryService: Symbol("IAutocompleteUsersQueryService"),
  ICreateRechargeService: Symbol("ICreateRechargeService"),
  ICreateDocumentService: Symbol("ICreateDocumentService"),
  IGetDocumentService: Symbol("IGetDocumentService"),
  IGetPricesEstablishmentsService: Symbol("IGetPricesEstablishmentsService"),
  ILocationsEstablishmentsService: Symbol("ILocationsEstablishmentsService"),
  IListRechargesService: Symbol("IListRechargesService"),
  ISendJustificationTransactionService: Symbol(
    "ISendJustificationTransactionService"
  ),
  IRemoveJustificationTransactionService: Symbol(
    "IRemoveJustificationTransactionService"
  ),
  IDetailsRechargeService: Symbol("IDetailsRechargeService"),
  IReverseRechargeService: Symbol("IReverseRechargeService"),
  IListDepartmentsService: Symbol("IListDepartmentsService"),
  IRemoveDepartmentService: Symbol("IRemoveDepartmentService"),
  ICreateDepartmentService: Symbol("ICreateDepartmentService"),
  IEditDepartmentService: Symbol("IEditDepartmentService"),
  IListFunctionsService: Symbol("IListFunctionsService"),
  ICreateFunctionService: Symbol("ICreateFunctionService"),
  IEditFunctionService: Symbol("IEditFunctionService"),
  IRemoveFunctionService: Symbol("IRemoveFunctionService"),
  IListCostCentersService: Symbol("IListCostCentersService"),
  ICreateCostCenterService: Symbol("ICreateCostCenterService"),
  IEditCostCenterService: Symbol("IEditCostCenterService"),
  IRemoveCostCenterService: Symbol("IRemoveCostCenterService"),
  IListVehiclesService: Symbol("IListVehiclesService"),
  IRemoveVehicleService: Symbol("IRemoveVehicleService"),
  IListBrandVehicleService: Symbol("IListBrandVehicleService"),
  IListModelVehicleService: Symbol("IListModelVehicleService"),
  IListFleetFuelService: Symbol("IListFleetFuelService"),
  ICreateVehicleService: Symbol("ICreateVehicleService"),
  IGetVehicleDataService: Symbol("IGetVehicleDataService"),
  IEditVehicleService: Symbol("IEditVehicleService"),
  IListDriversService: Symbol("IListDriversService"),
  IGetDriverService: Symbol("IGetDriverService"),
  ICreateDriverService: Symbol("ICreateDriverService"),
  IEditDriverService: Symbol("IEditDriverService"),
  IRemoveDriverService: Symbol("IRemoveDriverService"),
  IResetDriverPasswordService: Symbol("IResetDriverPasswordService"),
  IListPenaltiesService: Symbol("IListPenaltiesService"),
  IMarkAsReadPenaltyService: Symbol("IMarkAsReadPenaltyService"),
  IGetTransactionsCreditService: Symbol("IGetTransactionsCreditService"),
  IDownloadVehicleService: Symbol("IDownloadVehicleService"),
  IRemoveDocumentWithIdService: Symbol("IRemoveDocumentWithIdService"),
};
