import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IGetCostCentersConsumptionDTO } from "../dtos/IGetCostCentersConsumptionDTO";
import { IGetCostCentersConsumptionService } from "../models/IGetCostCentersConsumptionService";

@injectable()
export class GetCostCentersConsumptionService
  implements IGetCostCentersConsumptionService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;
  public async execute(data: {
    companyID: string;
  }): Promise<IGetCostCentersConsumptionDTO> {
    const response = await this.httpInstance.get<IGetCostCentersConsumptionDTO>(
      `/companies/${data.companyID}/report/consumption/cost_centers_or_departments`
    );
    return response;
  }
}
