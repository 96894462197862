import {
  Collapse,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      titleFilter: {
        color: theme.palette.tertiary.textButtons.primary,
        fontSize: "1.6rem",
        fontWeight: typography.fontWeightBold,
      },
      labelFilterHide: {
        opacity: 0,
      },
      filterRootInput: {
        backgroundColor:
          theme.palette.type === "dark"
            ? theme.palette.hexToRGBWithAlpha("#ffffff", 0.13)
            : "#fff",
        borderRadius: "1.5rem",
      },
      filterInput: {
        padding: "1.8rem 1.2rem",
      },
      notUnderline: {
        "&::before": {
          display: "none",
        },
        "&::after": {
          display: "none",
        },
      },
      labelFilter: {
        color: theme.palette.tertiary.textButtons.primary,
      },
    })
);

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
  typographyProps?: TypographyProps;
  textFieldProps?: TextFieldProps;
  onClear?: () => void;
  isFilled?: boolean;
  title: string;
}

const StyledSelect: React.FC<Props> = ({
  children,
  textFieldProps,
  typographyProps,
  onClear,
  isFilled,
  title,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <div {...rest}>
      <Typography className={classes.titleFilter} {...typographyProps}>
        {title}
      </Typography>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ flexWrap: "nowrap" }}
      >
        <TextField
          fullWidth
          select
          InputLabelProps={{
            classes: {
              focused: classes.labelFilterHide,
              root: classes.labelFilter,
              shrink: classes.labelFilterHide,
            },
          }}
          InputProps={{
            classes: {
              root: classes.filterRootInput,
              underline: classes.notUnderline,
              input: classes.filterInput,
            },
          }}
          variant="filled"
          {...textFieldProps}
        >
          {children}
        </TextField>
        <Collapse unmountOnExit in={onClear && isFilled}>
          <IconButton onClick={onClear} style={{ marginLeft: "0.8rem" }}>
            <Cancel />
          </IconButton>
        </Collapse>
      </Grid>
    </div>
  );
};

export { StyledSelect };
