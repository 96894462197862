import { Types } from "@ioc/types";
import { IListModelVehicleDTO } from "@modules/company/dtos/IListModelVehicleDTO";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IListVehicleModelsByCompanyService } from "../models/IListVehicleModelsByCompanyService";

@injectable()
export class ListVehicleModelsByCompanyService
  implements IListVehicleModelsByCompanyService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
  }): Promise<IListModelVehicleDTO[]> {
    const resp = await this.httpInstance.get<IListModelVehicleDTO[]>(
      `/fleet/model/company/${data.companyID}`
    );

    return resp;
  }
}
