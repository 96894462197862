import DragInDropBox from "@components/DragInDropBox";
import { StyledDialog } from "@components/StyledDialog";
import {
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { Form, Formik } from "formik";
import React from "react";
import excelImg from "../../assets/icon-excel.png";
import { FormUploadUsersSchemaValidation } from "./FormUploadUsersSchemaValidation";
import { useUsersPage } from "./UsersPageContext";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      buttonDownload: {
        "&:hover": {
          textDecoration: "none",
        },
      },
      sendFileText: {
        padding: "1rem 0",
        color: theme.palette.tertiary.textButtons.primary,
        fontWeight: typography.fontWeightBold,
        fontSize: pxToRem(12),
      },
      buttons: {
        fontWeight: typography.fontWeightBold,
      },
      cancelButton: {
        color: theme.palette.tertiary.textButtons.primary,
      },
    })
);

const ModalUploadUsers: React.FC = () => {
  const classes = useStyles();
  const usersPageContext = useUsersPage();
  return (
    <StyledDialog
      closeFunc={() => usersPageContext.setOpenModalUploadUsers(false)}
      open={usersPageContext.openModalUploadUsers}
      title="Importar usuários"
      subtitle="Faca upload de um arqquivo .xlsx para adicionar vários usuários por vez."
    >
      <DialogContent>
        <Grid container justify="center">
          <Button
            color="primary"
            startIcon={<img src={excelImg} alt="ícone excel" />}
            className={classes.buttonDownload}
          >
            Faça download da planilha modelo
          </Button>
        </Grid>
        <Formik
          initialValues={{ file: null }}
          onSubmit={() => {}}
          validationSchema={FormUploadUsersSchemaValidation}
        >
          {(props) => {
            return (
              <Form style={{ marginTop: "2rem" }}>
                <Typography className={classes.sendFileText}>
                  Enviar o arquivo
                </Typography>
                <DragInDropBox
                  error={!!props.errors.file}
                  helperText="Apenas arquivos .xlsx são permitidos"
                  onChange={(file) => props.setFieldValue("file", file)}
                  onTouch={() => props.setFieldTouched("file", true)}
                  touched={Boolean(props.touched.file)}
                  value={props.values.file}
                />
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Grid container justify="center">
          <Button
            className={clsx(classes.buttons, classes.cancelButton)}
            onClick={() => usersPageContext.setOpenModalUploadUsers(false)}
          >
            Cancelar
          </Button>
          <Button color="primary" className={classes.buttons}>
            Adicionar
          </Button>
        </Grid>
      </DialogActions>
    </StyledDialog>
  );
};

export { ModalUploadUsers };
