import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IGetInfoCompanyDTO } from "../dtos/IGetInfoCompanyDTO";
import { IGetInfoCompanyService } from "../models/IGetInfoCompanyService";

@injectable()
export class GetInfoCompanyService implements IGetInfoCompanyService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
  }): Promise<IGetInfoCompanyDTO> {
    const response = this.httpInstance.get<IGetInfoCompanyDTO>(
      `/companies/${data.companyID}`
    );

    return response;
  }
}
