export interface Search<T> {
  page: number;
  pages: number;
  count: number;
  total?: number;
  result: T[];
}

export const SearchInitialValues: Search<any> = {
  page: 1,
  pages: 1,
  count: 0,
  total: 0,
  result: [],
};
