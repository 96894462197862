import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IRemoveJustificationTransactionService } from "../models/IRemoveJustificationTransactionService";

@injectable()
export class RemoveJustificationTransactionService
  implements IRemoveJustificationTransactionService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    idTransaction: string;
  }): Promise<void> {
    await this.httpInstance.post(
      `/fleet/permissibility/${data.idTransaction}/company/${data.companyID}/disallow`,
      null
    );
  }
}
