import {createStyles, makeStyles} from "@material-ui/core";

export default makeStyles((theme) =>
    createStyles({
        container: {
            display: "flex",
            "& :first-child": {
                textAlign: "start"
            }
        },
        selectRoot: {
            "&:focus": {
                backgroundColor: "transparent",
            },
        },
        textFieldRoot: {
            minWidth: 440
        },
        selectFilter: {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            height: 40,
            backgroundColor: "#0F71D0",
            color: "#FFF",
            fontWeight: "bold",
            "& fieldset": {
                borderColor: "rgba(0, 0, 0, 0.23) !important",
            },
        },
        selectInput: {
            borderRight: "none",
        },
        textFieldFilter: {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            height: 40,
            "& fieldset": {
                borderColor: "rgba(0, 0, 0, 0.23) !important",
            },
        },
        textFieldInput: {
            borderLeft: "none",
        },
        filterContainer: {
            padding: "10px"
        },
        pill: {
            height: "24px",
            backgroundColor: "#0F71D0",
            fontWeight: "bold",
            color: "#FFF",
            "&:focus": {
                backgroundColor: "#0F71D0"
            },
            "& svg": {
                color: "#FFF",
                "&:hover": {
                    color: "#FFF",
                },
            }
        },
        buttonContainer: {
            justifyContent: "end"
        }
    })
);
