import {
  Button,
  CircularProgress,
  createStyles,
  DialogActions,
  Grid,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      buttons: {
        fontWeight: typography.fontWeightBold,
      },
      cancelButton: {
        color: theme.palette.tertiary.textButtons.primary,
      },
      removeButton: {
        color: theme.palette.tertiary.error.primary,
      },
      loading: {
        height: "25px !important",
        width: "25px !important",
      },
      colorLoadingCancellable: {
        color: theme.palette.tertiary.error.primary,
      },
    })
);

export interface IStyledButtonActionsProps {
  loading: boolean;
  closeFunc?: () => void;
  actionFunc?: () => void;
  leftButtonLabel?: string;
  rightButtonLabel?: string;
  styleButtons: "cancellable" | "info";
  formID?: string;
}

const StyledButtonActions: React.FC<IStyledButtonActionsProps> = ({
  loading,
  closeFunc,
  actionFunc,
  leftButtonLabel,
  rightButtonLabel,
  styleButtons,
  formID,
}) => {
  const classes = useStyles();
  return (
    <DialogActions>
      <Grid container justify="center">
        {leftButtonLabel && (
          <Button
            className={clsx(classes.buttons, classes.cancelButton)}
            onClick={closeFunc}
          >
            {leftButtonLabel}
          </Button>
        )}
        {rightButtonLabel && (
          <Button
            className={clsx(classes.buttons, {
              [classes.removeButton]: styleButtons === "cancellable",
            })}
            color="primary"
            startIcon={
              loading && (
                <CircularProgress
                  color="primary"
                  className={clsx(classes.loading, {
                    [classes.colorLoadingCancellable]:
                      styleButtons === "cancellable",
                  })}
                />
              )
            }
            type={formID ? "submit" : "button"}
            form={formID}
            onClick={() => !loading && actionFunc && actionFunc()}
          >
            {rightButtonLabel}
          </Button>
        )}
      </Grid>
    </DialogActions>
  );
};

export { StyledButtonActions };
