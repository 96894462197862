import { useIoCContext } from "@hooks/IoCContext";
import { useLayoutContext } from "@hooks/LayoutContext";
import { useUserContext } from "@hooks/UserContext";
import { Types } from "@ioc/types";
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import { Autocomplete } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import { IUserAutocomplete } from "@modules/company/dtos/IAutocompleteUsersDTO";
import { IAutocompleteUsersQueryService } from "@modules/company/models/IAutocompleteUsersQueryService";
import { ICreateRechargeService } from "@modules/company/models/ICreateRechargeService";
import AppError from "@utils/AppError";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { FormManualRechargeSchemeValidation } from "./FormManualRechargeSchemeValidation";
import { IFormManualRecharge } from "./IForm";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      dialogTitle: {
        fontWeight: typography.fontWeightBold,
        fontSize: pxToRem(16),
      },
      dialogPaper: {
        minWidth: "95vw",
        [theme.breakpoints.up("sm")]: {
          minWidth: "50vw",
        },
      },
      subtitleDialog: {
        color: theme.palette.tertiary.textButtons.primary,
        fontSize: pxToRem(14),
        textAlign: "center",
        [theme.breakpoints.up("xs")]: {
          textAlign: "left",
        },
      },
      divider: {
        backgroundColor: theme.palette.tertiary.background.primary,
      },
      sectionTitles: {
        fontWeight: typography.fontWeightBold,
      },
      underlineTextField: {
        "&:before": {
          borderColor: theme.palette.tertiary.background.primary,
        },
      },
      buttons: {
        fontWeight: typography.fontWeightBold,
      },
      cancelButton: {
        color: theme.palette.tertiary.textButtons.primary,
      },
      cardUserName: {
        fontSize: pxToRem(16),
      },
      cardUserDocument: {
        fontSize: pxToRem(12),
      },
      tableHeaderCell: {
        color: "#fff",
        backgroundColor: theme.palette.primary.main,

        "&:first-child": {
          borderTopLeftRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: theme.shape.borderRadius,
        },
        "&:last-child": {
          borderTopRightRadius: theme.shape.borderRadius,
          borderBottomRightRadius: theme.shape.borderRadius,
        },
      },
      tableCell: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
        maxWidth: 120,
        "&:hover": {
          overflow: "visible",
        },
        [theme.breakpoints.up("sm")]: {
          maxWidth: "unset",
          width: "100%",
        },
      },
      deleteButton: {
        color: theme.palette.tertiary.error.primary,
      },
      loadingIconButton: {
        width: `${pxToRem(20)} !important`,
        height: `${pxToRem(20)} !important`,
      },
    })
);

const DialogManualRecharge: React.FC = () => {
  const classes = useStyles();
  const iocContext = useIoCContext();
  const { selectedCompany } = useUserContext();
  const layoutContext = useLayoutContext();
  const userContext = useUserContext();
  const { enqueueSnackbar } = useSnackbar();

  const autocompleteUsersService = iocContext.serviceContainer.get<IAutocompleteUsersQueryService>(
    Types.Company.IAutocompleteUsersQueryService
  );
  const rechargeService = iocContext.serviceContainer.get<ICreateRechargeService>(
    Types.Company.ICreateRechargeService
  );

  const [inputQuery, setInputQuery] = useState("");
  const [loadingInputQuery, setLoadingInputQuery] = useState(false);
  const [loadingSubmitForm, setLoadingSubmitForm] = useState(false);
  const [usersFinded, setUsersFinded] = useState<IUserAutocomplete[]>([]);
  const [userRecharge, setUserRecharge] = useState<IUserAutocomplete | null>(
    null
  );

  const fetchAutocomplete = useCallback(
    async (query: string) => {
      if (!selectedCompany) return;
      try {
        setLoadingInputQuery(true);
        const queryResult = await autocompleteUsersService.execute({
          companyID: selectedCompany.uuid,
          query,
        });

        const user = queryResult.result.find(
          (user) =>
            layoutContext.openManualRecharge.data &&
            layoutContext.openManualRecharge.data.document === user.document
        );
        if (user) {
          setUserRecharge(user);
        }

        setUsersFinded(queryResult.result);
      } catch (error) {
      } finally {
        setLoadingInputQuery(false);
      }
    },
    [
      autocompleteUsersService,
      layoutContext.openManualRecharge.data,
      selectedCompany,
    ]
  );

  useEffect(() => {
    if (layoutContext.openManualRecharge.data) {
      fetchAutocomplete(layoutContext.openManualRecharge.data.document);
    }
  }, [fetchAutocomplete, layoutContext.openManualRecharge.data]);

  useEffect(() => {
    fetchAutocomplete(inputQuery);
  }, [fetchAutocomplete, inputQuery]);

  const initialValues: IFormManualRecharge = {
    usersSelected: userRecharge ? [userRecharge] : [],
    description: layoutContext.openManualRecharge.data?.description || "",
    rechargeValue: layoutContext.openManualRecharge.data?.rechargeValue || null,
    date: layoutContext.openManualRecharge.data?.date || new Date(),
  };

  return (
    <Dialog
      open={layoutContext.openManualRecharge.open}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle disableTypography>
        <Grid container>
          <Grid item xs={11} container alignItems="center">
            <Typography className={classes.dialogTitle}>
              Nova recarga manual
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              onClick={() => {
                layoutContext.setOpenManualRecharge({
                  open: false,
                  data: null,
                });
                setUserRecharge(null);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.subtitleDialog}>
              Faça uma recarga manual para os usuários selecionados.
            </Typography>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            if (!userContext.selectedCompany) return;
            if (!userContext.companyInfo) return;
            try {
              setLoadingSubmitForm(true);
              await rechargeService.execute({
                companyID: userContext.selectedCompany.uuid,
                name: values.description,
                recharge_date: values.date,
                recharge_type: userContext.companyInfo.in_money
                  ? "in_money"
                  : "in_liter",
                transactions: values.usersSelected.map((user) => {
                  return {
                    document: user.document,
                    quantity: values.rechargeValue as number,
                  };
                }),
              });
              layoutContext.setOpenManualRecharge({ open: false, data: null });
            } catch (error) {
              if (error instanceof AppError) {
                return enqueueSnackbar(error.message, {
                  variant: error.variant,
                });
              }
              enqueueSnackbar("Ocorreu um erro ao realizar recarga", {
                variant: "error",
              });
            } finally {
              setLoadingSubmitForm(false);
            }
          }}
          validationSchema={FormManualRechargeSchemeValidation}
          enableReinitialize={userRecharge ? true : false}
        >
          {(props) => {
            return (
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Field
                      InputLabelProps={{
                        classes: { root: classes.sectionTitles },
                      }}
                      InputProps={{
                        classes: { underline: classes.underlineTextField },
                      }}
                      label="Descrição"
                      fullWidth
                      name="description"
                      error={
                        props.touched.description && !!props.errors.description
                      }
                      helperText={
                        props.touched.description && props.errors.description
                      }
                      as={TextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      InputLabelProps={{
                        classes: { root: classes.sectionTitles },
                      }}
                      InputProps={{
                        classes: { underline: classes.underlineTextField },
                      }}
                      inputProps={{
                        // não permite números negativos ou nulos
                        type: "number",
                        min: "0.01",
                        step: "0.01",
                        // ios teclado numérico
                        inputMode: "decimal",
                      }}
                      label={
                        userContext.companyInfo?.cs_plano === "PRE_PAGO"
                          ? "Valor da recarga"
                          : "Quantidade de litros"
                      }
                      fullWidth
                      name="rechargeValue"
                      error={
                        props.touched.rechargeValue &&
                        !!props.errors.rechargeValue
                      }
                      helperText={
                        props.touched.rechargeValue &&
                        props.errors.rechargeValue
                      }
                      as={TextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DatePicker
                      autoOk
                      clearLabel="Apagar"
                      fullWidth
                      label="Data da recarga"
                      clearable
                      cancelLabel="Cancelar"
                      disablePast
                      value={props.values.date}
                      onChange={(date) => props.setFieldValue("date", date)}
                      format="dd/MM/yyyy"
                      error={props.touched.date && !!props.errors.date}
                      helperText={props.touched.date && props.errors.date}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      options={usersFinded}
                      getOptionLabel={(option) => option.name}
                      loading={loadingInputQuery}
                      noOptionsText={`Nenhum resultado encontrado para "${inputQuery}"`}
                      loadingText={"Buscando usuários..."}
                      onChange={(event, value) => {
                        if (value) {
                          const added = props.values.usersSelected.find(
                            (user) => user.document === value.document
                          );
                          if (added) {
                            return;
                          }
                          props.setFieldValue("usersSelected", [
                            ...props.values.usersSelected,
                            value,
                          ]);
                        }
                      }}
                      onInputChange={(event, newInputValue) => {
                        setInputQuery(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            classes: { root: classes.sectionTitles },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            classes: {
                              underline: classes.underlineTextField,
                            },
                            endAdornment: (
                              <React.Fragment>
                                {loadingInputQuery ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                          label="Buscar usuários"
                          fullWidth
                          error={
                            props.touched.usersSelected &&
                            !!props.errors.usersSelected
                          }
                          helperText={
                            props.touched.usersSelected &&
                            props.errors.usersSelected
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {props.values.usersSelected.length ? (
                      <Table size="small">
                        <TableRow>
                          <TableHead>
                            <TableCell className={classes.tableHeaderCell}>
                              Nome
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell}>
                              Documento
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell} />
                          </TableHead>
                          <TableBody>
                            {props.values.usersSelected.map((user) => {
                              return (
                                <TableRow>
                                  <TableCell className={classes.tableCell}>
                                    {user.name}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {user.document}
                                  </TableCell>
                                  <TableCell>
                                    <Tooltip title="Remover usuário">
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          const usersSelected = props.values.usersSelected.filter(
                                            (userFiltered) =>
                                              userFiltered.id !== user.id
                                          );
                                          props.setFieldValue(
                                            "usersSelected",
                                            usersSelected
                                          );
                                        }}
                                        className={classes.deleteButton}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </TableRow>
                      </Table>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={6} container justify="center">
                    <Button
                      onClick={() =>
                        layoutContext.setOpenManualRecharge({
                          open: false,
                          data: null,
                        })
                      }
                      className={clsx(classes.buttons, classes.cancelButton)}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={6} container justify="center">
                    <Button
                      type="submit"
                      className={classes.buttons}
                      color="primary"
                      startIcon={
                        loadingSubmitForm && (
                          <CircularProgress
                            className={classes.loadingIconButton}
                          />
                        )
                      }
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export { DialogManualRecharge };
