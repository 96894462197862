import React, { useEffect, useState } from "react";
import StyleSheet from "@pages/Drivers/StyleSheet";
import { Title } from "@components/Title/Title";
import { ROUTES } from "@config/routesConfig";
import { useIoCContext } from "@hooks/IoCContext";
import { Types } from "@ioc/types";
import { useUserContext } from "@hooks/UserContext";
import { Formik } from "formik";
import { EditValidationSchema } from "@pages/Drivers/EditValidationSchema";
import { Loading } from "@components/Loading";
import Message from "@components/Message";
import { useHistory, useParams } from "react-router";
import { DriverClient } from "../../client/DriverClient";
import AppError from "@utils/AppError";
import { EditForm } from "@pages/Drivers/EditForm";
import { Driver, DriverInitialValues } from "../../client/model/Driver";
import { Row } from "@components/Row";
import { Button } from "@components/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

const DriversEditPage: React.FC = () => {
  const styleSheet = StyleSheet();
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const history = useHistory();
  const params = useParams<{ id?: string }>();
  const [driverId] = useState(params.id);
  const [driver, setDriver] = useState<Driver>(DriverInitialValues);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [updateStatusDialogOpen, setUpdateStatusDialogOpen] = useState(false);
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const driverClient = iocContext.serviceContainer.get<DriverClient>(
    Types.Driver
  );

  const fetchDriver = () => {
    if (!selectedCompany) return;
    if (!driverId) return;
    setLoading(true);
    driverClient.findById(selectedCompany.id, driverId).then((result) => {
      setDriver({
        ...result,
        activityId:
          !result.activityId || result.activityId === "null"
            ? "0"
            : result.activityId,
        departmentId:
          !result.departmentId || result.departmentId === "null"
            ? "0"
            : result.departmentId,
        costCenterId:
          !result.costCenterId || result.costCenterId === "null"
            ? "0"
            : result.costCenterId,
      });
      setLoading(false);
    });
  };

  useEffect(fetchDriver, [selectedCompany, driverId, driverClient]);

  const handleSubmit = async (event: Driver, redirect = true) => {
    setLoading(true);
    try {
      if (selectedCompany) {
        const payload = {
          ...event,
          cpf: event.cpf?.replace(/[^0-9]/g, ""),
          phoneNumber: event.phoneNumber?.replace(/[^0-9]/g, ""),
          activityId: event.activityId === "0" ? null : event.activityId,
          departmentId: event.departmentId === "0" ? null : event.departmentId,
          costCenterId: event.costCenterId === "0" ? null : event.costCenterId,
        };
        if (!driverId) {
          await driverClient.save(selectedCompany.uuid, payload);
        } else {
          await driverClient.update(selectedCompany.uuid, driverId, payload);
        }
        if (redirect) {
          history.push({
            pathname: ROUTES.DRIVERS,
            state: { success: true },
          });
        }
      } else {
        throw new AppError("company_id is undefined", "error");
      }
    } catch (error) {
      if (error instanceof AppError) {
        setErrorMessage(error.message);
      }
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const updateDriver = async (data: Driver) => {
    await handleSubmit(data, false);
    await fetchDriver();
    setUpdateStatusDialogOpen(false);
    setResetPasswordDialogOpen(false);
    setSuccess(true);
  };

  return (
    <div className={styleSheet.container}>
      <Title subtitles={driverId ? ["Editar"] : ["Adicionar"]}>
        Motoristas
      </Title>
      {!loading && driverId ? (
        <Row align="end">
          <Button
            color={driver.status ? "error" : "success"}
            onClick={() => setUpdateStatusDialogOpen(true)}
          >
            {driver.status ? "Desativar" : "Ativar"}
          </Button>
          <div style={{ width: 6 }} />
          <Button
            color="warning"
            onClick={() => setResetPasswordDialogOpen(true)}
          >
            Resetar Senha
          </Button>
        </Row>
      ) : (
        <></>
      )}
      <Formik
        onSubmit={(event) => handleSubmit(event as Driver)}
        enableReinitialize
        initialValues={driver}
        validationSchema={EditValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        <EditForm />
      </Formik>
      <Loading open={loading} />
      <Message.Error
        open={error}
        onClose={() => {
          setError(false);
          setErrorMessage("");
        }}
      >
        {errorMessage}
      </Message.Error>
      <Message.Success open={success} onClose={() => setSuccess(false)} />
      <Dialog
        open={updateStatusDialogOpen}
        onClose={() => setUpdateStatusDialogOpen(false)}
      >
        <DialogTitle>Atenção</DialogTitle>
        <DialogContent>
          {driver.status
            ? `${driver.driver} será desativado e não poderá utilizar o sistema. Deseja continuar?`
            : `Tem certeza que deseja ativar ${driver.driver}`}
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => setUpdateStatusDialogOpen(false)}
          >
            Não
          </Button>
          <Button
            color="error"
            onClick={() => updateDriver({ ...driver, status: !driver.status })}
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={resetPasswordDialogOpen}
        onClose={() => setResetPasswordDialogOpen(false)}
      >
        <DialogTitle>Atenção</DialogTitle>
        <DialogContent>
          No próximo abastecimento, {driver.driver} será obrigado a cadastrar
          uma nova senha. Deseja continuar?
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => setResetPasswordDialogOpen(false)}
          >
            Não
          </Button>
          <Button
            color="error"
            onClick={() => updateDriver({ ...driver, password: null })}
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export { DriversEditPage };
