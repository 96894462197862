import {ErrorLoading} from "@components/ErrorLoading";
import {LoadingOverlayDataGrid} from "@components/LoadingOverlayDataGrid";
import {NoData} from "@components/NoData";
import {Pagination} from "@components/Pagination";
import {ReloadButton} from "@components/ReloadButton";
import {StyledSelect} from "@components/StyledSelect";
import {Tag} from "@components/Tag";
import {TitleWithButtons} from "@components/TitleWithButtons";
import {ROUTES} from "@config/routesConfig";
import {useIoCContext} from "@hooks/IoCContext";
import {useLayoutContext} from "@hooks/LayoutContext";
import {useUserContext} from "@hooks/UserContext";
import {Types} from "@ioc/types";
import {
    CircularProgress,
    Collapse,
    createStyles,
    Grid,
    Hidden,
    IconButton,
    makeStyles,
    MenuItem,
    Tooltip,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import {ColDef, DataGrid, GridOverlay} from "@material-ui/data-grid";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FilterListIcon from "@material-ui/icons/FilterList";
import {IVehicleDTO} from "@modules/company/dtos/IListPermissibilityFleetDTO";
import {IListVehicleDTO} from "@modules/company/dtos/IListVehicleDTO";
import {IDownloadVehicleService} from "@modules/company/models/IDownloadVehicleService";
import {IListVehiclesService} from "@modules/company/models/IListVehiclesService";
import {IFilterUsersDTO} from "@modules/user/dtos/IFilterUsersDTO";
import {IGetFiltersUserService} from "@modules/user/models/IGetFiltersUserService";
import AppError from "@utils/AppError";
import {tableStyles} from "@utils/index";
import {useSnackbar} from "notistack";
import React, {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import {ModalRemoveEquipment} from "./ModalRemoveEquipment";

const useStyles = makeStyles(
    ({typography: {pxToRem, ...typography}, ...theme}) =>
        createStyles({
            spacingButtonFilter: {marginTop: "2rem"},
            loading: {
                height: "25px !important",
                width: "25px !important",
            },
            cell: {
                borderBottom: `1px solid ${theme.palette.tertiary.background.primary} !important`,
            },
            headerCell: {
                fontSize: pxToRem(16),
                fontWeight: typography.fontWeightMedium,
                color: theme.palette.tertiary.graphics[4],
            },
            buttonDelete: {
                color: theme.palette.tertiary.error.primary,
            },
            tableEquipments: tableStyles(theme).dataGridTable,
        })
);

export interface IModalVehicle {
    open: boolean;
    data: IVehicleDTO | null;
}

const EquipmentsPage: React.FC = () => {
    const layoutContext = useLayoutContext();
    const classes = useStyles();
    const userContext = useUserContext();
    const iocContext = useIoCContext();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
        defaultMatches: true,
    });
    const {enqueueSnackbar} = useSnackbar();

    const getFiltersUserService = iocContext.serviceContainer.get<IGetFiltersUserService>(
        Types.User.IGetFiltersUserService
    );
    const getVehiclesService = iocContext.serviceContainer.get<IListVehiclesService>(
        Types.Company.IListVehiclesService
    );
    const downloadVehicleService = iocContext.serviceContainer.get<IDownloadVehicleService>(
        Types.Company.IDownloadVehicleService
    );

    const [loadingDownload, setLoadingDownload] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [loadingFilters, setLoadingFilters] = useState(false);
    const [errorLoadingFilters, setErrorLoadingFilters] = useState(false);
    const [filters, setFilters] = useState<IFilterUsersDTO | null>(null);
    const [selectedFilters, setSelectedFilters] = useState<{
        statusID: string;
        functionID: string;
        costCenterID: string;
        departmentID: string;
        query: string;
    }>({
        costCenterID: "",
        functionID: "",
        statusID: "",
        departmentID: "",
        query: "",
    });
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [vehicles, setVehicles] = useState<IListVehicleDTO>({
        page: 1,
        count: 0,
        result: [],
    });
    const [loadingVehicles, setLoadingEquipments] = useState(false);
    const [errorLoadingVehicles, setErrorLoadingEquipments] = useState(false);
    const [
        openModalRemoveEquipment,
        setOpenModalRemoveEquipment,
    ] = useState<IModalVehicle>({
        open: false,
        data: null,
    });
    const [sizeTable, setSizeTable] = useState<null | number>(null);

    const fetchFilters = useCallback(async () => {
        if (!userContext.selectedCompany) return;
        try {
            setLoadingFilters(true);
            const filters = await getFiltersUserService.execute({
                companyID: userContext.selectedCompany.id,
            });
            setFilters(filters);
            setErrorLoadingFilters(false);
        } catch (error) {
            setErrorLoadingFilters(true);
        } finally {
            setLoadingFilters(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFiltersUserService, userContext.selectedCompany]);

    const fetchEquipments = useCallback(async () => {
        if (!userContext.selectedCompany) return;
        try {
            setLoadingEquipments(true);
            const resp = await getVehiclesService.execute({
                companyID: userContext.selectedCompany.uuid,
                limit,
                page,
                costCenterID: selectedFilters.costCenterID,
                departmentID: selectedFilters.departmentID,
                statusID: selectedFilters.statusID,
                isEquipments: true,
                query: selectedFilters.query,
            });
            setVehicles(resp);

            setErrorLoadingEquipments(false);
        } catch (error) {
            setErrorLoadingEquipments(true);
        } finally {
            setLoadingEquipments(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext.selectedCompany, limit, page, selectedFilters]);

    const donwloadVehicles = useCallback(async () => {
        if (!userContext.selectedCompany) return;
        try {
            setLoadingDownload(true);
            const urlDownload = await downloadVehicleService.execute({
                companyID: userContext.selectedCompany.uuid,
                costCenterID: selectedFilters.costCenterID,
                departmentID: selectedFilters.departmentID,
                statusID: selectedFilters.statusID,
                query: selectedFilters.query,
                isEquipments: true,
            });

            window.open(urlDownload);
        } catch (error) {
            if (error instanceof AppError) {
                return enqueueSnackbar(error.message, {variant: error.variant});
            }
            enqueueSnackbar("Ocorreu um erro ao baixar o arquivo", {
                variant: "error",
            });
        } finally {
            setLoadingDownload(false);
        }
    }, [
        downloadVehicleService,
        enqueueSnackbar,
        selectedFilters.costCenterID,
        selectedFilters.departmentID,
        selectedFilters.query,
        selectedFilters.statusID,
        userContext.selectedCompany,
    ]);

    useEffect(() => {
        fetchFilters();
    }, [fetchFilters]);

    useEffect(() => {
        if (openModalRemoveEquipment.open === false) {
            fetchEquipments();
        }
    }, [fetchEquipments, openModalRemoveEquipment.open]);

    useEffect(() => {
        fetchEquipments();
    }, [fetchEquipments]);

    useEffect(() => {
        setPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilters]);

    const columns: ColDef[] = [
        {
            field: "edit",
            headerName: " ",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 70,
            sortable: false,
            renderCell: (params) => {
                const row = params.row as IVehicleDTO;
                return (
                    <Tooltip title="Editar equipamento">
                        <IconButton
                            color="primary"
                            component={Link}
                            to={ROUTES.EQUIPMENTS_EDIT({equipmentID: row.id})}
                        >
                            <EditIcon/>
                        </IconButton>
                    </Tooltip>
                );
            },
        },
        {
            field: "patrimony",
            headerName: "Patrimônio",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 350,
            sortable: false,
        },
        {
            field: "plate",
            headerName: "Identificação",
            sortable: false,
            width: 180,
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
        },
        {
            field: "Vehicle.Mmodel.name",
            headerName: "Equipamento",
            sortable: false,
            width: 350,
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            valueGetter: (params) => {
                const vehicle = params.row as IVehicleDTO;
                return vehicle.Mmodel.name;
            },
        },
        {
            field: "departamento",
            headerName: "Departamento",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 250,
            sortable: false,
            valueGetter: (params) => {
                const vehicle = params.row as IVehicleDTO;
                return filters?.departments.find(
                    (department) => vehicle.departament_id === department.id
                )?.tx_departamento;
            },
        },
        {
            field: "costCenter",
            headerName: "Centro de custo",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 250,
            sortable: false,
            valueGetter: (params) => {
                const vehicle = params.row as IVehicleDTO;
                return filters?.costCenters.find(
                    (costCenter) => vehicle.cost_center_id === costCenter.id
                )?.tx_centro_custo;
            },
        },
        {
            field: "status",
            headerName: "Status",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 200,
            sortable: false,
            renderCell: (params) => {
                const row = params.row as IVehicleDTO;

                return (
                    <Tag type={row.status === "ATIVO" ? "success" : "error"}>
                        {params.getValue(params.field)}
                    </Tag>
                );
            },
        },
        {
            field: "exclude",
            headerName: " ",
            cellClassName: classes.cell,
            headerClassName: classes.headerCell,
            width: 100,
            sortable: false,
            renderCell: (params) => {
                const row = params.row as IVehicleDTO;
                return (
                    <Tooltip title="Remover equipamento">
                        <IconButton
                            className={classes.buttonDelete}
                            onClick={() =>
                                setOpenModalRemoveEquipment({
                                    open: true,
                                    data: row,
                                })
                            }
                        >
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                );
            },
        },
    ];

    return (
        <>
            <TitleWithButtons
                title="Equipamentos cadastrados"
                buttons={[
                    {
                        label: "Importar equipamentos",
                        buttonProps: {
                            onClick: () => layoutContext.setOpenComingSoonDialog(true),
                        },
                    },
                    {
                        label: "Adicionar equipamento",
                        buttonProps: {
                            component: Link,
                            to: ROUTES.EQUIPMENTS_CREATE,
                        },
                    },
                    {
                        label: "Download",
                        buttonProps: {
                            onClick: donwloadVehicles,
                            startIcon: loadingDownload && (
                                <CircularProgress size={21} color="primary"/>
                            ),
                        },
                    },
                ]}
            />

            <Hidden smUp>
                {loadingFilters ? (
                    <Grid
                        className={classes.spacingButtonFilter}
                        container
                        justify="center"
                    >
                        <CircularProgress className={classes.loading}/>
                    </Grid>
                ) : errorLoadingFilters ? (
                    <Grid
                        className={classes.spacingButtonFilter}
                        container
                        justify="center"
                    >
                        <ErrorLoading messageError="Ocorreu um erro ao baixar dados dos filtros"/>
                        <ReloadButton
                            reloadFunc={fetchFilters}
                            reloading={loadingFilters}
                        />
                    </Grid>
                ) : (
                    <Grid
                        className={classes.spacingButtonFilter}
                        container
                        justify="center"
                    >
                        <IconButton onClick={() => setShowFilters(!showFilters)}>
                            <FilterListIcon/>
                        </IconButton>
                    </Grid>
                )}
            </Hidden>

            <Collapse in={showFilters || !isMobile} style={{minHeight: "auto"}}>
                {loadingFilters ? (
                    <Grid container justify="center">
                        <CircularProgress style={{height: 30, width: 30}}/>
                    </Grid>
                ) : errorLoadingFilters ? (
                    <Grid container justify="center">
                        <ErrorLoading messageError="Ocorreu um erro ao baixar dados dos filtros"/>
                        <ReloadButton
                            reloadFunc={fetchFilters}
                            reloading={loadingFilters}
                        />
                    </Grid>
                ) : (
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <StyledSelect
                                onClear={() =>
                                    setSelectedFilters({...selectedFilters, departmentID: ""})
                                }
                                isFilled={!!selectedFilters.departmentID}
                                textFieldProps={{
                                    label: "Selecione um registro",
                                    value: selectedFilters.departmentID,
                                    onChange: ({target}) =>
                                        setSelectedFilters({
                                            ...selectedFilters,
                                            departmentID: target.value,
                                        }),
                                }}
                                title="Departamento"
                            >
                                {filters
                                    ? filters.departments.map((filter) => {
                                        return (
                                            <MenuItem key={filter.id} value={filter.id}>
                                                {filter.tx_departamento}
                                            </MenuItem>
                                        );
                                    })
                                    : []}
                            </StyledSelect>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <StyledSelect
                                onClear={() =>
                                    setSelectedFilters({...selectedFilters, costCenterID: ""})
                                }
                                isFilled={!!selectedFilters.costCenterID}
                                textFieldProps={{
                                    label: "Selecione um registro",
                                    value: selectedFilters.costCenterID,
                                    onChange: ({target}) =>
                                        setSelectedFilters({
                                            ...selectedFilters,
                                            costCenterID: target.value,
                                        }),
                                }}
                                title="Centro de custos"
                            >
                                {filters
                                    ? filters.costCenters.map((filter) => {
                                        return (
                                            <MenuItem key={filter.id} value={filter.id}>
                                                {filter.tx_centro_custo}
                                            </MenuItem>
                                        );
                                    })
                                    : []}
                            </StyledSelect>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <StyledSelect
                                onClear={() =>
                                    setSelectedFilters({...selectedFilters, statusID: ""})
                                }
                                isFilled={!!selectedFilters.statusID}
                                textFieldProps={{
                                    label: "Selecione um registro",
                                    value: selectedFilters.statusID,
                                    onChange: ({target}) =>
                                        setSelectedFilters({
                                            ...selectedFilters,
                                            statusID: target.value,
                                        }),
                                }}
                                title="Status"
                            >
                                {filters
                                    ? filters.status.map((filter) => {
                                        return (
                                            <MenuItem key={filter.id} value={filter.id}>
                                                {filter.status}
                                            </MenuItem>
                                        );
                                    })
                                    : []}
                            </StyledSelect>
                        </Grid>
                    </Grid>
                )}
            </Collapse>

            {isMobile ? (
                <div
                    style={{
                        height: 500,
                        minHeight: 500,
                        width: "100%",
                        marginTop: "4rem",
                    }}
                    className={classes.tableEquipments}
                >
                    <DataGrid
                        rows={vehicles.result}
                        columns={columns}
                        onPageSizeChange={(props) => {
                            setLimit(props.pageSize);
                        }}
                        components={{
                            loadingOverlay: LoadingOverlayDataGrid,
                            pagination: ({pagination}) => (
                                <Pagination
                                    page={page}
                                    rowCount={vehicles.count}
                                    pageSize={pagination.pageSize}
                                    setPage={(value) => setPage(value)}
                                />
                            ),
                            errorOverlay: () => {
                                return (
                                    <div
                                        style={{
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <ErrorLoading/>
                                        <ReloadButton
                                            reloadFunc={fetchEquipments}
                                            reloading={loadingVehicles}
                                        />
                                    </div>
                                );
                            },
                            noRowsOverlay: () => (
                                <GridOverlay>
                                    <NoData/>
                                </GridOverlay>
                            ),
                        }}
                        loading={loadingVehicles}
                        error={errorLoadingVehicles ? true : null}
                        scrollbarSize={5}
                        disableSelectionOnClick
                        autoPageSize
                    />
                </div>
            ) : (
                <div
                    style={{
                        height: isMobile ? 500 : "100%",
                        width: "100%",
                        marginTop: "4rem",
                    }}
                    className={classes.tableEquipments}
                >
                    <AutoSizer onResize={() => setPage(1)}>
                        {({height, width}) => (
                            <div style={{height: sizeTable ? sizeTable : height, width}}>
                                <DataGrid
                                    rows={vehicles.result}
                                    columns={columns}
                                    onPageSizeChange={(props) => {
                                        setLimit(props.pageSize);
                                    }}
                                    components={{
                                        loadingOverlay: LoadingOverlayDataGrid,
                                        pagination: ({pagination}) => (
                                            <Pagination
                                                page={page}
                                                rowCount={vehicles.count}
                                                pageSize={pagination.pageSize}
                                                setPage={(value) => setPage(value)}
                                                sizeTable={sizeTable}
                                                setSizeTable={(value) => setSizeTable(value)}
                                            />
                                        ),
                                        errorOverlay: () => {
                                            return (
                                                <div
                                                    style={{
                                                        height: "100%",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <ErrorLoading/>
                                                    <ReloadButton
                                                        reloadFunc={fetchEquipments}
                                                        reloading={loadingVehicles}
                                                    />
                                                </div>
                                            );
                                        },
                                        noRowsOverlay: () => (
                                            <GridOverlay>
                                                <NoData/>
                                            </GridOverlay>
                                        ),
                                    }}
                                    loading={loadingVehicles}
                                    error={errorLoadingVehicles ? true : null}
                                    scrollbarSize={5}
                                    disableSelectionOnClick
                                    autoPageSize
                                />
                            </div>
                        )}
                    </AutoSizer>
                </div>
            )}

            <ModalRemoveEquipment
                open={openModalRemoveEquipment.open}
                data={openModalRemoveEquipment.data}
                closeFunc={() =>
                    setOpenModalRemoveEquipment({data: null, open: false})
                }
            />
        </>
    );
};

export {EquipmentsPage};
