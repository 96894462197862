export interface MaintenanceDataPayload {
  id?: string;
  companyId?: string;
  companyName?: string;
  reports?: string[];
  vehicleId?: string;
  vehiclePlate?: string;
  vehicleModel?: string;
  deadline?: Date | null;
  type?: string;
}

export const MaintenanceDataInitialValues: MaintenanceDataPayload = {
  reports: [],
  vehicleId: "",
  vehiclePlate: "",
  vehicleModel: "",
  deadline: null,
  type: "",
};

export interface VehicleMaintenance {
  id?: string;
  plate?: string;
  model?: string;
}

export interface MaintenanceFilter {
  plate?: string;
  order_id?: string;
  type?: string;
  status?: string;
  limit?: number;
  page?: number;
}

export default interface Maintenance {
  id?: string;
  company?: {
    id?: string;
    name?: string;
    document?: string;
  };
  orderId?: string;
  reports?: string[];
  vehicle?: VehicleMaintenance;
  type?: string;
  status?: string;
  deadline?: Date | null;
  createdAt?: Date;
  updatedAt?: Date;
}
