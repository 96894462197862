import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { ICreateDepartmentService } from "../models/ICreateDepartmentService";

@injectable()
export class CreateDepartmentService implements ICreateDepartmentService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    name: string;
  }): Promise<void> {
    await this.httpInstance.post(`/companies/${data.companyID}/departments`, {
      tx_departamento: data.name,
    });
  }
}
