import React, { useState } from "react";
import { Document } from "../../client/Document/Document";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { format } from "date-fns/esm";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/BorderColorOutlined";
import DownloadIcon from "@material-ui/icons/CloudDownloadRounded";
import { Button } from "@components/Button";
import { useUserContext } from "@hooks/UserContext";
import { useIoCContext } from "@hooks/IoCContext";
import { UploadClient } from "../../client/upload/UploadClient";
import { Types } from "@ioc/types";
import { DocumentClient } from "../../client/Document/DocumentClient";
import { useSnackbar } from "notistack";
import DocumentEdit from "@pages/Documents/Edit";

export interface Thumbnail {
  id: string;
  url: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 166,
    width: 166,
  },
  media: {
    height: 0,
    paddingTop: "76.25%",
    cursor: "pointer",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const getImageByExtension = (extension: string) => {
  if (["doc", "docx"].includes(extension)) {
    return "extensions/doc.jpeg";
  }
  if (["ppt", "pptx"].includes(extension)) {
    return "extensions/ppt.jpeg";
  }
  if (["xls", "xlsx"].includes(extension)) {
    return "extensions/xls.jpeg";
  }
  if (extension === "txt") {
    return "extensions/txt.jpeg";
  }
  if (extension === "pdf") {
    return "extensions/pdf.jpeg";
  }
  return "extensions/unknow.jpeg";
};

const formatDate = (value?: Date | null) => {
  if (!value) return "-";
  return format(value, "dd/MM/yyyy");
};

interface DocumentCardProps {
  document: Document;
  thumbnails: Thumbnail[];
  onChange: () => void;
  onLoading: (loading: boolean) => void;
}

const DocumentCard: React.FC<DocumentCardProps> = (props) => {
  const { document, thumbnails, onChange, onLoading } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany } = useUserContext();
  const iocContext = useIoCContext();
  const [openModalEditDocument, setOpenModalEditDocument] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

  const uploadClient = iocContext.serviceContainer.get<UploadClient>(
    Types.Upload
  );

  const documentClient = iocContext.serviceContainer.get<DocumentClient>(
    Types.Document
  );

  const getThumbnail = (document?: Document) => {
    if (!document) {
      return "extensions/unknow.jpeg";
    }
    const url = thumbnails.find((thumbnail) => thumbnail.id === document.id)
      ?.url;
    if (url) {
      return url;
    }
    return getImageByExtension(document?.extension || "");
  };

  const handleCloseModalEditDocument = () => {
    setOpenModalEditDocument(false);
    onChange();
  };

  const handleRemove = async () => {
    if (!selectedCompany || !document?.id) return;
    onLoading(true);
    try {
      await documentClient.remove(selectedCompany.uuid, document.id);
      onChange();
    } catch (error) {
      enqueueSnackbar(error.response?.data?.message, { variant: "error" });
    } finally {
      onLoading(false);
    }
    setRemoveDialogOpen(false);
  };

  const download = () => {
    if (!selectedCompany) return;
    onLoading(true);
    try {
      uploadClient
        .resolveLocation(selectedCompany.uuid, document.path)
        .then((result) => {
          window.open(result);
        });
    } catch (error) {
      enqueueSnackbar(error.response?.data?.message, { variant: "error" });
    } finally {
      onLoading(false);
    }
  };

  return (
    <div style={{ padding: "10px 20px" }}>
      <Card className={classes.root}>
        <CardHeader
          title={document.name}
          style={{ fontWeight: "bold" }}
          disableTypography
        />
        <CardMedia
          className={classes.media}
          image={getThumbnail(document)}
          title={document.name}
          onDoubleClick={download}
        />
        <CardContent>
          <div
            style={{
              fontWeight: "initial",
              fontSize: "12px",
              fontStyle: "italic",
            }}
          >
            Data de Expiração:
            <br /> {formatDate(document.expirationDate)}
          </div>
        </CardContent>
        <CardActions disableSpacing>
          <Tooltip title="Remover">
            <IconButton
              onClick={() => {
                setRemoveDialogOpen(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar">
            <IconButton
              onClick={() => {
                setOpenModalEditDocument(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Baixar">
            <IconButton onClick={download}>
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>
      <DocumentEdit
        open={openModalEditDocument}
        id={document.id}
        onClose={handleCloseModalEditDocument}
      />
      <Dialog
        open={removeDialogOpen}
        onClose={() => setRemoveDialogOpen(false)}
      >
        <DialogTitle>Atenção</DialogTitle>
        <DialogContent>
          Tem certeza que deseja excluir este documento?
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setRemoveDialogOpen(false)}>
            Não
          </Button>
          <Button color="error" onClick={handleRemove}>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export { DocumentCard };
