import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    "data-table": {
      width: "100%",
    },
    header: {
      fontSize: "14px",
      fontWeight: "bold",
      textAlign: "left",
      color: "#58595B",
    },
    cell: {
      borderTop: "solid 1px #E8E9EB",
      padding: "6px",
      fontSize: "14px",
    },
    "no-content": {
      fontStyle: "italic",
    },
    root: {
      "& > *": {
        marginTop: theme.spacing(2),
        justifyContent: "center",
        display: "flex",
      },
    },
    cellAction: {
      width: "36px",
      textAlign: "center",
      color: "rgba(0, 0, 0, 0.23)",
      cursor: "pointer",
    },
    contextMenuButton: {
      width: "28px",
      background: "none",
      border: "none",
      color: "rgba(0, 0, 0, 0.23)",
      cursor: "pointer",
      padding: "0px",
    },
    contextMenuContainer: {
      webkitBoxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    },
    contextMenuItem: {
      borderTop: "solid 1px rgba(0, 0, 0, 0.04)",
    },
    contextMenuIcon: {
      minWidth: "32px",
      "& svg": {
        fontSize: "16px",
      },
    },
    contextMenuText: {
      "& span": {
        fontSize: "12px",
      },
    },
  })
);
