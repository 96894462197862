import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { v4 as uuidv4 } from "uuid";
import { IGetTransactionsCreditDTO } from "../dtos/IGetTransactionsCreditDTO";
import { IGetTransactionsCreditService } from "../models/IGetTransactionsCreditService";

@injectable()
export class GetTransactionsCreditService
  implements IGetTransactionsCreditService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    page: number;
    limit: number;
  }): Promise<IGetTransactionsCreditDTO> {
    const resp = await this.httpInstance.get<IGetTransactionsCreditDTO>(
      `/companies/${data.companyID}/transactions/credit`,
      {
        params: {
          limit: data.limit,
          page: data.page,
        },
      }
    );

    resp.result = resp.result.map((result) => {
      return {
        ...result,
        id: uuidv4(),
        created_at: new Date(result.created_at),
        quantity: Number(result.quantity),
      };
    });

    return resp;
  }
}
