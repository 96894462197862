import { CardHeaderCharts } from "@components/CardHeaderCharts";
import { ErrorLoading } from "@components/ErrorLoading";
import { NoData } from "@components/NoData";
import { useIoCContext } from "@hooks/IoCContext";
import { useUserContext } from "@hooks/UserContext";
import { Types } from "@ioc/types";
import {
  Card,
  CardActions,
  CardContent,
  Collapse,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { IGetPriceProducts } from "@modules/dashboard/models/IGetPriceProducts";
import { formatCurrency } from "@utils/index";
import Chartist from "chartist";
import "chartist-plugin-fill-donut";
import "chartist-plugin-tooltips-updated";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { v4 as uuidv4 } from "uuid";
import "../tooltip.css";

const useStyles = makeStyles((theme) =>
  createStyles({
    legend: {
      display: "flex",
      alignItems: "center",
      flex: "1 1 20rem",
    },
    legendText: {
      marginLeft: "1rem",
      color: theme.palette.tertiary.textButtons.primary,
      fontSize: "1.6rem",
    },
    circleLegend: {
      minHeight: "1.6rem",
      minWidth: "1.6rem",
      borderRadius: "50%",
    },
    donutValue: {
      fontWeight: "bold",
      fontSize: "3.2rem",
      textAlign: "center",
      [theme.breakpoints.down("lg")]: {
        fontSize: "2.8rem",
      },
      [theme.breakpoints.down(1160)]: {
        fontSize: "2rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "2.4rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "2rem",
        transform: "translate(0,12rem)",
      },
    },
    donutSubtitle: {
      fontSize: "1.4rem",
      textAlign: "center",
      color: theme.palette.tertiary.textButtons.primary,
      [theme.breakpoints.down(1340)]: {
        display: "none",
      },
      [theme.breakpoints.down("sm")]: {
        display: "block",
        fontSize: "1.8rem",
      },
      [theme.breakpoints.down(690)]: {
        display: "none",
      },
    },
    divider: {
      backgroundColor: theme.palette.tertiary.background.primary,
    },
    chart: {
      [theme.breakpoints.down("xs")]: {
        marginBottom: "5rem",
      },
    },
  })
);

const ChartFuelConsume: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const iocContext = useIoCContext();
  const { selectedCompany } = useUserContext();

  const getPricesService = iocContext.serviceContainer.get<IGetPriceProducts>(
    Types.Dashboard.IGetPriceProducts
  );

  const [loading, setLoading] = useState(false);
  const [priceProducts, setPriceProducts] = useState<
    { meta: string; value: number }[]
  >([]);

  useEffect(() => {
    // Drawing a donut chart
    const chart = new Chartist.Pie(
      "#chart1",
      { series: priceProducts },
      {
        donut: true,
        showLabel: false,
        donutWidth: 20,
        donutSolid: true,
        plugins: [
          Chartist.plugins.tooltip({
            transformTooltipTextFnc: (value: number) => formatCurrency(value),
            appendToBody: true,
          }),
          Chartist.plugins.fillDonut({
            items: [
              {
                content: ReactDOMServer.renderToString(
                  <div>
                    <Typography className={classes.donutValue}>
                      {formatCurrency(
                        priceProducts.reduce((a, b) => a + b.value, 0)
                      )}
                    </Typography>
                    <Typography className={classes.donutSubtitle}>
                      Consumo total em reais no mês
                    </Typography>
                  </div>
                ),
              },
            ],
          }),
        ],
      }
    );

    //@ts-ignore
    chart.on("draw", (data) => {
      if (data.type === "slice") {
        data.element.attr({
          style: `fill: ${
            // @ts-ignore
            theme.palette.tertiary.graphics[data.index]
          }`,
        });
      }
    });
  }, [classes, priceProducts, theme.palette.tertiary.graphics]);

  const fetchPrices = useCallback(async () => {
    if (selectedCompany) {
      try {
        setLoading(true);
        const price = await getPricesService.execute({
          companyID: selectedCompany.uuid,
        });

        setPriceProducts(
          price.map((product) => {
            return {
              meta: product.label,
              value: product.total,
            };
          })
        );
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  }, [getPricesService, selectedCompany]);

  useEffect(() => {
    fetchPrices();
  }, [fetchPrices, getPricesService]);

  return (
    <Card>
      <CardHeaderCharts
        title="Consumo total nos últimos 30 dias (R$)"
        reloadButtonProps={{
          reloadFunc: fetchPrices,
          reloading: loading,
        }}
      />
      <Divider className={classes.divider} />
      <CardContent>
        <Collapse in={loading} timeout={400}>
          <Skeleton
            component="div"
            className="ct-chart ct-octave"
            style={{ height: "35rem" }}
          />
        </Collapse>
        <Collapse in={!loading && priceProducts.length > 0} timeout={400}>
          <div
            id="chart1"
            className={clsx("ct-chart", "ct-octave", classes.chart)}
          />
        </Collapse>
        <Collapse in={priceProducts.length === 0 && !loading}>
          <NoData />
        </Collapse>
      </CardContent>
      <CardActions>
        {loading ? (
          <Grid
            container
            justify="center"
            wrap="wrap"
            style={{ padding: "0 6rem" }}
          >
            {[1, 2, 3, 4, 5, 6].map((ele) => {
              return (
                <Skeleton
                  key={uuidv4()}
                  height={50}
                  style={{
                    flex: "1 1 100px",
                    margin: "1rem",
                  }}
                />
              );
            })}
          </Grid>
        ) : (
          <Grid container wrap="wrap" style={{ padding: "0 6rem" }}>
            {priceProducts.map((ele, idx) => {
              return (
                <div key={uuidv4()} className={classes.legend}>
                  <div
                    className={classes.circleLegend}
                    style={{
                      backgroundColor:
                        //@ts-ignore
                        theme.palette.tertiary.graphics[idx],
                    }}
                  />
                  <Typography className={classes.legendText}>
                    {ele.meta}
                  </Typography>
                </div>
              );
            })}
          </Grid>
        )}
      </CardActions>
    </Card>
  );
};

export { ChartFuelConsume };
