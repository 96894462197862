import {DataColumn} from "@components/DataTable";

export const ColumnReference = (inMoney: boolean): DataColumn[] => {
    const field = inMoney ? "value" : "quantity";
    const headerName = inMoney ? "Valor" : "Quantidade";
    const converter = inMoney ? "currency" : "liter";
    return [
        {
            field: "user",
            headerName: "Usuário"
        },
        {
            field,
            headerName,
            converter,
            width: 200
        },
        {
            field: "date",
            headerName: "Data e hora",
            width: 200
        },
    ]
};
