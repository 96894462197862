import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IRemoveDocumentWithIdService } from "../models/IRemoveDocumentWithIdService.ts";

@injectable()
export class RemoveDocumentWithIdService
  implements IRemoveDocumentWithIdService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(
    id: string,
    tenantId: string,
    userId: string
  ): Promise<void> {
    await this.httpInstance.delete(`/v1/documents/${id}`, {
      headers: { tenant_id: tenantId, user_id: userId },
    });
  }
}
