import { useIoCContext } from "@hooks/IoCContext";
import { useUserContext } from "@hooks/UserContext";
import { Types } from "@ioc/types";
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { IReverseRechargeService } from "@modules/company/models/IReverseRechargeService";
import clsx from "clsx";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import { useRecharge } from "./RechargesContext";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      dialogPaper: {
        minWidth: "95vw",
        [theme.breakpoints.up("sm")]: {
          minWidth: "25vw",
        },
      },
      dialogTitle: {
        fontWeight: typography.fontWeightBold,
        fontSize: pxToRem(16),
      },
      divider: {
        backgroundColor: theme.palette.tertiary.background.primary,
      },
      buttons: {
        fontWeight: typography.fontWeightBold,
      },
      cancelButton: {
        color: theme.palette.tertiary.textButtons.primary,
      },
      spacingDivider: {
        margin: "2.4rem 0",
      },
      message: {
        marginTop: "2.4rem",
        fontSize: "1.6rem",
        "& span": {
          fontWeight: typography.fontWeightBold,
        },
      },
      subTitle: {
        color: theme.palette.tertiary.textButtons.primary,
        marginBottom: "3.2rem",
        fontSize: "1.6rem",
      },
    })
);

const ModalReverseRecharge: React.FC = () => {
  const classes = useStyles();
  const rechargesContext = useRecharge();
  const iocContext = useIoCContext();
  const userContext = useUserContext();
  const { enqueueSnackbar } = useSnackbar();

  const reverseRechargeService = iocContext.serviceContainer.get<IReverseRechargeService>(
    Types.Company.IReverseRechargeService
  );

  const [loading, setLoading] = useState(false);

  const reverseRecharge = useCallback(async () => {
    if (!userContext.selectedCompany) return;
    if (!rechargesContext.reverseDialog.data) return;
    try {
      setLoading(true);

      await reverseRechargeService.execute({
        companyID: userContext.selectedCompany.uuid,
        rechargeID: rechargesContext.reverseDialog.data.rechargeID,
        in_money: rechargesContext.reverseDialog.data.in_money,
      });
      rechargesContext.setReverseDialog({ open: false, data: null });
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro ao estornar recarga", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [
    enqueueSnackbar,
    rechargesContext,
    reverseRechargeService,
    userContext.selectedCompany,
  ]);

  return (
    <Dialog
      open={rechargesContext.reverseDialog.open}
      classes={{ paper: classes.dialogPaper }}
      onClose={() =>
        rechargesContext.setReverseDialog({
          open: false,
          data: null,
        })
      }
    >
      <DialogTitle disableTypography>
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography className={classes.dialogTitle}>
              Estornar recarga?
            </Typography>
          </Grid>
          <Grid item container justify="flex-end" xs={2}>
            <IconButton
              onClick={() =>
                rechargesContext.setReverseDialog({
                  open: false,
                  data: null,
                })
              }
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider className={classes.divider} />
      <DialogContent>
        <Typography className={classes.message}>
          Tem certeza que deseja fazer o estorno da{" "}
          <span>
            &nbsp;
            {rechargesContext.reverseDialog.data
              ? rechargesContext.reverseDialog.data.name
              : ""}
          </span>{" "}
          com data
          <span>
            &nbsp;
            {rechargesContext.reverseDialog.data
              ? format(rechargesContext.reverseDialog.data.date, "dd/MM/yyyy")
              : ""}
          </span>
        </Typography>
        <Divider className={clsx(classes.divider, classes.spacingDivider)} />
        <Typography className={classes.subTitle}>
          Todos os saldos serão estornados mas a transação não será excluída do
          extrato do usuário.
        </Typography>
        <Grid container justify="center">
          <Button
            className={clsx(classes.buttons, classes.cancelButton)}
            onClick={() =>
              rechargesContext.setReverseDialog({
                open: false,
                data: null,
              })
            }
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            className={classes.buttons}
            onClick={() => !loading && reverseRecharge()}
            startIcon={
              loading && <CircularProgress color="primary" size={20} />
            }
          >
            Estornar
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export { ModalReverseRecharge };
