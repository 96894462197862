import { useLayoutContext } from "@hooks/LayoutContext";
import { useUserContext } from "@hooks/UserContext";
import {
  Avatar,
  Grid,
  IconButton,
  SwipeableDrawer,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { getNumberOfNames } from "@utils/index";
import React from "react";
import RightSIdeBarStyle from "./RightSIdeBarStyle";
import { useAuth } from "@hooks/AuthContext";

const RightSideBar: React.FC = () => {
  const layoutContext = useLayoutContext();
  const {
    loadingCompanies,
    companies,
    selectedCompany,
    setSelectedCompany,
  } = useUserContext();

  const auth = useAuth();
  const classes = RightSIdeBarStyle();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
    defaultMatches: true,
  });
  const handleLogout = () => {
    auth.signOut();
  };

  return (
    <SwipeableDrawer
      open={layoutContext.openRightSideBar}
      anchor="right"
      onClose={() => layoutContext.setOpenRightSideBar(false)}
      onOpen={() => layoutContext.setOpenRightSideBar(true)}
      swipeAreaWidth={!isMobile ? 0 : 20}
    >
      <Grid container spacing={2} className={classes.container}>
        <Grid item container justify="space-between" alignItems="center">
          <Typography
            className={classes.textExit}
            onClick={() => layoutContext.setOpenRightSideBar(false)}
          >
            FECHAR
          </Typography>
          <IconButton
            className={classes.exitIcon}
            onClick={handleLogout}
            title="sair"
          >
            <PowerSettingsNewIcon color="primary" />
          </IconButton>
        </Grid>
        <Grid item container justify="center">
          <Avatar
            className={classes.avatar}
            src="https://image.flaticon.com/icons/png/512/1077/1077012.png"
          />
        </Grid>
        <Grid item container justify="center">
          {loadingCompanies ? (
            <Skeleton />
          ) : (
            <Typography className={classes.personName}>
              {getNumberOfNames(companies ? companies.name : "", 2)}
            </Typography>
          )}
          <Typography align="center" className={classes.otherTexts}>
            Bem vindo a sua área!
          </Typography>
        </Grid>
        {/*{<Grid item container justify="center">*/}
        {/*    <Button fullWidth className={classes.buttons}>*/}
        {/*        Meus dados*/}
        {/*    </Button>*/}
        {/*</Grid>}*/}
        <Grid item container justify="center">
          <Typography align="center" className={classes.otherTexts}>
            Você está visualizando apenas os dados da empresa{" "}
            <span className={classes.companyName}>{selectedCompany?.name}</span>
          </Typography>
        </Grid>
        <Grid item container justify="center">
          {loadingCompanies ? (
            <Skeleton />
          ) : (
            <Autocomplete
              options={companies ? companies.companies : []}
              getOptionLabel={(option) => option.name}
              value={selectedCompany}
              disableCloseOnSelect
              noOptionsText="Sem correspondência"
              onChange={(inputValue, selectedValue) => {
                if (selectedValue) {
                  setSelectedCompany(selectedValue);
                  layoutContext.setOpenRightSideBar(false);
                }
              }}
              renderOption={(options) => {
                return (
                  <Typography className={classes.autoCompleteText}>
                    {options.name}
                  </Typography>
                );
              }}
              renderInput={(params) => (
                <TextField
                  label="Selecionar Empresa"
                  variant="outlined"
                  style={{ width: 270, fontSize: 12 }}
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    classes: {
                      input: classes.autoCompleteInputText,
                    },
                  }}
                />
              )}
              closeIcon={null}
            />
          )}
        </Grid>
      </Grid>
    </SwipeableDrawer>
  );
};

export { RightSideBar };
