import {Types} from "@ioc/types";
import {IHttpService} from "@modules/infra/http/models/IHttpService";
import {goToLogout} from "@utils/index";
import React, {createContext, useCallback, useContext} from "react";
import {useIoCContext} from "./IoCContext";
import Keycloak from "keycloak-js";
import {FlagcardCompanyWrapper} from "../client/FlagcardCompanyWrapper";

export interface AuthProps {
    keycloak: Keycloak.KeycloakInstance;
    token: string;
    userID: string;
    name: string;
}

interface AuthContextData {
    token: string;
    userID: string;
    name: string;

    signOut(): Promise<void>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<AuthProps> = ({children, ...props}) => {
    const iocContext = useIoCContext();
    const httpService = iocContext.serviceContainer.get<IHttpService>(
        Types.IHttpService
    );

    const flagcardCompanyWrapper = iocContext.serviceContainer.get<FlagcardCompanyWrapper>(
        Types.FlagcardCompanyWrapper
    );

    httpService.setAuthorization(`Bearer ${props.token}`);
    flagcardCompanyWrapper.addKeycloak(props.keycloak);

    const signOut = useCallback(async () => {
        props.keycloak.logout()
            .then(() => {
                goToLogout();
            });
    }, [props.keycloak]);

    return (
        <AuthContext.Provider
            value={{
                ...props,
                signOut,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = (): AuthContextData => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth deve ser utilizado dentro de um AuthProvider");
    }
    return context;
};

export {useAuth, AuthProvider};
