import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IResetDriverPasswordService } from "../models/IResetDriverPasswordService";

@injectable()
export class ResetDriverPasswordService implements IResetDriverPasswordService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    driverID: string;
  }): Promise<void> {
    await this.httpInstance.patch(
      `/companies/${data.companyID}/drivers/${data.driverID}`,
      {
        tx_password: null,
      }
    );
  }
}
