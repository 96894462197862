import * as yup from "yup";

const REQUIRED_MESSAGE = "campo obrigatório";

export const EditValidationSchema = yup.object({
    registration: yup.string().nullable().trim().required(REQUIRED_MESSAGE),
    driver: yup.string().nullable().trim().required(REQUIRED_MESSAGE),
    cnh: yup.string().nullable().trim().required(REQUIRED_MESSAGE),
    category: yup.string().notOneOf(["0"], REQUIRED_MESSAGE),
    expirationDate: yup.date().typeError(REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
    rg: yup.string().nullable().trim().required(REQUIRED_MESSAGE),
    cpf: yup.string().nullable().trim().required(REQUIRED_MESSAGE),
    phoneNumber: yup.string().nullable().trim().required(REQUIRED_MESSAGE),
    activity: yup.string().notOneOf(["0"], REQUIRED_MESSAGE),
    department: yup.string().notOneOf(["0"], REQUIRED_MESSAGE),
    costCenter: yup.string().notOneOf(["0"], REQUIRED_MESSAGE),
});
