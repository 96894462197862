import { DataColumn } from "@components/DataTable";
import { Chip } from "@material-ui/core";
import React from "react";
import Vehicle from "../../client/vehicle/Vehicle";

export const ColumnDefinition: DataColumn[] = [
  {
    field: "vehicle",
    headerName: "Placa",
    render: (vehicle: Vehicle) => {
      return vehicle.plate;
    },
  },
  {
    field: "providerName",
    headerName: "Orgão",
  },
  {
    field: "infractionCode",
    headerName: "Código",
  },
  {
    field: "description",
    headerName: "Descrição",
  },
  {
    field: "infractionPoint",
    headerName: "Pontos",
  },
  {
    field: "infractionValue",
    headerName: "Valor",
    converter: "currency",
  },
  {
    field: "status",
    headerName: "Status",
    render: (status: String) => {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {status === "em_aberto" ? (
            <Chip
              size="small"
              label="Pendente"
              style={{
                backgroundColor: "#ffcc00",
                color: "#FFF",
                fontWeight: "bold",
              }}
            />
          ) : status === "vencido" ? (
            <Chip
              size="small"
              label="Vencido"
              style={{
                backgroundColor: "#C3352B",
                color: "#FFF",
                fontWeight: "bold",
              }}
            />
          ) : status === "pago" ? (
            <Chip
              size="small"
              label="Pago"
              style={{
                backgroundColor: "#54863F",
                color: "#FFF",
                fontWeight: "bold",
              }}
            />
          ) : status === "cancelado" ? (
            <Chip
              size="small"
              label="Cancelado"
              style={{
                backgroundColor: "#663714",
                color: "#FFF",
                fontWeight: "bold",
              }}
            />
          ) : (
            <Chip
              size="small"
              label="Prescrito"
              style={{
                backgroundColor: "#144366",
                color: "#FFF",
                fontWeight: "bold",
              }}
            />
          )}
        </div>
      );
    },
  },
];
