import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IDownloadReportDriversPenaltiesService } from "../models/IDownloadReportDriversPenaltiesService";

@injectable()
export class DownloadReportDriversPenaltiesService
  implements IDownloadReportDriversPenaltiesService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    from: Date;
    to: Date;
  }): Promise<string> {
    const resp = await this.httpInstance.get(
      `/companies/${data.companyID}/report/penalties`,
      {
        params: {
          from_date: data.from,
          to_date: data.to,
          type: "driver",
        },
        responseType: "arraybuffer",
      }
    );

    const blob = new Blob([resp], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);
    return url;
  }
}
