import React from "react";
import { Alert, Color } from "@material-ui/lab";
import { Slide, SlideProps, Snackbar } from "@material-ui/core";

interface MessageProps {
  open: boolean;
  severity: Color;
  onClose?: () => void;
}

const Transition: React.FC<SlideProps> = (props) => {
  return <Slide {...props} direction="left" />;
};

const Message: React.FC<MessageProps> = (props) => {
  const { open, severity, children, onClose } = props;
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    if (onClose) onClose();
  };
  return (
    <Snackbar
      style={{ top: 94 }}
      open={open}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      TransitionComponent={Transition}
      onClose={handleClose}
      onClick={handleClose}
    >
      <Alert severity={severity} variant="filled">
        {children}
      </Alert>
    </Snackbar>
  );
};

const Success: React.FC<Omit<MessageProps, "severity">> = ({
  open,
  onClose,
  children,
}) => {
  return (
    <Message open={open} severity="success" onClose={onClose}>
      {children || "Operação realizada com sucesso"}
    </Message>
  );
};
const Error: React.FC<Omit<MessageProps, "severity">> = ({
  open,
  onClose,
  children,
}) => {
  return (
    <Message open={open} severity="error" onClose={onClose}>
      {children || "Não foi possível realizar a operação"}
    </Message>
  );
};

export default { Success, Error };
