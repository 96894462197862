import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Paper,
  TextField,
} from "@material-ui/core";
import { Button } from "@components/Button";
import Permission from "../../../../client/permission/Permission";
import Product from "../../../../client/product/model/Product";
import InputMask from "react-input-mask";
import StyleSheet from "@pages/Vehicles/StyleSheet";

interface DialogConsumptionProps {
  open: boolean;
  selected?: Permission;
  onCancel: () => void;
  onSave: (percent: number) => void;
}

export const DialogConsumption: React.FC<DialogConsumptionProps> = (props) => {
  const styleSheet = StyleSheet();
  const { selected, open, onCancel, onSave } = props;
  const [percentage, setPercentage] = useState(10);

  useEffect(() => {
    if (open) {
      setPercentage(selected?.options?.percentage || 10);
    }
  }, [open]);

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle disableTypography style={{ padding: "0px 16px" }}>
        <h2>Restrição por percentual de consumo médio</h2>
      </DialogTitle>
      <DialogContent style={{ padding: "16px" }}>
        <InputMask
          id="percentage"
          name="percentage"
          onChange={(event) =>
            setPercentage((event.target.value as unknown) as number)
          }
          value={percentage}
          mask="99%"
          maskChar={null}
        >
          {() => (
            <TextField
              style={{ width: "100%" }}
              classes={{ root: styleSheet.textField }}
              label="Percentual"
              variant="outlined"
              size="small"
            />
          )}
        </InputMask>
        <div style={{ height: 10 }} />
        <div style={{ padding: "10px 4px" }}>
          <Paper style={{ backgroundColor: "rgb(50, 192, 197, 0.1)" }}>
            <div style={{ padding: "10px 4px" }}>
              <span style={{ fontSize: 16, fontWeight: "bolder" }}>
                Exemplo
              </span>
              <div style={{ height: 10 }} />
              <span>Consumo Esperado: </span>
              <span style={{ fontWeight: "bolder" }}>12 km/l</span>
              <div style={{ height: 2 }} />
              <span>Percentual Definido: </span>
              <span style={{ fontWeight: "bolder" }}>10%</span>
              <div style={{ height: 2 }} />
              <span>Consumo Atual: </span>
              <span style={{ fontWeight: "bolder" }}>14 km/l</span>
              <div style={{ height: 2 }} />
              <span>Status: </span>
              <span style={{ fontWeight: "bolder" }}>
                <span style={{ color: "red" }}>Negado</span>, pois o consumo
                atual excede o consumo máximo permitido de 13.2 km/l (consumo
                esperado + 10%)
              </span>
              <div style={{ height: 2 }} />
            </div>
          </Paper>
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancel}>
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={() => {
            onSave(percentage);
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
