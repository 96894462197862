import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IListFunctionsDTO } from "../dtos/IListFunctionsDTO";
import { IListFunctionsService } from "../models/IListFunctionsService";

@injectable()
export class ListFunctionsService implements IListFunctionsService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
  }): Promise<IListFunctionsDTO[]> {
    const resp = await this.httpInstance.get<IListFunctionsDTO[]>(
      `/companies/${data.companyID}/functions`
    );

    return resp.map((functions) => {
      return {
        ...functions,
        created_at: new Date(functions.created_at),
      };
    });
  }
}
