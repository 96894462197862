import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IChangeKMDebtReportService } from "../models/IChangeKMDebtReportService";

@injectable()
export class ChangeKMDebtReportService implements IChangeKMDebtReportService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    tokenAuth: string;
    mileage: number;
  }): Promise<void> {
    await this.httpInstance.put(`/fleet/transactions`, {
      token_auth: data.tokenAuth,
      mileage: data.mileage,
    });
  }
}
