import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IExpiringLicenseDTO } from "../dtos/IExpiringLicenseDTO";
import { IGetExpiringLicencesService } from "../models/IGetExpiringLicencesService";

@injectable()
export class GetExpiringLicencesService implements IGetExpiringLicencesService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
  }): Promise<IExpiringLicenseDTO[]> {
    const response = await this.httpInstance.get<IExpiringLicenseDTO[]>(
      `/companies/${data.companyID}/drivers/expiring-licenses`
    );

    return response.map((license) => {
      return {
        ...license,
        cnh_expiration_date: new Date(license.cnh_expiration_date),
      };
    });
  }
}
