import { DataColumn } from "@components/DataTable";
import { VehicleMaintenance } from "../../../../client/Maintenance/Maintenance";
import { Chip } from "@material-ui/core";
import AccessTime from "@material-ui/icons/AccessTime";
import AccountTree from "@material-ui/icons/AccountTree";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import Timelapse from "@material-ui/icons/Timelapse";
import CheckCircle from "@material-ui/icons/CheckCircle";

export const ColumnDefinition: DataColumn[] = [
  {
    field: "orderId",
    headerName: "Ordem de Serviço",
    width: 132,
  },
  {
    field: "vehicle",
    headerName: "Veículo",
    render: (vehicle: VehicleMaintenance) => {
      return vehicle?.plate + " - " + vehicle?.model;
    },
  },
  {
    field: "type",
    headerName: "Tipo",
    width: 120,
    render: (type: string) => {
      if (type === "MAINTENANCE") {
        return "Manutenção Corretiva";
      }
      if (type === "PREVENTIVE") {
        return "Manutenção Preventiva";
      }
      if (type === "WASH") {
        return "Higienização";
      }
      return "-";
    },
  },
  {
    field: "createdAt",
    headerName: "Criação",
    width: 120,
    converter: "date",
  },
  {
    field: "deadline",
    headerName: "Previsão de Início",
    width: 132,
    converter: "date",
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    render: (status: string) => {
      if (status === "PENDING") {
        return (
          <Chip
            icon={<AccessTime />}
            label="Aguardando Fornecedor"
            color="primary"
            variant="outlined"
          />
        );
      }
      if (status === "IN_QUOTATION") {
        return (
          <Chip
            icon={<AccountTree style={{ color: "#FDA50F" }} />}
            label="Aguardando Proposta"
            style={{ color: "#FDA50F", border: "solid 1px #FDA50F" }}
            variant="outlined"
          />
        );
      }
      if (status === "READY_TO_APPROVAL") {
        return (
          <Chip
            icon={<AssignmentTurnedIn style={{ color: "#CCCC00" }} />}
            label="Disponível Para Aprovação"
            style={{ color: "#CCCC00", border: "solid 1px #CCCC00" }}
            variant="outlined"
          />
        );
      }
      if (status === "APPROVED") {
        return (
          <Chip
            icon={<CheckCircleOutline style={{ color: "#009900" }} />}
            label="Aguardando Início"
            style={{ color: "#009900", border: "solid 1px #009900" }}
            variant="outlined"
          />
        );
      }
      if (status === "IN_EXECUTION") {
        return (
          <Chip
            icon={<Timelapse style={{ color: "#4169E1" }} />}
            label="Em Andamento"
            style={{ color: "#4169E1", border: "solid 1px #4169E1" }}
            variant="outlined"
          />
        );
      }
      if (status === "FINISHED") {
        return (
          <Chip
            icon={<CheckCircle style={{ color: "#006400" }} />}
            label="Finalizada"
            style={{ color: "#006400", border: "solid 1px #006400" }}
            variant="outlined"
          />
        );
      }
      return "-";
    },
  },
];
