import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IDriverDTO } from "../dtos/IListDriversDTO";
import { IEditDriverService } from "../models/IEditDriverService";

@injectable()
export class EditDriverService implements IEditDriverService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    driver: IDriverDTO;
  }): Promise<void> {
    await this.httpInstance.patch(
      `/companies/${data.companyID}/drivers/${data.driver.id}`,
      {
        ...data.driver,
      }
    );
  }
}
