import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IListRechargesDTO } from "../dtos/IListRechargesDTO";
import { IListRechargesService } from "../models/IListRechargesService";

@injectable()
export class ListRechargesService implements IListRechargesService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    page: number;
    limit: number;
  }): Promise<IListRechargesDTO> {
    const resp = await this.httpInstance.get<IListRechargesDTO>(
      `/companies/${data.companyID}/recharges?page=${data.page}&limit=${data.limit}`
    );

    resp.result = resp.result.map((recharge) => {
      return {
        ...recharge,
        createdAt: new Date(recharge.createdAt),
      };
    });

    return resp;
  }
}
