import { DataColumn } from "@components/DataTable";
import { Chip } from "@material-ui/core";
import React from "react";
import Vehicle from "../../client/vehicle/Vehicle";
import { format } from "date-fns/esm";

const formatDate = (value?: Date | null) => {
  if (!value) return "-";
  return format(new Date(String(value)), "dd/MM/yyyy");
};

export const ColumnDefinition: DataColumn[] = [
  {
    field: "vehicle",
    headerName: "Placa",
    render: (vehicle: Vehicle) => {
      return vehicle.plate;
    },
  },
  {
    field: "providerName",
    headerName: "Orgão",
  },
  {
    field: "type",
    headerName: "Tipo de Débito",
    render: (type: string) => {
      if (type === "licenciamento") {
        return "Licenciamento";
      }
      if (type === "dpvat") {
        return "DPVAT";
      }
      if (type === "ipva") {
        return "IPVA";
      }
      if (type === "outros_debitos") {
        return "Outros Débitos";
      }
    },
  },
  {
    field: "year",
    headerName: "Ano de Referência",
  },
  {
    field: "installment",
    headerName: "Parcela",
    render: (installment: string) => {
      if (installment === "única") {
        return "Única";
      }
      return installment;
    },
  },
  {
    field: "paymentValue",
    headerName: "Valor",
    converter: "currency",
  },
  {
    field: "expirationDate",
    headerName: "Data de Vencimento",
    render: (expirationDate: Date) => {
      return formatDate(expirationDate);
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 90,
    render: (status: String) => {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {status === "em_aberto" ? (
            <Chip
              size="small"
              label="Pendente"
              style={{
                backgroundColor: "#ffcc00",
                color: "#FFF",
                fontWeight: "bold",
              }}
            />
          ) : (
            <Chip
              size="small"
              label="Dívida Ativa"
              style={{
                backgroundColor: "#144366",
                color: "#FFF",
                fontWeight: "bold",
              }}
            />
          )}
        </div>
      );
    },
  },
];
