import { Filter } from "@components/SearchContainer";

export const FilterDefinition: Filter[] = [
  {
    label: "Placa",
    field: "plate",
    placeholder: "Pesquisar por Placa",
  },
  {
    label: "Número",
    field: "order_id",
    placeholder: "Pesquisar por Número",
  },
  // {
  //     label: "Tipo",
  //     field: "type",
  //     placeholder: "Pesquisar por Tipo",
  // },
  // {
  //     label: "Status",
  //     field: "status",
  //     placeholder: "Pesquisar por Status",
  // },
];
