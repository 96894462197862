import React, { ChangeEvent, useState } from "react";
import StyleSheet from "./StyleSheet";
import { Pagination, Skeleton } from "@material-ui/lab";
import Active from "@material-ui/icons/CheckCircleOutline";
import Inactive from "@material-ui/icons/HighlightOff";
import Context from "@material-ui/icons/MoreVertTwoTone";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import clsx from "clsx";
import EditIcon from "@material-ui/icons/BorderColorOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "@components/Button";
import { formatCurrency } from "@utils/index";
import { format } from "date-fns/esm";
import { useHistory } from "react-router";

export interface ContextMenu {
  name: string;
  icon?: any;
  onClick?: (rowId?: string) => void;
}

export interface ContentProps {
  converter?: string;
  defaultValue?: string;
  navigateToOnClick?: (rowId?: string) => string;
  onClick?: (row?: any) => void;
  rowId?: string;
  row?: any;
}

export interface DataColumn {
  field: string;
  headerName: string;
  defaultValue?: string;
  width?: number;
  converter?: string;
  navigateToOnClick?: (rowId?: string) => string;
  render?: (field?: any) => any;
}

interface DataTableProps {
  rows: any[];
  columns: DataColumn[];
  loading?: boolean;
  limit?: number;
  pages?: number;
  total?: number;
  editable?: boolean;
  removable?: boolean;
  contexts?: ContextMenu[];
  onEdit?: (rowId?: string) => void;
  onRemove?: (rowId?: string) => void;
  onClick?: (row?: any) => void;
  onPagination?: (page: number) => void;
  onLimitChange?: (limit: number) => void;
}

const converters: Record<string, any> = {
  cpf: (document: string) => {
    if (!document) return "-";
    return String(document)
      .replace(".", "")
      .replace("-", "")
      .padStart(11, "0")
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  },
  status: (str: any) => {
    const status = Boolean(str);
    return (
      <div style={{ width: "100%", textAlign: "center" }}>
        {status ? (
          <Tooltip title="Ativo">
            <Active style={{ color: "#2E8b57" }} />
          </Tooltip>
        ) : (
          <Tooltip title="Inativo">
            <Inactive style={{ color: "B90E0A" }} />
          </Tooltip>
        )}
      </div>
    );
  },
  loading: (str: any) => {
    const status = Boolean(str);
    return (
      <div style={{ width: "100%", textAlign: "center" }}>
        {status ? (
          <CircularProgress size={20} />
        ) : (
          <Active style={{ color: "#2E8b57" }} />
        )}
      </div>
    );
  },
  currency: (value?: number) => {
    return formatCurrency(value || 0);
  },
  liter: (value: number) => {
    return (
      new Intl.NumberFormat("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value || 0) + " L"
    );
  },
  date: (value: Date) => {
    if (!value) return "-";
    const date = typeof value === "string" ? new Date(value) : value;
    return format(date, "dd/MM/yyyy");
  },
  default: (str: string) => str || "-",
};

const NoContent: React.FC<{ loading?: boolean; size: number }> = ({
  loading,
  size,
}) => {
  const styleSheet = StyleSheet();
  return (
    <tr>
      <td className={styleSheet.cell} colSpan={size}>
        {loading ? (
          <Skeleton />
        ) : (
          <span className={styleSheet["no-content"]}>
            Nenhuma informação encontrada
          </span>
        )}
      </td>
    </tr>
  );
};

const Content: React.FC<ContentProps> = (props) => {
  const {
    children,
    defaultValue,
    navigateToOnClick,
    rowId,
    row,
    onClick,
  } = props;
  const history = useHistory();
  const converter = props.converter
    ? converters[props.converter]
    : converters["default"];
  const styleSheet = StyleSheet();
  const style = navigateToOnClick || onClick ? { cursor: "pointer" } : {};
  const handleClick = () => {
    if (navigateToOnClick) {
      history.push({ pathname: navigateToOnClick(rowId) });
    } else {
      if (onClick) {
        onClick(row);
      }
    }
  };
  return (
    <td style={style} onClick={handleClick} className={styleSheet.cell}>
      {converter(children || defaultValue)}
    </td>
  );
};

const DataTable: React.FC<DataTableProps> = (props) => {
  const {
    columns,
    rows,
    loading,
    onEdit,
    onRemove,
    editable,
    removable,
    contexts,
    onClick,
  } = props;
  const styleSheet = StyleSheet();
  const limit = props.limit || 10;
  const total = props.total || rows.length;
  const pages = props.pages || 1;
  const showPagination = total > limit;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [rowId, setRowId] = useState<string>();
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [hasContextMenu] = useState(
    !!editable || !!removable || contexts?.length
  );

  const handleChange = (_: any, page: number) => {
    if (props.onPagination) {
      props.onPagination(page);
    }
  };

  const onLimitChange = (
    event: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    if (props.onLimitChange) {
      props.onLimitChange(event.target.value as number);
    }
  };

  const handleClickContextMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    setAnchorEl(event.currentTarget);
    setRowId(id);
  };

  const handleCloseContextMenu = () => {
    setAnchorEl(null);
  };

  const contextEditMenu = [];
  if (onEdit) {
    contextEditMenu.push(
      <MenuItem
        classes={{ root: styleSheet.contextMenuItem }}
        onClick={() => {
          handleCloseContextMenu();
          onEdit(rowId);
        }}
      >
        <ListItemIcon classes={{ root: styleSheet.contextMenuIcon }}>
          <EditIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText classes={{ root: styleSheet.contextMenuText }}>
          Editar
        </ListItemText>
      </MenuItem>
    );
  }

  return (
    <>
      <table className={styleSheet["data-table"]}>
        <thead>
          <tr className={styleSheet.header}>
            {columns.map((column, index) => {
              const style = { width: column.width };
              return (
                <td key={`column-${index}`} style={style}>
                  {column.headerName}
                </td>
              );
            })}
            {hasContextMenu ? <td /> : <></>}
          </tr>
        </thead>
        <tbody>
          {rows.length === 0 ? (
            <NoContent loading={loading} size={columns.length + 1} />
          ) : (
            rows.map((row) => {
              return (
                <tr key={row.id}>
                  {columns.map((column, index) => {
                    return (
                      <Content
                        key={index}
                        rowId={row.id}
                        converter={column.converter}
                        navigateToOnClick={column.navigateToOnClick}
                        defaultValue={column.defaultValue}
                        onClick={onClick}
                        row={row}
                      >
                        {column.render
                          ? column.render(row[column.field])
                          : row[column.field]}
                      </Content>
                    );
                  })}
                  {hasContextMenu ? (
                    <td
                      className={clsx(styleSheet.cell, styleSheet.cellAction)}
                    >
                      <button
                        key={"button_" + row.id}
                        className={styleSheet.contextMenuButton}
                        onClick={(event) => {
                          handleClickContextMenu(event, row.id);
                        }}
                      >
                        <Context />
                      </button>
                      <Menu
                        key={"context_" + row.id}
                        classes={{ paper: styleSheet.contextMenuContainer }}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseContextMenu}
                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {[
                          <MenuItem
                            classes={{ root: styleSheet.contextMenuItem }}
                            onClick={() => {
                              handleCloseContextMenu();
                              if (onEdit) {
                                onEdit(rowId);
                              }
                            }}
                          >
                            <ListItemIcon
                              classes={{ root: styleSheet.contextMenuIcon }}
                            >
                              <EditIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                              classes={{ root: styleSheet.contextMenuText }}
                            >
                              Editar
                            </ListItemText>
                          </MenuItem>,
                        ]
                          .filter(() => !!onEdit)
                          .map((context) => context)}
                        {contexts?.map((context) => {
                          return (
                            <MenuItem
                              classes={{ root: styleSheet.contextMenuItem }}
                              onClick={() => {
                                handleCloseContextMenu();
                                if (context.onClick) {
                                  context.onClick(rowId);
                                }
                              }}
                            >
                              <ListItemIcon
                                classes={{ root: styleSheet.contextMenuIcon }}
                              >
                                {context.icon}
                              </ListItemIcon>
                              <ListItemText
                                classes={{ root: styleSheet.contextMenuText }}
                              >
                                {context.name}
                              </ListItemText>
                            </MenuItem>
                          );
                        })}
                        {[
                          <MenuItem
                            classes={{ root: styleSheet.contextMenuItem }}
                            onClick={() => {
                              handleCloseContextMenu();
                              setRemoveDialogOpen(true);
                            }}
                          >
                            <ListItemIcon
                              classes={{ root: styleSheet.contextMenuIcon }}
                            >
                              <DeleteIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                              classes={{ root: styleSheet.contextMenuText }}
                            >
                              Excluir
                            </ListItemText>
                          </MenuItem>,
                        ]
                          .filter(() => !!onRemove)
                          .map((context) => context)}
                      </Menu>
                    </td>
                  ) : (
                    <></>
                  )}
                </tr>
              );
            })
          )}
          <tr>
            <td className={styleSheet.cell} colSpan={columns.length + 1}>
              {loading ? <LinearProgress color="primary" /> : <></>}
            </td>
          </tr>
        </tbody>
      </table>
      {props.onLimitChange ? (
        <div style={{ display: "flex" }}>
          Mostrar
          <div style={{ padding: "0px 12px", marginTop: "-8px" }}>
            <Select value={limit} label="Age" onChange={onLimitChange}>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </div>
          Registros
        </div>
      ) : (
        <></>
      )}
      <Pagination
        hidden={!showPagination}
        className={styleSheet.root}
        count={pages}
        shape="rounded"
        showFirstButton
        showLastButton
        size="small"
        onChange={handleChange}
      />
      <Dialog
        open={removeDialogOpen}
        onClose={() => setRemoveDialogOpen(false)}
      >
        <DialogTitle>Atenção</DialogTitle>
        <DialogContent>
          Tem certeza que deseja excluir este registro?
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setRemoveDialogOpen(false)}>
            Não
          </Button>
          <Button
            color="error"
            onClick={() => {
              setRemoveDialogOpen(false);
              if (onRemove) {
                onRemove(rowId);
              }
            }}
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export { DataTable };
