import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { ICreateCostCenterService } from "../models/ICreateCostCenterService";

@injectable()
export class CreateCostCenterService implements ICreateCostCenterService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    name: string;
  }): Promise<void> {
    await this.httpInstance.post(`/companies/${data.companyID}/costcenters`, {
      tx_centro_custo: data.name,
    });
  }
}
