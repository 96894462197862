import { DataColumn } from "@components/DataTable";

export const ColumnReference = (inMoney: boolean): DataColumn[] => {
  const headerName = inMoney ? "Valor" : "Quantidade";
  const converter = inMoney ? "currency" : "liter";
  return [
    {
      field: "user",
      headerName: "Usuário",
      width: 350,
    },
    {
      field: "department",
      headerName: "Departamento",
      width: 250,
    },
    {
      field: "function",
      headerName: "Função",
      width: 350,
    },
    {
      field: "center",
      headerName: "Centro de custos",
      width: 250,
    },
    {
      field: "quantity",
      headerName,
      converter,
      width: 250,
    },
    {
      field: "date",
      headerName: "Última atualização",
      width: 350,
    },
  ];
};
