import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IFleetFuelDTO } from "../dtos/IFleetFuelDTO";
import { IListFleetFuelService } from "../models/IListFleetFuelService";

@injectable()
export class ListFleetFuelService implements IListFleetFuelService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(): Promise<IFleetFuelDTO[]> {
    const resp = await this.httpInstance.get<IFleetFuelDTO[]>(`/fleet/fuel`);
    return resp;
  }
}
