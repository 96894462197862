import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IGetPriceProductsDTO } from "../dtos/IGetPriceProductsDTO";
import { IGetPriceProducts } from "../models/IGetPriceProducts";

@injectable()
export class GetPriceProducts implements IGetPriceProducts {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
  }): Promise<IGetPriceProductsDTO[]> {
    const response = await this.httpInstance.get<IGetPriceProductsDTO[]>(
      `/companies/${data.companyID}/report/consumption/products`
    );

    if (response.find((ele) => ele.total > 0)) {
      return response;
    } else {
      return [];
    }
  }
}
