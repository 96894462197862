import { Search } from "../model/Search";
import { inject, injectable } from "inversify";
import { Types } from "@ioc/types";
import Debit, { FilterDebit } from "./Debit";

export interface DebitClient {
  find(companyId: string, filter?: FilterDebit): Promise<Search<Debit>>;
}

@injectable()
export class DebitClientImpl implements DebitClient {
  @inject(Types.FlagcardCompanyWrapper)
  // @ts-ignore
  private instance: FlagcardCompanyWrapper;

  private readonly endpoint = "/rest/v1/debits";

  public find(companyId: string, filter?: FilterDebit): Promise<Search<Debit>> {
    const plate = filter?.plate?.replaceAll("-", "").toUpperCase() || undefined;
    const page = filter?.page || 1;
    return this.instance.get(this.endpoint, {
      params: { ...filter, plate, page },
      headers: { company_id: companyId },
    });
  }
}
