import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IVehicleDTO } from "../dtos/IListPermissibilityFleetDTO";
import { IGetVehicleDataService } from "../models/IGetVehicleDataService";

@injectable()
export class GetVehicleDataService implements IGetVehicleDataService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    vehicleID: string;
    companyID: string;
  }): Promise<IVehicleDTO> {
    const resp = await this.httpInstance.get<IVehicleDTO>(
      `/fleet/vehicle/${data.vehicleID}/${data.companyID}`
    );

    resp.block_hours = Number(resp.block_hours);

    return resp;
  }
}
