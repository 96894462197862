import { IFilterUsersDTO } from "@modules/user/dtos/IFilterUsersDTO";
import { IListUsersDTO, IUserDTO } from "@modules/user/dtos/IListUsersDTO";
import React, { createContext, useContext, useState } from "react";

interface UsersPageContext {
  page: number;
  limit: number;
  users: IListUsersDTO;
  loadingUsers: boolean;
  errorLoadingUsers: boolean;
  openModalUser: IModalUser;
  filters: IFilterUsersDTO | null;
  openModalRemoveUser: IModalUser;
  openModalUploadUsers: boolean;
  setLoadingUsers: (data: boolean) => void;
  setUsers: (data: IListUsersDTO) => void;
  setPage: (data: number) => void;
  setErrorLoadingUsers: (data: boolean) => void;
  setLimit: (data: number) => void;
  setOpenModalUser: (data: IModalUser) => void;
  setFilters: (data: IFilterUsersDTO | null) => void;
  setOpenModalRemoveUser: (data: IModalUser) => void;
  setOpenModalUploadUsers: (data: boolean) => void;
}

interface IModalUser {
  open: boolean;
  data: IUserDTO | null;
}

const UsersPageContext = createContext({} as UsersPageContext);

const UsersPageProvider: React.FC = ({ children }) => {
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [errorLoadingUsers, setErrorLoadingUsers] = useState(false);
  const [openModalUser, setOpenModalUser] = useState<IModalUser>({
    open: false,
    data: null,
  });
  const [openModalRemoveUser, setOpenModalRemoveUser] = useState<IModalUser>({
    open: false,
    data: null,
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [users, setUsers] = useState<IListUsersDTO>({
    page: 1,
    count: 0,
    result: [],
  });
  const [filters, setFilters] = useState<IFilterUsersDTO | null>(null);
  const [openModalUploadUsers, setOpenModalUploadUsers] = useState(false);

  return (
    <UsersPageContext.Provider
      value={{
        limit,
        page,
        users,
        loadingUsers,
        errorLoadingUsers,
        openModalUser,
        filters,
        openModalRemoveUser,
        openModalUploadUsers,
        setLoadingUsers,
        setUsers,
        setPage,
        setErrorLoadingUsers,
        setLimit,
        setOpenModalUser,
        setFilters,
        setOpenModalRemoveUser,
        setOpenModalUploadUsers,
      }}
    >
      {children}
    </UsersPageContext.Provider>
  );
};

const useUsersPage = () => {
  const context = useContext(UsersPageContext);
  if (Object.values(context).length === 0) {
    throw new Error(
      "useUsersPage não pode ser utilizado fora de um UsersPageProvider"
    );
  }
  return context;
};

export { useUsersPage, UsersPageProvider };
