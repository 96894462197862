import {parseISO} from "date-fns";
import {version} from "../../package.json";

export const appConfig = {
    keycloack: {
        urlServer: "https://auth.flagcard.com.br/auth",
        clientID: "ragadast",
        realm: "flagcard",
        urlLogout: {
            development: "http://localhost:8000",
            production: "https://admin.flagcard.com.br",
        },
    },
    api: {
        timeout: 10000,
        url: {
            production: "https://bifrost.flagcard.com.br/rest",
            development: "https://bifrost.flagcard.com.br/rest",
        },
    },
    keysLocalStorage: {
        darkMode: "@darkMode",
    },
    version: {
        number: version,
        date: parseISO("2021-09-14"),
    },
};
