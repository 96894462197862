import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { ICreateDocumentService } from "@modules/company/models/ICreateDocumentService";
import { DocumentDTO } from "@modules/company/dtos/DocumentDTO";

@injectable()
export class CreateDocumentService implements ICreateDocumentService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(
    data: DocumentDTO,
    tenantId: string,
    userId: string
  ): Promise<void> {
    await this.httpInstance.post(`/v1/documents`, data, {
      headers: { tenant_id: tenantId, user_id: userId },
    });
  }
}
