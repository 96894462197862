import {
  Avatar,
  Button,
  Collapse,
  createStyles,
  Divider,
  FormHelperText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import BackupIcon from "@material-ui/icons/Backup";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import clsx from "clsx";
import React, { useState } from "react";
import { ReactComponent as FolderIcon } from "../../assets/folder.svg";
import notFileImg from "../../assets/nao-compativel.png";
import { Search } from "@material-ui/icons";

const useStyles = makeStyles((theme) =>
  createStyles({
    boxUpload: {
      paddingTop: "2.4rem",
      paddingBottom: "3.2rem",

      border: "0.2rem dashed rgba(0,0,0,0.12)",
      borderRadius: theme.shape.borderRadius,

      "& svg": {
        fontSize: "4rem",
      },

      "& *:not([data-cancelDrag])": {
        pointerEvents: "none",
      },
      "& *[data-cancelDrag='true']": {
        pointerEvents: "all",
      },
    },
    boxUploadInvalidFile: {
      border: "0.2rem dashed rgba(0,0,0,0.12)",
      borderColor: theme.palette.tertiary.error.primary,
    },
    bounceUpload: {
      animationName: "$bounce",
      animationDuration: "1s",
      animationIterationCount: "infinite",
      animationDirection: "alternate",
    },
    bounceInDragging: {
      animationName: "$bounceIn",
      animationDuration: "1s",
      animationIterationCount: "infinite",
      animationDirection: "alternate",
    },
    centerBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    "@keyframes bounce": {
      from: {
        transform: "translateY(0px)",
      },
      to: {
        transform: "translateY(-15px)",
      },
    },
    "@keyframes bounceIn": {
      "0%, 20%, 40%, 60%, 80%, 100%": {
        transitionTimingFunction: "cubic-bezier(0.215, 0.610, 0.355, 1.000)",
      },
      "0%": {
        opacity: 0,
        transform: "scale3d(.3, .3, .3)",
      },
      "20%": {
        transform: "scale3d(1.1, 1.1, 1.1)",
      },
      "40%": {
        transform: "scale3d(.9, .9, .9)",
      },
      "60%": {
        transform: "scale3d(1.03, 1.03, 1.03)",
      },
      "80%": {
        transform: "scale3d(.97, .97, .97)",
      },
      "100%": {
        transform: "scale3d(1, 1, 1)",
      },
    },
    input: {
      display: "none",
    },
    file: {
      width: "6rem",
      height: "6rem",
      backgroundColor: "#E0E0E0",
      "& svg": {
        width: "3rem",
        height: "3rem",
      },
    },
    divider: {
      width: "25%",
      margin: "2rem",
    },
    text: {
      color: theme.palette.tertiary.textButtons.secondary,
      fontSize: "1.6rem",
    },
    icons: {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
  })
);

interface Props {
  onChange?: (file: File) => void;
  onSelectedFiles?: (files: FileList) => void;
  multiple?: boolean;
  onTouch?: () => void;
  touched?: boolean;
  error?: boolean;
  helperText?: string;
  value?: File | null;
}

const DragInDropBox: React.FC<Props> = ({
  onSelectedFiles,
  onChange,
  error,
  helperText,
  onTouch,
  value,
  multiple,
}) => {
  const classes = useStyles();

  const [isDragging, setIsDragging] = useState(false);

  const handleDragIn = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer && e.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  };

  const handleDragOut = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    if (onChange) {
      onChange(e.dataTransfer.files[0]);
    }
    if (onSelectedFiles) {
      onSelectedFiles(e.dataTransfer.files);
    }
    setIsDragging(false);
  };

  return (
    <>
      <div
        onDragOver={handleDragIn}
        onDragLeave={handleDragOut}
        onDrop={handleDrop}
        className={clsx(classes.boxUpload, {
          [classes.boxUploadInvalidFile]: error,
        })}
      >
        <input
          className={classes.input}
          id="file"
          name="file"
          type="file"
          multiple={!!multiple}
          onChange={(e) => {
            if (e.target.files?.length) {
              if (onChange) {
                onChange(e.target.files[0]);
              }
              if (onSelectedFiles) {
                onSelectedFiles(e.target.files);
              }
            }
            setTimeout(() => {
              if (onTouch) {
                onTouch();
              }
            }, 1);
          }}
        />
        <Collapse in={!isDragging}>
          <div className={classes.centerBox}>
            {value && (
              <>
                {error && <Avatar src={notFileImg} className={classes.file} />}
                {!error && (
                  <Avatar className={classes.file}>
                    <FolderIcon className={classes.icons} />
                  </Avatar>
                )}
                <Typography>{value && value.name}</Typography>
                <Divider className={classes.divider} />
              </>
            )}
            {!value && (
              <BackupIcon
                className={clsx(classes.bounceUpload, classes.icons)}
              />
            )}
            <Typography className={classes.text}>
              Arraste e solte seu arquivo aqui
            </Typography>
            <Typography className={classes.text}>ou</Typography>

            <label
              htmlFor="file"
              data-canceldrag="true"
              style={{ cursor: "pointer" }}
            >
              <Button
                component="span"
                data-canceldrag="true"
                color="primary"
                startIcon={<Search />}
              >
                Procurar
              </Button>
            </label>
          </div>
        </Collapse>
        <Collapse in={isDragging}>
          <div className={classes.centerBox}>
            <FileCopyIcon
              className={clsx(classes.bounceInDragging, classes.icons)}
            />
            <Typography className={classes.text}>
              Solte o seu arquivo para enviar
            </Typography>
          </div>
        </Collapse>
      </div>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </>
  );
};

export default DragInDropBox;
