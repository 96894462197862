import { DataColumn } from "@components/DataTable";
import Active from "@material-ui/icons/CheckCircleOutline";

export const ColumnReference: DataColumn[] = [
  {
    field: "store",
    headerName: "Estabelecimento",
  },
  {
    field: "driver",
    headerName: "Usuário",
  },
  {
    field: "plate",
    headerName: "Veículo",
  },
  {
    field: "mileage",
    headerName: "Hodômetro",
    render: (value) => {
      if (!value) {
        return "-";
      }
      return (
        new Intl.NumberFormat("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(parseFloat(value)) + " km"
      );
    },
  },
  {
    field: "product",
    headerName: "Produto",
  },
  {
    field: "quantity",
    headerName: "Quantidade",
    converter: "liter",
  },
  {
    field: "average_consumption",
    headerName: "Consumo",
  },
  {
    field: "value",
    headerName: "Valor",
    converter: "currency",
  },
  {
    field: "discount",
    headerName: "Desconto",
    converter: "currency",
  },
  {
    field: "total",
    headerName: "Total",
    converter: "currency",
  },
  {
    field: "completely_full",
    headerName: "Tanque Cheio",
    width: 96,
    render: (value) => {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {value ? <Active style={{ color: "#2E8b57" }} /> : "-"}
        </div>
      );
    },
  },
  {
    field: "date",
    width: 136,
    headerName: "Data e hora",
  },
];
