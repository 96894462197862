import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IDownloadReportNewUsersService } from "../models/IDownloadReportNewUsersService";

@injectable()
export class DownloadReportNewUsersService
  implements IDownloadReportNewUsersService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    companyID: string;
    page: number;
    limit: number;
  }): Promise<string> {
    const resp = await this.httpInstance.get(
      `/companies/${data.companyID}/report/card/download`,
      {
        params: {
          page: data.page,
          limit: data.limit,
        },
        responseType: "arraybuffer",
      }
    );

    const blob = new Blob([resp], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);
    return url;
  }
}
