import React from "react";
import { Form, useFormikContext } from "formik";
import { Row } from "@components/Row";
import { TextField } from "@material-ui/core";
import { Button } from "@components/Button";
import { ROUTES } from "@config/routesConfig";
import StyleSheet from "./../../List/StyleSheet";
import { Folder } from "../../../../client/folder/Folder";

const DepartmentEditForm: React.FC = () => {
  const styleSheet = StyleSheet();
  const { values, errors, setFieldValue } = useFormikContext<Folder>();
  return (
    <Form noValidate>
      <Row>
        <TextField
          id="description"
          name="description"
          style={{ width: "100%" }}
          classes={{ root: styleSheet.textField }}
          required
          label="Descrição"
          variant="outlined"
          onChange={(event) => setFieldValue("description", event.target.value)}
          value={values.description}
          inputProps={{ maxLength: 256 }}
          error={!!errors.description}
          helperText={errors.description}
          size="small"
        />
      </Row>
      <Row align="end">
        <Button to={ROUTES.DEPARTMENTS} color="secondary">
          Voltar
        </Button>
        <div style={{ width: 6 }} />
        <Button color="primary" type="submit">
          Salvar
        </Button>
      </Row>
    </Form>
  );
};

export default DepartmentEditForm;
