import {
  IListPermissibilityFleetDTO,
  IPermissibilityFleetDTO,
} from "@modules/company/dtos/IListPermissibilityFleetDTO";
import React, { createContext, useContext, useState } from "react";

interface IInvalidTransactionsContext {
  page: number;
  limit: number;
  transactions: IListPermissibilityFleetDTO;
  loadingTransactions: boolean;
  errorLoadingTransactions: boolean;
  openDialog: boolean;
  infoDialog: IPermissibilityFleetDTO | null;
  query: string;
  openDialogJustification: IOpenModalJustification;
  setLoadingTransactions: (data: boolean) => void;
  setTransactions: (data: IListPermissibilityFleetDTO) => void;
  setPage: (data: number) => void;
  setErrorLoadingTransactions: (data: boolean) => void;
  setOpenDialog: (data: boolean) => void;
  setInfoDialog: (data: IPermissibilityFleetDTO) => void;
  setLimit: (data: number) => void;
  setQuery: (data: string) => void;
  setOpenDialogJustification: (data: IOpenModalJustification) => void;
}

export interface IDataModalJustification {
  release_issuer_id: string;
  justification?: string;
  idTransaction: string;
}
export interface IOpenModalJustification {
  open: boolean;
  data: IDataModalJustification | null;
}

const InvalidTransactionsContext = createContext(
  {} as IInvalidTransactionsContext
);

const InvalidTransactionsProvider: React.FC = ({ children }) => {
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [
    openDialogJustification,
    setOpenDialogJustification,
  ] = useState<IOpenModalJustification>({ data: null, open: false });
  const [infoDialog, setInfoDialog] = useState<IPermissibilityFleetDTO | null>(
    null
  );
  const [errorLoadingTransactions, setErrorLoadingTransactions] = useState(
    false
  );
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(1);
  const [transactions, setTransactions] = useState<IListPermissibilityFleetDTO>(
    { page: 1, count: 0, result: [] }
  );
  const [query, setQuery] = useState("");

  return (
    <InvalidTransactionsContext.Provider
      value={{
        limit,
        page,
        transactions,
        loadingTransactions,
        errorLoadingTransactions,
        openDialog,
        infoDialog,
        query,
        openDialogJustification,
        setLoadingTransactions,
        setTransactions,
        setPage,
        setErrorLoadingTransactions,
        setOpenDialog,
        setInfoDialog,
        setLimit,
        setQuery,
        setOpenDialogJustification,
      }}
    >
      {children}
    </InvalidTransactionsContext.Provider>
  );
};

const useInvalidTransactions = () => {
  const context = useContext(InvalidTransactionsContext);
  if (Object.values(context).length === 0) {
    throw new Error(
      "useInvalidTransactions não pode ser utilizado fora de um InvalidTransactionsProvider"
    );
  }
  return context;
};

export { useInvalidTransactions, InvalidTransactionsProvider };
