import { useIoCContext } from "@hooks/IoCContext";
import { useUserContext } from "@hooks/UserContext";
import { Types } from "@ioc/types";
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import WarningIcon from "@material-ui/icons/Warning";
import { IRemoveUserService } from "@modules/user/models/IRemoveUserService";
import AppError from "@utils/AppError";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import { useUsersPage } from "./UsersPageContext";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      dialogPaper: {
        minWidth: "95vw",
        [theme.breakpoints.up("sm")]: {
          minWidth: "25vw",
        },
      },
      warningIcon: {
        fontSize: "8rem",
        color: theme.palette.tertiary.error.primary,
      },
      title: {
        fontSize: "2.4rem",
        fontWeight: typography.fontWeightBold,
        marginBottom: "1.4rem",
      },
      warningText: {
        color: theme.palette.tertiary.textButtons.primary,
        marginTop: "1.6rem",
      },
      buttons: {
        fontWeight: typography.fontWeightBold,
      },
      cancelButton: {
        color: theme.palette.tertiary.textButtons.primary,
      },
      removeButton: {
        color: theme.palette.tertiary.error.primary,
      },
      loading: {
        height: "25px !important",
        width: "25px !important",
        color: theme.palette.tertiary.error.primary,
      },
    })
);

const ModalRemoveUser: React.FC = () => {
  const classes = useStyles();
  const usersPageContext = useUsersPage();
  const userContext = useUserContext();
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();

  const removeUserService = iocContext.serviceContainer.get<IRemoveUserService>(
    Types.User.IRemoveUserService
  );

  const [loading, setLoading] = useState(false);

  const removeUser = useCallback(async () => {
    if (!userContext.selectedCompany) return;
    if (!usersPageContext.openModalRemoveUser.data) return;
    try {
      setLoading(true);
      await removeUserService.execute({
        companyID: userContext.selectedCompany.uuid,
        userID: usersPageContext.openModalRemoveUser.data.id,
      });
      usersPageContext.setOpenModalRemoveUser({ data: null, open: false });
    } catch (error) {
      if (error instanceof AppError) {
        return enqueueSnackbar(error.message, { variant: error.variant });
      }
      enqueueSnackbar("Ocorreu um erro ao remover usuário", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [
    enqueueSnackbar,
    removeUserService,
    userContext.selectedCompany,
    usersPageContext,
  ]);

  return (
    <Dialog
      open={usersPageContext.openModalRemoveUser.open}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent style={{ position: "relative" }}>
        <IconButton
          onClick={() =>
            usersPageContext.setOpenModalRemoveUser({
              open: false,
              data: null,
            })
          }
          style={{ position: "absolute", right: "1rem", top: "0.5rem" }}
        >
          <Close />
        </IconButton>
        <Grid container alignItems="center" direction="column">
          <WarningIcon className={classes.warningIcon} />
          <Typography className={classes.title}>Remover usuário?</Typography>
        </Grid>
        <Typography align="center">
          Tem certeza que deseja remover o usuário&nbsp;
          <strong>
            {usersPageContext.openModalRemoveUser.data?.tx_usuario}
          </strong>
          &nbsp;dos usuários cadastrados?
        </Typography>
        <Typography align="center" className={classes.warningText}>
          Essa ação não pode ser desfeita.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Grid container justify="center">
          <Button
            className={clsx(classes.buttons, classes.cancelButton)}
            onClick={() =>
              usersPageContext.setOpenModalRemoveUser({
                open: false,
                data: null,
              })
            }
          >
            Cancelar
          </Button>
          <Button
            className={clsx(classes.buttons, classes.removeButton)}
            startIcon={
              loading && <CircularProgress className={classes.loading} />
            }
            onClick={() => !loading && removeUser()}
          >
            Remover
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export { ModalRemoveUser };
