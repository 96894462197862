// @ts-ignore
import linkXLSXTemplate from "@assets/recarga_template.xlsx";
import DragInDropBox from "@components/DragInDropBox";
import { useIoCContext } from "@hooks/IoCContext";
import { useUserContext } from "@hooks/UserContext";
import { Types } from "@ioc/types";
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Link,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { DatePicker } from "@material-ui/pickers";
import { ICreateRechargeService } from "@modules/company/models/ICreateRechargeService";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import xlsx from "xlsx";
import excelImg from "../../assets/icon-excel.png";
import { FormUploadRechargeSchemeValidation } from "./FormUploadRechargeSchemeValidation";
import { IFormUploadRecharges } from "./IFormUploadRecharges";
import { useRecharge } from "./RechargesContext";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      dialogPaper: {
        minWidth: "95vw",
        [theme.breakpoints.up("sm")]: {
          minWidth: "50vw",
        },
      },
      dialogTitle: {
        fontWeight: typography.fontWeightBold,
        fontSize: pxToRem(16),
      },
      subtitleDialog: {
        color: theme.palette.tertiary.textButtons.primary,
        fontSize: pxToRem(14),
        textAlign: "center",
        [theme.breakpoints.up("xs")]: {
          textAlign: "left",
        },
      },
      divider: {
        backgroundColor: theme.palette.tertiary.background.primary,
      },
      sendFileText: {
        padding: "1rem 0",
        color: theme.palette.tertiary.textButtons.primary,
        fontWeight: typography.fontWeightBold,
        fontSize: pxToRem(12),
      },
      buttons: {
        fontWeight: typography.fontWeightBold,
      },
      cancelButton: {
        color: theme.palette.tertiary.textButtons.primary,
      },
      buttonDownload: {
        "&:hover": {
          textDecoration: "none",
        },
      },
    })
);

const ModalUploadRecharge: React.FC = () => {
  const classes = useStyles();
  const rechargesContext = useRecharge();
  const userContext = useUserContext();
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();

  const rechargeService = iocContext.serviceContainer.get<ICreateRechargeService>(
    Types.Company.ICreateRechargeService
  );

  const initialValues: IFormUploadRecharges = {
    description: "",
    date: new Date(),
    file: null,
  };

  const getXLSXData = async (
    file: File
  ): Promise<
    {
      document: string;
      quantity: number;
      date: Date | null;
    }[]
  > => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = (event) => {
        try {
          // @ts-ignore
          const file = xlsx.read(event.target?.result, {
            type: "binary",
          });
          let rows: {
            document: string;
            quantity: number;
            date: Date | null;
          }[] = [];

          const json = xlsx.utils.sheet_to_json(
            file.Sheets[file.SheetNames[0]]
          );
          rows = json.map((row) => {
            return {
              // @ts-ignore
              date: row["Data de Efetivação"]
                ? new Date(
                    // @ts-ignore
                    (row["Data de Efetivação"] +
                      Math.floor(Date.now() / 1000)) *
                      1000
                  )
                : null,
              // @ts-ignore
              document: String(row["Documento *"]),
              // @ts-ignore
              quantity: row["Quantidade (Litros/Dinheiro) *"],
            };
          });

          resolve(rows);
        } catch (error) {
          reject(error);
        }
      };
    });
  };

  return (
    <Dialog
      open={rechargesContext.openModalUploadRecharges}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle disableTypography>
        <Grid container>
          <Grid item xs={11} container alignItems="center">
            <Typography className={classes.dialogTitle}>
              Nova recarga manual
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              onClick={() =>
                rechargesContext.setOpenModalUploadRecharges(false)
              }
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.subtitleDialog}>
              Faça upload da planilha modelo preenchida para recarregar os
              cartões dos seus usuários.
            </Typography>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
      </DialogTitle>
      <DialogContent>
        <Grid container justify="center">
          <Button
            color="primary"
            startIcon={<img src={excelImg} alt="ícone excel" />}
            component={Link}
            href={linkXLSXTemplate}
            className={classes.buttonDownload}
          >
            Faça download da planilha modelo
          </Button>
        </Grid>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            if (!values.file) return;

            try {
              const rows = await getXLSXData(values.file);
              if (!userContext.selectedCompany) return;
              if (!userContext.companyInfo) return;

              await rechargeService.execute({
                companyID: userContext.selectedCompany.uuid,
                name: values.description,
                recharge_date: values.date,
                recharge_type: userContext.companyInfo.in_money
                  ? "in_money"
                  : "in_liter",
                transactions: rows.map((row) => {
                  return {
                    document: row.document,
                    quantity: row.quantity,
                  };
                }),
              });

              rechargesContext.setOpenModalUploadRecharges(false);
              enqueueSnackbar("Recargas enviadas com sucesso.", {
                variant: "success",
              });
            } catch (error) {
              enqueueSnackbar("Ocorreu um erro ao ler arquivo.", {
                variant: "error",
              });
            }
          }}
          validationSchema={FormUploadRechargeSchemeValidation}
        >
          {(props) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      label="Descrição"
                      name="description"
                      placeholder="Informe uma descrição para esta recarga"
                      fullWidth
                      error={
                        !!props.touched.description &&
                        !!props.errors.description
                      }
                      helperText={
                        props.touched.description && props.errors.description
                      }
                      as={TextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DatePicker
                      autoOk
                      clearLabel="Apagar"
                      fullWidth
                      label="Data da recarga"
                      clearable
                      cancelLabel="Cancelar"
                      disablePast
                      onChange={(date) => props.setFieldValue("date", date)}
                      format="dd/MM/yyyy"
                      value={props.values.date}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.sendFileText}>
                      Enviar o arquivo
                    </Typography>
                    <DragInDropBox
                      error={!!props.errors.file}
                      helperText="Apenas arquivos .xlsx são permitidos"
                      value={props.values.file}
                      touched={Boolean(props.touched.file)}
                      onChange={(file) => props.setFieldValue("file", file)}
                      onTouch={() => props.setFieldTouched("file", true)}
                    />
                    <Grid container justify="center">
                      <Button
                        onClick={() =>
                          rechargesContext.setOpenModalUploadRecharges(false)
                        }
                        className={clsx(classes.buttons, classes.cancelButton)}
                      >
                        Cancelar
                      </Button>
                      <Button
                        type="submit"
                        className={classes.buttons}
                        color="primary"
                        startIcon={
                          props.isSubmitting && <CircularProgress size={22} />
                        }
                      >
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export { ModalUploadRecharge };
